import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { COLORS } from '@styles/colors'

interface BottomNavProps {
  children?: ReactNode
  background?: string
}

const BottomNav: FC<BottomNavProps> = ({
  children = [],
  background = COLORS.gray[70],
}) => {
  const styles = css({
    background,
    width: '100%',
    display: 'flex',
  })

  return <nav css={styles}>{children}</nav>
}

export { BottomNav }
