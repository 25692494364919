/* eslint-disable max-lines */
// TODO: Ta bort detta när datan ersätts med GQL.
import React, { Fragment, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@components/header'
import { SaveAlt } from '@material-ui/icons'
import { EkDatePicker } from '@components/ek-datepicker/ek-datepicker'
import { Subtitle2 } from '@components/base-extended'
import { TextField } from '@pure-components/input'
import { Chip } from '@pure-components/data-display'
import { COLORS } from '@styles'
import { EkList, EkListCell, EkListRow } from '@components/ek-list'
import { Divider } from '@pure-components/graphics'
import { EkSelect } from '@components/ek-select'
import {
  StorageSpaceTransaction,
  StorageSpaceTransactionInput,
  StorageSpaceTransactionPage,
} from '@types'
import {
  CREATE_STORAGE_SPACE_TRANSACTION,
  GET_STORAGE_SPACE_TRANSACTIONS,
} from '@api/storage-space-transaction'
import { withMutation } from '@components/with-mutation'
import { withQuery } from '@components/with-query'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'

const reportData = [
  {
    name: 'Artikelnamn',
    number: 'ABC123',
    diff: -4,
  },
  {
    name: 'Artikelnamn',
    number: 'EF453',
    diff: 8,
  },
  {
    name: 'Artikelnamn',
    number: 'GRE98',
    diff: -5,
  },
]

const itemData = [
  {
    id: '1',
    value: 'Första värdet',
    name: 'Första värdet',
  },
  {
    id: '2',
    value: 'Andra värdet',
    name: 'Andra värdet',
  },
  {
    id: '3',
    value: 'Tredje värdet',
    name: 'Tredje värdet',
  },
]

const _Reports = (_: RouteComponentProps) => {
  const styles = css({
    display: 'grid',
    rowGap: '12px',
    padding: 24,
    '.datepickers': {
      display: 'grid',
      gap: 24,
      gridTemplateColumns: '1fr 1fr',
    },
  })

  const tagStyles = css({
    display: 'flex',
    gap: 12,
    overflowX: 'scroll',
    padding: '0 0 12px 0',

    '> *:first-of-type': {
      marginLeft: 24,
    },
  })

  const [open, setOpen] = useState(false)
  const toggle = (open: boolean) => {
    setOpen(!open)
  }
  const [startDate, setStartDate] = useState(new Date().toString())
  const [endDate, setEndDate] = useState(new Date().toString())

  const handleStartDate = (ev: any) => setStartDate(ev.target.value)
  const handleEndDate = (ev: any) => setEndDate(ev.target.value)

  return (
    <>
      <SimpleHeader text="Rapporter">
        <SaveAlt />
      </SimpleHeader>
      <LayoutStandard>
        <section css={styles}>
          <Subtitle2>Datum</Subtitle2>
          <div className="datepickers">
            <EkDatePicker
              value={startDate || '2020-01-01'}
              onChange={handleStartDate}
            />
            <EkDatePicker
              value={endDate || '2020-01-01'}
              onChange={handleEndDate}
            />
          </div>
          <Subtitle2>Orsak</Subtitle2>
          <EkSelect
            title="Välj en orsak"
            items={itemData}
            multiSelect={false}
            onToggle={() => toggle(open)}
            open={open}
          />
          <Subtitle2>Taggar</Subtitle2>
          <TextField />
        </section>
        <section css={tagStyles}>
          <Chip background={COLORS.orange[10]} text="Elektronik" />
          <Chip background={COLORS.orange[10]} text="Elektronik" />
          <Chip background={COLORS.orange[10]} text="Elektronik" />
          <Chip background={COLORS.orange[10]} text="Elektronik" />
          <Chip background={COLORS.orange[10]} text="Elektronik" />
          <Chip background={COLORS.orange[10]} text="Elektronik" />
          <Chip background={COLORS.orange[10]} text="Elektronik" />
          <Chip background={COLORS.orange[10]} text="Elektronik" />
        </section>
        <section>
          <EkList>
            <EkListRow>
              <EkListCell variant="header">Artikel</EkListCell>
              <EkListCell variant="header">Artikelnummer</EkListCell>
              <EkListCell variant="header" width="24px">
                Diff
              </EkListCell>
            </EkListRow>
            {reportData.map(({ name, number, diff }, i) => (
              <Fragment key={`article${number}`}>
                <EkListRow>
                  <EkListCell>{name}</EkListCell>
                  <EkListCell>{number}</EkListCell>
                  <EkListCell width="24px" justify="end">
                    {diff}
                  </EkListCell>
                </EkListRow>
                {i < reportData.length - 1 && <Divider />}
              </Fragment>
            ))}
          </EkList>
        </section>
      </LayoutStandard>
    </>
  )
}

const Reports = withQuery<StorageSpaceTransactionPage>({
  query: GET_STORAGE_SPACE_TRANSACTIONS,
  loader: EkLoader,
})(
  withMutation<
    StorageSpaceTransaction[],
    StorageSpaceTransactionInput
  >(CREATE_STORAGE_SPACE_TRANSACTION)(_Reports)
)

export { Reports }
