/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const PERFORM_MOVE_TRANSACTION_MULTIPLE = gql`
  mutation PerformMoveTransactionMultiple(
    $fromInventories: [InventoryEntry!]
    $toStorage: ID!
    $comment: String
  ) {
    performMoveTransactionMultiple(
      fromInventories: $fromInventories
      toStorage: $toStorage
      comment: $comment
    ) {
      _id

      item {
        _id

        number
        name
        description
        tags {
          data {
            _id
            name
          }
          before
          after
        }
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
      }
      quantity
      fromStorageSpace {
        _id

        name
        description
        type
        parent
      }
      toStorageSpace {
        _id

        name
        description
        type
        parent
      }
      toOrder {
        _id

        description
        orderNumber
        sdfId
        createdDate
        serviceDate
        customerPhone
        projectNumber
        projectName
        serviceAddress
        invoiceAddress
        status
      }
      user {
        _id

        firstName
        lastName
        role
      }
      type
      comment
    }
  }
`

const PERFORM_MOVE_TRANSACTION_ORDER_TO_ORDER_MULTIPLE = gql`
  mutation PerformMoveTransactionOrderToOrderMultiple(
    $fromOrderItems: [InventoryEntry!]
    $toOrder: ID!
    $comment: String
  ) {
    performMoveTransactionOrderToOrderMultiple(
      fromOrderItems: $fromOrderItems
      toOrder: $toOrder
      comment: $comment
    ) {
      _id

      item {
        _id

        number
        name
        description
        tags {
          data {
            _id
            name
          }
          before
          after
        }
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
      }
      quantity
      fromStorageSpace {
        _id

        name
        description
        type
        parent
      }
      toStorageSpace {
        _id

        name
        description
        type
        parent
      }
      toOrder {
        _id

        description
        orderNumber
        sdfId
        createdDate
        serviceDate
        customerPhone
        projectNumber
        projectName
        serviceAddress
        invoiceAddress
        status
      }
      user {
        _id

        firstName
        lastName
        role
      }
      type
      comment
    }
  }
`

const PERFORM_MOVE_TRANSACTION_ORDER_TO_STORAGE_MULTIPLE = gql`
  mutation PerformMoveTransactionOrderToStorageMultiple(
    $fromOrderItems: [InventoryEntry!]
    $toStorage: ID!
    $comment: String
  ) {
    performMoveTransactionOrderToStorageMultiple(
      fromOrderItems: $fromOrderItems
      toStorage: $toStorage
      comment: $comment
    ) {
      _id

      item {
        _id

        number
        name
        description
        tags {
          data {
            _id
            name
          }
          before
          after
        }
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
      }
      quantity
      fromStorageSpace {
        _id

        name
        description
        type
        parent
      }
      toStorageSpace {
        _id

        name
        description
        type
        parent
      }
      toOrder {
        _id

        description
        orderNumber
        sdfId
        createdDate
        serviceDate
        customerPhone
        projectNumber
        projectName
        serviceAddress
        invoiceAddress
        status
      }
      user {
        _id

        firstName
        lastName
        role
      }
      type
      comment
    }
  }
`

export {
  PERFORM_MOVE_TRANSACTION_MULTIPLE,
  PERFORM_MOVE_TRANSACTION_ORDER_TO_ORDER_MULTIPLE,
  PERFORM_MOVE_TRANSACTION_ORDER_TO_STORAGE_MULTIPLE,
}
