/* eslint-disable max-lines */
import { css } from '@emotion/react'
import React, { FC, useState } from 'react'
import { StorageSpace } from '@types'
import { EkListRow } from './ek-list-row'
import { EkListCell } from './ek-list-cell'
import { Add, Remove } from '@material-ui/icons'

type ExtendedStorage = StorageSpace & {
  children: ExtendedStorage[]
}

interface StorageListRowNestedProps {
  item?: ExtendedStorage
  subitems: ExtendedStorage[]
  onClick?: (storage: ExtendedStorage) => void
  selectedId?: string
  depth?: number
}

const StorageListRowNested: FC<StorageListRowNestedProps> = ({
  subitems = [],
  onClick = () => {},
  item = undefined,
  selectedId = undefined,
  depth = 0,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnClick = (storage: ExtendedStorage) => {
    onClick(storage)
  }

  const styles = css({
    '.nestedRow': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px solid rgba(150,150,150,0.7)',
      backgroundColor: `rgba(100,100,100,${depth * 0.05})`,
    },
    '.flex-fix': {
      flex: 0.7,
    },
    '.expander': {
      marginLeft: '1rem',
      paddingRight: '0.4rem',
      display: 'none',
    },
    '.open': {
      display: 'block',
    },
    '.expandButton': {
      cursor: 'pointer',
    },
  })

  return (
    <>
      {item && (
        <div css={styles}>
          <div className="nestedRow">
            <EkListRow
              key={`${depth}_${item._id}`}
              classes={
                selectedId === item._id
                  ? 'selected flex-fix'
                  : 'flex-fix'
              }
              onClick={(e) => handleOnClick(item)}
            >
              <EkListCell>
                <p className="storageName">{item.name}</p>
              </EkListCell>
            </EkListRow>
            <div
              className="expandButton"
              role="button"
              tabIndex={-1}
              onClick={() => {
                setIsOpen(!isOpen)
              }}
              onKeyDown={() => {
                setIsOpen(!isOpen)
              }}
            >
              {!isOpen && item.children.length > 0 && <Add />}
              {isOpen && item.children.length > 0 && <Remove />}
            </div>
          </div>
          <div className={'expander ' + (isOpen ? 'open' : '')}>
            {item.children.map((child: ExtendedStorage, i) => (
              <StorageListRowNested
                key={`${depth}_${child._id}`}
                item={child}
                subitems={child.children}
                onClick={(s) => onClick(s)}
                selectedId={selectedId}
                depth={depth + 1}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export { StorageListRowNested }
export type { ExtendedStorage }
