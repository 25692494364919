import { css } from '@emotion/react'

const standardActive = css({
  transition: 'transform 200ms ease',
  '&:active': {
    transform: 'scale(0.96) translate3d( 0, 0, 0) perspective(1px)',
  },
})

export { standardActive }
