/* eslint-disable max-lines */
// TODO: Radera detta när GraphQL implementerats, filen blir för lång med spökdatan vilket är varför disable är tillagt.
import React, { FC, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { SimpleHeader } from '@components/header'

import { useQueryParam } from '@hooks'

import { Button, ButtonSize, TextField } from '@pure-components/input'
import { COLORS } from '@styles'

import { withQuery, WithQueryExtension } from '@components/with-query'
import { ItemPage, Item } from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { LayoutStandard } from '@layouts'

import { AppRoutes } from '@router'
import { EkList, EkListCell, EkListRow } from '@components/ek-list'
import { GET_ITEMS } from '@api/item'
import { cancellableArticleSearch } from '@common/search/search-items'
import { Chip } from '@pure-components/data-display'

interface WorkOrderProps extends RouteComponentProps {
  id?: string
}

const _SearchArticle: FC<
  WorkOrderProps & WithQueryExtension<ItemPage>
> = ({ queryData, refetch, id }) => {
  const {
    items: { data = [] },
  } = queryData

  data.sort((a, b) => parseInt(a.number) - parseInt(b.number))

  const [activeSearch, setActiveSearch] = useState<any>(null)
  const [resultList, setResultList] = useState<Item[]>(data)

  const [searchFilter, setSearchFilter] = useState('')

  /*const { setChildren, setIsOpen } = useBottomSheet()

  const bottomSheetStyles = css({
    display: 'grid',
    gap: '1em',
    marginTop: '2em',
  })*/

  /*const removeDuplicates = (items: Item[], refs: Item[]) => {
    const ids: string[] = []
    const results: Item[] = []

    if (items.length > 0 && refs.length > 0) {
      for (const item of [...items, ...refs]) {
        if (!ids.includes(item.sdfId)) {
          ids.push(item.sdfId)
          results.push(item)
        }
      }
    } else {
      return [...items, ...refs]
    }
    return results
  }*/

  const removeDuplicates = (items: Item[], refs: Item[]): Item[] => {
    const results: Item[] = [...refs]

    for (const item of items) {
      const duplicateCheck = (a: Item, b: Item) =>
        item.sdfId !== '' && item.sdfId !== 'PLACEHOLDER'
          ? a.sdfId === b.sdfId
          : a.number === b.number && a.name === b.name

      const collision = results.find((r) => duplicateCheck(r, item))

      if (collision) {
        results[results.indexOf(collision)] = item
      } else {
        results.unshift(item)
      }
    }

    return results
  }

  const searchArticles = async (query: string) => {
    //console.log('performing search for ', query)
    const search = cancellableArticleSearch(query)
    setActiveSearch(search)

    const items = await search.items
    const refs = await search.refs
    //console.log('items', items)
    //console.log('refs', refs)
    const result = removeDuplicates(items, refs)
    setResultList(result)
    setActiveSearch(null)
  }

  const handleSearchChange = async (event: any) => {
    setSearchFilter(event.target.value)
  }

  const handleSearchButton = async (event: any) => {
    //console.log('handleSearchButton')
    if (activeSearch !== null) {
      console.log('aborting active search')
      activeSearch.abort()
    }

    //await searchArticles(event.target.value)

    if (searchFilter.length >= 1) {
      await searchArticles(searchFilter)
    } else {
      //setItemList(data)
      setActiveSearch(null)
    }
  }

  const handleKeyPress = async (
    event: React.KeyboardEvent<Element>
  ) => {
    if (event.key === 'Enter') {
      await handleSearchButton(event)
    }
  }

  const view = useQueryParam('view')

  const onRowClick = async (clickedArticle: Item) => {
    if (clickedArticle._id === '') {
      navigate(
        `${AppRoutes.Articles}/sdf?sdfid=${clickedArticle.sdfId}`
      )
    } else {
      navigate(`${AppRoutes.Articles}/${clickedArticle._id}`)
    }
  }

  const searchFieldContainerStyles = css({
    height: '70px',
    width: '100%',
    overflow: 'hidden !important',
    background: COLORS.gray[0],
  })

  const searchFieldStyles = css({
    padding: '12px 12px',
    width: '100%',
    background: COLORS.gray[0],
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr',
    gridTemplateAreas: '"input button"',
    columnGap: '0.7rem',
    alignItems: 'baseline',
    '.searchinput': {
      gridArea: 'input',
    },
    '.searchbutton': {
      gridArea: 'button',
      width: '60px',
    },
  })

  const styles = css({
    display: 'grid',
    color: COLORS.gray[90],
    padding: view === 'card' ? '24px' : '0',
    gap: '1em',
    height: resultList.length < 8 ? '100vh' : 'auto',
    '.storage-info': {
      padding: '0 15px 10px 15px',
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: 'auto 1fr',
      marginTop: '10px',
      borderBottom: '1px lightgray solid',
      '.icon': {
        display: 'grid',
        minHeight: 24,
        placeContent: 'center center',
        fontSize: '24px',
      },
      '.description': {
        paddingTop: '6px',
      },
    },
    '.button-wrapper-selected-article': {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: '0.5em',
      padding: '1rem 1.5rem 0 1.5rem',
      borderTop: '1px lightgray solid',
      '.article-info': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '.button-wrapper-reset': {
      display: 'flex',
      gap: '0.5em',
      padding: '0 24px',
      '.article-info': {},
    },
    '.tags': {
      display: 'flex',
      gap: 8,
      padding: '0 0 12px 0',
      marginTop: 5,
      flexWrap: 'wrap',
      alignContent: 'flex-start',
    },
    '.tags button': {
      backgroundColor: COLORS.gray[40],
    },
    '.supplierIndicator': {
      fontStyle: 'italic',
    },
  })

  // @ts-ignore
  return (
    <>
      <SimpleHeader text={`Sök Artikel`}>
        {/*
        <MoveToInbox onClick={() => openMoveToStorageModal()} />
        <Search onClick={() => openMoveToOrderModal()} />
      */}
      </SimpleHeader>
      <div css={searchFieldContainerStyles}>
        <section css={searchFieldStyles}>
          <div className="searchinput">
            <TextField
              placeholder="Sök på namn, artikelnummer, tag"
              inputType="search"
              value={searchFilter}
              onChange={(e) => handleSearchChange(e)}
              onKeyUp={(e) => handleKeyPress(e)}
            />
          </div>
          <div className="searchbutton">
            <Button
              disabled={
                activeSearch !== null || searchFilter.length < 1
              }
              size={ButtonSize.Small}
              onClick={handleSearchButton}
            >
              Sök
            </Button>
          </div>
        </section>
      </div>
      <LayoutStandard scroll={true}>
        <section css={styles}>
          <>
            {activeSearch !== null ? (
              <EkLoader />
            ) : (
              <EkList>
                {resultList.map((item: Item, i) => (
                  <EkListRow
                    key={item._id}
                    onClick={(e) => onRowClick(item)}
                  >
                    <EkListCell>
                      <p className="itemName">{item.name}</p>
                      <p className="itemNumber">{item.number}</p>
                      <p className="supplierIndicator">
                        {item.supplier}
                      </p>
                      <div className="tags">
                        {item.tags.data &&
                          item.tags.data.length > 0 &&
                          item.tags.data!.map((tag) => (
                            <Chip
                              key={tag._id}
                              background={COLORS.orange[10]}
                              text={tag.name}
                            />
                          ))}
                      </div>
                    </EkListCell>
                  </EkListRow>
                ))}
              </EkList>
            )}
          </>
        </section>
      </LayoutStandard>
    </>
  )
}

const SearchArticle = withQuery<ItemPage>({
  query: GET_ITEMS,
  variables: { _size: 500 },
  loader: EkLoader,
})(_SearchArticle)

export { SearchArticle }
