/* eslint-disable max-lines */
// TODO: Ta bort denna när datan ersätts med GQL
import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { useBottomNav } from '@hooks'
import { NavItem } from '@context'
import { StoragesListItem } from '@components/pages-components/storage'
import { H6 } from '@components/base'
import { getIconByVariant } from '@components/pages-components/storage/common/get-icon-by-variant'
import {
  StorageSpaceInput,
  StorageSpacePage,
  STORAGE_SPACE_TYPE,
} from '@src/types'
import { Divider } from '@pure-components/graphics'
import { withQuery, WithQueryExtension } from '@components/with-query'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import {
  CREATE_STORAGE_SPACE,
  GET_STORAGE_SPACES,
} from '@api/storage-space'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { Pagination } from '@components/pagination'

interface FindStorageProps extends RouteComponentProps {
  saveFunction?: (
    _id: string,
    name: string,
    type: STORAGE_SPACE_TYPE
  ) => void
}

const _FindStorage: FC<
  FindStorageProps &
    WithMutationExtension<Storage[], StorageSpaceInput> &
    WithQueryExtension<StorageSpacePage>
> = ({
  queryData,
  addData,
  previousPage,
  nextPage,
  saveFunction,
}) => {
  const {
    storageSpaces: { data = [], after, before },
  } = queryData

  const { setActiveItem } = useBottomNav()

  useEffect(() => {
    setActiveItem(NavItem.Storages)
  })

  const styles = css({
    padding: 0,
    '.subtitle': {
      margin: '0 24px',
      marginBottom: 12,
    },
    '.buttons': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      margin: 12,
      gap: 12,
    },
  })

  return (
    <>
      <div css={styles}>
        <H6 className="subtitle">Välj förvaringsplats</H6>
        {data.map(({ _id, name, type }, i) => (
          <React.Fragment key={`item${name}`}>
            <StoragesListItem
              label={name}
              icon={getIconByVariant(type)}
              onClick={() => saveFunction?.(_id, name!, type)}
            />
            {i < data.length - 1 && <Divider key={`divider${_id}`} />}
          </React.Fragment>
        ))}
        <Pagination
          before={before}
          after={after}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </div>
    </>
  )
}

const FindStorage = withQuery<StorageSpacePage>({
  query: GET_STORAGE_SPACES,
  loader: EkLoader,
})(
  withMutation<Storage[], StorageSpaceInput>(CREATE_STORAGE_SPACE)(
    _FindStorage
  )
)

export { FindStorage }
