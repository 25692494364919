import {
  ApolloClient,
  NormalizedCacheObject,
  QueryOptions,
} from '@apollo/client'
import { getApolloClient } from '@api/client'
import { SEARCH_ORDERS } from '@api/order'
import { Order, OrderPage, ORDER_STATUS } from '@types'

const getQueryOptions = (
  orderStatus: ORDER_STATUS,
  searchQuery: string
) => {
  const query = SEARCH_ORDERS
  return {
    query,
    variables: {
      query: searchQuery,
      status: orderStatus,
    },
  }
}

const executeSearchQuery = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  abortController: AbortController
) => async (
  queryName: string,
  searchQuery: string,
  status: ORDER_STATUS
): Promise<OrderPage> => {
  const options: QueryOptions = getQueryOptions(status, searchQuery)
  options.context = {
    fetchOptions: { signal: abortController.signal },
  }
  const result = await apolloClient.query(options)
  //console.log(queryName, result)
  if (result?.data) {
    return result.data[queryName]
  } else {
    const e: any = new Error('graphql error')
    e.body = result
    throw e
  }
}

const searchOrders = async (
  search: string,
  status: ORDER_STATUS,
  abortController: AbortController,
  client: ApolloClient<NormalizedCacheObject>
): Promise<Order[]> => {
  const runQuery = executeSearchQuery(client, abortController)

  const page = await runQuery('searchOrders', search, status)

  return page?.data || []
}

const cancellableOrderSearch = (
  search: string,
  orderStatus: ORDER_STATUS
) => {
  const client = getApolloClient(process.env)
  const controller = new window.AbortController()
  return {
    abort: () => {
      console.log('aborting')
      controller.abort()
    },
    orders: searchOrders(search, orderStatus, controller, client),
  }
}

export { searchOrders, cancellableOrderSearch }
