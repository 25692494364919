/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const CREATE_STORAGE_SPACE_TRANSACTION = gql`
  mutation CreateStorageSpaceTransaction(
    $item: StorageSpaceTransactionItemRelation!
    $quantity: Float!
    $balanceBeforeTransactionA: Float
    $balanceAfterTransactionA: Float
    $balanceBeforeTransactionB: Float
    $balanceAfterTransactionB: Float
    $fromStorageSpace: StorageSpaceTransactionFromStorageSpaceRelation
    $toStorageSpace: StorageSpaceTransactionToStorageSpaceRelation
    $toOrder: StorageSpaceTransactionToOrderRelation
    $fromOrder: StorageSpaceTransactionFromOrderRelation
    $user: StorageSpaceTransactionUserRelation!
    $type: STORAGE_SPACE_TRANSACTION_TYPE!
    $comment: String
  ) {
    createStorageSpaceTransaction(
      data: {
        item: $item
        quantity: $quantity
        balanceBeforeTransactionA: $balanceBeforeTransactionA
        balanceAfterTransactionA: $balanceAfterTransactionA
        balanceBeforeTransactionB: $balanceBeforeTransactionB
        balanceAfterTransactionB: $balanceAfterTransactionB
        fromStorageSpace: $fromStorageSpace
        toStorageSpace: $toStorageSpace
        toOrder: $toOrder
        fromOrder: $fromOrder
        user: $user
        type: $type
        comment: $comment
      }
    ) {
      _id
      item {
        _id
        number
        sdfId
        name
        description
        tags {
          data {
            _id
            name
          }
          before
          after
        }
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
      }
      quantity
      fromStorageSpace {
        _id
        name
        description
        type
        parent
      }
      toStorageSpace {
        _id
        name
        description
        type
        parent
      }
      toOrder {
        _id
        description
        orderNumber
        sdfId
        createdDate
        serviceDate
        customerPhone
        projectNumber
        projectName
        serviceAddress
        invoiceAddress
        status
      }
      user {
        _id
        firstName
        lastName
        role
      }
      type
      comment
    }
  }
`

export { CREATE_STORAGE_SPACE_TRANSACTION }
