import React, { FC, ReactNode } from 'react'
import { BottomNavItem } from '@pure-components/navigation/bottom-nav'
import {
  buttonStyleOverrides,
  iconStyle,
  cameraStyle,
} from './styles'
import { iconSizeInPx, verticalPadding } from './constants'
import { NavIcon } from './icons'

interface EkBottomNavItemProps {
  onClick: (navIcon: NavIcon) => void
  navIcon: NavIcon
  isActive: boolean
  children: ReactNode
}

const EkBottomNavItem: FC<EkBottomNavItemProps> = ({
  onClick,
  isActive,
  navIcon,
  children,
}) => {
  const idsOfOrangeBackground = [NavIcon.PhotoCamera]

  const getIconStyle = (navIcon: NavIcon) =>
    idsOfOrangeBackground.includes(navIcon)
      ? cameraStyle(iconSizeInPx)
      : iconStyle(iconSizeInPx)

  return (
    <BottomNavItem
      key={navIcon}
      style={buttonStyleOverrides}
      onClick={() => onClick(navIcon)}
      padding={`${verticalPadding}px 0`}
    >
      <div
        css={getIconStyle(navIcon)}
        className={isActive ? 'selected' : ''}
      >
        {children}
      </div>
    </BottomNavItem>
  )
}

export { EkBottomNavItem }
