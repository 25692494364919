import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { InsertPhoto } from '@material-ui/icons'
import { COLORS, TEXT_STYLE } from '@styles'
import { Button } from '@pure-components/input'

interface StoragesListItemProps {
  icon?: ReactNode
  label?: string
  checked?: boolean
  onClick?: () => void
}

const StoragesListItem: FC<StoragesListItemProps> = ({
  label = 'label',
  icon = <InsertPhoto />,
  checked = false,
  onClick,
}) => {
  const styling = css({
    color: COLORS.gray[90],
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '24px auto 24px',
    gap: 5,
    padding: '0 24px',
    '.icon': {
      display: 'grid',
      minHeight: 24,
      placeContent: 'center center',
      fontSize: '24px',
    },
    '.label': [
      TEXT_STYLE.list[1],
      {
        textAlign: 'left',
        padding: 6,
        minHeight: 24,
        alignSelf: 'center',
      },
    ],
    '.checkbox': {
      display: 'grid',
      placeContent: 'start end',
      padding: 6,
    },
  })
  return (
    <article css={styling}>
      <div className="icon">{icon}</div>
      <p className="label">{label}</p>
      <div className="checkbox">
        <Button onClick={onClick}>Välj</Button>
      </div>
    </article>
  )
}

export { StoragesListItem }
