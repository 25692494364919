/* eslint-disable max-lines */
// TODO: Refactor this so the file is sub 100 lines

import React, { FC, useState } from 'react'
import {
  navigate,
  RouteComponentProps,
  useLocation,
} from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@components/header'
//import { AddAPhoto } from '@material-ui/icons'

import { COLORS } from '@styles'

import { P2 } from '@components/base'
import { CREATE_ITEM } from '@api/item'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { Item, ItemInput, UNIT_OF_MEASURE } from '@types'
import {
  TextField,
  Button,
  ButtonVariant,
} from '@pure-components/input'
import { parse } from 'query-string'
import { EkSelect } from '@components/ek-select'

interface ArticleProps extends RouteComponentProps {
  id?: string
}

const _Article: FC<
  ArticleProps & WithMutationExtension<Item[], ItemInput>
> = ({ addData }) => {
  /*const imageStyles = css({
    padding: '0 24px',
    position: 'relative',
    '.photo': { width: '100%', height: '200px', objectFit: 'cover' },
    '.photobutton': {
      width: 32,
      height: 32,
      position: 'absolute',
      top: 20,
      right: 20,
      background: COLORS.gray[30],
      padding: 3,
      borderRadius: 5,
    },
    marginTop: 12,
    marginBottom: 24,
  })*/

  const styles = css({
    padding: '0 24px',
    marginBottom: 12,
    '.info': {
      padding: '6px 24px',
    },
    '.details': {
      color: COLORS.gray[50],
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '0.2em',
      padding: '12px 0',
      placeContent: 'center center',
    },
    '.tempFix': {
      width: 'calc(100% - 1.8em)',
    },
    '.margin-fix': {
      marginBottom: '0.8rem',
    },
    '.tags': {
      display: 'flex',
      gap: 12,
      overflowX: 'scroll',
      padding: '0 0 12px 0',
    },
    '.tags > *:first-of-type': {
      marginLeft: 24,
    },
    '.buttons': {
      display: 'grid',
      gap: 12,
      marginTop: 24,
    },
  })

  const [name, setName] = useState('')
  //const [quantity, setQuantity] = useState('')
  const [number, setNumber] = useState('')
  const [price, setPrice] = useState('')
  const [description, setDescription] = useState('')
  const [unit, setUnit] = useState(UNIT_OF_MEASURE.DEFAULT)
  //const [tags, setTags] = useState([])

  const location = useLocation()
  const params = parse(location.search)
  //console.log('params: ', params)
  const backRoute: string = params.from as string

  //console.log('backroute', backRoute)

  //console.log()

  const validated = () => {
    return name !== ''
  }

  const handleCancel = () => {
    window.history.back()
  }

  const handleConfirm = async () => {
    await handleSave()
  }

  const handleSave = async () => {
    try {
      await addData({
        variables: {
          name,
          number,
          sdfId: '',
          description,
          netPrice: parseFloat(price),
          addedManually: true,
          unitOfMeasure: unit,
          tags: {
            connect: [],
          },
        },
      })

      if (backRoute) {
        const route = backRoute.includes('?')
          ? `${backRoute}&query=${encodeURIComponent(name)}`
          : `${backRoute}?query=${encodeURIComponent(name)}`
        navigate(route, { replace: true })
      } else {
        window.history.back()
      }
    } catch (e) {
      console.log('save error', e)
    }
  }

  return (
    <>
      <SimpleHeader text={`Lägg till ny artikel`} />

      <LayoutStandard>
        {/*<section css={imageStyles}>
          <img
            className="photo"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Solid_black.svg/768px-Solid_black.svg.png"
            alt="USER_CAMERA_NOT_IMPLEMENTED"
          />
          {/* Logiken för fotoknappen ska vara här, ta en bild och uppdatera databasen 
          Gör en funktion som sparar bilden i databas, passa in denna funktion i kamerakomponenten som behöver 
          modifieras lite för att tillåta detta (ta bort video), barcodescannern behöver dependa på denna och 
          denna behöver dependa på samma kamerakomponent (ny komponenet som ska skrivar) //låt stå
          }

          <div className="photobutton" role="button">
            <AddAPhoto />
          </div>
        </section>*/}
        <article css={styles}>
          <P2>Namn *</P2>
          <TextField
            name="name"
            placeholder="Ange namn"
            value={name}
            onChange={(e) => {
              setName((e.target as HTMLInputElement).value)
            }}
          />

          {/*<P2>Antal</P2>
          <TextField
            name="quantity"
            placeholder="Ange antal"
            onChange={(e) => {
              setQuantity((e.target as HTMLInputElement).value)
            }}
          />*/}

          <P2>Artikelnummer</P2>
          <TextField
            name="number"
            placeholder="Ange Artikelnummer"
            value={number}
            onChange={(e) => {
              setNumber((e.target as HTMLInputElement).value)
            }}
          />

          <P2>Enhet</P2>
          <div className="margin-fix">
            <EkSelect
              title="Välj Enhet"
              items={Object.entries(UNIT_OF_MEASURE).map((v, k) => ({
                id: k + '',
                value: UNIT_OF_MEASURE[v[0] as UNIT_OF_MEASURE],
                name: v[0],
              }))}
              onChange={(selection) => {
                if (selection.length > 0) {
                  setUnit(selection[0].value)
                }
              }}
            />
          </div>

          <P2>Pris (per enhet)</P2>
          <TextField
            name="number"
            placeholder="Ange Pris"
            value={price}
            inputType="number"
            onChange={(e) => {
              setPrice((e.target as HTMLInputElement).value)
            }}
          />

          <P2>Beskrivning</P2>
          <div className="tempFix">
            <TextField
              name="description"
              multiline={true}
              rows={4}
              value={description}
              onChange={(e) => {
                setDescription((e.target as HTMLInputElement).value)
              }}
            />
          </div>

          {/*<P2>Taggar</P2>
          <TextField
            name="tags"
            placeholder="Ange tagg"
            /*onChange={(e) => {
              setPassword((e.target as HTMLInputElement).value)
            }}
          />*/}

          <div className="buttons">
            <Button
              variant={ButtonVariant.Outlined}
              onClick={() => {
                handleCancel()
              }}
            >
              Avbryt
            </Button>
            <Button
              disabled={!validated()}
              variant={ButtonVariant.Primary}
              onClick={() => {
                handleConfirm()
              }}
            >
              Skapa Artikel
            </Button>
          </div>
        </article>
      </LayoutStandard>
    </>
  )
}

const NewArticle = withMutation<Item[], ItemInput>(CREATE_ITEM)(
  _Article
)

export { NewArticle }
