/* eslint-disable max-lines */
import { css } from '@emotion/react'
import {
  Button,
  ButtonVariant,
  TextField,
} from '@pure-components/input'
import {
  navigate,
  RouteComponentProps,
  useLocation,
} from '@reach/router'
import { parse } from 'query-string'
import { COLORS } from '@styles'
import Logo from '../../static/logo.svg'
import React, { ChangeEvent, useState } from 'react'
import { AppRoutes } from '@router'
import { P2, P1 } from '@components/base'
import { client } from '@api/client'
import { UPDATE_ACCOUNT_PASSWORD_SMS_KEY } from '@api/account/account-mutations'
import { Snackbar } from '@pure-components/feedback'
import { useSnackbar } from '@hooks'

const Newpassword = (_: RouteComponentProps) => {
  const location = useLocation()
  const params = parse(location.search)
  const smsKey: string = params.key as string

  const { addAlert } = useSnackbar()

  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const validInput = () => {
    return newPassword.length > 5 && newPassword === confirmPassword
  }

  const updatePassword = async () => {
    try {
      const mutation = UPDATE_ACCOUNT_PASSWORD_SMS_KEY
      //console.log('query:', query, variables)
      const result = await client.mutate({
        mutation,
        variables: {
          smsKey,
          newPassword,
        },
      })
      console.log('account update result', result)
      addAlert(<Snackbar text={'Password change successfully'} />)
      navigate(AppRoutes.Login)
    } catch (e) {
      console.log(e)
      addAlert(<Snackbar text={'Something went wrong'} />)
    }
  }

  const styling = css({
    background: COLORS.orange[30],
    height: '100vh',
    '.logo-header': {
      display: 'grid',
      placeItems: 'center center',
    },
    '.logo': {
      width: 'clamp(25%, 320px, 50%)',
      height: '100%',
      margin: '5em 0',
    },
    '.form': {
      display: 'grid',
      rowGap: '12px',
      padding: '1em',
    },
    '.infotext': {
      textAlign: 'center',
    },
  })

  const isValidLink = true //TODO: Någon slags check måste genomföras att länken är giltig

  return (
    <article css={styling}>
      <header className="logo-header">
        <img src={Logo} alt="logotyp" className="logo" />
      </header>
      {isValidLink ? (
        <section className="form">
          <P1 className="infotext">Ange ditt nya lösenord.</P1>

          <TextField
            variant="primary"
            inputType="password"
            placeholder="Nytt lösenord"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(
                (e as ChangeEvent<HTMLInputElement>).target.value
              )
            }}
          />
          <TextField
            variant="primary"
            inputType="password"
            placeholder="Bekräfta nytt lösenord"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(
                (e as ChangeEvent<HTMLInputElement>).target.value
              )
            }}
          />
          <Button
            variant={ButtonVariant.White}
            disabled={!validInput()}
            onClick={updatePassword}
          >
            Spara
          </Button>
        </section>
      ) : (
        <section className="form">
          <P2 className="infotext">Länken är inte längre giltig.</P2>
        </section>
      )}
    </article>
  )
}

export { Newpassword }
