/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const GET_STORAGE_SPACES = gql`
  query storageSpaces($_size: Int, $_cursor: String) {
    storageSpaces(_size: $_size, _cursor: $_cursor) {
      data {
        _id

        name
        description
        virtual
        type
        displayLabel {
          _id

          barcode
        }
        parent
        deleted
      }
      before
      after
    }
  }
`

const GET_STORAGE_SPACE = gql`
  query findStorageSpaceByID($id: ID!) {
    findStorageSpaceByID(id: $id) {
      _id

      name
      description
      type
      displayLabel {
        _id

        barcode
      }
      parent
      deleted
      items {
        data {
          _id

          quantity
          item {
            _id

            number
            name
            description
            tags {
              data {
                _id

                name
              }
            }
            images
            defaultImage
            unitOfMeasure
            supplier
            netPrice
            grossPrice
            sellingPrice
            inventoryItems {
              data {
                _id
                quantity
                storageSpace {
                  _id
                  name
                  description
                  type
                  virtual
                  parent
                  displayLabel {
                    _id
                    barcode
                  }
                }
              }
              before
              after
            }
          }
        }
      }
    }
  }
`

const GET_STORAGE_SPACE_BY_BARCODE = gql`
  query findStorageSpaceByBarcode($barcode: String!) {
    findStorageSpaceByBarcode(barcode: $barcode) {
      _id

      name
      description
      type
      displayLabel {
        _id

        barcode
      }
      parent
      deleted
    }
  }
`

const GET_VIRTUAL_STORAGE_SPACES = gql`
  query getStorageByVirtual($_size: Int, $_cursor: String) {
    getStorageByVirtual(
      virtual: true
      _size: $_size
      _cursor: $_cursor
    ) {
      data {
        _id

        name
        description
        virtual
        type
        parent
        deleted
      }
      before
      after
    }
  }
`

const GET_PHYSICAL_STORAGE_SPACES = gql`
  query getStorageByVirtual($_size: Int, $_cursor: String) {
    getStorageByVirtual(
      virtual: false
      _size: $_size
      _cursor: $_cursor
    ) {
      data {
        _id

        name
        description
        virtual
        type
        parent
        deleted
      }
      before
      after
    }
  }
`

const GET_STORAGES_BY_PARENT = gql`
  query getStoragesByParent(
    $parentId: String!
    $_size: Int
    $_cursor: String
  ) {
    getStoragesByParent(
      parentId: $parentId
      _size: $_size
      _cursor: $_cursor
    ) {
      data {
        _id

        name
        description
        virtual
        type
        parent
        deleted
      }
      before
      after
    }
  }
`

const GET_ROOT_STORAGES = gql`
  query getStoragesRoot($_size: Int, $_cursor: String) {
    getStoragesRoot(_size: $_size, _cursor: $_cursor) {
      data {
        _id

        name
        description
        virtual
        type
        parent
        deleted
      }
      before
      after
    }
  }
`

export {
  GET_STORAGE_SPACES,
  GET_STORAGE_SPACE,
  GET_STORAGE_SPACE_BY_BARCODE,
  GET_PHYSICAL_STORAGE_SPACES,
  GET_VIRTUAL_STORAGE_SPACES,
  GET_STORAGES_BY_PARENT,
  GET_ROOT_STORAGES,
}
