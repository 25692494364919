import { COLORS, TEXT_STYLE } from '@styles'
import { ButtonVariant } from './button-variant'
import { ButtonSize } from './button-size'

const backgroundColor = new Map([
  [ButtonVariant.Primary, COLORS.orange[50]],
  [ButtonVariant.Outlined, 'transparent'],
  [ButtonVariant.White, COLORS.gray[10]],
  [ButtonVariant.Danger, COLORS.danger[50]],
])

const textColor = new Map([
  [ButtonVariant.Primary, COLORS.gray[0]],
  [ButtonVariant.Outlined, COLORS.gray[90]],
  [ButtonVariant.White, COLORS.gray[90]],
  [ButtonVariant.Danger, COLORS.gray[0]],
])

const border = new Map([
  [ButtonVariant.Primary, `2px solid ${COLORS.orange[50]}`],
  [ButtonVariant.Outlined, `2px solid ${COLORS.orange[50]}`],
  [ButtonVariant.White, `2px solid transparent`],
  [ButtonVariant.Danger, `2px solid ${COLORS.danger[50]}`],
])

const textStyle = new Map([
  [ButtonSize.Small, TEXT_STYLE.button[1]],
  [ButtonSize.Large, TEXT_STYLE.button[1]],
])

const padding = new Map([
  [ButtonSize.Small, '8px 16px'],
  [ButtonSize.Large, '16px 24px'],
])

const getBackgroundColor = (variant = ButtonVariant.Primary) =>
  backgroundColor.get(variant) ?? COLORS.orange[50]

const getTextColor = (variant = ButtonVariant.Primary) =>
  textColor.get(variant) ?? COLORS.gray[0]

const getBorder = (variant = ButtonVariant.Primary) =>
  border.get(variant) ?? `2px solid ${COLORS.orange[50]}`

const getTextStyle = (size = ButtonSize.Large) =>
  textStyle.get(size) ?? TEXT_STYLE.button[1]

const getPadding = (size = ButtonSize.Large) =>
  padding.get(size) ?? '16px 24px'

export {
  getBackgroundColor,
  getTextColor,
  getTextStyle,
  getPadding,
  getBorder,
}
