import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Redirect, RouteComponentProps } from '@reach/router'
import { isLoggedIn as checkLoggedIn } from '@common/is-logged-in'
import { AppRoutes } from '@router'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { useIsAdmin, useLogin } from '@hooks'
import { checkIsAdmin, getRole } from '@common/get-role'
import { getUserId } from '@common/get-user-id-from-token'
import { UserObject } from './create-has-access'

interface RouteGuardProps extends RouteComponentProps {
  children?: ReactNode
  hasAccess?: (a: any, b: any) => boolean
  onNoAccess?: () => ReactNode
}

const RouteGuard: FC<RouteGuardProps> = ({
  children = [],
  hasAccess = (user: UserObject, path: string) => true,
  onNoAccess = () => null,
  location,
}) => {
  const [isLoading, setLoading] = useState(true)
  const { isLoggedIn, setIsLoggedIn } = useLogin()
  /*eslint-disable */
  const {
    isAdmin,
    setIsAdmin,
    role,
    setRole,
    userId,
    setUserId,
  } = useIsAdmin()
  /*eslint-enable */

  useEffect(() => {
    const handleAuthCheck = async () => {
      const is_signed_in = await checkLoggedIn()
      setIsLoggedIn(is_signed_in)

      const user_id = getUserId()

      const user_role = getRole()

      const is_user_admin = checkIsAdmin()

      setRole(user_role)
      setIsAdmin(is_user_admin)
      setUserId(user_id)
      setLoading(false)
    }
    handleAuthCheck()
  })

  return (
    <div className="page-wrapper">
      {isLoading ? (
        <EkLoader />
      ) : isLoggedIn ? (
        hasAccess({ role }, location!.pathname) ? (
          <>{children}</>
        ) : (
          <>{onNoAccess()}</>
        )
      ) : (
        <Redirect noThrow to={AppRoutes.Login} />
      )}
    </div>
  )
}

export { RouteGuard }
