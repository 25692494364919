/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const PERFORM_WITHDRAW_TRANSACTION_MULTIPLE = gql`
  mutation PerformWithdrawTransactionMultiple(
    $inventories: [InventoryEntry!]
    $order: ID!
    $comment: String
  ) {
    performWithdrawTransactionMultiple(
      inventories: $inventories
      order: $order
      comment: $comment
    ) {
      _id

      item {
        _id

        number
        name
        description
        tags {
          data {
            _id
            name
          }
          before
          after
        }
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
      }
      quantity
      fromStorageSpace {
        _id

        name
        description
        type
        parent
      }
      toStorageSpace {
        _id

        name
        description
        type
        parent
      }
      toOrder {
        _id

        description
        orderNumber
        sdfId
        createdDate
        serviceDate
        customerPhone
        projectNumber
        projectName
        serviceAddress
        invoiceAddress
        status
      }
      user {
        _id

        firstName
        lastName
        role
      }
      type
      comment
    }
  }
`

export { PERFORM_WITHDRAW_TRANSACTION_MULTIPLE }
