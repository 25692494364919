/* eslint-disable max-lines */
// This file needs to be a bit larger than 100 lines due to various elements needing to be present. Refactoring is ill advised/unnecessary
import React, { FC, useState } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@components/header'
import {
  TextField,
  Button,
  ButtonVariant,
} from '@pure-components/input'
import { COLORS, resetButton, TEXT_STYLE } from '@styles'
import { CropFree } from '@material-ui/icons'
import { Modal, useModal } from '@components/modal'
import { NewStorageModal } from '@components/pages-components/storage/new-storage-modal'
import {
  StorageSpace,
  StorageSpaceInput,
  STORAGE_SPACE_TYPE,
} from '@src/types'
import { getStorageVariant } from '@components/pages-components/storage/common'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { CREATE_STORAGE_SPACE } from '@api/storage-space'
import { BarcodeScanner } from '@components/barcode-scanner'
import { ModalSelectStorage } from '@components/pages-components/storage'

interface BarcodeResponse {
  labelId: string
}

interface WorkOrderProps extends RouteComponentProps {}

const _NewStorage: FC<
  WorkOrderProps & WithMutationExtension<any, StorageSpaceInput>
> = ({ addData }) => {
  const styles = css({
    display: 'grid',
    rowGap: '12px',
    padding: 24,
    '.submenu': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: 12,
      placeItems: 'center center',
      padding: '24px 0',
    },
    '.actiontitle': [
      TEXT_STYLE.button[1],
      { color: COLORS.gray[90] },
    ],
    '.icon': {
      fontSize: 54, // Storleken på ikonen är 40x40 i Figma, men MUI-Icon skapar en margin
      textAlign: 'center',
      color: COLORS.gray[90],
    },
    '.button': [
      resetButton,
      {
        display: 'grid',
        border: 'none',
      },
    ],
    '.label-grid': {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridGap: '10px',
      alignItems: 'start',
    },
    '.barcodeScannerWrapper': {
      position: 'absolute',
      zIndex: 2000,
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      '.barcodeScannerContent': {
        maxWidth: '300px',
      },
    },
    '.barcodeScannerCloseButton': {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: '10px',
      marginTop: '20px',
    },
  })

  const { isVisible, closeModal, openModal } = useModal()
  const [selectedVariant, setSelectedVariant] = useState(
    STORAGE_SPACE_TYPE.DEFAULT
  )

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const [
    labelIdBarcodeScannerVisible,
    setLabelIdBarcodeScannerVisible,
  ] = useState(false)
  /*const [
    parentIdBarcodeScannerVisible,
    setParentIdBarcodeScannerVisible,
  ] = useState(false)*/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [labelId, setLabelId] = useState('')
  const [parent, setParent] = useState<StorageSpace | undefined>(
    undefined
  )

  const onSelectVariant = (selectedVariant: STORAGE_SPACE_TYPE) => {
    closeModal()
    setSelectedVariant(selectedVariant)
  }

  const vibrate = () => {
    if (window?.navigator?.vibrate) {
      window.navigator.vibrate(30)
    }
  }

  const handleSave = () => {
    const variables: StorageSpaceInput = {
      name,
      description,
      type: selectedVariant,
      virtual: false,
    }
    if (labelId) {
      variables.displayLabel = { create: { barcode: labelId } }
    }
    if (parent) {
      variables.parent = parent._id
    }
    addData({
      variables,
    })
      .then((response) =>
        navigate(
          `/storages/${response?.data?.createStorageSpace?._id}`
        )
      )
      .catch((e) => console.log(e))
  }

  const launchLabelIdScanner = () => {
    setLabelIdBarcodeScannerVisible(true)
  }

  const handleLabelIdBarcodeMatch = (text: string) => {
    const labelId: string = text
    //console.log(labelId)
    if (labelId) {
      vibrate()
      setLabelId(labelId)
      handleLabelIdBarcodeClose()
    }
  }

  const handleLabelIdBarcodeClose = () => {
    setLabelIdBarcodeScannerVisible(false)
  }

  /*const launchParentPicker = () => {
    setParentIdBarcodeScannerVisible(true)
  }

  const handleParentIdBarcodeMatch = (text: string) => {
    const labelId: string = text

    if (labelId) {
      vibrate()
      setParentId(labelId)
      handleParentIdBarcodeClose()
    }
  }

  const handleParentIdBarcodeClose = () => {
    setParentIdBarcodeScannerVisible(false)
  }*/

  const handleCancel = () => {
    window.history.back()
  }

  const {
    isVisible: isPickStorageModalVisible,
    closeModal: closePickStorageModal,
    openModal: openPickStorageModal,
  } = useModal()

  const handleParentPicked = (
    parentStorage: StorageSpace | undefined
  ) => {
    setParent(parentStorage ?? undefined)
    closePickStorageModal()
  }

  const launchParentPicker = () => {
    openPickStorageModal()
  }

  return (
    <>
      <SimpleHeader text={`Lägg till förvaringsplats`} />
      <LayoutStandard scroll={true}>
        <div css={styles}>
          <TextField
            placeholder="Namn"
            value={name}
            onChange={(e) => {
              setName((e.target as HTMLInputElement).value)
            }}
          />

          <TextField
            placeholder="Beskrivning"
            multiline={true}
            rows={3}
            value={description}
            onChange={(e) => {
              setDescription((e.target as HTMLInputElement).value)
            }}
          />

          <div className="submenu">
            <button
              className="button"
              onKeyDown={openModal}
              onClick={openModal}
            >
              <p className="actiontitle">
                {selectedVariant === STORAGE_SPACE_TYPE.DEFAULT
                  ? 'Välj typ'
                  : getStorageVariant.label(selectedVariant)}
              </p>
              <div className="icon">
                {getStorageVariant.icon(selectedVariant)}
              </div>
            </button>
            <button
              className="button"
              onKeyDown={() => launchLabelIdScanner()}
              onClick={() => launchLabelIdScanner()}
            >
              <p className="actiontitle">Skanna etikett</p>
              <div className="icon">
                <CropFree fontSize="inherit" />
              </div>
            </button>
          </div>
          <TextField
            placeholder="Etikett-id"
            rows={3}
            value={labelId}
            readOnly={true}
          />
          <div className="label-grid">
            <Button
              variant={ButtonVariant.Primary}
              onClick={() => launchParentPicker()}
            >
              Välj förvaring
            </Button>
            <TextField
              placeholder="Förvaring"
              rows={3}
              value={parent?.name ?? parent?._id}
              readOnly={true}
              helperText="Förvaras i denna förvaringsplats"
            />
          </div>
          <Button
            variant={ButtonVariant.Outlined}
            onClick={() => {
              handleCancel()
            }}
          >
            Avbryt
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            onClick={() => {
              handleSave()
            }}
          >
            Spara
          </Button>

          {labelIdBarcodeScannerVisible && (
            <div className="barcodeScannerWrapper">
              <div className="barcodeScannerContent">
                <BarcodeScanner onMatch={handleLabelIdBarcodeMatch} />
                <div className="barcodeScannerCloseButton">
                  <Button
                    variant={ButtonVariant.Outlined}
                    onClick={() => handleLabelIdBarcodeClose()}
                  >
                    Avbryt
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        <NewStorageModal
          isVisible={isVisible}
          closeModal={closeModal}
          onSelectVariant={onSelectVariant}
        ></NewStorageModal>
      </LayoutStandard>
      <Modal
        isVisible={isPickStorageModalVisible}
        closeModal={closePickStorageModal}
      >
        <ModalSelectStorage
          closeModal={handleParentPicked}
          currentStorageId={''}
          selectedItems={[]}
        />
      </Modal>
    </>
  )
}

const NewStorage = withMutation<any, StorageSpaceInput>(
  CREATE_STORAGE_SPACE
)(_NewStorage)

export { NewStorage }
