/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const GET_ITEMS = gql`
  query items($_size: Int, $_cursor: String) {
    items(_size: $_size, _cursor: $_cursor) {
      data {
        _id
        balanceWarning
        number
        sdfId
        name
        description
        tags {
          data {
            _id
            name
          }
          before
          after
        }
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
        addedManually
      }
      before
      after
    }
  }
`

const GET_ITEM = gql`
  query findItemByID($id: ID!) {
    findItemByID(id: $id) {
      _id
      balanceWarning
      number
      sdfId
      name
      description
      tags {
        data {
          _id
          name
        }
        before
        after
      }
      images
      defaultImage
      unitOfMeasure
      supplier
      netPrice
      grossPrice
      sellingPrice
      addedManually
      inventoryItems {
        data {
          _id
          quantity
          storageSpace {
            _id
            name
            description
            type
            virtual
            parent
            displayLabel {
              _id
              barcode
            }
          }
        }
        before
        after
      }
    }
  }
`

const getSearchQuery = (queryName: string) => gql`
  query items($query: String!) {
    ${queryName}(query: $query) {
      data {
        _id
        balanceWarning
        number
        sdfId
        name
        description
        tags {
          data {
            _id
            name
          }
        }
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
        addedManually
      }
      before
      after
    }
  }
`

const GET_ITEMS_WITH_BALANCE_WARNING = gql`
  query itemsWithBalanceWarning($_size: Int, $_cursor: String) {
    itemsWithBalanceWarning(_size: $_size, _cursor: $_cursor) {
      data {
        _id
        balanceWarning
        number
        sdfId
        name
        description
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
        addedManually
        tags {
          data {
            _id
            name
          }
          before
          after
        }
        inventoryItems {
          data {
            _id
            quantity
            storageSpace {
              _id
              name
              description
              type
              virtual
              parent
              displayLabel {
                _id
                barcode
              }
            }
          }
        }
      }
      before
      after
    }
  }
`

export {
  GET_ITEMS,
  GET_ITEM,
  getSearchQuery,
  GET_ITEMS_WITH_BALANCE_WARNING,
}
