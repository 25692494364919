import React, { FC, ReactNode } from 'react'

interface ArticleInventoryListProps {
  children?: ReactNode
}

const ArticleInventoryList: FC<ArticleInventoryListProps> = ({
  children = <></>,
}) => {
  return <div>{children}</div>
}

export { ArticleInventoryList }
