import { createContext, ReactNode } from 'react'

type SnackbarContextType = {
  addAlert: (content: ReactNode, autoDismissTime?: number) => void
  dismissAlert: () => void
}

const SnackbarContext = createContext<SnackbarContextType>({
  addAlert: () => {},
  dismissAlert: () => {},
})

export { SnackbarContext }
