/* eslint-disable max-lines */
import React, { FC, useMemo, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { MenuOpen, ArrowDropDownCircle } from '@material-ui/icons'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
//import { SimpleHeader } from '@components/header'
import { useQueryParam } from '@hooks'
import {
  DetailsItem,
  WorkingOrderCardView,
  WorkingOrderListItem,
  WorkingOrderListView,
  ContextMenu,
} from '@components/pages-components/working-orders/id'
import { EkCardWorkOrder } from '@components/ek-card-workorder'
import { useBottomSheet } from '@hooks'
import { Button, ButtonVariant } from '@pure-components/input'
import { COLORS, resetButton } from '@styles'
//import { EkViewSwitcher } from '@components/ek-view-switcher'
import {
  ASSIGN_ORDER_TO_USER,
  CREATE_ORDER,
  GET_ORDER,
} from '@api/order'
import { Divider } from '@pure-components/graphics'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { Order, OrderInput, OrderItem, StorageSpace } from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { client } from '@api/client'
import { gql } from '@apollo/client'
import { SEND_ORDER_TO_SDF } from '@api/sdf/send-order-mutation'
import { AppRoutes } from '@router'
import { H6 } from '@components/base'
import { BackButton } from '@components/back-button.ts'
import {
  ModalSelectOrder,
  ModalSelectStorage,
  SelectedItemsList,
} from '@components/pages-components/storage'
import { Modal, useModal } from '@components/modal'
import {
  PERFORM_MOVE_TRANSACTION_ORDER_TO_ORDER_MULTIPLE,
  PERFORM_MOVE_TRANSACTION_ORDER_TO_STORAGE_MULTIPLE,
} from '@api/perform-transaction/perform-move-transaction-multiple-mutation'

interface WorkOrderProps extends RouteComponentProps {
  id?: string
}

interface OrderCartItem {
  item: OrderItem
  quantity: number
}

const _WorkingOrder: FC<
  WorkOrderProps &
    WithMutationExtension<Order[], OrderInput> &
    WithQueryExtension<Order>
> = ({ queryData, id, refetch }) => {
  const {
    findOrderByID: {
      //_id,
      description,
      orderNumber,
      createdDate,
      serviceDate,
      customerPhone,
      projectNumber,
      projectName,
      serviceAddress,
      invoiceAddress,
      status,
      items,
      assignedUser,
    },
  } = queryData

  const data = items?.data || []

  const { setChildren, setIsOpen } = useBottomSheet()

  const view = useQueryParam('view')

  const [editEnabled, setEditEnabled] = useState(false)

  const [selectedItems, setSelectedItems] = useState<OrderCartItem[]>(
    []
  )

  const selectedItemsLength = useMemo(() => {
    return selectedItems.reduce(
      (sum: number, item: OrderCartItem) => {
        sum += item.quantity
        return sum
      },
      0
    )
  }, [selectedItems])

  //const handleEditMode = createSetEditMode(location)

  //When we select articles for checkout
  const onUpdateItemAmount = (
    itemToUpdate: OrderItem,
    selectedQuantity: number
  ) => {
    // create object dictionary for easier manipulation
    const dictionary: { [x: string]: OrderCartItem } = {}
    for (const ci of selectedItems) {
      dictionary[ci.item._id] = ci
    }

    //Add or update Item based on _id
    dictionary[itemToUpdate._id] = {
      item: itemToUpdate,
      quantity: selectedQuantity,
    }

    // Convert dictionary to array of objects
    const selected = Array.from(Object.values(dictionary)).filter(
      (i) => i.quantity !== 0
    )

    setSelectedItems(selected)
  }

  const bottomSheetStyles = css({
    display: 'grid',
    gap: '1em',
    marginTop: '2em',
    padding: 24,
  })

  const sendOrderToSDF = async () => {
    //console.log('Send to SDF')
    if (id) {
      try {
        const result = await client.mutate({
          mutation: gql`
            ${SEND_ORDER_TO_SDF}
          `,
          variables: { orderId: id },
        })
        console.log('result', result)
        refetch()
      } catch (e) {
        console.log('apollo error', e)
      }
    }
    setIsOpen(false)
  }

  const handleAddArticle = () => {
    navigate(
      `${AppRoutes.Scan}?order=${id}&ordernumber=${orderNumber}`
    )
    setIsOpen(false)
  }

  const handleAssignUser = async (userId: string | undefined) => {
    //console.log('Send to SDF')
    if (id) {
      try {
        const result = await client.mutate({
          mutation: gql`
            ${ASSIGN_ORDER_TO_USER}
          `,
          variables: { id, assignedUser: { connect: userId } },
        })
        console.log('result', result)
        refetch()
      } catch (e) {
        console.log('apollo error', e)
      }
    }
    setIsOpen(false)
  }

  const moveArticlesToOrder = async (orderId: string | undefined) => {
    const entries = selectedItems
      .filter((i) => i.quantity !== 0)
      .map((i) => ({
        id: i.item._id,
        quantity: i.quantity,
      }))
    //console.log('orderid', orderId, entries)
    if (orderId && entries.length > 0) {
      try {
        //console.log('query:', query, variables)
        const result = await client.mutate({
          mutation: gql`
            ${PERFORM_MOVE_TRANSACTION_ORDER_TO_ORDER_MULTIPLE}
          `,
          variables: {
            fromOrderItems: entries,
            toOrder: orderId,
          },
        })
        console.log('result', result)
        refetch()
      } catch (e) {
        console.log(e)
      }
    }
    closeMoveToOrderModal()
    setIsOpen(false)
  }

  const moveArticlesToStorage = async (
    storageSpace: StorageSpace | undefined
  ) => {
    //console.log(storageSpace?._id)
    if (storageSpace?._id) {
      try {
        //console.log('query:', query, variables)
        const result = await client.mutate({
          mutation: PERFORM_MOVE_TRANSACTION_ORDER_TO_STORAGE_MULTIPLE,
          variables: {
            fromOrderItems: selectedItems
              .filter((i) => i.quantity !== 0)
              .map((i) => ({
                id: i.item._id,
                quantity: i.quantity,
              })),
            toStorage: storageSpace._id,
          },
        })
        console.log('result', result)
        refetch()
      } catch (e) {
        console.log(e)
      }
    }
    closeMoveToStorageModal()
    setIsOpen(false)
  }

  const handleEnableEdit = () => {
    setEditEnabled(!editEnabled)
    setIsOpen(false)
  }

  const {
    isVisible: isMoveToStorageModalVisible,
    closeModal: closeMoveToStorageModal,
    openModal: openMoveToStorageModal,
  } = useModal()
  const {
    isVisible: isMoveToOrderModalVisible,
    closeModal: closeMoveToOrderModal,
    openModal: openMoveToOrderModal,
  } = useModal()

  const openBottomSheet = () => {
    setChildren(
      <ContextMenu
        handleAddArticle={handleAddArticle}
        handleEditMode={handleEnableEdit}
        handleSendToSDF={sendOrderToSDF}
        handleAssignUser={handleAssignUser}
        editModeEnabled={editEnabled}
      />
    )
    setIsOpen(true)
  }

  const openBottomSheetTransfer = () => {
    setChildren(
      <>
        <SelectedItemsList
          itemList={selectedItems}
        ></SelectedItemsList>
        <section css={bottomSheetStyles}>
          <>
            <Button
              variant={ButtonVariant.Outlined}
              onClick={() => openMoveToOrderModal()}
            >
              Flytta till annan order
            </Button>
            <Button
              variant={ButtonVariant.Outlined}
              onClick={() => openMoveToStorageModal()}
            >
              Flytta till förvaringsplats
            </Button>
          </>
        </section>
      </>
    )
    setIsOpen(true)
  }

  const detailsRow = [
    { caption: 'Telefonnummer', text: customerPhone ?? '' },
    { caption: 'Skapad', text: createdDate },
    {
      caption: 'Servicedatum',
      text: serviceDate,
    },
    {
      caption: 'Serviceadress',
      text: serviceAddress,
    },
    {
      caption: 'Fakturaadress',
      text: invoiceAddress,
    },
    {
      caption: 'Projektnummer',
      text: projectNumber,
    },
    {
      caption: 'Beskrivning',
      text: description,
    },
    {
      caption: 'Ordernummer',
      text: orderNumber,
    },
    {
      caption: 'Status',
      text: status,
    },
    {
      caption: 'Tilldelad',
      text: `${assignedUser?.firstName || ''} ${
        assignedUser?.lastName || ''
      }`,
    },
  ]

  const styles = css({
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: '12px',
    justifyContent: 'center',
    padding: view === 'card' ? '0px' : '0 0px ',
    '.heading': {
      padding: '12px 14px',
      fontSize: '1.2rem',
      width: '100%',
      borderBottom: '1px lightgray solid',
    },
  })

  const cartStyles = css({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: '0.5em',
    padding: '1rem 1.5rem 0 1.5rem',
    borderTop: '1px lightgray solid',
    '.article-info': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  const contextMenuStyles = css({
    padding: '12px 24px',
    width: '100%',
    background: COLORS.gray[0],
  })

  const [detailsOpen, setDetailsOpen] = useState(false)

  const headerWrapperStyles = css({
    display: 'flex',
    //justifyContent: 'space-between',
    //alignItems: 'center',
    background: COLORS.gray[70],
    color: COLORS.gray[10],
    padding: '16px 16px',
  })

  const headerStyles = css({
    display: 'grid',
    //zIndex: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    '.order-number': {
      fontSize: '12px',
    },
    '.service-address': {
      fontSize: '12px',
    },
    '.project-order': {
      fontSize: '16px',
      fontWeight: 800,
    },
    '.header-line-two': {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      marginTop: '10px',
    },
  })
  const detailStyles = css({
    display: detailsOpen ? 'grid' : 'none',
    opacity: detailsOpen ? 1 : 0,
    height: detailsOpen ? 'auto' : 0,
    maxHeight: 1000,
    background: COLORS.gray[70],
    color: COLORS.gray[10],
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: '12px 24px',
    paddingBottom: '2em',
    gap: '0.5em',
    transition: 'transform 250ms ease',
    //zIndex: 999,
  })

  const backWrapperStyle = css({
    //flex: 1
    marginRight: 16,
  })

  const buttonStyling = css([
    resetButton,
    {
      color: COLORS.gray[10],
    },
  ])

  return (
    <>
      <div css={headerWrapperStyles}>
        <div css={backWrapperStyle}>
          <BackButton />
        </div>
        <div css={headerStyles}>
          <div className="order-number">
            {projectName ? projectName + ',' : ''} {serviceAddress}
          </div>
          <div className="header-line-two">
            <div className="project-order">{orderNumber}</div>
            <button
              css={buttonStyling}
              onClick={() => setDetailsOpen(!detailsOpen)}
            >
              {detailsOpen ? (
                <ArrowDropDownCircle transform="rotate(-180)" />
              ) : (
                <ArrowDropDownCircle />
              )}
            </button>
          </div>
        </div>
      </div>
      {/*false && (
        <SimpleHeader
          text={`${truncateString(
            orderNumber,
            18
          )} ${invoiceAddress}`}
          afterText={<></>}
        >
          <EkViewSwitcher view={view} location={location} />
        </SimpleHeader>
          )*/}
      <section css={detailStyles}>
        {detailsRow.map((detail) => (
          <DetailsItem key={`key${detail.caption}`} {...detail} />
        ))}
      </section>
      <LayoutStandard scroll={true}>
        <section css={styles}>
          <H6 className="heading">Orderrader</H6>
          {view === 'card' ? (
            <WorkingOrderCardView>
              {data.map((orderItem: OrderItem) => (
                <EkCardWorkOrder key={orderItem._id} {...orderItem} />
              ))}
            </WorkingOrderCardView>
          ) : (
            <WorkingOrderListView>
              {data
                .filter((oi) => oi.quantity > 0)
                .map((orderItem: OrderItem, i: number) => (
                  <div key={`orderItem${orderItem._id}`}>
                    <WorkingOrderListItem
                      key={orderItem._id}
                      editEnabled={editEnabled}
                      {...orderItem}
                      onUpdateAmount={(selectedQuantity: number) => {
                        console.log(
                          'onUpdateAmount',
                          selectedQuantity
                        )
                        onUpdateItemAmount(
                          orderItem,
                          selectedQuantity
                        )
                      }}
                    />
                    {i < data.length - 1 && (
                      <Divider key={`divider${orderItem._id}`} />
                    )}
                  </div>
                ))}
            </WorkingOrderListView>
          )}
        </section>
      </LayoutStandard>
      {selectedItemsLength > 0 && (
        <div css={cartStyles}>
          <div className="article-info">
            {selectedItemsLength} vald
            {selectedItemsLength === 1 ? '' : 'a'}{' '}
            {selectedItemsLength === 1 ? 'artikel' : 'artiklar'}
          </div>
          <Button
            variant={ButtonVariant.Primary}
            onClick={() => openBottomSheetTransfer()}
            disabled={selectedItemsLength === 0}
          >
            Flytta
          </Button>
        </div>
      )}
      <section css={contextMenuStyles}>
        <Button
          variant={ButtonVariant.Outlined}
          onClick={openBottomSheet}
        >
          <MenuOpen />
        </Button>
      </section>
      <Modal
        isVisible={isMoveToOrderModalVisible}
        closeModal={closeMoveToOrderModal}
      >
        <ModalSelectOrder closeModal={moveArticlesToOrder} />
      </Modal>
      <Modal
        isVisible={isMoveToStorageModalVisible}
        closeModal={closeMoveToStorageModal}
      >
        <ModalSelectStorage
          closeModal={moveArticlesToStorage}
          currentStorageId={id}
          selectedItems={selectedItems}
          confirmText="Flytta artiklar"
        />
      </Modal>
    </>
  )
}

const WorkingOrder = withQuery<Order>({
  query: GET_ORDER,
  loader: EkLoader,
})(withMutation<Order[], OrderInput>(CREATE_ORDER)(_WorkingOrder))

export { WorkingOrder }
