import React, { ChangeEvent, FC } from 'react'
import { css } from '@emotion/react'
import { COLORS } from '@styles'

interface EkNumberFieldProps {
  name?: string
  value?: number | ''
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  max?: number | string | undefined
  min?: number | string | undefined
  width?: string
  error?: string
  helperText?: string
}

const EkNumberField: FC<EkNumberFieldProps> = ({
  name = '',
  value = 0,
  onChange = () => {},
  disabled = false,
  max,
  min,
  width = '10',
  error = '',
  helperText = '',
}) => {
  const textfieldStyles = css({
    width: width + 'px' || '',
    color: COLORS.gray[90],
    border: `1px solid`,
    borderColor: error ? COLORS.danger[50] : COLORS.gray[30],
    padding: '1em',
    borderRadius: '5px',
    opacity: disabled ? 0.4 : 1,
    transition: 'all 250ms',
    '&:focus': {
      borderColor: error ? COLORS.danger[50] : COLORS.gray[70],
    },
  })

  const focusHandler = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    event.target.select()
  }

  const helperTextNormal = css({
    padding: '5px',
    fontSize: '10px',
  })

  const helperTextStyles = css({
    color: COLORS.danger[50],
  })
  return (
    <>
      <input
        css={textfieldStyles}
        type="number"
        name={name}
        value={value}
        onChange={onChange}
        onFocus={focusHandler}
        disabled={disabled}
        max={max}
        min={min}
      />
      {(helperText && helperText) ||
        (error && error && (
          <div>
            <div css={helperTextNormal}>
              {helperText && helperText}
            </div>
            <span css={helperTextStyles}>{error && error}</span>
          </div>
        ))}
    </>
  )
}

export { EkNumberField }
