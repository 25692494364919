import { NavItem } from '@context'
import { NavIcon } from './icons'

const getActiveIconType = (navItem: NavItem): NavIcon => {
  const map = new Map([
    [NavItem.Home, NavIcon.Home],
    [NavItem.Order, NavIcon.Assignment],
    [NavItem.Storages, NavIcon.StoreMall],
    [NavItem.SideMenu, NavIcon.ViewHeadline],
    [NavItem.None, NavIcon.Home],
  ])

  return map.get(navItem) || NavIcon.Home
}

export { getActiveIconType }
