/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const CREATE_STORAGE_SPACE = gql`
  mutation CreateStorageSpace(
    $name: String!
    $description: String
    $type: STORAGE_SPACE_TYPE!
    $displayLabel: StorageSpaceDisplayLabelRelation
    $parent: String
    $virtual: Boolean!
  ) {
    createStorageSpace(
      data: {
        name: $name
        description: $description
        type: $type
        displayLabel: $displayLabel
        parent: $parent
        virtual: $virtual
      }
    ) {
      _id

      name
      description
      virtual
      type
      displayLabel {
        _id

        barcode
      }
      parent
      deleted
    }
  }
`

const UPDATE_STORAGE_SPACE = gql`
  mutation UpdateStorageSpace(
    $id: ID!
    $name: String!
    $description: String
    $type: STORAGE_SPACE_TYPE!
    $displayLabel: StorageSpaceDisplayLabelRelation
    $parent: String
    $virtual: Boolean!
    $deleted: Boolean
  ) {
    updateStorageSpace(
      id: $id
      data: {
        name: $name
        description: $description
        type: $type
        displayLabel: $displayLabel
        parent: $parent
        virtual: $virtual
        deleted: $deleted
      }
    ) {
      _id

      name
      description
      virtual
      type
      displayLabel {
        _id

        barcode
      }
      parent
      deleted
    }
  }
`

const REPLACE_STORAGE_SPACE_BARCODE = gql`
  mutation replaceStorageSpaceBarcode(
    $barcode: String!
    $newBarcode: String!
  ) {
    replaceStorageSpaceBarcode(
      barcode: $barcode
      newBarcode: $newBarcode
    ) {
      _id

      name
      description
      type
      displayLabel {
        _id

        barcode
      }
      parent
      deleted
    }
  }
`

export {
  CREATE_STORAGE_SPACE,
  UPDATE_STORAGE_SPACE,
  REPLACE_STORAGE_SPACE_BARCODE,
}
