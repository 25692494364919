const depth: number = 2

enum BoxShadow {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  /** not in use - use 'Small', 'Medium' or 'Large' only*/
  Huge = 'huge',
}

const composeCss = (depthInput: number) =>
  `0 ${depthInput}px ${
    depthInput * 3
  }px rgba(0,0,0,0.12), 0 ${depthInput}px ${
    depthInput * 2
  }px rgba(0,0,0,0.24)`

const boxShadowMap = new Map([
  [BoxShadow.Small, composeCss(depth)],
  [BoxShadow.Medium, composeCss(depth + 2)],
  [BoxShadow.Large, composeCss(depth + 4)],
  [BoxShadow.Huge, composeCss(depth + 8)],
])

const getBoxShadow = (boxShadow = BoxShadow.Small) => {
  return (
    boxShadowMap.get(boxShadow) ?? BoxShadow.Small, composeCss(depth)
  )
}

export { getBoxShadow, BoxShadow }
