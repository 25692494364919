import { useLocation, WindowLocation } from '@reach/router'

const useQueryParam = (
  queryParam: string,
  overrideLocation?: WindowLocation
) => {
  const location = overrideLocation ?? useLocation()
  const search = new URLSearchParams(location.search)
  return search.get(queryParam) ?? ''
}

export { useQueryParam }
