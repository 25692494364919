import styled from '@emotion/styled'
import { COLORS } from '@styles'
import { TEXT_STYLE } from '@styles'

const Caption = styled.p(
  TEXT_STYLE.caption[1],
  ({ color = COLORS.gray[100] }) => ({ color })
)

export { Caption }
