import { gql } from '@apollo/client'
import { UserInput } from '../../../../lib/types'

const getUpdateUserMutation = (id: string, data: UserInput) => {
  const { firstName, lastName, role } = data

  return gql`
    mutation {
      updateUser(id: "${id}", data: {
        firstName: "${firstName}"
        lastName: "${lastName}"
        role: "${role}"
      }) {
        _id
        firstName
        lastName
        role
        account {
          _id
          phoneNumber
        }
      }
    }
  `
}

export { getUpdateUserMutation }
