import React, { FC, ReactNode, ChangeEvent, useState } from 'react'
import { css } from '@emotion/react'
import { Done } from '@material-ui/icons'
import { COLORS } from '@styles'

interface CheckboxProps {
  name?: string
  checked?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  borderColor?: string
  background?: string
  iconColor?: string
  disabled?: boolean

  icon?: ReactNode
}

const defaultCheckbox = <Done />

const Checkbox: FC<CheckboxProps> = ({
  name = '',
  checked = false,
  disabled = false,
  onChange = () => {},
  borderColor = COLORS.gray[100],
  background = COLORS.gray[0],
  iconColor = COLORS.gray[100],

  icon = defaultCheckbox,
}) => {
  const size = '24px'
  const borderWidth = '3px'

  const styles = css({
    width: size,
    height: size,
    border: `${borderWidth} solid ${borderColor}`,
    borderRadius: 3,
    position: 'relative',
    transition: 'all 250ms ease',
    background,
    opacity: disabled ? 0.4 : 1,
    '&:after': {
      pointerEvents: 'none',
      position: 'absolute',
      content: '""',
      top: '50%',
      left: '50%',
      height: `calc(${size} * 2)`,
      width: `calc(${size} * 2)`,
      borderRadius: '9999px',
      transition: 'all 250ms ease',
      transform: 'translate(-50%, -50%)',
      background: 'transparent',
    },
    '&:hover:after': {
      // TODO: standardize hover
      background: 'rgba(0,0,0,0.15)',
    },
  })

  const iconStyles = css({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: iconColor,
    pointerEvents: 'none',
    '&*': {
      pointerEvents: 'none',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })

  const hiddenCheckbox = css({
    cursor: 'pointer',
    width: `calc(${borderWidth} * 2 + 100%)`,
    height: `calc(${borderWidth} * 2 + 100%)`,
    position: 'absolute',
    top: `-${borderWidth}`,
    left: `-${borderWidth}`,
    opacity: 0,
    margin: 0,
  })
  const [selected, setSelected] = useState(checked)

  return (
    <div css={styles}>
      <input
        type="checkbox"
        name={name}
        css={hiddenCheckbox}
        onChange={(event) => {
          setSelected(!selected)
          onChange(event)
        }}
        disabled={disabled}
      />
      {selected ? <div css={iconStyles}>{icon}</div> : null}
    </div>
  )
}

export { Checkbox }
