import React, { ReactNode, MouseEvent, FC } from 'react'
import { css } from '@emotion/react'
import { resetButton } from '@styles'

interface ShadowOverlayProps {
  children: ReactNode
  color?: string
  opacity?: number
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  transition?: string
  onTransitionEnd?: () => void
  pointerEvents?:
    | 'auto'
    | 'none'
    | 'visiblePainted'
    | 'visibleFill'
    | 'visibleStroke'
    | 'visible'
    | 'painted'
    | 'fill'
    | 'stroke'
    | 'all'
    | 'inherit'
}

const ShadowOverlay: FC<ShadowOverlayProps> = ({
  children = [],
  color = 'black',
  opacity = 0.5,
  onClick = () => {},
  transition = 'all 250ms ease',
  onTransitionEnd = () => {},
  pointerEvents = 'auto',
}) => {
  const styles = css({
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    zIndex: 2000,
    position: 'fixed',
    pointerEvents,
    '.children': {
      position: 'fixed',
      width: '100%',
      top: 0,
      left: 0,
    },
  })

  const shadowStyle = css([
    resetButton,
    {
      background: color,
      opacity: opacity,
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      cursor: 'pointer',
      transition,
    },
  ])

  return (
    <div css={styles}>
      <button
        onClick={onClick}
        css={shadowStyle}
        onTransitionEnd={onTransitionEnd}
      />
      <div className="children">{children}</div>
    </div>
  )
}

export { ShadowOverlay }
