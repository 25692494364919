/* eslint-disable max-lines */
//TODO: maybe refactor routeguard to be less verbose and keep file under 100 lines
import React from 'react'
import { Router } from '@reach/router'
import {
  Login,
  Forgotpassword,
  Newpassword,
  Home,
  NotFound,
  WorkingOrders,
  WorkingOrder,
  AssignToOrder,
  ArchivedOrders,
  ArchivedOrder,
  Article,
  ArticleFromSDF,
  NewArticle,
  ArticleInventory,
  StockBalance,
  IncomingProducts,
  IncomingProduct,
  Help,
  Users,
  UserPage,
  NewUser,
  Reports,
  Storages,
  NewStorage,
  Storage,
  Scan,
  SetPassword,
  ReportPage,
} from '@pages'
import { AppRoutes } from './app-routes'
import { createHasAccess, RouteGuard } from './route-guard'
import { AddToStorage } from '@pages/storages/add'
import { SearchOrder } from '@pages/working-orders/search'
import { AdjustStorageInventory } from '@pages/storages/adjust'
import { AdjustStorageInventoryRemove } from '@pages/storages/removearticles'
import { SearchArticle } from '@pages/articles/search'
import { EditStorage } from '@pages/storages/edit'
import { Logout } from '@pages/logout'
import { SearchArchivedOrder } from '@pages/archived-orders/search'
import { EditArticle } from '@pages/articles/edit'
import { BalanceThresholdPage } from '@pages/admin/balance-threshold'
//import { useIsAdmin } from '@hooks'

// Implement this method
/*const onNoAccess = (message = 'Sorry, no access') => {
  // Probably want a component or something like this
  // return <NoAccess errorMessage={message} />
  return <div />
}*/

const AppRouter = () => {
  // Probably from user object stored in context
  //const { role, isAdmin } = useIsAdmin()

  const userAllowed = [
    AppRoutes.Home,
    AppRoutes.IncomingProducts,
    AppRoutes.IncomingProduct,
    AppRoutes.WorkingOrders,
    AppRoutes.WorkingOrderSearch,
    AppRoutes.WorkingOrder,
    AppRoutes.AssignToOrder,
    AppRoutes.ArchivedOrders,
    AppRoutes.ArchivedOrder,
    AppRoutes.ArchivedOrderSearch,
    AppRoutes.Article,
    AppRoutes.NewArticle,
    AppRoutes.EditArticle,
    AppRoutes.ArticleFromSDF,
    AppRoutes.ArticleSearch,
    AppRoutes.ArticleInventory,
    AppRoutes.StockBalance,
    AppRoutes.Help,
    AppRoutes.User,
    AppRoutes.Storages,
    AppRoutes.NewStorage,
    AppRoutes.EditStorage,
    AppRoutes.Storage,
    AppRoutes.AddToStorage,
    AppRoutes.AdjustStorageInventory,
    AppRoutes.AdjustStorageInventoryRemove,
    AppRoutes.Scan,
  ]

  const adminAllowed = [
    ...userAllowed,
    AppRoutes.Users,
    AppRoutes.NewUser,
    AppRoutes.Reports,
    AppRoutes.NewStorage,
    AppRoutes.AdminReports,
    AppRoutes.AdminBalanceThreshold,
  ]

  const roleMap = {
    admin: adminAllowed,
    user: userAllowed,
  }

  const hasAccess = createHasAccess(roleMap)

  return (
    <Router>
      <NotFound default />
      <Login path={AppRoutes.Login} />
      <Logout path={AppRoutes.Logout} />
      <Forgotpassword path={AppRoutes.Forgotpassword} />
      <Newpassword path={AppRoutes.Newpassword} />
      <SetPassword path={AppRoutes.SetPassword} />
      <RouteGuard path={AppRoutes.Home} hasAccess={hasAccess}>
        <>
          <Home path={AppRoutes.Home} />
          <IncomingProducts path={AppRoutes.IncomingProducts} />
          <IncomingProduct path={AppRoutes.IncomingProduct} />
          <WorkingOrders path={AppRoutes.WorkingOrders} />
          <WorkingOrder path={AppRoutes.WorkingOrder} />
          <SearchOrder path={AppRoutes.WorkingOrderSearch} />
          <AssignToOrder path={AppRoutes.AssignToOrder} />
          <ArchivedOrders path={AppRoutes.ArchivedOrders} />
          <ArchivedOrder path={AppRoutes.ArchivedOrder} />
          <SearchArchivedOrder path={AppRoutes.ArchivedOrderSearch} />
          <Article path={AppRoutes.Article} />
          <EditArticle path={AppRoutes.EditArticle} />
          <NewArticle path={AppRoutes.NewArticle} />
          <ArticleFromSDF path={AppRoutes.ArticleFromSDF} />
          <SearchArticle path={AppRoutes.ArticleSearch} />
          <ArticleInventory path={AppRoutes.ArticleInventory} />
          <StockBalance path={AppRoutes.StockBalance} />
          <Help path={AppRoutes.Help} />
          <UserPage path={AppRoutes.User} />
          <Storages path={AppRoutes.Storages} />
          <NewStorage path={AppRoutes.NewStorage} />
          <EditStorage path={AppRoutes.EditStorage} />
          <Storage path={AppRoutes.Storage} />
          <AddToStorage path={AppRoutes.AddToStorage} />
          <AdjustStorageInventory
            path={AppRoutes.AdjustStorageInventory}
          />
          <AdjustStorageInventoryRemove
            path={AppRoutes.AdjustStorageInventoryRemove}
          />
          <Scan path={AppRoutes.Scan} />
        </>
        <>
          <Users path={AppRoutes.Users} />
          <NewUser path={AppRoutes.NewUser} />
          <Reports path={AppRoutes.Reports} />
          <NewStorage path={AppRoutes.NewStorage} />
          <ReportPage path={AppRoutes.AdminReports} />
          <BalanceThresholdPage
            path={AppRoutes.AdminBalanceThreshold}
          />
        </>
      </RouteGuard>
    </Router>
  )
}

export { AppRouter }
