import React, { ReactNode, MouseEvent, FC } from 'react'
import { css } from '@emotion/react'
import { TEXT_STYLE, COLORS, resetButton } from '@styles'

interface ChipProps {
  startIcon?: ReactNode
  endIcon?: ReactNode
  text?: string
  color?: string
  background?: string
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

const Chip: FC<ChipProps> = ({
  startIcon,
  text = '',
  endIcon,
  color = COLORS.gray[90],
  background = COLORS.gray[40],
  onClick = () => {},
}) => {
  const styles = css([
    resetButton,
    TEXT_STYLE.caption[1],
    {
      display: 'flex',
      whiteSpace: 'nowrap',
      background,
      borderRadius: '99999px',
      alignItems: 'center',
      padding: '4px 8px',
      '&>svg': [TEXT_STYLE.caption[1], { color }],
    },
  ])

  const textStyle = css({
    margin: '0 6px',
    color,
  })

  return (
    <div>
      <button css={styles} onClick={onClick}>
        {startIcon}
        <span css={textStyle}>{text}</span>
        {endIcon}
      </button>
    </div>
  )
}

export { Chip }
