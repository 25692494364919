import {
  Home,
  StoreMallDirectory,
  PhotoCamera,
  Assignment,
  ViewHeadline,
} from '@material-ui/icons'

enum NavIcon {
  Home = 'home',
  StoreMall = 'store-mall',
  PhotoCamera = 'photo-camera',
  Assignment = 'assignment',
  ViewHeadline = 'view-headline',
}

const icons = [
  { Component: Home, type: NavIcon.Home },
  { Component: StoreMallDirectory, type: NavIcon.StoreMall },
  { Component: PhotoCamera, type: NavIcon.PhotoCamera },
  { Component: Assignment, type: NavIcon.Assignment },
  { Component: ViewHeadline, type: NavIcon.ViewHeadline },
]

export { icons, NavIcon }
