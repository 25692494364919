import { useState } from 'react'

const useModal = () => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleModal = () => {
    setIsVisible(!isVisible)
  }

  const closeModal = () => {
    setIsVisible(false)
  }

  const openModal = () => {
    setIsVisible(true)
  }

  return {
    isVisible,
    toggleModal,
    closeModal,
    openModal,
  }
}
export { useModal }
