const formatDateIso = (date: string): string => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  month = month.length < 2 ? '0' + month : month
  day = day.length < 2 ? '0' + day : day

  return [year, month, day].join('-')
}

const normalizeDate = (input: string) => {
  if (new Date(input).toLocaleDateString() === 'Invalid Date') {
    const parts = input.split('T')
    const year = parts[0].substring(0, 4)
    const month = parts[0].substring(4, 6)
    const day = parts[0].substring(6, 8)

    const fixedISO = [[year, month, day].join('-'), parts[1]].join(
      'T'
    )

    if (new Date(fixedISO).toLocaleDateString() === 'Invalid Date') {
      return input
    } else {
      return formatDateIso(new Date(fixedISO).toLocaleDateString())
    }
  } else {
    return formatDateIso(new Date(input).toLocaleDateString())
  }
}

export { normalizeDate, formatDateIso }
