import React from 'react'
import {
  Skeleton,
  SkeletonItem,
} from '@pure-components/graphics/skeleton'

const DefaultSkeleton = () => {
  return (
    <Skeleton>
      <SkeletonItem type="title" />
      <SkeletonItem type="text" />
      <SkeletonItem type="text" />
    </Skeleton>
  )
}

export { DefaultSkeleton }
