/* eslint-disable max-lines */
// TODO: Radera detta när GraphQL implementerats, filen blir för lång med spökdatan vilket är varför disable är tillagt.
import React, { FC, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { SimpleHeader } from '@components/header'

import { useQueryParam } from '@hooks'
import {
  StorageListItemSelect,
  StorageListView,
} from '@components/pages-components/storage'

import {
  Button,
  ButtonVariant,
  TextField,
} from '@pure-components/input'
import { COLORS } from '@styles'
import { Divider } from '@pure-components/graphics'
import { Modal, useModal } from '@components/modal'
import { GET_STORAGE_SPACE } from '@api/storage-space'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { withQuery, WithQueryExtension } from '@components/with-query'
import {
  InventoryItem,
  InventoryItemInput,
  StorageSpace,
  StorageSpaceTransaction,
} from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { LayoutStandard } from '@layouts'
import { getIconByVariant } from '@components/pages-components/storage/common'
//import { ModalEditArticleCard } from '@components/pages-components/storage/modal-edit-article-card'
import { PERFORM_ADD_TRANSACTION } from '@api/perform-transaction'

import { gql } from '@apollo/client'
import { client } from '@api/client'
import { getSortedArray } from '@common/get-sorted-array'
import {
  AdjustItem,
  AdjustItemList,
} from '@components/pages-components/storage/article-adjust-list'
import { PERFORM_CORRECTION_TRANSACTION_MULTIPLE } from '@api/perform-transaction/perform-correction-transaction-multiple-mutation'
import { STORAGE_SPACE_TRANSACTION_TYPE } from '@src/functions/lib/types'
import { DELETE_INVENTORY_ITEM } from '@api/inventory-item'

interface WorkOrderProps extends RouteComponentProps {
  id?: string
}

const _AdjustStorageInventoryRemove: FC<
  WorkOrderProps &
    WithMutationExtension<
      StorageSpaceTransaction,
      { inventoryItem: InventoryItemInput }
    > &
    WithQueryExtension<StorageSpace>
> = ({ queryData, addData, refetch, id }) => {
  const {
    //findStorageSpaceByID: storageSpace,
    findStorageSpaceByID: { items, type, description },
  } = queryData

  const data = items?.data || []

  const [itemsToAdjust, setItemsToAdjust] = useState<AdjustItem[]>([])
  //const [diffItems, setdiffItems] = useState<AdjustItem[]>([])

  const [notes, setNotes] = useState('')

  //const { setChildren, setIsOpen } = useBottomSheet()

  const handleNotesChange = (event: any) => {
    setNotes(event.target.value)
  }

  const bottomSheetStyles = css({
    display: 'grid',
    gap: '1em',
    marginTop: '2em',
    padding: 24,
  })

  const notesFieldStyles = css({
    padding: '12px 24px 6px 24px',
    width: '100%',
    //background: COLORS.gray[0],
  })

  const { isVisible, closeModal, openModal } = useModal()

  const openBottomSheetTransfer = () => {
    openModal()
  }

  /*const {
    isVisible: isEditArticleModalCardVisible,
    closeModal: closeEditArticleModal,
    openModal: openEditArticleModalCard,
  } = useModal()*/

  /*const [inventoryItem, setInventoryItem] = useState<
    InventoryItem | undefined
  >()*/

  const view = useQueryParam('view')

  /*const contextMenuStyles = css({
    background: COLORS.gray[0],
    marginTop: '20px',
  })
  })*/

  //When we add a new article
  /*const onInventoryItemChange = (
    inventoryItem: InventoryItemInput
  ) => {
    console.log(inventoryItem)
    addData({
      variables: { inventoryItem },
    })
      .then((r) => refetch())
      .catch((e) => console.log(e))
  }*/

  //When we select articles for checkout
  const onUpdateItemIsSelected = (
    itemToUpdate: InventoryItem,
    isSelected: boolean
  ) => {
    const dataDictionary: { [x: string]: InventoryItem } = {}
    for (const inv of data) {
      dataDictionary[inv._id] = inv
    }

    // create object dictionary for easier manipulation
    const dictionary: { [x: string]: AdjustItem } = {}
    for (const ci of itemsToAdjust) {
      dictionary[ci.item._id] = ci
    }

    //Add or update Item based on _id
    dictionary[itemToUpdate._id] = {
      item: itemToUpdate,
      quantity: 0,
      delete: isSelected,
      diff: 0,
    }

    // Convert dictionary to array of objects
    const selected = Array.from(Object.entries(dictionary))
      .filter(([key, value]) => value.delete)
      .map(([k, v]) => ({
        ...v,
        diff: v.quantity - dataDictionary[k].quantity,
      }))

    /*const diffList = Array.from(Object.entries(dictionary))
      .filter(
        ([key, value]) =>
          value.quantity !== dataDictionary[key].quantity
      )
      .map(([k, v]) => ({
        ...v,
        quantity: v.quantity - dataDictionary[k].quantity,
      }))*/

    //console.log('adjust', selected)
    //console.log('selected length', selected.length)

    setItemsToAdjust(selected)
    //setdiffItems(diffList)
  }

  const handleSaveAdjustment = async () => {
    await createAdjustment()
    await deleteInventoryItems()
    refetch()
    closeModal()
  }

  const createAdjustment = async () => {
    if (itemsToAdjust.length > 0) {
      try {
        const result = await client.mutate({
          mutation: gql`
            ${PERFORM_CORRECTION_TRANSACTION_MULTIPLE}
          `,
          variables: {
            inventories: itemsToAdjust
              .filter((i) => i.quantity >= 0)
              .map((i) => ({
                id: i.item._id,
                quantity: i.quantity,
                diff: i.diff,
              })),
            type: STORAGE_SPACE_TRANSACTION_TYPE.KORRIGERING,
            comment: notes,
          },
        })
        console.log('result', result)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const deleteInventoryItems = async () => {
    if (itemsToAdjust.length > 0) {
      for (const item of itemsToAdjust) {
        try {
          const result = await client.mutate({
            mutation: gql`
              ${DELETE_INVENTORY_ITEM}
            `,
            variables: {
              id: item.item._id,
            },
          })
          console.log('result', result)
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  /*const contextMenuStyles = css({
    //bottom: '70px',
    padding: '12px 24px',
    width: '100%',
    //position: 'fixed',
    background: COLORS.gray[0],
  })*/

  const cartStyles = css({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: '0.5em',
    padding: '1rem 1.5rem 0 1.5rem',
    borderTop: '1px lightgray solid',
    '.article-info': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  const storageInfoStyles = css({
    padding: '10px 15px 10px 15px',
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'auto 1fr',
    marginTop: '10px',
    borderBottom: '1px lightgray solid',
    position: 'fixed',
    top: 45,
    height: 40,
    width: '100%',
    background: COLORS.gray[0],
    '.icon': {
      display: 'grid',
      minHeight: 24,
      placeContent: 'center center',
      fontSize: '24px',
    },
    '.description': {
      paddingTop: '6px',
    },
  })

  const styles = css({
    display: 'grid',
    color: COLORS.gray[90],
    padding: view === 'card' ? '24px' : '0',
    gap: '1em',
    '.button-wrapper-reset': {
      display: 'flex',
      gap: '0.5em',
      padding: '0 24px',
      '.article-info': {},
    },
  })

  // @ts-ignore
  return (
    <>
      <SimpleHeader text={`Korrigera Saldo`}>
        {/*
        <MoveToInbox onClick={() => openMoveToStorageModal()} />
        <Search onClick={() => openMoveToOrderModal()} />
      */}
      </SimpleHeader>
      <LayoutStandard scroll={true} padding={'45px 0 0 0'}>
        <div css={storageInfoStyles}>
          <div className="icon">{getIconByVariant(type)}</div>
          <div className="description">{description}</div>
        </div>
        <section css={styles}>
          <>
            <StorageListView>
              {data.length > 0 &&
                getSortedArray(
                  data.filter((i) => i.quantity >= 0),
                  (a, b) =>
                    parseInt(a.item.number) - parseInt(b.item.number)
                ).map((inventoryItem: InventoryItem, i: number) => (
                  <React.Fragment
                    key={`item${inventoryItem.item.name}`}
                  >
                    <StorageListItemSelect
                      key={inventoryItem.item.name}
                      {...inventoryItem}
                      onUpdateIsSelected={(isSelected: boolean) => {
                        console.log('onUpdateIsSelected', isSelected)
                        onUpdateItemIsSelected(
                          inventoryItem,
                          isSelected
                        )
                      }}
                    />
                    {i < data.length - 1 && (
                      <Divider
                        key={`divider${inventoryItem.item.name}`}
                      />
                    )}
                  </React.Fragment>
                ))}
            </StorageListView>
          </>
        </section>
      </LayoutStandard>
      {itemsToAdjust.length > 0 && (
        <div css={cartStyles}>
          <div className="article-info">
            {itemsToAdjust.length}
            {itemsToAdjust.length === 1
              ? ' artikel '
              : ' artiklar '}{' '}
            att korrigera
          </div>
          <Button
            variant={ButtonVariant.Primary}
            onClick={() => openBottomSheetTransfer()}
            disabled={itemsToAdjust.length === 0}
          >
            Korrigera
          </Button>
        </div>
      )}
      <Modal isVisible={isVisible} closeModal={closeModal}>
        <>
          <AdjustItemList itemList={itemsToAdjust}></AdjustItemList>
          <section css={bottomSheetStyles}>
            <section css={notesFieldStyles}>
              <TextField
                placeholder="Notering"
                value={notes}
                onChange={(e) => handleNotesChange(e)}
              />
            </section>
            <Button
              variant={ButtonVariant.Outlined}
              onClick={() => {
                handleSaveAdjustment()
              }}
            >
              Ta bort markerade artiklar
            </Button>
          </section>
        </>
      </Modal>
    </>
  )
}

const AdjustStorageInventoryRemove = withQuery<StorageSpace>({
  query: GET_STORAGE_SPACE,
  loader: EkLoader,
})(
  withMutation<
    StorageSpaceTransaction,
    { inventoryItem: InventoryItemInput }
  >(PERFORM_ADD_TRANSACTION)(_AdjustStorageInventoryRemove)
)

export { AdjustStorageInventoryRemove }
