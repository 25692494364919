/* eslint-disable max-lines */
import React, { FC } from 'react'
import { H5 } from '@components/base'
import { RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { Button } from '@pure-components/input'

interface ModalAddToHomeScreenProps extends RouteComponentProps {
  closeModal?: () => void
}

const _ModalAddToHomeScreen: FC<
  ModalAddToHomeScreenProps & RouteComponentProps
> = ({ closeModal = () => {} }) => {
  const styles = css({
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto',
    overflow: 'hidden',
    '.title': {
      marginBottom: '10px',
    },
    h5: {
      fontSize: '1.2rem',
    },
  })

  const handleAddToHomeScreen = () => {
    closeModal()
  }

  return (
    <section css={styles}>
      <H5 className="title">Lägg till som app på hemskärmen?</H5>

      <Button onClick={() => handleAddToHomeScreen()}>
        Lägg till
      </Button>
    </section>
  )
}

const ModalAddToHomeScreen = _ModalAddToHomeScreen

export { ModalAddToHomeScreen }
