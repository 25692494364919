import { ApolloError } from '@apollo/client'
import React, { FC } from 'react'

interface DefaultErrorProps {
  queryError?: ApolloError | undefined
}

const DefaultError: FC<DefaultErrorProps> = ({ queryError }) => {
  console.error(queryError)
  return <div>Error</div>
}

export { DefaultError }
