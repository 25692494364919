// This should be the type that is actually stored in context

//import { AppRoutes } from '@router/app-routes'

// Replace when user in context is implemented
interface UserObject {
  role: string
}

/*
 If the default role in user context has a dummy role like "unauthorized"
 until it gets its actual role, this should be a sufficient check
*/
const createHasAccess = (roleMap: { [x: string]: string[] } = {}) => (
  { role }: UserObject,
  path: string
) => {
  const route = createRoute(path)
  /*console.log(
    'has access',
    path,
    route,
    roleMap[role] && roleMap[role].includes(route)
  )*/
  /*console.log(
    'has access',
    roleMap[role] && roleMap[role].includes(route)
  )*/
  return roleMap[role] && roleMap[role].includes(route)
}

const createRoute = (path: string) => {
  return path.replaceAll(/[0-9]+/g, ':id')
}

export { createHasAccess }

export type { UserObject }
