/* eslint-disable max-lines */
import LogRocket from 'logrocket'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'

import { LayoutStandard } from '@layouts'
import { useBottomNav, useIsAdmin } from '@hooks'
import { NavItem } from '@context'
import { Scan } from '@pure-components/graphics'

import { A, H4 } from '@components/base'
import { HomeCard } from '@components/pages-components/home'
import {
  AddCircleOutlineRounded,
  AssignmentOutlined,
  HelpOutlineRounded,
  SearchOutlined,
} from '@material-ui/icons'
import { TEXT_STYLE } from '@styles'
import { AppRoutes } from '@router'
//import { isPWAInstalled } from '@common/is-pwa-installed'
import { Modal, useModal } from '@components/modal'
import { ModalAddToHomeScreen } from '@components/pages-components/home/modal-add-to-home-screen'
import '@common/search/search-items' //cancellableArticleSearch,
import { GET_USER } from '@api/user'
import { client } from '@api/client'
import { User } from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
//searchItems,
//searchReferences,

const Home = (_: RouteComponentProps) => {
  const { setActiveItem } = useBottomNav()

  const { userId } = useIsAdmin()

  const {
    isVisible,
    closeModal,
    //openModal,
  } = useModal()

  const [loading, setLoading] = useState(true)
  const [userName, setUserName] = useState('')

  //const isPWA = isPWAInstalled()

  useEffect(() => {
    const getUser = async (): Promise<User> => {
      const query = GET_USER
      //console.log('query:', query, variables)
      const result = await client.query({
        query,
        variables: {
          id: userId,
        },
      })
      //console.log(result.data)
      return result.data.findUserByID
    }
    // Run! Like go get some data from an API.
    const runLoader = async () => {
      try {
        const user = await getUser()
        setUserName(user.firstName)
        LogRocket.identify(user._id, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.account?.phoneNumber || '',
        })
      } catch (e) {}
      setLoading(false)
    }
    runLoader()
  }, [userId])

  useEffect(() => {
    /*const pwaPromptTime = getPWAPromptTime()
    const showPrompt = !isPWA && (!pwaPromptTime || Date.parse(pwaPromptTime) < Date.now() - 1000*60*2)
    if (showPrompt) {
      openModal()
      storePWAPromptTime(Date.now())
    }*/
    /*const trySearch = async () => {
      const search = cancellableArticleSearch('0435')

      //const items = await search.items
      //const refs = await search.refs
      await new Promise((r) => setTimeout(r, 1000))
      search.abort()
      await new Promise((r) => setTimeout(r, 1000))
      const search2 = cancellableArticleSearch('0435')
      const items = await search2.items
      const refs = await search2.refs
      console.log(items, refs)
    }*/

    //trySearch()
    setActiveItem(NavItem.Home)
  })

  const styles = css({
    padding: '16px',
    paddingTop: '32px',
    display: 'grid',
    rowGap: '16px',
    '.scan': {
      display: 'grid',
      rowGap: '12px',
      placeItems: 'center center',
      padding: '32px 0',
    },
    '.scantext': [TEXT_STYLE.button[1]],
    '.cards': {
      width: '240px',
      margin: '0 auto',
      display: 'grid',
      placeItems: 'center center',
      gridTemplateColumns: 'repeat( auto-fit, minmax(100px, 1fr) )',
      gap: '1em',
    },
  })

  const cardData = [
    {
      label: 'Se mina order',
      icon: <AssignmentOutlined />,
      url: AppRoutes.WorkingOrders,
    },
    {
      label: 'Sök Artikel',
      icon: <SearchOutlined />,
      url: AppRoutes.ArticleSearch,
    },

    {
      label: 'Jag har en fråga',
      icon: <HelpOutlineRounded />,
      url: AppRoutes.Help,
    },
    {
      label: 'Lägg till ny lagerplats',
      icon: <AddCircleOutlineRounded />,
      url: AppRoutes.NewStorage,
    },
  ]

  return (
    <>
      {!loading && (
        <>
          <LayoutStandard>
            <div css={styles}>
              <H4>Hej {userName}</H4>
              <section className="scan">
                <A to={`${AppRoutes.Scan}`}>
                  <Scan />
                </A>
                <p className="scantext">Skanna kod</p>
              </section>
              <section className="cards">
                {cardData.map((card) => (
                  <HomeCard {...card} key={card.label} />
                ))}
              </section>
            </div>
          </LayoutStandard>
          <Modal
            isVisible={isVisible}
            closeModal={closeModal}
            autoHeight={true}
          >
            <ModalAddToHomeScreen closeModal={closeModal} />
          </Modal>
        </>
      )}
      {loading && <EkLoader />}
    </>
  )
}

export { Home }
