import React from 'react'

const Scan = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M3.5 21V3H21.5" stroke="#939599" strokeWidth="6" />
        <path
          d="M59.5 3L77.5 3L77.5 21"
          stroke="#939599"
          strokeWidth="6"
        />
        <path
          d="M77.5 59L77.5 77L59.5 77"
          stroke="#939599"
          strokeWidth="6"
        />
        <path
          d="M21.5 77L3.5 77L3.5 59"
          stroke="#939599"
          strokeWidth="6"
        />
        <path d="M0.5 40L80.5 40" stroke="#939599" strokeWidth="6" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="80"
            height="80"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export { Scan }
