import { css } from '@emotion/react'
import { COLORS } from '@styles'
import React, { FC, ReactNode } from 'react'
import { A } from '@components/base'

interface NavBottomSheetItemProps {
  text: string
  icon: ReactNode
  to: string
}

const NavBottomSheetItem: FC<NavBottomSheetItemProps> = ({
  text,
  icon,
  to,
}) => {
  const iconCircleSize = '36px'

  const style = css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: '18px',
    padding: '12px 34px',
    '.icon': {
      color: COLORS.gray[90],
      background: COLORS.orange[10],
      height: iconCircleSize,
      width: iconCircleSize,
      fontSize: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '9999px',
    },
  })

  return (
    <A style={style} to={to}>
      <span className="icon">{icon}</span>
      <span>{text}</span>
    </A>
  )
}

export { NavBottomSheetItem }
