import { navigate } from '@reach/router'
import { AppRoutes } from '@router'
import { clearAccessToken } from './local-store'

const signOut = () => {
  clearAccessToken()
  navigate(AppRoutes.Login)
}

export { signOut }
