import { User } from '@types'

const ACCESS_TOKEN_STORE_KEY = 'access_token'
const USER_STORE_KEY = 'ek_user'
const PWA_PROMPT_KEY = 'pwa_prompt_timestamp'

const storeAccessToken = (token: string): void => {
  localStorage.setItem(ACCESS_TOKEN_STORE_KEY, token)
}

const clearAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_STORE_KEY)
}

const getAccessTokenFromStore = (): string => {
  return localStorage.getItem(ACCESS_TOKEN_STORE_KEY) ?? ''
}

const storeUser = (user: User): void => {
  localStorage.setItem(USER_STORE_KEY, JSON.stringify(user))
}

const getUserFromStore = (): User | null => {
  const data = localStorage.getItem(USER_STORE_KEY) ?? ''
  return data ? JSON.parse(data) : null
}

const storePWAPromptTime = (time: string): void => {
  localStorage.setItem(PWA_PROMPT_KEY, time)
}

const getPWAPromptTime = (): string => {
  return localStorage.getItem(PWA_PROMPT_KEY) ?? ''
}

export {
  storeAccessToken,
  clearAccessToken,
  getAccessTokenFromStore,
  storeUser,
  getUserFromStore,
  storePWAPromptTime,
  getPWAPromptTime,
}
