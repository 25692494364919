/* eslint-disable max-lines */
import React, { FC, useState } from 'react'
import { H5 } from '@components/base'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { RouteComponentProps } from '@reach/router'
import { User, UserPage } from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { css } from '@emotion/react'
import { Button, TextField } from '@pure-components/input'
import { EkList, EkListCell, EkListRow } from '@components/ek-list'
import { GET_USERS } from '@api/user'

interface ModalSelectUserProps extends RouteComponentProps {
  closeModal?: (userId: string | undefined) => void
}

const _ModalSelectUser: FC<
  ModalSelectUserProps &
    RouteComponentProps &
    WithQueryExtension<UserPage>
> = ({
  queryData,
  previousPage,
  nextPage,
  closeModal = () => {},
}) => {
  const {
    users: { data = [] },
  } = queryData

  const [searchFilter, setSearchFilter] = useState<string>('')
  const [selectedUser, setselectedUser] = useState<null | User>(null)

  const styles = css({
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto',
    overflow: 'hidden',
    '.title': {
      marginBottom: '10px',
    },
  })

  const handleSearchChange = (event: any) => {
    setSearchFilter(event.target.value)
  }

  const onRowClick = (clickedItem: User) => {
    if (selectedUser?._id === clickedItem._id) {
      setselectedUser(null)
    } else setselectedUser(clickedItem)
  }

  const isButtonDisabled = () => {
    return selectedUser === null
  }

  return (
    <section css={styles}>
      <H5 className="title">Välj användare</H5>
      <TextField
        placeholder="Sök användare"
        inputType="search"
        value={searchFilter}
        onChange={(e) => handleSearchChange(e)}
      />

      <EkList>
        {data
          .filter(
            (user: User) =>
              `${user.firstName} ${user.lastName}`.indexOf(
                searchFilter
              ) > -1 ||
              user.firstName.indexOf(searchFilter) > -1 ||
              user.lastName.indexOf(searchFilter) > -1
          )
          .map((user: User, i) => (
            <EkListRow
              key={user._id}
              classes={
                selectedUser?._id === user._id ? 'selected' : ''
              }
              onClick={(e) => onRowClick(user)}
            >
              <EkListCell>
                <p>{`${user.firstName} ${user.lastName}`}</p>
              </EkListCell>
            </EkListRow>
          ))}
      </EkList>

      <Button
        disabled={isButtonDisabled()}
        onClick={() => closeModal(selectedUser?._id)}
      >
        Tilldela Order
      </Button>
    </section>
  )
}

const ModalSelectUser = withQuery<UserPage>({
  query: GET_USERS,
  variables: { _size: 5000 },
  loader: EkLoader,
})(_ModalSelectUser)

export { ModalSelectUser }
