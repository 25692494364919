/* eslint-disable max-lines */
// TODO: Remove this once GraphQL is implemented. This was added so the ghost data could be present for testing purposes. It will be checked into git for demo purposes. Ignore this behaviour in Code Review.
import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
//import { Search } from '@material-ui/icons'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { useBottomNav, useQueryParam } from '@hooks'
import { NavItem } from '@context'
import { SimpleHeader } from '@components/header'

//import { EkViewSwitcher } from '@components/ek-view-switcher'
import { Divider } from '@pure-components/graphics'
import { A } from '@components/base'
import { COLORS } from '@styles'
import { IncomingOrderPage } from '@types'
import { withQuery, WithQueryExtension } from '@components/with-query'

import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { Pagination } from '@components/pagination'
import { AppRoutes } from '@router/app-routes'
import { IncomingOrdersListView } from '@components/pages-components/incoming-products/incoming-orders-list-view'
import { IncomingOrdersListItem } from '@components/pages-components/incoming-products/incoming-orders-list-item'
import { GET_INCOMING_ORDERS_LIMITED } from '@api/incoming-order'
//import { sortByDate } from '@common/sort-by-date'
//import { getSortedArray } from '@common/get-sorted-array'

const _IncomingProducts: FC<
  RouteComponentProps & WithQueryExtension<IncomingOrderPage>
> = ({ queryData, previousPage, nextPage }) => {
  const {
    incomingOrders: { data = [], after, before },
  } = queryData
  const { activeItem, setActiveItem } = useBottomNav()

  //console.log(data)

  useEffect(() => {
    setActiveItem(NavItem.Order)
  }, [activeItem, setActiveItem])

  //const location = useLocation()
  const view = useQueryParam('view')

  const styles = css({
    color: COLORS.gray[90],
    width: '100%',
    padding: view === 'card' ? '24px' : '0',
    '.heading': {
      margin: '12px 24px',
    },
    '.spacingtop': {
      marginTop: '2em',
    },
  })

  // TODO: Once GQL is implemented, rework theses to fit the data structure

  // const filterMyOrders = workingOrderData.filter(
  //   (o) => o.assignTo === 'me'
  // )
  // const filterAllOrders = workingOrderData.filter(
  //   (o) => o.assignTo !== 'me'
  // )

  return (
    <>
      <SimpleHeader text="Inkommande Order">
        {/*<EkViewSwitcher view={view} location={location} />
        <Search />*/}
      </SimpleHeader>
      <LayoutStandard scroll={true}>
        <section css={styles}>
          <>
            <IncomingOrdersListView>
              {data &&
                data.map((order, i) => (
                  <React.Fragment key={`incomingorder${order._id}`}>
                    <A
                      to={`${AppRoutes.IncomingProducts}/${order._id}`}
                    >
                      <IncomingOrdersListItem
                        key={order._id}
                        {...order}
                      />
                    </A>
                    {i < data.length - 1 && (
                      <Divider key={`divider${order._id}`} />
                    )}
                  </React.Fragment>
                ))}
            </IncomingOrdersListView>
          </>
        </section>
      </LayoutStandard>
      <Pagination
        before={before}
        after={after}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </>
  )
}

const IncomingProducts = withQuery<IncomingOrderPage>({
  query: GET_INCOMING_ORDERS_LIMITED,
  variables: { _size: 100 },
  loader: EkLoader,
})(_IncomingProducts)

export { IncomingProducts }
