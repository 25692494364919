/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/media-has-caption */
import { css } from '@emotion/react'
import React, {
  ChangeEvent,
  FC,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Button as MaterialButton } from '@material-ui/core'
/*eslint-disable */
import { FlashOn, SwitchCamera } from '@material-ui/icons'
/*eslint-enable */

import { Html5Qrcode } from 'html5-qrcode'
import { CameraDevice } from 'html5-qrcode/esm/camera/core'
import { scanImageData } from 'zbar.wasm'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { P1 } from '@components/base'
import { getImageData, processImage } from './barcode-utils'

// inspired by https://unpkg.com/browse/scandit-sdk@4.6.1/src/lib/cameraAccess.ts
const backCameraKeywords: string[] = [
  'rear',
  'back',
  'rück',
  'arrière',
  'trasera',
  'trás',
  'traseira',
  'posteriore',
  '后面',
  '後面',
  '背面',
  '后置', // alternative
  '後置', // alternative
  '背置', // alternative
  'задней',
  'الخلفية',
  '후',
  'arka',
  'achterzijde',
  'หลัง',
  'baksidan',
  'bagside',
  'sau',
  'bak',
  'tylny',
  'takakamera',
  'belakang',
  'אחורית',
  'πίσω',
  'spate',
  'hátsó',
  'zadní',
  'darrere',
  'zadná',
  'задня',
  'stražnja',
  'belakang',
  'बैक',
]

/*enum Html5QrcodeSupportedFormats {
  QR_CODE = 0,
  AZTEC,
  CODABAR,
  CODE_39,
  CODE_93,
  CODE_128,
  DATA_MATRIX,
  MAXICODE,
  ITF,
  EAN_13,
  EAN_8,
  PDF_417,
  RSS_14,
  RSS_EXPANDED,
  UPC_A,
  UPC_E,
  UPC_EAN_EXTENSION,
}*/

interface MediaDevice {
  deviceId: string
  groupId: string
  kind: MediaDeviceKind
  label: string
}

interface BarcodeScannerProps {
  onMatch?: (barcode: string) => void
  onNoMatch?: () => void
  intervalForScan?: number
  resumeTrigger?: number
}

/* function contrastImage(imageData: any, contrast: number) {
  const data = imageData.data
  const factor = (259 * (contrast + 255)) / (255 * (259 - contrast))

  for (let i = 0; i < data.length; i += 4) {
    data[i] = factor * (data[i] - 128) + 128
    data[i + 1] = factor * (data[i + 1] - 128) + 128
    data[i + 2] = factor * (data[i + 2] - 128) + 128
  }
  return imageData
}

const whiteoutImage = (imageData: any) => {
  const data = imageData.data
  const threshold = 100

  for (let i = 0; i < data.length; i += 4) {
    if (
      data[i] < threshold &&
      data[i + 1] < threshold &&
      data[i + 2] < threshold
    ) {
      data[i] = data[i] * 0.2
      data[i + 1] = data[i + 1] * 0.2
      data[i + 2] = data[i + 2] * 0.2
    } else {
      data[i] = Math.min(data[i] * 1.5, 255)
      data[i + 1] = Math.min(data[i + 1] * 1.5, 255)
      data[i + 2] = Math.min(data[i + 2] * 1.5, 255)
    }
  }
  return imageData
}

const processImage = (imageData: any) => {
  return contrastImage(whiteoutImage(imageData), 200)
} */

/* const getImageData = (src: string): Promise<ImageData> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      //const canvas = createCanvas(img.width, img.height)
      const ctx = canvas.getContext('2d')
      ctx!.drawImage(img, 0, 0)
      resolve(ctx!.getImageData(0, 0, img.width, img.height))
    }
    img.src = src
  })
} */

const gotDevices = (
  mediaDevices: CameraDevice[]
): Promise<CameraDevice[]> =>
  new Promise((resolve, reject) => {
    const availableVideoInputs: CameraDevice[] = []
    //console.log('Media Devices', mediaDevices)
    availableVideoInputs.push(...mediaDevices)

    if (availableVideoInputs.length > 0) {
      const backCameras = availableVideoInputs.filter((d) =>
        isBackCameraLabel(d.label)
      )
      //const backCameras: CameraDevice[] = []
      const devices =
        backCameras.length > 0 ? backCameras : availableVideoInputs
      resolve(devices)
    } else {
      reject(new Error('ERR::NO_MEDIA_TO_STREAM'))
    }
  })

const isBackCameraLabel = (label: string): boolean => {
  const lowercaseLabel = label.toLowerCase()

  return backCameraKeywords.some((keyword) =>
    lowercaseLabel.includes(keyword)
  )
}

const closeStream = (stream: MediaProvider | null | undefined) => {
  //console.log('closing stream')
  return new Promise<void>((resolve, reject) => {
    try {
      if (stream) {
        ;(stream as MediaStream)
          .getTracks()
          .forEach((track) => track.stop())
      }
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

const BarcodeScanner: FC<BarcodeScannerProps> = ({
  onMatch = () => {},
  onNoMatch = () => {},
  intervalForScan = 300,
  resumeTrigger = 0,
}) => {
  const videoRef = useRef<HTMLDivElement>(null)
  const fileRef = useRef<HTMLDivElement>(null)

  const [videoDevices, setVideoDevices] = useState<CameraDevice[]>([])
  const [previousDevice, setPreviousDevice] = useState(-1)
  const [device, setDevice] = useState(0)
  const [reader, setReader] = useState<null | Html5Qrcode>(null)
  /*eslint-disable */
  const [fileReader, setFileReader] = useState<null | Html5Qrcode>(
    /*eslint-enable */
    null
  )
  const [flashOn, setFlashOn] = useState(false)

  const [loading, setLoading] = useState(false)
  const [warning, setWarning] = useState(false)

  //let qrReader: null | Html5Qrcode = null

  let isScanningCode = false

  useEffect(() => {
    if (resumeTrigger) {
      //console.log('resumeScan')
      if (reader) {
        reader!.resume()
      }
    }
  }, [resumeTrigger, reader])

  useEffect(() => {
    setReader(new Html5Qrcode('qr-reader'))
    setFileReader(new Html5Qrcode('file-reader'))
    /*try {
      Quagga.registerReader('qrcode', QrCodeReader)
    } catch (e) {
      console.log('qr reader already registered')
    }*/

    const getDevices = () => {
      Html5Qrcode.getCameras()
        .then(gotDevices)
        .then((cameras: CameraDevice[]) => {
          setVideoDevices(cameras)
        })
        .catch(handleError)
    }

    getDevices()

    return function unmount() {
      //console.log('unmount')
      const cleanup = async () => {
        try {
          await closeStream(
            /* eslint-disable */
            (videoRef.current?.firstElementChild as HTMLVideoElement)
              ?.srcObject
            /* eslint-enable */
          )
        } catch (e) {
          console.log(e)
        }
      }
      cleanup()
    }
  }, [])

  function handleError(error: any) {
    console.log(
      'navigator.MediaDevices.getUserMedia error: ',
      error.message,
      error.name
    )
  }

  const scanData = (
    data: ImageData,
    debug_str: string
  ): Promise<string> => {
    //console.log('imagedata', data)
    return new Promise((resolve, reject) => {
      scanImageData(data)
        .then((result) => {
          if (result.length > 0) {
            //console.log(result[0].typeName) // ZBAR_QRCODE
            const code = result[0].decode()
            //console.log(debug_str, result[0].typeName, code)
            resolve(code)
          } else {
            //console.log('no result', result)
            reject('no result')
          }
        })
        .catch((e) => {
          console.log(e)
          reject(e)
        })
    })
  }

  const codePromiseHandler = (code: string) => {
    if (isScanningCode) {
      isScanningCode = false
      setLoading(false)
      setWarning(false)
      onMatch(code)
    }
  }

  const handleImageFile = async (f: File) => {
    const processPromises: Promise<ImageData>[] = []
    const scanPromises: Promise<string>[] = []
    const src = URL.createObjectURL(f)
    const original = await getImageData(src)

    /*const original_src = URL.createObjectURL(
      await ImageDataToBlob(original)
    )*/
    //promises.push(scanData(original, 'original'))

    isScanningCode = true

    const pO = scanData(original, 'original')
    pO.then(codePromiseHandler)
    scanPromises.push(pO)

    const pr1 = processImage(original, 10, 20)
    pr1.then((data) => {
      //console.log('scan v1', 'isScanning = ', isScanningCode)
      const ps = scanData(data, 'v1: contrast 10, threshold 20')
      ps.then(codePromiseHandler).catch((e) => {})
      scanPromises.push(ps)
    })
    processPromises.push(pr1)
    //const v1_src = URL.createObjectURL(await ImageDataToBlob(v1))

    //promises.push(scanData(v1, 'v1: contrast 10, threshold 20'))

    //const v2 = processImage(original, 30, 60)
    //const v2_src = URL.createObjectURL(await ImageDataToBlob(v2))

    //promises.push(scanData(v2, 'v2: contrast 30, threshold 60'))
    //scanData(v2, 'v2: contrast 30, threshold 60')
    const pr2 = processImage(original, 30, 60)
    pr2.then((data) => {
      const ps = scanData(data, 'v2: contrast 30, threshold 60')
      ps.then(codePromiseHandler).catch((e) => {})
      scanPromises.push(ps)
    })
    processPromises.push(pr2)

    //const v3 = processImage(original, 50, 100)
    //const v3_src = URL.createObjectURL(await ImageDataToBlob(v3))

    //promises.push(scanData(v3, 'v3: contrast 50, threshold 100'))
    const pr3 = processImage(original, 50, 100)
    pr3.then((data) => {
      const ps = scanData(data, 'v3: contrast 50, threshold 100')
      ps.then(codePromiseHandler).catch((e) => {})
      scanPromises.push(ps)
    })
    processPromises.push(pr3)

    //const v4 = processImage(original, 50, 150)
    //const v4_src = URL.createObjectURL(await ImageDataToBlob(v4))

    //promises.push(scanData(v4, 'v4: contrast 50, threshold 150'))

    const pr4 = processImage(original, 50, 150)
    pr4.then((data) => {
      const ps = scanData(data, 'v4: contrast 50, threshold 150')
      ps.then(codePromiseHandler).catch((e) => {})
      scanPromises.push(ps)
    })
    processPromises.push(pr4)

    //const v5 = processImage(original, 200, 100)
    //const v5_src = URL.createObjectURL(await ImageDataToBlob(v5))

    //promises.push(scanData(v5, 'v5: contrast 200, threshold 100'))

    const pr5 = processImage(original, 200, 100)
    pr5.then((data) => {
      const ps = scanData(data, 'v5: contrast 200, threshold 100')
      ps.then(codePromiseHandler).catch((e) => {})
      scanPromises.push(ps)
    })
    processPromises.push(pr5)

    await Promise.allSettled(processPromises)
    Promise.allSettled(scanPromises).then((results) => {
      isScanningCode = false
      setLoading(false)
      let allRejected = true
      for (const r of results) {
        if (r.status === 'fulfilled') {
          allRejected = false
          break
        }
      }

      if (allRejected) {
        setWarning(true)
      } else {
        setWarning(false)
      }
    })
  }

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    //console.log('onChange')
    if (e.target.files && e.target.files.length === 0) {
      // No file selected, ignore
      return
    }

    const imageFile = e.target.files![0]
    setLoading(true)
    handleImageFile(imageFile)
    /*fileReader!
      .scanFile(imageFile, true)
      .then((decodedText) => {
        // success, use decodedText

        console.log('decoded text', decodedText)
        setLoading(false)
        setWarning(false)
        onMatch(decodedText)
      })
      .catch(async (err) => {
        // failure, handle it.
        console.log(`Error scanning file. Reason: ${err}`)
        const src = URL.createObjectURL(imageFile)
        const img: any = processImage(await getImageData(src))
        const res = await scanImageData(img)
        console.log('zbar result', res)
        if (res.length > 0) {
          console.log(res[0].typeName) // ZBAR_QRCODE
          const code = res[0].decode()
          console.log(code)
          setLoading(false)
          setWarning(false)
          onMatch(code)
        } else {
          setLoading(false)
          setWarning(true)
          //addAlert('Barcode not found. Try Again.')
        }
      })*/
    /*const config: QuaggaJSConfigObject = {
      src,
      numOfWorkers: 4,
      decoder: {
        readers: ['code_128_reader', 'code_39_reader', 'qrcode'],
      },
      locate: true,
    }
    Quagga.decodeSingle(config, (data) => {
      console.log('quagga data', data)
    })*/
  }

  useEffect(() => {
    const onScanSuccess = (
      decodedText: string,
      decodedResult: any
    ) => {
      // console.log(`Code matched = ${decodedText}`, decodedResult)
      //console.log()
      reader!.pause()
      onMatch(decodedText)
    }

    const onScanFailure = (error: any) => {
      //console.warn(`Code scan error = ${error}`)
    }

    if (videoDevices.length > 0) {
      //console.log(videoDevices[device])
      //console.log(reader)

      const cameraId = videoDevices[device].id

      const config = {
        fps: 30,
        qrbox: { width: 280, height: 280 },
        aspectRatio: 4 / 3,
      }

      const enableStream = async () => {
        try {
          //console.log('enableStream')
          const video = videoRef.current
            ?.firstElementChild as HTMLVideoElement
          if (reader && video) {
            video.pause() //.current.pause()
            await closeStream(video.srcObject)
            try {
              await reader!.stop()
            } catch (e) {}
          }

          /*const torchConstraints: any = {
            torch: flashOn,
            //advanced: [{ torch: flashOn }],
          }*/

          //await reader?.applyVideoConstraints(torchConstraints)

          await reader!.start(
            { deviceId: { exact: cameraId } },
            config,
            onScanSuccess,
            onScanFailure
          )
          setPreviousDevice(device)

          //console.log('Torch Constraints', torchConstraints)
          //await reader?.applyVideoConstraints(torchConstraints)
        } catch (err) {
          console.log(err)
          // Removed for brevity
        }
      }

      if (device !== previousDevice) {
        //console.log('device not match', device, previousDevice)
        enableStream()
      }
    }
  }, [videoDevices, previousDevice, device, reader, onMatch, flashOn])

  const switchCamera = async () => {
    const newindex =
      device === videoDevices.length - 1 ? 0 : device + 1
    setPreviousDevice(device)
    setDevice(newindex)
  }

  /*eslint-disable */
  const toggleFlash = () => {
    setFlashOn(!flashOn)
  }
  /*eslint-enable */

  const styles = css({
    position: 'relative',
    maxWidth: '100vw',
    minHeight: '80vh',
    '.video': {
      overflow: 'hidden',
      /*
        Need to set important to override the html
        and make it responsive
      */
      width: '100% !important',
      height: 'auto !important',
    },
    '.qrReader': {
      width: '300px',
      '@media (max-width: 1024px)': {
        marginTop: '-1rem',
        marginBottom: '-2rem',
        clipPath: 'inset(10% 0)',
      },
    },
    '.fileReader': {
      width: '300px',
      '@media (max-width: 1024px)': {
        marginTop: '-1rem',
        marginBottom: '-2rem',
        clipPath: 'inset(10% 0)',
      },
      display: 'none',
    },
    '.cameraFlipButton': {
      position: 'absolute',
      bottom: '15%',
      right: 0,
      marginBottom: '10px',
    },
    '#cameraFileInput': {
      display: 'none',
    },
    '.labelButton': {
      outline: '2px solid rgb(239,150,46)',
      padding: '5px',
      'border-radius': '3px',
      'font-size': '0.8em',
      'font-weight': 400,
    },
    '.fileInputButton': {
      position: 'absolute',
      bottom: '15%',
      left: 0,
      marginBottom: '10px',
    },
    '.FlashButton': {
      position: 'absolute',
      bottom: '15%',
      left: 0,
      marginBottom: '10px',
    },
  })

  return (
    <>
      <div css={styles}>
        <div ref={videoRef} id="qr-reader" className="qrReader"></div>

        <div
          ref={fileRef}
          id="file-reader"
          className="fileReader"
        ></div>
        {loading && <EkLoader />}
        {warning && <P1> Barcode not found. Try again.</P1>}
        {/*<Button className="FlashButton" onClick={() => toggleFlash()}>
        <FlashOn />
  </Button>*/}
        <>
          <div className="fileInputButton">
            <label className="labelButton" htmlFor="cameraFileInput">
              Ta Bild Manuellt
            </label>
          </div>
        </>

        <input
          id="cameraFileInput"
          type="file"
          accept="image/*"
          onChange={(e: any) => handleUpload(e)}
          capture="environment"
        />

        {videoDevices?.length > 1 && (
          <div className="cameraFlipButton">
            <MaterialButton onClick={() => switchCamera()}>
              <SwitchCamera />
            </MaterialButton>
          </div>
        )}
      </div>
    </>
  )
}

export { BarcodeScanner }
