/* eslint-disable max-lines */
// This is likely because of all the GQL-fakedata. I suppose this needs to be reviewed. TODO: Review this.
import React, { FC, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { useSnackbar } from '@hooks'
import { EkList } from '@components/ek-list'
import {
  ExtendedStorage,
  StorageListRowNested,
} from '@components/ek-list/storage-list-row-nested'
import {
  InvItemStub,
  ItemReport,
  StorageSpace,
  StorageSpacePage,
} from '@types'
import { GET_PHYSICAL_STORAGE_SPACES } from '@api/storage-space'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { WithQueryExtension, withQuery } from '@components/with-query'
import { COLORS } from '@styles'
import { Button, ButtonVariant } from '@pure-components/input'
import { H4 } from '@components/base'
import { GET_RELEVANT_INVENTORY_ITEMS } from '@api/special/item-report/item-report-queries'
import { client } from '@api/client'
import {
  CircularSpinnerLoader,
  Snackbar,
} from '@pure-components/feedback'
import { exportToSheetJS } from '@pages/admin/reports/excel-export/excel-export'
import { reduceToReport } from '../utils/compile-report'

const nestData = (flat: StorageSpace[]) => {
  const rootId = '-1'

  const clonedArray: ExtendedStorage[] = JSON.parse(
    JSON.stringify(flat)
  ).map((s: ExtendedStorage) => {
    s.children = []
    if (!s.parent) {
      s.parent = '-1'
    }
    return s
  })

  const r: ExtendedStorage[] = []
  const o: any = {}
  clonedArray.forEach(function (a) {
    if (o[a._id] && o[a._id].children) {
      a.children = o[a._id] && o[a._id].children
    }
    o[a._id] = a
    if (a.parent === rootId) {
      r.push(a)
    } else {
      o[a.parent!] = o[a.parent!] || {}
      o[a.parent!].children = o[a.parent!].children || []
      o[a.parent!].children.push(a)
    }
  })

  const naturalSort = (a: string, b: string): number => {
    const aParts = a
      .split(/(\d+)/)
      .map((part) => (isNaN(parseInt(part)) ? part : parseInt(part)))
    const bParts = b
      .split(/(\d+)/)
      .map((part) => (isNaN(parseInt(part)) ? part : parseInt(part)))

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      if (aParts[i] === undefined) return -1
      if (bParts[i] === undefined) return 1
      if (aParts[i] < bParts[i]) return -1
      if (aParts[i] > bParts[i]) return 1
    }
    return 0
  }

  const sortChildren = (node: ExtendedStorage) => {
    node.children.sort((a, b) => naturalSort(a.name, b.name))
    node.children.forEach(sortChildren)
  }

  /* eslint-disable */
  r.map((p) => {
    p.parent = undefined
    sortChildren(p)
  })
  /* eslint-enable */

  //console.log(r)
  return r
}

const getAllChildren = (item: {
  _id: string
  children?: any[]
}): any[] => {
  let result: string[] = []

  if (item.children) {
    result = item.children.flatMap((child) => [
      child._id,
      ...getAllChildren(child),
    ])
  }

  return result
}

interface ReportProps extends RouteComponentProps {}

const _ReportPage: FC<
  ReportProps & WithQueryExtension<StorageSpacePage>
> = ({ queryData }) => {
  const { addAlert } = useSnackbar()

  const [selectedStorage, setSelectedStorage] = useState<
    undefined | StorageSpace
  >(undefined)

  const [isLoading, setIsLoading] = useState(false)

  const [cachedInventoryItems, setCachedInventoryItems] = useState<{
    expires: number
    data: InvItemStub[]
  }>({ expires: 0, data: [] })

  const {
    getStorageByVirtual: { data = [] },
  } = queryData

  const nestedData = nestData(data.filter((s) => !s.deleted))

  const onRowClick = (clickedItem: StorageSpace) => {
    if (selectedStorage?._id === clickedItem._id)
      setSelectedStorage(undefined)
    else setSelectedStorage(clickedItem)
  }

  const getStorageIds = () => {
    const children = getAllChildren(selectedStorage!)
    return [selectedStorage!._id, ...children]
  }

  const getInventoryItems = async (
    variables: { cursor?: string } = {}
  ) => {
    return await client.query({
      query: GET_RELEVANT_INVENTORY_ITEMS,
      variables,
    })
  }

  const getAllInventoryItems = async () => {
    if (cachedInventoryItems.expires > Date.now()) {
      return cachedInventoryItems.data
    }

    const inventoryResult = await getInventoryItems()

    const page = inventoryResult.data?.['relevantInventoryItems']

    let all: InvItemStub[] = page?.data || []
    let after = page?.after

    if (after) {
      do {
        const inventoryResult = await getInventoryItems({
          cursor: after,
        })
        const page = inventoryResult.data?.['relevantInventoryItems']
        all = all.concat(page?.data || [])
        after = page?.after
      } while (after)
    }

    const expires = Date.now() * 300000 // cache expires in 5 minutes
    setCachedInventoryItems({ expires, data: all })

    return all
  }

  const getReportForSelected = async () => {
    setIsLoading(true)
    try {
      const storageIds = getStorageIds()

      const all = await getAllInventoryItems()

      const filtered = all.filter((entry) =>
        storageIds.includes(entry.storageSpace!.id)
      )

      const report = reduceToReport(filtered)

      setIsLoading(false)
      return report
    } catch (e) {
      setIsLoading(false)
      console.log(e)
      addAlert(<Snackbar text={'Something went wrong'} />)
    }

    //let entries = report.data?.filter(entry => entry.) || []
  }

  const getReport = async (): Promise<ItemReport | undefined> => {
    try {
      setIsLoading(true)
      /*const result = await client.query({
        query: GET_ITEM_REPORT_ALL,
      })*/
      const result = await getAllInventoryItems()
      const report = reduceToReport(result)
      setIsLoading(false)
      addAlert(<Snackbar text={'Success'} />)
      return report
      //exportExcel(result.data[key], exportName)
      //exportToSheetJS(result.data[key], exportName, exportName)
    } catch (e) {
      setIsLoading(false)
      addAlert(<Snackbar text={'Something went wrong'} />)
      console.log(e)
    }
  }

  const getReportWarnings = async () => {
    setIsLoading(true)
    try {
      const all = await getAllInventoryItems()

      const filtered = all.filter(
        (entry) =>
          (entry.quantity || 0) < (entry.item?.balanceWarning || 0)
      )

      const report = reduceToReport(filtered)

      setIsLoading(false)
      return report
    } catch (e) {
      setIsLoading(false)
      console.log(e)
      addAlert(<Snackbar text={'Something went wrong'} />)
    }

    //let entries = report.data?.filter(entry => entry.) || []
  }

  const handleExportAllButton = async () => {
    const report = await getReport()
    if (!report) {
      addAlert(<Snackbar text={'Something went wrong'} />)
    } else {
      exportToSheetJS(
        report,
        'elkrafterna_lagersystem',
        'elkrafterna_lagersystem'
      )
    }
  }

  const handleExportSelectedButton = async () => {
    const selectedReport = await getReportForSelected()
    if (!selectedReport) {
      addAlert(<Snackbar text={'Something went wrong'} />)
    } else {
      exportToSheetJS(
        selectedReport,
        selectedStorage!.name,
        selectedStorage!.name
      )
    }
  }

  const handleExportWarningsButton = async () => {
    const selectedReport = await getReportWarnings()
    if (!selectedReport?.data) {
      addAlert(<Snackbar text={'Something went wrong'} />)
    } else if (selectedReport.data?.length === 0) {
      addAlert(<Snackbar text={'Inga artiklar under brytpunkt'} />)
    } else {
      exportToSheetJS(
        selectedReport,
        'Saldo_under_brytpunkt',
        'Saldo_under_brytpunkt'
      )
    }
  }

  const styles = css({
    height: 'calc(100vh - 70px)',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: '"left right"',
    '.left': {
      padding: '2rem',
      gridArea: 'left',
      height: '100%',
    },
    '.right': {
      gridArea: 'right',
      background: COLORS.gray[10],
      height: '100%',
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '"list"',
      overflowY: 'scroll',
      borderLeft: `2px solid ${COLORS.gray[50]}`,
    },
    '.storageList': {
      //maxHeight: '60%',
      gridArea: 'list',
    },
    '.buttonRow': {
      display: 'flex',
      flexDirection: 'row',

      marginTop: '0.5rem',
      paddingTop: '1rem',

      justifyContent: 'space-between',

      borderTop: `2px solid ${COLORS.gray[50]}`,
    },
    '.button': {
      maxWidth: '15rem',
    },
    '.loader': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      marginTop: '4rem',
    },
  })

  return (
    <>
      <section css={styles}>
        <div className="left">
          <H4>Export</H4>
          <div className="buttonRow">
            <div className="button">
              <Button
                variant={ButtonVariant.Primary}
                onClick={handleExportAllButton}
              >
                Saldo Alla Artiklar
              </Button>
            </div>
            <div className="button">
              <Button
                variant={ButtonVariant.Outlined}
                disabled={selectedStorage === undefined}
                onClick={handleExportSelectedButton}
              >
                Saldo Valt Lager
              </Button>
            </div>
          </div>
          <div className="buttonRow">
            <div className="button">
              <Button
                variant={ButtonVariant.Primary}
                onClick={handleExportWarningsButton}
              >
                Saldo under brytpunkt
              </Button>
            </div>
          </div>
          <div className="loader">
            {isLoading && (
              <>
                <CircularSpinnerLoader />
              </>
            )}
          </div>
        </div>
        <div className="right">
          <div className="storageList">
            <EkList>
              {nestedData
                .filter(
                  (storage: ExtendedStorage) =>
                    !storage.virtual && !storage.deleted
                )
                /*.filter(
                  (storage: ExtendedStorage) =>
                    storage
                      .name!.toLowerCase()
                      .indexOf(searchFilter.toLowerCase()) > -1
                )*/
                .map((storage: ExtendedStorage, i) => (
                  <StorageListRowNested
                    key={storage._id}
                    item={storage}
                    subitems={storage.children}
                    onClick={(s) => onRowClick(s)}
                    selectedId={selectedStorage?._id}
                  />
                ))}
            </EkList>
          </div>
        </div>
      </section>
    </>
  )
}

const ReportPage = withQuery<StorageSpacePage>({
  query: GET_PHYSICAL_STORAGE_SPACES,
  variables: { _size: 16000 },
  loader: EkLoader,
})(_ReportPage)

export { ReportPage }
