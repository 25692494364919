import { storeAccessToken } from './local-store'
import { replaceDomain } from './replace-domain'

const loginWithCredentials = async (
  phoneNumber: string,
  password: string
): Promise<boolean> => {
  try {
    const headers = new Headers()
    headers.append('api-client-id', 'react')
    headers.append('api-client-secret', 'secret')
    headers.append(
      'Authorization',
      `Basic ${btoa(phoneNumber + ':' + password)}`
    )
    const response = await fetch(
      replaceDomain(
        process.env.REACT_APP_AUTH_URL || '',
        window.location.host
      ) ?? '',
      {
        method: 'post',
        headers,
      }
    )

    const data = await response.json()
    if (data?.accessToken) {
      storeAccessToken(data.accessToken)
    } else {
      throw new Error('missing_token')
    }
    return true
  } catch (e) {
    throw e
  }
}

export { loginWithCredentials }
