import { getAccessTokenFromStore } from '@common/local-store'
import { decode } from 'jsonwebtoken'
import { JWTPayload } from '@functions/lib/authenticate/handlers/token-payload-handler'
import { isExpired } from '@common/is-expired'
import { client } from '@api/client'
import { gql } from 'apollo-server-lambda'

const tryRequest = async (): Promise<boolean> => {
  try {
    const result = await client.query({
      query: gql`
        query Authenticated {
          authenticated
        }
      `,
    })
    return result.data.authenticated
  } catch (err) {
    return false
  }
}

const isLoggedIn = async (): Promise<boolean> => {
  const token = getAccessTokenFromStore()
  try {
    const { exp } = decode(token) as JWTPayload
    return !isExpired(exp) && (await tryRequest())
  } catch (e) {
    return false
  }
}

export { isLoggedIn }
