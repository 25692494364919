import React, { ReactNode, FC } from 'react'
import { css } from '@emotion/react'
import {
  BoxShadow,
  getBoxShadow,
} from '@src/styles/box-shadow/box-shadow'
import { getBoxShadowHover } from '@styles/utils'

interface CardProps {
  children?: ReactNode
  width?: string
  minWidth?: string
  maxWidth?: string
  padding?: string
  borderRadius?: string
}

const Card: FC<CardProps> = ({
  children = <></>,
  padding = '1em',
  borderRadius = '5px',
  width = '100%',
  minWidth = '100px',
  maxWidth = '400px',
}) => {
  const cardStyle = css([
    getBoxShadowHover(BoxShadow.Small),

    {
      width,
      minWidth,
      maxWidth,
      padding,
      borderRadius,
      boxShadow: getBoxShadow(BoxShadow.Small),
      position: 'relative',
      top: '0',
    },
  ])

  return <div css={cardStyle}>{children}</div>
}

export { Card }
