import React, { FC } from 'react'
import { css } from '@emotion/react'
import { Button, ButtonVariant } from '@pure-components/input'
import { Modal, useModal } from '@components/modal'
import { ContextMenuConfirmSdf } from './context-menu-confirm-sdf'
import { ModalSelectUser } from '../modal-select-user'
interface ContextMenuProps {
  handleEditMode: () => void
  handleSendToSDF: () => void
  handleAddArticle: () => void
  handleAssignUser: (userId: string | undefined) => void
  editModeEnabled?: boolean
}

const ContextMenu: FC<ContextMenuProps> = ({
  handleEditMode = () => {},
  handleSendToSDF = () => {},
  handleAddArticle = () => {},
  handleAssignUser = () => {},
  editModeEnabled = false,
}) => {
  const { isVisible, closeModal, openModal } = useModal()

  const {
    isVisible: isAssignModalVisible,
    closeModal: closeAssignModal,
    openModal: openAssignModal,
  } = useModal()

  const styles = css({
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    '> *': {
      marginBottom: '0.5em',
    },
  })

  return (
    <div css={styles}>
      <Button
        variant={ButtonVariant.Outlined}
        onClick={handleEditMode}
      >
        {!editModeEnabled ? 'Flytta Artiklar' : 'Avbryt Flytt'}
      </Button>
      <Button variant={ButtonVariant.Outlined} onClick={openModal}>
        Skicka till SDF
      </Button>
      <Button
        variant={ButtonVariant.Outlined}
        onClick={handleAddArticle}
      >
        Lägg till Artikel
      </Button>

      <Button
        variant={ButtonVariant.Outlined}
        onClick={openAssignModal}
      >
        Tilldela
      </Button>

      <Modal isVisible={isVisible} closeModal={closeModal}>
        <ContextMenuConfirmSdf
          orderNumber={'ordern'}
          onConfirm={() => {
            closeModal()
            handleSendToSDF()
          }}
          onCancel={closeModal}
        />
      </Modal>

      <Modal
        isVisible={isAssignModalVisible}
        closeModal={closeAssignModal}
      >
        <ModalSelectUser
          closeModal={(userId) => {
            closeAssignModal()
            handleAssignUser(userId)
          }}
        />
      </Modal>
    </div>
  )
}

export { ContextMenu }
