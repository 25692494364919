import { css } from '@emotion/react'
import { Card } from '@pure-components/surface'
import { Checkbox } from '@pure-components/input'
import { COLORS, TEXT_STYLE } from '@styles'
import React, { FC } from 'react'
import { A } from '@components/base'
import { AppRoutes } from '@router'

interface EkCardWorkOrderSingleProps {
  _id?: string | undefined
  description?: string | undefined
  orderNumber?: string | undefined
  createdDate?: string | undefined
  serviceDate?: string | undefined
}

const EkCardWorkOrderSingle: FC<EkCardWorkOrderSingleProps> = ({
  _id = '',
  description = '',
  orderNumber = '',
  createdDate = '',
  serviceDate = '',
}) => {
  const cardStyle = css({
    display: 'grid',
    gridTemplateColumns: '1.8fr 0.2fr',
    gridTemplateRows: 'repeat(4, 1fr)',
    gap: '1em',
    padding: '1em',
    gridTemplateAreas:
      '"headline checkbox" "subline subline" "address address" "city city"',
    '.headline': [
      TEXT_STYLE.subtitle['1'],
      {
        gridArea: 'headline',
      },
    ],
    '.checkbox': {
      gridArea: 'checkbox',
    },
    '.checkbox > div': {
      borderRadius: 999,
      border: `2px solid ${COLORS.success['50']}`,
      color: COLORS.success['50'],
    },
    '.checkbox > div *': {
      borderRadius: 999,
      background: COLORS.success['50'],
      color: COLORS.gray['0'],
    },
    '.subline': [
      TEXT_STYLE.caption['1'],
      {
        gridArea: 'subline',
      },
    ],
    '.address': [
      TEXT_STYLE.subtitle['1'],
      {
        gridArea: 'address',
      },
    ],
    '.city': [
      TEXT_STYLE.caption['1'],
      {
        gridArea: 'city',
      },
    ],
  })
  return (
    <A to={`${AppRoutes.WorkingOrders}/${_id}`}>
      <Card>
        <article css={cardStyle}>
          <p className="headline">{orderNumber}</p>
          <div className="checkbox">
            <Checkbox checked={true} />
            {/* TODO: This needs to be if the asignee is the current logged in user */}
          </div>
          <p className="subline">{_id}</p>
          <p className="subline">{description}</p>
          <p className="address">{createdDate}</p>
          <p className="city">{serviceDate}</p>
        </article>
      </Card>
    </A>
  )
}

export { EkCardWorkOrderSingle }
