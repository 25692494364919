/* eslint-disable max-lines */
import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { EkList, EkListCell, EkListRow } from '@components/ek-list'
import { Divider } from '@pure-components/graphics'
import { Search } from '@material-ui/icons'
import { SimpleHeader } from '@components/header'
import { LayoutStandard } from '@layouts'
import { A } from '@components/base'
import { Caption, Subtitle2 } from '@components/base-extended'
import { truncateString } from '@common'
import { AppRoutes } from '@router'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { GET_ITEM } from '@api/item'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { InventoryItem, Item, STORAGE_SPACE_TYPE } from '@types'

interface StockBalanceProps extends RouteComponentProps {
  id?: string
}

const mapType = (type: STORAGE_SPACE_TYPE) => {
  switch (type) {
    case STORAGE_SPACE_TYPE.FORDON:
      return 'Fordon'
    case STORAGE_SPACE_TYPE.HYLLA:
      return 'Hylla'
    case STORAGE_SPACE_TYPE.LADA:
      return 'Låda'
    case STORAGE_SPACE_TYPE.LAGER:
      return 'Lager'
    case STORAGE_SPACE_TYPE.YTA:
      return 'Yta'
    case STORAGE_SPACE_TYPE.DEFAULT:
      return 'Default'
  }
}

const _StockBalance: FC<
  StockBalanceProps & WithQueryExtension<Item>
> = ({
  id,
  queryData,
}: StockBalanceProps & WithQueryExtension<Item>) => {
  const {
    findItemByID: item,
    findItemByID: {
      inventoryItems: { data = [] },
    },
  } = queryData

  const storagesMap: { [key: string]: InventoryItem } = {}

  for (const inv of data) {
    const key = inv.storageSpace._id
    const sum = storagesMap[key]
      ? (storagesMap[key].quantity += inv.quantity)
      : inv.quantity
    storagesMap[key] = { ...inv, quantity: sum }
  }

  const storages = Object.values(storagesMap)
  //console.log(storages)

  return (
    <>
      <SimpleHeader
        text={`Lagersaldo ${truncateString(item.name, 18)} `}
      >
        <Search />
      </SimpleHeader>
      <LayoutStandard scroll={true}>
        <section>
          <EkList>
            {storages.map((stock, i) => (
              <>
                <A
                  to={`${AppRoutes.Storages}/${stock.storageSpace._id}`}
                  key={`stock${stock._id}`}
                >
                  <EkListRow key={`stock_row${stock._id}`}>
                    <EkListCell key={`stock_cell1${stock._id}`}>
                      <Subtitle2 key={`stock_subtitle${stock._id}`}>
                        {stock.storageSpace.name}
                      </Subtitle2>
                      <Caption key={`stock_caption${stock._id}`}>
                        {mapType(stock.storageSpace.type)}
                      </Caption>
                    </EkListCell>
                    <EkListCell
                      justify="end"
                      key={`stock_cell2${stock._id}`}
                    >
                      {stock.quantity}
                    </EkListCell>
                  </EkListRow>
                </A>
                {i < storages.length - 1 && (
                  <Divider key={`divider${stock._id}`} />
                )}
              </>
            ))}
          </EkList>
        </section>
      </LayoutStandard>
    </>
  )
}

const StockBalance = withQuery<Item>({
  query: GET_ITEM,
  loader: EkLoader,
})(_StockBalance)

export { StockBalance }
