import { getIconByVariant, getLabelByVariant } from '.'

/** 
 * When adding a new storage type both icon and label needs to be added.
 * - src/components/pages-components/storage/common/get-icon-by-variant.tsx
 * - src/components/pages-components/storage/common/get-label-by-variant.ts
 */ 
const getStorageVariant = {
  icon: getIconByVariant,
  label: getLabelByVariant,
}

export { getStorageVariant }
