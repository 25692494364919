import styled from '@emotion/styled'
import { COLORS } from '@styles'

interface HandleProps {
  containerHeight?: string
  handleWidth?: string
}

const Handle = styled.div(
  {
    height: '40px',
    position: 'relative',
    touchAction: 'none',
    '&::after': {
      position: 'absolute',
      content: '""',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '4px',
      width: '40px',
      background: COLORS.gray[30],
      borderRadius: '9999px',
    },
  },
  ({
    containerHeight = '40px',
    handleWidth = '40px',
  }: HandleProps) => ({
    height: containerHeight,
    '&::after': { width: handleWidth },
  })
)

export { Handle }
