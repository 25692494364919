/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const GET_INCOMING_ORDERS = gql`
  query incomingOrders($_size: Int, $_cursor: String) {
    incomingOrders(_size: $_size, _cursor: $_cursor) {
      data {
        _id

        orderNumber
        supplier
        createdDate
        expectedDeliveryDate
        ordererName
        ordererContact
        totalPrice
        scanned
        storageSpace {
          _id

          name
          description
          virtual
          type
          displayLabel {
            _id

            barcode
          }
          parent
          items {
            data {
              _id

              quantity
              item {
                _id

                number
                name
                description
                tags {
                  data {
                    _id

                    name
                  }
                }
                images
                defaultImage
                unitOfMeasure
                supplier
                netPrice
                grossPrice
                sellingPrice
              }
            }
          }
        }
        items {
          data {
            _id

            item {
              _id

              number
              sdfId
              name
              description
              tags {
                data {
                  _id
                  name
                }
              }
              images
              defaultImage
              unitOfMeasure
              supplier
              netPrice
              grossPrice
              sellingPrice
            }
            quantity
            price
          }
        }
      }
      before
      after
    }
  }
`

const GET_INCOMING_ORDERS_LIMITED = gql`
  query incomingOrders($_size: Int, $_cursor: String) {
    incomingOrders(_size: $_size, _cursor: $_cursor) {
      data {
        _id

        orderNumber
        supplier
        createdDate
        expectedDeliveryDate
        ordererName
        ordererContact
        totalPrice
        scanned
        storageSpace {
          _id

          name
          description
          virtual
          type
          displayLabel {
            _id

            barcode
          }
        }
      }
      before
      after
    }
  }
`

const GET_INCOMING_ORDER = gql`
  query findIncomingOrderByID($id: ID!) {
    findIncomingOrderByID(id: $id) {
      _id

      orderNumber
      supplier
      createdDate
      expectedDeliveryDate
      ordererName
      ordererContact
      totalPrice
      scanned
      storageSpace {
        _id

        name
        description
        virtual
        type
        displayLabel {
          _id

          barcode
        }
        parent
        items {
          data {
            _id

            quantity
            item {
              _id

              number
              name
              description
              tags {
                data {
                  _id

                  name
                }
              }
              images
              defaultImage
              unitOfMeasure
              supplier
              netPrice
              grossPrice
              sellingPrice
            }
          }
        }
      }
      items {
        data {
          _id

          item {
            _id

            number
            sdfId
            name
            description
            tags {
              data {
                _id
                name
              }
            }
            images
            defaultImage
            supplier
            netPrice
            grossPrice
            sellingPrice
          }
          quantity
          price
        }
      }
    }
  }
`

export {
  GET_INCOMING_ORDERS,
  GET_INCOMING_ORDER,
  GET_INCOMING_ORDERS_LIMITED,
}
