import { css } from '@emotion/react'
import React, { FC, ReactNode } from 'react'
import { COLORS, TEXT_STYLE } from '@styles'

type VariantType = 'header' | 'row'
interface EkListRowProps {
  children?: ReactNode
  gap?: string
  padding?: string
  variant?: VariantType
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  classes?: string
}

const EkListRow: FC<EkListRowProps> = ({
  children = [],
  gap = '1em',
  padding = '0 24px',
  variant = 'row',
  onClick = () => {},
  classes = '',
}) => {
  const listStyle = css([TEXT_STYLE.list[1]], {
    width: '100%',
    color: COLORS.gray[90],
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
    gap: gap || '0.5em',
    margin: '6px 0',
    padding: padding || '0 12px',
  })

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick(e)
  }

  return (
    <div
      className={classes}
      onClick={(e: React.MouseEvent<HTMLDivElement>) =>
        handleOnClick(e)
      }
      aria-hidden="true"
      css={listStyle}
    >
      {children}
    </div>
  )
}

export { EkListRow }
export type { VariantType }
