/* eslint-disable max-lines */
import React, { FC, useState } from 'react'
import { H5 } from '@components/base'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { RouteComponentProps } from '@reach/router'
import {
  StorageSpace,
  StorageSpaceInput,
  StorageSpacePage,
} from '@types'
import {
  CREATE_STORAGE_SPACE,
  GET_PHYSICAL_STORAGE_SPACES,
} from '@api/storage-space'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { TextField } from '@pure-components/input'
import { css } from '@emotion/react'
import { EkList, EkListCell, EkListRow } from '@components/ek-list'
import { CartItem } from './article-cart-list'

interface ModalSearchStorageProps extends RouteComponentProps {
  confirmText?: string
  currentStorageId?: string
  selectedItems?: CartItem[]
  closeModal?: (storage_id: string) => void
}

const _ModalSearchStorage: FC<
  ModalSearchStorageProps &
    RouteComponentProps &
    WithMutationExtension<Storage[], StorageSpaceInput> &
    WithQueryExtension<StorageSpacePage>
> = ({
  queryData,
  selectedItems = [],
  addData,
  previousPage,
  nextPage,
  path,
  currentStorageId = '',
  confirmText = 'Välj Förvaringsplats',
  closeModal = () => {},
}) => {
  const {
    getStorageByVirtual: { data = [] },
  } = queryData

  /*const item =
    selectedItems.length === 1 ? selectedItems[0].item.item : null*/

  /*const recommendedStorages =
    item?.inventoryItems.data
      ?.filter(
        (inv) =>
          inv.storageSpace._id !== currentStorageId &&
          !inv.storageSpace.virtual &&
          !inv.storageSpace.deleted
      )
      .sort((a, b) => b.quantity - a.quantity)
      .map((inv) => inv.storageSpace) || []*/

  //console.log(recommendedStorages)

  const [searchFilter, setSearchFilter] = useState('')

  const handleSearchChange = (event: any) => {
    setSearchFilter(event.target.value)
  }

  const onRowClick = (clickedItem: StorageSpace) => {
    closeModal(clickedItem._id)
  }

  const styles = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    overflow: 'hidden',
    position: 'relative',
    '.header': {
      display: 'grid',
      gridTemplateColumns: 'repeat( auto-fit, minmax(150px, 1fr) )',
      gap: '1em',
    },
  })

  return (
    <section css={styles}>
      <div className="header">
        <div>
          <H5>Sök förvaringsplats</H5>
        </div>
      </div>
      <TextField
        placeholder="Sök på namn"
        inputType="search"
        value={searchFilter}
        onChange={(e) => handleSearchChange(e)}
      />
      <EkList>
        {data
          .filter(
            (storage: StorageSpace) =>
              storage._id !== currentStorageId &&
              !storage.deleted &&
              !storage.virtual
          )
          .filter(
            (storage: StorageSpace) =>
              storage
                .name!.toLowerCase()
                .indexOf(searchFilter.toLowerCase()) > -1
          )
          .map((storage: StorageSpace, i) => (
            <EkListRow
              key={storage._id}
              onClick={(e) => onRowClick(storage)}
            >
              <EkListCell>
                <p className="storageName">{storage.name}</p>
              </EkListCell>
            </EkListRow>
          ))}
      </EkList>
    </section>
  )
}

const ModalSearchStorage = withQuery<StorageSpacePage>({
  query: GET_PHYSICAL_STORAGE_SPACES,
  variables: { _size: 5000 },
  loader: EkLoader,
})(
  withMutation<Storage[], StorageSpaceInput>(CREATE_STORAGE_SPACE)(
    _ModalSearchStorage
  )
)

export { ModalSearchStorage }
