import styled from '@emotion/styled'
import { COLORS } from '@styles'
import { TEXT_STYLE } from '@styles'

/** Used for when Figma uses Button text style as a text element.
 *
 * A Pure text element like this does not exist within the Figma so this is the best option before the Figma has support for this in terms of a new text style.
 *
 */

const ButtonText = styled.p(
  TEXT_STYLE.button[1],
  ({ color = COLORS.gray[100] }) => ({ color })
)

export { ButtonText }
