/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react'
import {
  navigate,
  RouteComponentProps,
  useLocation,
} from '@reach/router'
import { css } from '@emotion/react'
import { SimpleHeader } from '@components/header'

import { useQueryParam } from '@hooks'

import {
  Button,
  ButtonSize,
  ButtonVariant,
  TextField,
} from '@pure-components/input'
import { COLORS } from '@styles'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { withQuery, WithQueryExtension } from '@components/with-query'
import {
  Item,
  InventoryItemInput,
  StorageSpaceTransaction,
  ItemPage,
} from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { LayoutStandard } from '@layouts'
import { PERFORM_ADD_TRANSACTION } from '@api/perform-transaction'

import { client } from '@api/client'
import { parse } from 'query-string'
import { AppRoutes } from '@router'
import { GET_ITEMS } from '@api/item'
import { EkList, EkListCell, EkListRow } from '@components/ek-list'
import { cancellableArticleSearch } from '@common/search/search-items'
import { SYNC_ITEM_FROM_SDF } from '@api/sdf/sync-item-mutation'
import { Chip } from '@pure-components/data-display'
import { Add } from '@material-ui/icons'
import { H6 } from '@components/base'
import { Divider } from '@material-ui/core'

interface AddItemPageProps extends RouteComponentProps {
  id?: string
}

const _AddToStorage: FC<
  AddItemPageProps &
    WithMutationExtension<
      StorageSpaceTransaction,
      { inventoryItem: InventoryItemInput }
    > &
    WithQueryExtension<ItemPage>
> = ({
  queryData,
  addData,
  refetch,
  id,
  location: routeLocation,
}) => {
  const location = useLocation()
  const params = parse(location.search)
  //console.log('params: ', params)
  const storageId: string = params.storage as string
  const query: string = params.query as string
  const recommendedItems: Item[] =
    (routeLocation?.state as any).recommendedItems || []

  //console.log('recommended Items', recommendedItems)
  const {
    items: { data = [] },
  } = queryData

  data.sort((a, b) => parseInt(a.number) - parseInt(b.number))

  const [selectedItem, setSelectedItem] = useState<Item | null>(null)
  const [quantity, setQuantity] = useState<string>('')
  const [activeSearch, setActiveSearch] = useState<any>(null)
  const [itemList, setItemList] = useState<Item[]>(data)

  /*const { setChildren, setIsOpen } = useBottomSheet()

  const bottomSheetStyles = css({
    display: 'grid',
    gap: '1em',
    marginTop: '2em',
  })*/

  //const recommendedItems: Item[] = []

  const [searchFilter, setSearchFilter] = useState('')

  /* eslint-disable */
  useEffect(() => {
    if (query) {
      setSearchFilter(decodeURIComponent(query))
      searchArticles(decodeURIComponent(query))
    }
  }, [])
  /* eslint-enable */

  const handleNewButton = () => {
    navigate(`/articles/new?from=/storages/add?storage=${storageId}`)
  }

  /*const removeDuplicates = (items: Item[], refs: Item[]) => {
    const ids: string[] = []
    const results: Item[] = []

    if (items.length > 0 && refs.length > 0) {
      for (const item of [...items, ...refs]) {
        if (!ids.includes(item.sdfId)) {
          ids.push(item.sdfId)
          results.push(item)
        }
      }
    } else {
      return [...items, ...refs]
    }
    return results
  }*/

  const removeDuplicates = (items: Item[], refs: Item[]): Item[] => {
    const results: Item[] = [...refs]

    for (const item of items) {
      const duplicateCheck = (a: Item, b: Item) =>
        item.sdfId !== '' && item.sdfId !== 'PLACEHOLDER'
          ? a.sdfId === b.sdfId
          : a.number === b.number && a.name === b.name

      const collision = results.find((r) => duplicateCheck(r, item))

      if (collision) {
        results[results.indexOf(collision)] = item
      } else {
        results.unshift(item)
      }
    }

    return results
  }

  const searchArticles = async (query: string) => {
    //console.log('performing search for ', query)
    const search = cancellableArticleSearch(query)
    setActiveSearch(search)

    const items = await search.items
    const refs = await search.refs
    //console.log(items, refs)
    const result = removeDuplicates(items, refs)
    setItemList(result)
    setActiveSearch(null)
  }

  const handleSearchChange = async (event: any) => {
    setSearchFilter(event.target.value)
  }

  const handleSearchButton = async (event: any) => {
    //console.log('handleSearchButton')
    if (activeSearch !== null) {
      console.log('aborting active search')
      activeSearch.abort()
    }

    if (searchFilter.length >= 1) {
      await searchArticles(searchFilter)
    } else {
      //setItemList(data)
      setActiveSearch(null)
    }
  }

  const handleKeyPress = async (
    event: React.KeyboardEvent<Element>
  ) => {
    if (event.key === 'Enter') {
      await handleSearchButton(event)
    }
  }

  const view = useQueryParam('view')

  const handleQuantityChange = (event: any) => {
    setQuantity(event.target.value)
  }

  const handleAddArticle = async () => {
    const amount = quantity === '' ? 0 : parseFloat(quantity)

    if (selectedItem && amount > 0) {
      let item = selectedItem
      //console.log(item)
      if (selectedItem._id === '') {
        const result: any = await client.mutate({
          mutation: SYNC_ITEM_FROM_SDF,
          variables: {
            sdfId: selectedItem.sdfId,
          },
        })
        //console.log(result)
        item = result.data.syncItemFromSdf
      }
      const input: InventoryItemInput = {
        item: { connect: item._id },
        storageSpace: { connect: storageId },
        quantity: amount,
      }
      //console.log(input)
      await addArticlesToStorage(input)
    }
  }

  const addArticlesToStorage = async (
    input: InventoryItemInput | undefined
  ) => {
    //console.log(input)
    if (input) {
      try {
        const result = await client.mutate({
          mutation: PERFORM_ADD_TRANSACTION,
          variables: {
            inventoryItem: input,
          },
        })
        console.log('result', result)
        navigate(`${AppRoutes.Storages}/${storageId}`)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const isQuantityFieldDisabled = () => {
    return selectedItem === null
  }

  const isButtonDisabled = () => {
    return (
      selectedItem === null || !quantity || parseFloat(quantity) < 0
    )
  }

  const onRowClick = (clickedItem: Item): void => {
    setQuantity('')
    if (isSelectedItem(clickedItem)) {
      setSelectedItem(null)
    } else {
      setSelectedItem(clickedItem)
    }
  }

  const searchFieldContainerStyles = css({
    height: '70px',
    width: '100%',
    overflow: 'hidden !important',
    background: COLORS.gray[0],
  })

  const searchFieldStyles = css({
    padding: '12px 12px',
    width: '100%',
    background: COLORS.gray[0],
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr',
    gridTemplateAreas: '"input button"',
    columnGap: '0.7rem',
    alignItems: 'baseline',
    '.searchinput': {
      gridArea: 'input',
    },
    '.searchbutton': {
      gridArea: 'button',
      width: '60px',
    },
  })

  const quantityFieldStyles = css({
    padding: '12px 24px 6px 24px',
    width: '100%',
    //background: COLORS.gray[0],
  })

  const bottomButtonStyles = css({
    padding: '6px 24px 12px 24px',
    width: '100%',
    //background: COLORS.gray[0],
  })

  const styles = css({
    display: 'grid',
    color: COLORS.gray[90],
    padding: view === 'card' ? '24px' : '0',
    gap: '1em',
    height: itemList.length < 8 ? '100vh' : 'auto',
    '.storage-info': {
      padding: '0 15px 10px 15px',
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: 'auto 1fr',
      marginTop: '10px',
      borderBottom: '1px lightgray solid',
      '.icon': {
        display: 'grid',
        minHeight: 24,
        placeContent: 'center center',
        fontSize: '24px',
      },
      '.description': {
        paddingTop: '6px',
      },
    },
    '.button-wrapper-selected-article': {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: '0.5em',
      padding: '1rem 1.5rem 0 1.5rem',
      borderTop: '1px lightgray solid',
      '.article-info': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '.button-wrapper-reset': {
      display: 'flex',
      gap: '0.5em',
      padding: '0 24px',
      '.article-info': {},
    },
    '.tags': {
      display: 'flex',
      gap: 8,
      padding: '0 0 12px 0',
      marginTop: 5,
      flexWrap: 'wrap',
      alignContent: 'flex-start',
    },
    '.tags button': {
      backgroundColor: COLORS.gray[40],
    },
    '.supplierIndicator': {
      fontStyle: 'italic',
    },
  })

  const recommendedBoxStyles = css({
    padding: '0 1rem 1rem 1rem',
    marginBottom: '0.5rem',
    '.recommended-header': {
      marginBottom: '1rem',
    },
  })

  const isSelectedItem = (item: Item) => {
    const idToCheck = item._id !== '' ? item._id : item.sdfId

    return (
      idToCheck === selectedItem?._id ||
      idToCheck === selectedItem?.sdfId
    )
  }

  // @ts-ignore
  return (
    <>
      <SimpleHeader text={`Sök Artikel`}>
        <div
          onClick={handleNewButton}
          role="button"
          onKeyDown={handleNewButton}
          tabIndex={-2}
        >
          <Add />
        </div>
      </SimpleHeader>
      <div css={searchFieldContainerStyles}>
        <section css={searchFieldStyles}>
          <div className="searchinput">
            <TextField
              placeholder="Sök på namn, artikelnummer, tag"
              inputType="search"
              value={searchFilter}
              onChange={(e) => handleSearchChange(e)}
              onKeyUp={(e) => handleKeyPress(e)}
            />
          </div>
          <div className="searchbutton">
            <Button
              disabled={
                activeSearch !== null || searchFilter.length < 1
              }
              size={ButtonSize.Small}
              onClick={handleSearchButton}
            >
              Sök
            </Button>
          </div>
        </section>
      </div>
      <div css={recommendedBoxStyles}>
        {recommendedItems.length > -1 && (
          <>
            <div className="recommended-header">
              <H6>Föreslagna artiklar</H6>
            </div>
            <EkList>
              {recommendedItems.map((item: Item, i) => (
                <EkListRow
                  key={`recommended${item._id}`}
                  classes={
                    selectedItem?._id === item._id ? 'selected' : ''
                  }
                  onClick={(e) => onRowClick(item)}
                >
                  <EkListCell>
                    <p className="itemName">{item.name}</p>
                    <p className="itemNumber">{item.number}</p>
                    <p className="supplierIndicator">
                      {item.supplier}
                    </p>
                    <div className="tags">
                      {item.tags.data &&
                        item.tags.data.length > 0 &&
                        item.tags.data!.map((tag) => (
                          <Chip
                            key={tag._id}
                            background={COLORS.orange[10]}
                            text={tag.name}
                          />
                        ))}
                    </div>
                  </EkListCell>
                </EkListRow>
              ))}
            </EkList>
            <Divider />
          </>
        )}
      </div>
      <LayoutStandard scroll={true}>
        <section css={styles}>
          <>
            {activeSearch !== null ? (
              <EkLoader />
            ) : (
              <EkList>
                {itemList.map((item: Item, i) => (
                  <EkListRow
                    key={`item._id_${i}`}
                    classes={isSelectedItem(item) ? 'selected' : ''}
                    onClick={(e) => onRowClick(item)}
                  >
                    <EkListCell>
                      <p className="itemName">{item.name}</p>
                      <p className="itemNumber">{item.number}</p>
                      <p className="supplierIndicator">
                        {item.supplier}
                      </p>
                      <div className="tags">
                        {item.tags.data &&
                          item.tags.data.length > 0 &&
                          item.tags.data!.map((tag) => (
                            <Chip
                              key={tag._id}
                              background={COLORS.orange[10]}
                              text={tag.name}
                            />
                          ))}
                      </div>
                    </EkListCell>
                  </EkListRow>
                ))}
              </EkList>
            )}
          </>
        </section>
      </LayoutStandard>
      <section css={quantityFieldStyles}>
        <TextField
          disabled={isQuantityFieldDisabled()}
          placeholder="Antal"
          inputType="number"
          min={0}
          value={quantity}
          onChange={(e) => handleQuantityChange(e)}
        />
      </section>
      <section css={bottomButtonStyles}>
        <Button
          disabled={isButtonDisabled()}
          variant={ButtonVariant.Primary}
          onClick={handleAddArticle}
        >
          Lägg till Artikel
        </Button>
      </section>
    </>
  )
}

const AddToStorage = withQuery<ItemPage>({
  query: GET_ITEMS,
  variables: { _size: 500 },
  loader: EkLoader,
})(
  withMutation<
    StorageSpaceTransaction,
    { inventoryItem: InventoryItemInput }
  >(PERFORM_ADD_TRANSACTION)(_AddToStorage)
)

export { AddToStorage }
