import React, { ReactNode, useState } from 'react'
import { RoleContext } from './role-context'

type RoleProviderProps = {
  children: ReactNode
}

const RoleProvider = ({ children = [] }: RoleProviderProps) => {
  const [role, setRole] = useState('user')
  const [userId, setUserId] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  return (
    <RoleContext.Provider
      value={{
        isAdmin,
        setIsAdmin,
        role,
        setRole,
        userId,
        setUserId,
      }}
    >
      {children}
    </RoleContext.Provider>
  )
}

export { RoleProvider }
