import { getAccessTokenFromStore } from '@common/local-store'
import { decode } from 'jsonwebtoken'
import { JWTPayload } from '@functions/lib/authenticate/handlers/token-payload-handler'

const getRole = (): string => {
  const token = getAccessTokenFromStore()
  try {
    const { role } = decode(token) as JWTPayload
    return role
  } catch (_e) {
    return 'user'
  }
}

const getUserId = (): string => {
  const token = getAccessTokenFromStore()
  try {
    const { userId } = decode(token) as JWTPayload
    return userId
  } catch (_e) {
    return ''
  }
}

const checkIsAdmin = (): boolean => {
  const token = getAccessTokenFromStore()
  try {
    const { role } = decode(token) as JWTPayload
    return role === 'admin'
  } catch (e) {
    return false
  }
}

export { getRole, checkIsAdmin, getUserId }
