import * as XLSX from 'xlsx-js-style'

import { ItemReport, ItemReportEntry, UNIT_OF_MEASURE } from '@types'

const convertUnit = (unit?: UNIT_OF_MEASURE): string => {
  switch (unit) {
    case UNIT_OF_MEASURE.GRAM:
      return 'gram'
    case UNIT_OF_MEASURE.KILOGRAM:
      return 'kg'
    case UNIT_OF_MEASURE.KUBIKLITER:
      return 'kubikliter'
    case UNIT_OF_MEASURE.KUBIKMETER:
      return 'kubikmeter'
    case UNIT_OF_MEASURE.LITER:
      return 'liter'
    case UNIT_OF_MEASURE.METER:
      return 'meter'
    case UNIT_OF_MEASURE.RULLE:
      return 'rulle'
    case UNIT_OF_MEASURE.STYCK:
      return 'st'

    case undefined:
    case 'DEFAULT':
    default:
      return ''
  }
}

/*const convertStorageType = (type: STORAGE_SPACE_TYPE): string => {
  switch (type) {
    case STORAGE_SPACE_TYPE.FORDON:
      return 'Fordon'
    case STORAGE_SPACE_TYPE.HYLLA:
      return 'Hylla'
    case STORAGE_SPACE_TYPE.LADA:
      return 'Lada'
    case STORAGE_SPACE_TYPE.LAGER:
      return 'Lager'
    case STORAGE_SPACE_TYPE.YTA:
      return 'Yta'
    case STORAGE_SPACE_TYPE.DEFAULT:
    default:
      return ''
  }
}*/

const formatDataForSheetJS = (rawData: ItemReportEntry[]): any[] => {
  return rawData.map((entry) => ({
    'E-Nummer': entry.number,
    Namn: entry.name,
    Leverantör: entry.supplier,
    Brytpunkt: entry.balanceWarning,
    Saldo: entry.quantity,
    Enhet: convertUnit(entry.unit),
    Lagerplats: entry.storages
      ?.map((storage) => storage.name)
      .join(', '),
  }))
}

const exportToSheetJS = (
  report: ItemReport,
  sheetName: string,
  fileName: string
): void => {
  const formattedData = formatDataForSheetJS(report.data ?? [])

  const ws = XLSX.utils.json_to_sheet(formattedData)

  const colWidths = [
    { wch: 10 }, // Width of column A
    { wch: 55 }, // Width of column B
    { wch: 15 }, // Width of column C
    { wch: 10 }, // Width of column D
    { wch: 10 }, // Width of column E
    { wch: 10 }, // Width of column F
    { wch: 30 }, // Width of column G
  ]

  ws['!cols'] = colWidths

  for (let r = 1; r <= (report.data?.length || 1) + 1; r++) {
    const cellRef = `F${r}`
    ws[cellRef].s = { alignment: { horizontal: 'center' } }
  }

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, sheetName)

  XLSX.writeFile(wb, `${fileName}.xlsx`)
}

export { exportToSheetJS }
