import React, { FC } from 'react'
import { css } from '@emotion/react'
import { TEXT_STYLE } from '@styles'
import { Item } from '@types'

interface IncomingOrderListItemProps {
  _id: string
  item: Item
  quantity: number
}

const IncomingOrderListItem: FC<IncomingOrderListItemProps> = ({
  _id = '',
  item = {},
  quantity = '',
}) => {
  const listStyle = css([TEXT_STYLE.list[1]], {
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    margin: '1em 0',
    '.left': {
      display: 'grid',
      gridTemplateRows: 'repeat(2, auto)',
      alignSelf: 'center',
    },
    '.name': [TEXT_STYLE.subtitle[2]],
    '.number': [TEXT_STYLE.caption[1]],
    '.right': {
      gridRow: 'span 2',
      textAlign: 'left',
      alignSelf: 'center',
    },
  })
  return (
    <article css={listStyle}>
      <div className="left">
        <p className="name">{item.name}</p>
        <p className="number">{item.number}</p>
      </div>
      <div className="right">
        <p className="name">
          {quantity} {item.unitOfMeasure}
        </p>
      </div>
    </article>
  )
}

export { IncomingOrderListItem }
