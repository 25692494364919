import { css } from '@emotion/react'
import { COLORS } from '@styles'

const standardFocus = css({
  '&::before': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'all 200ms ease',
    boxShadow: `0 0 0 2px ${COLORS.gray[90]} inset`,
  },
  '&:focus': {
    outline: 'none',
    '&::before': {
      opacity: 1,
    },
  },
})

export { standardFocus }
