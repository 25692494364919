const getSortedArray = <T>(
  arr: T[],
  comparator: (a: T, b: T) => number
) => {
  if (!arr) {
    return []
  }
  const clone = [...arr]
  clone.sort(comparator)
  return clone
}

export { getSortedArray }
