import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'

interface WorkingOrdersCardViewProps {
  children?: ReactNode
}

const WorkingOrdersCardView: FC<WorkingOrdersCardViewProps> = ({
  children,
}) => {
  const cardStyles = css({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(150px, 1fr) )',
    gap: '1rem',
  })
  return <section css={cardStyles}>{children}</section>
}

export { WorkingOrdersCardView }
