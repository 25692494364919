import React from 'react'
import { ChevronLeft } from '@material-ui/icons'

const defaultIcon = (
  <ChevronLeft
    style={{
      transformOrigin: 'center center',
      transform: 'rotateZ(-90deg)',
    }}
  />
)

export { defaultIcon }
