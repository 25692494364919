import {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  PointerEvent,
} from 'react'

interface useSwipeDownProps {
  transition?: string
  onRequestClose?: () => void
  isOpen?: boolean
  closeThreshold?: number
}

const useSwipeDown = ({
  transition,
  onRequestClose = () => {},
  isOpen = false,
  closeThreshold = 0.4,
}: useSwipeDownProps) => {
  const [transformPercent, setTransformPercent] = useState(
    isOpen ? '0%' : '100%'
  )
  const [elementY, setElementY] = useState(0)
  const [pixelOffset, setPixelOffset] = useState(0)
  const [isLocked, setIsLocked] = useState(true)
  const [currentTransition, setTransition] = useState(transition)
  const [closePosition, setClosePosition] = useState(9999)
  const ref = useRef<HTMLElement>(null)
  const [transform, setTransform] = useState(
    `translateY(calc(${transformPercent} + ${pixelOffset}px))`
  )

  const turnOnTransition = () => setTransition(transition)
  const turnOffTransition = () => setTransition('')

  useEffect(() => {
    setTransformPercent(isOpen ? '0%' : '100%')
    setPixelOffset(0)
  }, [isOpen])

  useEffect(
    () =>
      setTransform(
        `translateY(calc(${transformPercent} + ${pixelOffset}px))`
      ),
    [transformPercent, pixelOffset]
  )

  useLayoutEffect(() => {
    if (ref.current !== null) {
      const { y, height } = ref.current.getBoundingClientRect()

      const parentHeight =
        ref.current.parentElement?.getBoundingClientRect().height ||
        100

      const actualY = y - parentHeight

      setClosePosition(actualY + height * closeThreshold)
    }
  }, [closeThreshold, isOpen])

  const resetValues = () => {
    setIsLocked(true)
    setElementY(0)
    setPixelOffset(0)
    turnOnTransition()
  }

  const onMove = (yPosition: number) => {
    if (!isLocked && yPosition - elementY >= 0) {
      setPixelOffset(yPosition - elementY)
    }
  }

  const onDown = (yPosition: number) => {
    setElementY(yPosition)
    turnOffTransition()
    setIsLocked(false)
  }

  const onRelease = (yPosition: number) => {
    if (!isLocked) {
      resetValues()

      if (yPosition >= closePosition) {
        onRequestClose()
      }
    }
  }

  const yFromPointer = (fn: (y: number) => void) => (
    event: PointerEvent
  ) => {
    fn(event.clientY)
  }

  return {
    ref,
    transform,
    onMove,
    onDown,
    onRelease,
    currentTransition,
    yFromPointer,
  }
}

export { useSwipeDown }
