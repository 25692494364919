import React, { FC } from 'react'
import { css } from '@emotion/react'
import { STORAGE_SPACE_TYPE } from '@src/types'
import { COLORS, TEXT_STYLE } from '@styles'
import { getStorageVariant } from './common'

interface NewStorageModalCardProps {
  variant?: STORAGE_SPACE_TYPE
}

const NewStorageModalCard: FC<NewStorageModalCardProps> = ({
  variant,
}) => {
  const styling = css({
    display: 'grid',
    placeItems: 'center center',
    '.icon': {
      background: COLORS.gray[70],
      color: COLORS.gray[10],
      fontSize: 48,
      padding: 12,
      borderRadius: 5,
      marginBottom: 12,
    },
    p: [TEXT_STYLE.button[1]],
  })
  return (
    <div css={styling}>
      <div className="icon">{getStorageVariant.icon(variant)}</div>
      <p>{getStorageVariant.label(variant)}</p>
    </div>
  )
}

export { NewStorageModalCard }
