import { BackButton } from '@components/back-button.ts'
import { css } from '@emotion/react'
import { COLORS, TEXT_STYLE } from '@styles'
import React, { FC, ReactNode } from 'react'

interface SimpleHeaderProps {
  text?: string
  afterText?: ReactNode
  children?: ReactNode
}

const SimpleHeader: FC<SimpleHeaderProps> = ({
  text = '',
  afterText = [],
  children = [],
}) => {
  const style = css([
    TEXT_STYLE.heading[6],
    {
      zIndex: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: COLORS.gray[70],
      color: COLORS.gray[10],
      padding: '16px 16px',
    },
  ])

  const backWrapperStyle = css({
    flex: 1,
  })

  const textStyle = css({
    flex: 5,
    paddingBottom: 5,
  })

  const iconWrapperStyle = css({
    display: 'flex',
    // alignItems: 'center',
    gap: '16px',
    flex: 1,
  })

  return (
    <div css={style}>
      <div css={backWrapperStyle}>
        <BackButton />
      </div>
      <span css={textStyle}>
        {text}
        {afterText}
      </span>
      <div css={iconWrapperStyle}>{children}</div>
    </div>
  )
}

export { SimpleHeader }
