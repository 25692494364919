import React, { FC, ReactNode } from 'react'
import { css, keyframes } from '@emotion/react'
import { Shimmer } from './shimmer'

interface SkeletonProps {
  children: ReactNode
  loading?: boolean
}

const Skeleton: FC<SkeletonProps> = ({
  children = <></>,
  loading = true,
}) => {
  const loadingComplete = keyframes`
  0% { opacity: 1 };
  90% { opacity: 0.5 };
  100% { opacity: 0 };
  `

  const styling = css({
    margin: '20px auto',
    //padding: '10px 24px',
    borderRadius: '4px',
    position: 'relative',
    overflow: 'hidden',
    animation: !loading ? `${loadingComplete} 250ms` : '',
  })
  return (
    <div role="figure" css={styling}>
      {children}
      <Shimmer />
    </div>
  )
}

export { Skeleton }

// TODO: Skapa unika kompositioner av olika "items" för att kunna representera element vi använder
// TODO: Skapa unika items istället för att använda classes
// TODO: Lös så att det går att iterera rätt antal element
// TODO: Säkerställ att komponenten är pure och helt återanvändbar
