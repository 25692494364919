/* eslint-disable max-lines */
// This file needs to be a bit larger than 100 lines due to various elements needing to be present. Refactoring is ill advised/unnecessary
import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@components/header'
import {
  TextField,
  Button,
  ButtonVariant,
} from '@pure-components/input'
import { COLORS, resetButton, TEXT_STYLE } from '@styles'
import { CropFree } from '@material-ui/icons'
import { Modal, useModal } from '@components/modal'
import { NewStorageModal } from '@components/pages-components/storage/new-storage-modal'
import {
  StorageSpace,
  StorageSpaceInput,
  STORAGE_SPACE_TYPE,
} from '@src/types'
import { getStorageVariant } from '@components/pages-components/storage/common'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import {
  GET_STORAGE_SPACE,
  UPDATE_STORAGE_SPACE,
} from '@api/storage-space'
import { BarcodeScanner } from '@components/barcode-scanner'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { ModalSelectStorage } from '@components/pages-components/storage'
import { client } from '@api/client'

interface BarcodeResponse {
  labelId: string
}

interface StorageProps extends RouteComponentProps {
  id?: string
}

const _EditStorage: FC<
  StorageProps &
    WithMutationExtension<any, StorageSpaceInput> &
    WithQueryExtension<StorageSpace>
> = ({ addData, id, queryData }) => {
  const styles = css({
    display: 'grid',
    rowGap: '12px',
    padding: 24,
    '.submenu': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: 12,
      placeItems: 'center center',
      padding: '24px 0',
    },
    '.actiontitle': [
      TEXT_STYLE.button[1],
      { color: COLORS.gray[90] },
    ],
    '.icon': {
      fontSize: 54, // Storleken på ikonen är 40x40 i Figma, men MUI-Icon skapar en margin
      textAlign: 'center',
      color: COLORS.gray[90],
    },
    '.button': [
      resetButton,
      {
        display: 'grid',
        border: 'none',
      },
    ],
    '.label-grid': {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridGap: '10px',
      alignItems: 'start',
    },
    '.barcodeScannerWrapper': {
      position: 'absolute',
      zIndex: 2000,
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      '.barcodeScannerContent': {
        maxWidth: '300px',
      },
    },
    '.barcodeScannerCloseButton': {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: '10px',
      marginTop: '20px',
    },
  })

  const {
    //findStorageSpaceByID: storageSpace,
    findStorageSpaceByID: {
      name: storageName,
      type,
      description: storageDescription,
      virtual,
      displayLabel,
      parent,
    },
  } = queryData

  //console.log('prev desc', storageDescription)

  const { _id: displayLabelId, barcode } = displayLabel || {}
  //const { _id: parentStorageId } = parent || {}

  const { isVisible, closeModal, openModal } = useModal()
  const [selectedVariant, setSelectedVariant] = useState(
    type || STORAGE_SPACE_TYPE.DEFAULT
  )

  const [name, setName] = useState(storageName || '')
  const [description, setDescription] = useState(
    storageDescription || ''
  )

  const [
    labelIdBarcodeScannerVisible,
    setLabelIdBarcodeScannerVisible,
  ] = useState(false)
  /*const [
    parentIdBarcodeScannerVisible,
    setParentIdBarcodeScannerVisible,
  ] = useState(false)*/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [labelId, setLabelId] = useState(barcode || '')
  const [newParent, setNewParent] = useState<
    StorageSpace | undefined
  >(undefined)

  const [parentName, setParentName] = useState('')

  useEffect(() => {
    const getParent = async () => {
      try {
        const query = GET_STORAGE_SPACE
        //console.log('query:', query, variables)
        const result = await client.query({
          query,
          variables: {
            id: parent,
          },
        })
        //console.log(result.data)
        const parentStorage: StorageSpace =
          result.data.findStorageSpaceByID
        setParentName(parentStorage.name)
      } catch (e) {
        setParentName(parent || '')
      }
    }

    if (parent && parent !== '') {
      getParent()
    }
  }, [parent])

  const onSelectVariant = (selectedVariant: STORAGE_SPACE_TYPE) => {
    closeModal()
    setSelectedVariant(selectedVariant)
  }

  const vibrate = () => {
    if (window?.navigator?.vibrate) {
      window.navigator.vibrate(30)
    }
  }

  const handleSave = () => {
    if (id) {
      const variables: StorageSpaceInput & { id: string } = {
        id,
        name,
        description,
        type: selectedVariant,
        virtual: virtual || false,
      }
      if (labelId) {
        //TODO: check if label already connected
        if (displayLabelId) {
          if (barcode !== labelId) {
            variables.displayLabel = { create: { barcode: labelId } }
          }
        } else {
          variables.displayLabel = { create: { barcode: labelId } }
        }
      }
      if (newParent) {
        variables.parent = newParent._id
      }
      addData({
        variables,
      })
        .then((response) => navigate(`/storages/${id}`))
        .catch((e) => console.log(e))
    }
  }

  const launchLabelIdScanner = () => {
    setLabelIdBarcodeScannerVisible(true)
  }

  const handleLabelIdBarcodeMatch = (text: string) => {
    const labelId: string = text
    //console.log(labelId)
    if (labelId) {
      vibrate()
      setLabelId(labelId)
      handleLabelIdBarcodeClose()
    }
  }

  const handleLabelIdBarcodeClose = () => {
    setLabelIdBarcodeScannerVisible(false)
  }

  /*const launchParentIdScanner = () => {
    setParentIdBarcodeScannerVisible(true)
  }

  const handleParentIdBarcodeMatch = (text: string) => {
    const labelId: string = text

    if (labelId) {
      vibrate()
      setParentId(labelId)
      handleParentIdBarcodeClose()
    }
  }

  const handleParentIdBarcodeClose = () => {
    setParentIdBarcodeScannerVisible(false)
  }*/

  const handleCancel = () => {
    window.history.back()
  }

  const {
    isVisible: isPickStorageModalVisible,
    closeModal: closePickStorageModal,
    openModal: openPickStorageModal,
  } = useModal()

  const handleParentPicked = (
    parentStorage: StorageSpace | undefined
  ) => {
    setNewParent(parentStorage ?? undefined)
    setParentName(parentStorage?.name ?? parentStorage?._id ?? '')
    closePickStorageModal()
  }

  const launchParentPicker = () => {
    openPickStorageModal()
  }

  return (
    <>
      <SimpleHeader text={`Redigera förvaringsplats`} />
      <LayoutStandard scroll={true}>
        <div css={styles}>
          <TextField
            placeholder="Namn"
            value={name}
            onChange={(e) => {
              setName((e.target as HTMLInputElement).value)
            }}
          />

          <TextField
            placeholder="Beskrivning"
            multiline={true}
            rows={3}
            value={description}
            onChange={(e) => {
              //console.log('onchange')
              setDescription((e.target as HTMLInputElement).value)
            }}
          />

          <div className="submenu">
            <button
              className="button"
              onKeyDown={openModal}
              onClick={openModal}
            >
              <p className="actiontitle">
                {selectedVariant === STORAGE_SPACE_TYPE.DEFAULT
                  ? 'Välj typ'
                  : getStorageVariant.label(selectedVariant)}
              </p>
              <div className="icon">
                {getStorageVariant.icon(selectedVariant)}
              </div>
            </button>
            <button
              className="button"
              onKeyDown={() => launchLabelIdScanner()}
              onClick={() => launchLabelIdScanner()}
            >
              <p className="actiontitle">Skanna etikett</p>
              <div className="icon">
                <CropFree fontSize="inherit" />
              </div>
            </button>
          </div>
          <TextField
            placeholder="Etikett-id"
            rows={3}
            value={labelId}
            readOnly={true}
          />
          <div className="label-grid">
            <Button
              variant={ButtonVariant.Primary}
              onClick={() => launchParentPicker()}
            >
              Välj förvaring
            </Button>
            <TextField
              placeholder="Förvaring"
              rows={3}
              value={parentName}
              readOnly={true}
              helperText="Förvaras i denna förvaringsplats"
            />
          </div>
          <Button
            variant={ButtonVariant.Outlined}
            onClick={() => {
              handleCancel()
            }}
          >
            Avbryt
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            onClick={() => {
              handleSave()
            }}
          >
            Spara
          </Button>

          {labelIdBarcodeScannerVisible && (
            <div className="barcodeScannerWrapper">
              <div className="barcodeScannerContent">
                <BarcodeScanner onMatch={handleLabelIdBarcodeMatch} />
                <div className="barcodeScannerCloseButton">
                  <Button
                    variant={ButtonVariant.Outlined}
                    onClick={() => handleLabelIdBarcodeClose()}
                  >
                    Avbryt
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        <NewStorageModal
          isVisible={isVisible}
          closeModal={closeModal}
          onSelectVariant={onSelectVariant}
        ></NewStorageModal>
      </LayoutStandard>
      <Modal
        isVisible={isPickStorageModalVisible}
        closeModal={closePickStorageModal}
      >
        <ModalSelectStorage
          closeModal={handleParentPicked}
          currentStorageId={id}
          selectedItems={[]}
        />
      </Modal>
    </>
  )
}

const EditStorage = withQuery<StorageSpace>({
  query: GET_STORAGE_SPACE,
  loader: EkLoader,
})(
  withMutation<any, StorageSpaceInput>(UPDATE_STORAGE_SPACE)(
    _EditStorage
  )
)

export { EditStorage }
