import {
  ApolloClient,
  NormalizedCacheObject,
  QueryOptions,
} from '@apollo/client'
import { getApolloClient } from '@api/client'
import { getSearchQuery } from '@api/item'
import { Item, ItemPage } from '@types'

/*
searchItemsByNumber(query: String!): ItemPage @faunaignore
    searchItemsByName(query: String!): ItemPage @faunaignore
    searchItemsByTag(query: String!): ItemPage @faunaignore
    searchItemsLookupByNumber(query: String!): ItemPage @faunaignore
    searchItemsLookupByName(query: String!): ItemPage @faunaignore
    */

const getQueryOptions = (queryName: string, searchQuery: string) => {
  const query = getSearchQuery(queryName)
  return {
    query,
    variables: {
      query: searchQuery,
    },
  }
}

const executeSearchQuery = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  abortController: AbortController
) => async (
  queryName: string,
  searchQuery: string
): Promise<ItemPage> => {
  const options: QueryOptions = getQueryOptions(
    queryName,
    searchQuery
  )
  options.context = {
    fetchOptions: { signal: abortController.signal },
  }
  const result = await apolloClient.query(options)
  //console.log(queryName, result)
  if (result?.data) {
    return result.data[queryName]
  } else {
    const e: any = new Error('graphql error')
    e.body = result
    throw e
  }
}

const searchItems = async (
  search: string,
  abortController: AbortController,
  client: ApolloClient<NormalizedCacheObject>
): Promise<Item[]> => {
  const runQuery = executeSearchQuery(client, abortController)

  const page = await runQuery('searchItems', search)
  //console.log('item page', page)

  return page?.data || []
}

const searchReferences = async (
  search: string,
  abortController: AbortController,
  client: ApolloClient<NormalizedCacheObject>
): Promise<Item[]> => {
  const runQuery = executeSearchQuery(client, abortController)

  const page = await runQuery('searchItemsLookup', search)
  //console.log('lookup page', page)

  return page?.data || []
}

const cancellableArticleSearch = (search: string) => {
  const client = getApolloClient(process.env)
  const controller = new window.AbortController()
  return {
    abort: () => {
      console.log('aborting')
      controller.abort()
    },
    items: searchItems(search, controller, client),
    refs: searchReferences(search, controller, client),
  }
}

export { searchItems, searchReferences, cancellableArticleSearch }
