/* eslint-disable max-lines */
// This is likely because of all the GQL-fakedata. I suppose this needs to be reviewed. TODO: Review this.
import React, { FC, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { useSnackbar } from '@hooks'
import { EkList, EkListCell, EkListRow } from '@components/ek-list'
import {
  InvItemsPage,
  Item,
  ItemReport,
  ItemReportEntry,
  PartialUpdateItemInput,
} from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { WithQueryExtension, withQuery } from '@components/with-query'
import { COLORS } from '@styles'
import {
  Button,
  ButtonVariant,
  TextField,
} from '@pure-components/input'
import { H5, H6 } from '@components/base'
import { GET_RELEVANT_INVENTORY_ITEMS } from '@api/special/item-report/item-report-queries'
import {
  CircularSpinnerLoader,
  Snackbar,
} from '@pure-components/feedback'
import { Caption, Subtitle2 } from '@components/base-extended'
import {
  WithMutationExtension,
  withMutation,
} from '@components/with-mutation'
import { UPDATE_ITEM_PARTIAL } from '@api/item'
import { reduceToReport } from '../utils/compile-report'
import { EkCheckbox } from '@components/input'
import { formatUnitOfMeasure } from '@common/get-unit-of-measure'
import { UNIT_OF_MEASURE } from '@src/functions/lib/types'

interface ReportProps extends RouteComponentProps {}

const _BalanceThresholdPage: FC<
  ReportProps &
    WithMutationExtension<Item, PartialUpdateItemInput> &
    WithQueryExtension<InvItemsPage>
> = ({ queryData, addData, refetch }) => {
  const { addAlert } = useSnackbar()

  const [selectedItem, setSelectedItem] = useState<
    undefined | ItemReportEntry
  >(undefined)

  const [searchFilter, setSearchFilter] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [balanceMinimum, setBalanceMinimum] = useState('0')
  const [isWarningEnabled, setIsWarningEnabled] = useState(false)

  const {
    relevantInventoryItems: { data },
  } = queryData

  const itemData: ItemReportEntry[] = (() => {
    const arr = reduceToReport(data || []).data || []
    arr.sort((a, b) => {
      if ((a.name || '') < (b.name || '')) {
        return -1
      }
      if ((a.name || '') > (b.name || '')) {
        return 1
      }
      return 0
    })
    return arr
  })()

  const onRowClick = (clickedItem: ItemReportEntry) => {
    if (selectedItem?.id === clickedItem.id) {
      setSelectedItem(undefined)
      setIsWarningEnabled(false)
      setBalanceMinimum('0')
    } else {
      setSelectedItem(clickedItem)
      setIsWarningEnabled(
        clickedItem?.balanceWarning !== null &&
          clickedItem?.balanceWarning !== undefined
      )
      setBalanceMinimum(
        (clickedItem?.balanceWarning !== null &&
        clickedItem?.balanceWarning !== undefined
          ? clickedItem.balanceWarning
          : 0) + ''
      )
    }
  }

  const handleCancel = async () => {
    setSelectedItem(undefined)
    setIsWarningEnabled(false)
    setBalanceMinimum('0')
  }

  const handleSave = async () => {
    if (selectedItem?.id) {
      setIsLoading(true)
      try {
        const balanceWarning = isWarningEnabled
          ? parseFloat(balanceMinimum)
          : null
        const variables: PartialUpdateItemInput & { id: string } = {
          id: selectedItem!.id,
          balanceWarning,
        }
        await addData({
          variables,
        })
        setIsLoading(false)
        refetch()
      } catch (e) {
        setIsLoading(false)
        console.log('save error', e)
        addAlert(<Snackbar text={'Something went wrong'} />)
      }
    }
  }

  const handleSearchChange = async (event: any) => {
    setSearchFilter(event.target.value)
  }

  const styles = css({
    height: 'calc(100vh - 70px)',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: '"left right"',
    '.right': {
      padding: '2rem',
      gridArea: 'right',
      height: '100%',
    },
    '.left': {
      gridArea: 'left',
      background: COLORS.gray[10],
      height: '100%',
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '"search" "list"',
      borderLeft: `2px solid ${COLORS.gray[50]}`,
    },
    '.searchinput': {
      //maxHeight: '60%',
      gridArea: 'search',
    },
    '.storageList': {
      //maxHeight: '60%',
      height: 'calc(100vh - 140px)',
      overflowY: 'scroll',
      gridArea: 'list',
    },
    '.buttonRow': {
      display: 'flex',
      flexDirection: 'row',

      marginTop: '0.5rem',
      paddingTop: '1rem',

      justifyContent: 'space-between',
      alignItems: 'center',

      borderTop: `2px solid ${COLORS.gray[50]}`,
    },
    '.input-group': {
      display: 'flex',
      alignItems: 'center',
    },
    '.label': {
      margin: '1rem 1rem 1.5rem 1rem',
    },
    '.checkbox': {
      margin: '1rem 1rem 1.5rem 1rem',
    },
    '.unit': {
      margin: '0.5rem 0.5rem 0.75rem 0.5rem',
    },
    '.button': {
      maxWidth: '15rem',
    },
    '.loader': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      marginTop: '4rem',
    },
  })

  return (
    <>
      <section css={styles}>
        <div className="left">
          <div className="searchinput">
            <TextField
              placeholder="filtrera på namn"
              inputType="search"
              value={searchFilter}
              onChange={(e) => handleSearchChange(e)}
              onKeyUp={(e) => handleSearchChange(e)}
            />
          </div>
          <div className="storageList">
            <EkList>
              {(searchFilter.length > 2
                ? itemData.filter((it) =>
                    it.name
                      ?.toLowerCase()
                      .includes(searchFilter.toLowerCase())
                  )
                : itemData
              ).map((item: ItemReportEntry, i) => (
                <EkListRow
                  classes={
                    selectedItem?.id === item.id
                      ? 'selected flex-fix'
                      : 'flex-fix'
                  }
                  key={item.id}
                  onClick={(s) => onRowClick(item)}
                >
                  <EkListCell key={`stock_cell1${item.id}`}>
                    <Subtitle2 key={`stock_subtitle${item.id}`}>
                      {item.name}
                    </Subtitle2>
                    <Caption key={`stock_caption${item.id}`}>
                      {item.number}
                    </Caption>
                  </EkListCell>
                  <EkListCell
                    justify="end"
                    key={`stock_cell2${item.id}`}
                  >
                    {item.quantity}
                  </EkListCell>
                </EkListRow>
              ))}
            </EkList>
          </div>
        </div>
        <div className="right">
          <H5>{selectedItem?.name || ''}</H5>
          {selectedItem && (
            <>
              <div className="buttonRow">
                <div className="label">
                  <H6>Brytpunkt:</H6>
                </div>
                <div className="input-group">
                  <div className="checkbox">
                    <EkCheckbox
                      checked={isWarningEnabled}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const state = event.target.checked
                        setIsWarningEnabled(state)
                      }}
                    />
                  </div>
                  <TextField
                    disabled={!isWarningEnabled}
                    placeholder="0"
                    inputType="number"
                    min={0}
                    step={0.01}
                    value={balanceMinimum}
                    onChange={(e) => {
                      setBalanceMinimum(
                        (e.target as HTMLInputElement).value
                      )
                    }}
                  />
                  <div className="unit">
                    <Subtitle2>
                      {formatUnitOfMeasure(
                        selectedItem.unit || UNIT_OF_MEASURE.DEFAULT
                      )}
                    </Subtitle2>
                  </div>
                </div>
              </div>
              <div className="buttonRow">
                <div className="button">
                  <Button
                    variant={ButtonVariant.Primary}
                    onClick={handleSave}
                  >
                    Spara
                  </Button>
                </div>
                <div className="button">
                  <Button
                    variant={ButtonVariant.Outlined}
                    disabled={selectedItem === undefined}
                    onClick={handleCancel}
                  >
                    Avbryt
                  </Button>
                </div>
              </div>
            </>
          )}

          <div className="loader">
            {isLoading && (
              <>
                <CircularSpinnerLoader />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

const BalanceThresholdPage = withQuery<ItemReport>({
  query: GET_RELEVANT_INVENTORY_ITEMS,
  loader: EkLoader,
})(
  withMutation<Item, PartialUpdateItemInput>(UPDATE_ITEM_PARTIAL)(
    _BalanceThresholdPage
  )
)

export { BalanceThresholdPage }
