import React, { FC, ReactNode } from 'react'
import { Link } from '@reach/router'
import { css, SerializedStyles } from '@emotion/react'

interface AProps {
  to: string
  children: ReactNode
  style?: SerializedStyles | SerializedStyles[]
}

const A: FC<AProps> = ({ to = '', children = [], style = css() }) => {
  const _style = css([
    {
      boxShadow: 'none',
      textDecoration: 'none',
      color: 'inherit',
    },
    {
      ...style,
    },
  ])

  return (
    <Link to={to} css={_style}>
      {children}
    </Link>
  )
}

export { A }
