import { gql } from '@apollo/client'

const CREATE_INCOMING_ORDER = gql`
  mutation CreateIncomingOrder(
    $orderNumber: String!
    $supplier: String!
    $createdDate: String!
    $expectedDeliveryDate: String
    $ordererName: String
    $ordererContact: String
    $totalPrice: Float
    $scanned: Boolean
    $items: IncomingOrderItemsRelation!
    $storageSpace: IncomingOrderStorageSpaceRelation!
  ) {
    createIncomingOrder(
      data: {
        orderNumber: $orderNumber
        supplier: $supplier
        createdDate: $createdDate
        expectedDeliveryDate: $expectedDeliveryDate
        ordererName: $ordererName
        ordererContact: $ordererContact
        totalPrice: $totalPrice
        scanned: $scanned
        items: $items
        storageSpace: $storageSpace
      }
    ) {
      _id

      orderNumber
      supplier
      createdDate
      expectedDeliveryDate
      ordererName
      ordererContact
      totalPrice
      scanned
      storageSpace {
        _id

        name
        description
        virtual
        type
        displayLabel {
          _id

          barcode
        }
        parent
      }
      items {
        data {
          _id

          item {
            _id

            number
            sdfId
            name
            description
            tags {
              data {
                _id
                name
              }
            }
            images
            defaultImage
            unitOfMeasure
            supplier
            netPrice
            grossPrice
            sellingPrice
          }
          quantity
          price
        }
      }
    }
  }
`

export { CREATE_INCOMING_ORDER }
