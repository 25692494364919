import React, { FC, ReactNode } from 'react'

interface WorkingOrdersListViewProps {
  children?: ReactNode
}

const WorkingOrdersListView: FC<WorkingOrdersListViewProps> = ({
  children,
}) => {
  return <section>{children}</section>
}

export { WorkingOrdersListView }
