import { gql } from '@apollo/client'

const GET_TAGS = gql`
  query tags($_size: Int, $_cursor: String) {
    tags(_size: $_size, _cursor: $_cursor) {
      data {
        _id

        name
      }
      before
      after
    }
  }
`

export { GET_TAGS }
