import React, { FC, ReactNode } from 'react'
import { ExpansionItem } from '@pure-components/surface'
import { css } from '@emotion/react'
import { COLORS, TEXT_STYLE } from '@src/styles'

interface EkExpansionItemProps {
  title?: ReactNode
  children?: ReactNode
  isOpen?: boolean
  onTitleClick?: () => void
}

const EkExpansionItem: FC<EkExpansionItemProps> = ({
  title,
  children,
  isOpen,
  onTitleClick,
}) => {
  const titleStyle = css([
    TEXT_STYLE.subtitle[2],
    {
      transition: 'all 300ms ease',
      padding: '8px 24px',
      color: isOpen ? COLORS.orange[50] : COLORS.gray[90],
    },
  ])

  const contentStyle = css([
    TEXT_STYLE.body[2],
    {
      padding: '0px 24px',
    },
  ])

  return (
    <ExpansionItem
      title={title}
      isOpen={isOpen}
      onTitleClick={onTitleClick}
      titleStyle={titleStyle}
      contentStyle={contentStyle}
    >
      {children}
    </ExpansionItem>
  )
}

export { EkExpansionItem }
