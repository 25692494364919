import React, { FC, ReactNode, useState, useEffect } from 'react'
import { RadioGroupContext } from './radio-group-context'

interface RadioGroupProps {
  name?: string
  onChange?: (value: string) => void
  value?: string
  children?: ReactNode
}

const RadioGroup: FC<RadioGroupProps> = ({
  name = '',
  onChange = () => {},
  value = '',
  children = [],
}) => {
  const [_value, setValue] = useState(value)

  useEffect(() => {
    setValue(_value)
    onChange(_value)
  }, [_value, onChange])

  return (
    <RadioGroupContext.Provider
      value={{
        value: _value,
        setValue,
      }}
    >
      {children}
    </RadioGroupContext.Provider>
  )
}

export { RadioGroup }
