import styled from '@emotion/styled'
import { COLORS } from '@styles'
import { TEXT_STYLE } from '@styles'

const H1 = styled.h1(
  TEXT_STYLE.heading[1],
  ({ color = COLORS.gray[100] }) => ({ color })
)

const H2 = styled.h2(
  TEXT_STYLE.heading[2],
  ({ color = COLORS.gray[100] }) => ({ color })
)

const H3 = styled.h3(
  TEXT_STYLE.heading[3],
  ({ color = COLORS.gray[100] }) => ({ color })
)

const H4 = styled.h4(
  TEXT_STYLE.heading[4],
  ({ color = COLORS.gray[100] }) => ({ color })
)

const H5 = styled.h5(
  TEXT_STYLE.heading[5],
  ({ color = COLORS.gray[100] }) => ({ color })
)

const H6 = styled.h6(
  TEXT_STYLE.heading[6],
  ({ color = COLORS.gray[100] }) => ({ color })
)

export { H1, H2, H3, H4, H5, H6 }
