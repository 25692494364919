import './logging/logrocket-setup'
import React from 'react'
import { render } from 'react-dom'
import { App } from './app'
import './index.css'
import { Global } from '@emotion/react'
import { resetGlobal, GLOBAL } from '@styles'
import { ApolloProvider } from '@apollo/client'
import { client } from './api/client'

render(
  <React.StrictMode>
    <Global styles={[resetGlobal, GLOBAL]} />
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
