import React, { useMemo } from 'react'
import { BottomNav } from '@pure-components/navigation/bottom-nav'
import { useBottomNav, useBottomSheet } from '@hooks'
import { getActiveIconType } from './get-active-icon-id'
import { icons, NavIcon } from './icons'
import { bottomNavWrapperStyles } from './styles'
import { navigate } from '@reach/router'
import { AppRoutes } from '@router'
import { EkBottomNavItem } from './ek-bottom-nav-item'
import { NavBottomSheet } from '@components/nav-bottom-sheet'
import { useLogin } from '@hooks'

const EkBottomNav = () => {
  const { activeItem } = useBottomNav()
  const { setIsOpen, isOpen, setChildren } = useBottomSheet()
  const { isLoggedIn } = useLogin()

  const handleHome = () => {
    navigate(AppRoutes.Home)
  }

  const handleStoreMall = () => {
    navigate(AppRoutes.Storages)
  }

  const handlePhotoCamera = () => {
    navigate(AppRoutes.Scan)
  }

  const handleAssignment = () => {
    navigate(AppRoutes.WorkingOrders)
  }

  const handleViewHeadline = () => {
    setChildren(<NavBottomSheet />)
    setIsOpen(!isOpen)
  }

  const functionMap = new Map([
    [NavIcon.Home, handleHome],
    [NavIcon.StoreMall, handleStoreMall],
    [NavIcon.PhotoCamera, handlePhotoCamera],
    [NavIcon.Assignment, handleAssignment],
    [NavIcon.ViewHeadline, handleViewHeadline],
  ])

  // using this to avoid re-renders of bottom-nav on every route-change
  return useMemo(() => {
    const handleClick = (icon: NavIcon) =>
      functionMap.get(icon) || handleHome

    const typeOfActiveIcon = getActiveIconType(activeItem)

    return (
      <>
        {isLoggedIn && (
          <div css={bottomNavWrapperStyles}>
            <BottomNav>
              {icons.map(({ Component, type }) => (
                <EkBottomNavItem
                  onClick={handleClick(type)}
                  isActive={typeOfActiveIcon === type}
                  navIcon={type}
                  key={type}
                >
                  <Component className="icon" />
                </EkBottomNavItem>
              ))}
            </BottomNav>
          </div>
        )}
      </>
    )
  }, [activeItem, functionMap, isLoggedIn])
}

export { EkBottomNav }
