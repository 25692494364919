import React, { FC } from 'react'
import { css } from '@emotion/react'
import { Skeleton } from '@pure-components/graphics/skeleton'
import { COLORS } from '@styles'

interface EkLoaderProps {
  loading?: boolean
}

const EkLoader: FC<EkLoaderProps> = ({ loading = true }) => {
  const styling = css({
    width: 60,
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 12px)',
    gap: 12,
    '.dot': {
      borderRadius: 999,
      background: COLORS.gray[50],
      height: 12,
      width: 12,
    },
  })
  return (
    <Skeleton loading={loading}>
      <div css={styling}>
        <div className="dot">&nbsp;</div>
        <div className="dot">&nbsp;</div>
        <div className="dot">&nbsp;</div>
      </div>
    </Skeleton>
  )
}
export { EkLoader }
