import React, { FC } from 'react'
import { WindowLocation } from '@reach/router'
import { ViewList, ViewModule } from '@material-ui/icons'
import { addQueryParams } from '@common'
import { A } from '@components/base'

interface EkViewSwitcherProps {
  view: string
  location: WindowLocation
}

const EkViewSwitcher: FC<EkViewSwitcherProps> = ({
  view = 'card',
  location,
}) => {
  return view === 'card' ? (
    <A
      to={addQueryParams([{ key: 'view', value: 'list' }], location)}
    >
      <ViewList />
    </A>
  ) : (
    <A
      to={addQueryParams([{ key: 'view', value: 'card' }], location)}
    >
      <ViewModule />
    </A>
  )
}

export { EkViewSwitcher }
