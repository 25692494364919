/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const SEND_ORDER_TO_SDF = gql`
  mutation SendOrderToSdf($orderId: ID!) {
    sendOrderToSdf(orderId: $orderId)
  }
`

export { SEND_ORDER_TO_SDF }
