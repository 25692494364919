/* eslint-disable max-lines */
import React, { FC, useState } from 'react'
import { H5 } from '@components/base'
import { RouteComponentProps } from '@reach/router'
import { Button, TextField } from '@pure-components/input'
import { css } from '@emotion/react'
import { TEXT_STYLE } from '@styles'

interface InputIncomingOrderProps extends RouteComponentProps {
  closeModal?: (orderId: string | null) => void
}

const ModalInputIncomingOrder: FC<InputIncomingOrderProps> = ({
  closeModal = () => {},
}) => {
  const [orderId, setOrderId] = useState<null | string>(null)

  const isButtonDisabled = () => {
    return orderId === null || orderId.length < 3
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderId(e.target.value)
  }

  const styles = css({
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto',
    gap: '1em',
    overflow: 'hidden',
    position: 'relative',
    '.header': {
      display: 'grid',
      gridTemplateColumns: 'repeat( auto-fit, minmax(150px, 1fr) )',
      gap: '1em',
    },

    '.barcodeScannerWrapper': {
      position: 'absolute',
      zIndex: 2000,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      '.barcodeScannerContent': {
        maxWidth: '300px',
      },
    },
    '.barcodeScannerCloseButton': {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: '10px',
      marginTop: '20px',
    },
    '.storageName': [
      TEXT_STYLE.subtitle[2],
      {
        textTransform: 'uppercase',
      },
    ],
  })

  return (
    <section css={styles}>
      <div className="header">
        <div>
          <H5>Länka följesedel</H5>
        </div>
      </div>
      <p>Skriv in ordernummer på följesedeln</p>
      <TextField
        placeholder="ordernummer"
        inputType="text"
        value={orderId ?? ''}
        onChange={(e) =>
          handleInputChange(e as React.ChangeEvent<HTMLInputElement>)
        }
      />
      <Button
        disabled={isButtonDisabled()}
        onClick={() => closeModal(orderId)}
      >
        Länka
      </Button>
    </section>
  )
}

export { ModalInputIncomingOrder }
