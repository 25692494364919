import React from 'react'
import { css, keyframes } from '@emotion/react'

const Shimmer = () => {
  const loading = keyframes`
  0% { transform: translateX(-150%) };
  50% { transform: translateX(-60%) };
  100% { transform: translateX(150%) };
  `

  const styling = css({
    position: 'absolute',
    /* parent must be relative position & overflow hidden */
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    animation: `${loading} 2.5s infinite`,

    '.shimmer': {
      width: '50%',
      height: '100%',
      background: 'rgba(255,255,255,0.2)',
      transform: 'skewX(-20deg)',
      boxShadow: '0 0 30px 30px rgba(255,255,255,0.2)',
    },
  })

  return (
    <div css={styling}>
      <div className="shimmer"></div>
    </div>
  )
}

export { Shimmer }
