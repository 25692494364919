import React, { FC, ReactNode } from 'react'
import { Checkbox } from '@pure-components/input'
import { Star, StarBorder } from '@material-ui/icons'
import { COLORS } from '@styles'

interface EkCheckBoxStarProps {
  checked?: boolean
  iconChecked?: ReactNode
  iconUnchecked?: ReactNode
  colorChecked?: string
  colorUnchecked?: string
}

const EkCheckBoxStar: FC<EkCheckBoxStarProps> = ({
  checked = true,
  iconChecked = <Star />,
  iconUnchecked = <StarBorder />,
  colorChecked = COLORS.orange[10],
  colorUnchecked = COLORS.gray[100],
}) => {
  return (
    <Checkbox
      checked={true}
      icon={checked ? iconChecked : iconUnchecked}
      iconColor={checked ? colorChecked : colorUnchecked}
      background={`rgba(0,0,0,0)`}
      borderColor={`rgba(0,0,0,0)`}
    />
  )
}

export { EkCheckBoxStar }
