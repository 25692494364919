import React, { FC } from 'react'
import { css } from '@emotion/react'
import { COLORS } from '@styles'
import { TextFieldProps } from './textfield'

const TextFieldMultiline: FC<TextFieldProps> = ({
  name,
  children,
  variant,
  multiline,
  rowsMax,
  rows,
  value,
  placeholder,
  error,
  helperText,
  onChange,
  onKeyUp,
  disabled,
  readOnly,
  width,
}) => {
  const textfieldStyles = css({
    width,
    color: COLORS.gray[90],
    border: `1px solid ${COLORS.gray[90]}`,
    borderColor: error ? COLORS.danger[50] : COLORS.gray[30],
    padding: '1em',
    borderRadius: '5px',
    opacity: disabled ? 0.4 : 1,
    transition: 'all 250ms',
    '&:focus': {
      borderColor: error ? COLORS.danger[50] : COLORS.gray[70],
    },
  })

  const helperTextStyles = css({
    color: COLORS.danger[50],
  })

  return (
    <div>
      <textarea
        name={name}
        css={textfieldStyles}
        id={placeholder}
        rows={rows}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={onChange}
        onKeyUp={onKeyUp}
      ></textarea>
      <div>
        {helperText && helperText}
        <span css={helperTextStyles}>{error && error}</span>{' '}
      </div>
    </div>
  )
}

export { TextFieldMultiline }
