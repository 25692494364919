import { TextFieldVariant } from './textfield-variant'

const width: Map<TextFieldVariant, string> = new Map([
  ['primary', '100%'],
  ['secondary', '10ch'],
])

const getWidth = (variant: TextFieldVariant = 'primary') =>
  width.get(variant) ?? '100%'

export { getWidth }
