import React from 'react'
import {
  Person,
  HelpOutline,
  Inbox,
  InsertChartOutlined,
  ExitToApp,
} from '@material-ui/icons'
import { NavBottomSheetItem } from './nav-bottom-sheet-item'
import { AppRoutes } from '@router'
import { useIsAdmin } from '@hooks'

const NavBottomSheet = () => {
  const { isAdmin } = useIsAdmin()
  const sheetItems = [
    {
      text: 'Hjälp och vanliga frågor',
      Icon: HelpOutline,
      to: AppRoutes.Help,
    },
    {
      text: 'Inkommande Order',
      Icon: Inbox,
      to: AppRoutes.IncomingProducts,
    },
    {
      text: 'Arkiverade Order',
      Icon: InsertChartOutlined,
      to: AppRoutes.ArchivedOrders,
    },
    {
      text: 'Logga ut',
      Icon: ExitToApp,
      to: AppRoutes.Logout,
    },
    /*{
      text: 'Rapporter',
      Icon: InsertChartOutlined,
      to: AppRoutes.Reports,
    },*/
  ]
  if (isAdmin) {
    sheetItems.unshift({
      text: 'Användare',
      Icon: Person,
      to: AppRoutes.Users,
    })
  }
  return (
    <nav>
      {sheetItems.map(({ text, Icon, to }) => {
        return (
          <NavBottomSheetItem
            key={text}
            text={text}
            icon={<Icon />}
            to={to}
          />
        )
      })}
    </nav>
  )
}

export { NavBottomSheet }
