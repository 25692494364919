import React, { FC, ChangeEvent, KeyboardEvent } from 'react'
import { TextFieldVariant } from './textfield-variant'
import { getWidth } from './textfield-utils'
import { TextFieldMultiline } from './textfield-multiline'
import { TextFieldSingleline } from './textfield-singleline'

type TextFieldInput =
  | 'text'
  | 'password'
  | 'tel'
  | 'number'
  | 'search'
interface TextFieldProps {
  name?: string
  variant?: TextFieldVariant
  multiline?: boolean
  rowsMax?: number
  rows?: number
  value?: string | number
  placeholder?: string
  error?: string
  helperText?: string
  onChange?: (event: ChangeEvent) => void
  onKeyUp?: (event: KeyboardEvent) => void
  disabled?: boolean
  readOnly?: boolean
  inputType?: TextFieldInput
  max?: number | string | undefined
  min?: number | string | undefined
  step?: number | string | undefined
  width?: string
}

const TextField: FC<TextFieldProps> = ({
  name = '',
  variant = 'primary',
  multiline = false,
  rowsMax = 4,
  rows = 1,
  value = '',
  placeholder = '',
  error = '',
  helperText = '',
  onChange,
  onKeyUp,
  disabled = false,
  readOnly = false,
  inputType = 'text',
  max,
  min,
  step,
  width = getWidth(variant),
}) => {
  return multiline ? (
    <TextFieldMultiline
      name={name}
      variant={variant}
      multiline={multiline}
      rowsMax={rowsMax}
      rows={rows}
      value={value}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onKeyUp={onKeyUp}
      disabled={disabled}
      readOnly={readOnly}
      inputType={inputType}
      width={width}
    />
  ) : (
    <TextFieldSingleline
      name={name}
      variant={variant}
      multiline={multiline}
      rowsMax={rowsMax}
      rows={rows}
      value={value}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onKeyUp={onKeyUp}
      disabled={disabled}
      readOnly={readOnly}
      inputType={inputType}
      max={max}
      min={min}
      width={width}
    />
  )
}

export { TextField }
export type { TextFieldProps, TextFieldInput }
