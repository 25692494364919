import { gql } from '@apollo/client'

const GET_FAQS = gql`
  query faqs($_size: Int, $_cursor: String) {
    faqs(_size: $_size, _cursor: $_cursor) {
      data {
        _id

        question
        answer
      }
      before
      after
    }
  }
`

export { GET_FAQS }
