import React, { ReactNode } from 'react'
import { css } from '@emotion/react'
//import { useBottomNav } from '@hooks'
import { CLAMP } from '@styles'

type LayoutStandardProps = {
  children?: ReactNode
  scroll?: boolean
  padding?: string
}

const LayoutStandard = ({
  children,
  scroll = false,
  padding = '',
}: LayoutStandardProps) => {
  //const { height } = useBottomNav()
  const overflow = scroll ? 'auto !important' : 'hidden'
  const styles = css([CLAMP.standard], {
    overflow,
    padding,
    //minHeight: '-webkit-fill-available',
    /*paddingBottom:
      parseInt(height) + 'px',*/
  })

  /*const marginBoxStyles = css({
    height: parseInt(height) + parseInt(height) + padding + 20 + 'px',
  })*/

  return (
    <div css={styles}>
      {children}
      {/*<div css={marginBoxStyles}></div>*/}
    </div>
  )
}

export { LayoutStandard }
