import { css } from '@emotion/react'

const styling = css({
  display: 'grid',
  rowGap: '24px',
  padding: 24,
  '.header': {
    display: 'grid',
    rowGap: 6,
  },
  '.changeamount': {
    display: 'grid',
    gridTemplateRows: 'repeat(2, auto)',
  },
  '.items': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    alignItems: 'center',
    gap: 12,
  },
  '.item': {
    display: 'grid',
    alignContent: 'center',
    rowGap: 6,
  },
  '.left': {
    justifyItems: 'start',
  },
  '.center': {
    justifyItems: 'center',
  },
  '.right': {
    justifyItems: 'end',
  },
  '.storages': {
    display: 'grid',
    gridTemplateColumns: '1fr 48px 1fr',
    gridTemplateRows: 'auto auto',
    rowGap: 24,
    alignItems: 'center',
  },
  '.storages > div': {
    display: 'grid',
    rowGap: 6,
  },
  '.actions': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 24,
    justifyContent: 'center',
    padding: '1em',
  },
})

export { styling }
