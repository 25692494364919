/* eslint-disable max-lines */
// TODO: Refactor this so the file is sub 100 lines

import React, { FC, useEffect, useState } from 'react'
import {
  RouteComponentProps,
  navigate,
  useLocation,
} from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { COLORS } from '@styles'
//import { A, H5, P2 } from '@components/base'
//import { AppRoutes } from '@router'
import { AppRoutes } from '@router'
import { client } from '@api/client'
import { SYNC_ITEM_FROM_SDF } from '@api/sdf/sync-item-mutation'
import { CircularSpinnerLoader } from '@pure-components/feedback'
import { parse } from 'query-string'

interface ArticleFromSdfProps extends RouteComponentProps {
  id?: string //sdfid
}

const _ArticleFromSDF: FC<ArticleFromSdfProps> = ({ id }) => {
  const location = useLocation()
  const params = parse(location.search)
  //console.log('params: ', params)

  const sdfId = params.sdfid
  //console.log('sdfid: ', sdfId)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const syncFromSDF = async () => {
      try {
        const result: any = await client.mutate({
          mutation: SYNC_ITEM_FROM_SDF,
          variables: {
            sdfId: sdfId,
          },
        })
        //console.log('sync to sdf results: ', result)
        const item = result.data.syncItemFromSdf
        navigate(`${AppRoutes.Articles}/${item._id}`, {
          replace: true,
        })
      } catch (e) {
        setIsLoading(false)
      }
    }

    syncFromSDF()
  }, [sdfId])

  const styles = css({
    width: '100%',
    height: '100vh',
    '.loader': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    '.info': {
      padding: '6px 24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
    },
    '.details': {
      color: COLORS.gray[90],
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '0.2em',
      padding: '12px 0',
      placeContent: 'center center',
    },
  })

  return (
    <div css={styles}>
      {isLoading && (
        <div className="loader">
          <CircularSpinnerLoader />
        </div>
      )}
      {!isLoading && (
        <LayoutStandard>
          <div className="info">
            <span>Kunde inte synka artikel från SDF.</span>
          </div>
        </LayoutStandard>
      )}
    </div>
  )
}

const ArticleFromSDF = _ArticleFromSDF

export { ArticleFromSDF }
