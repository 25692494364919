import React, { FC, useState } from 'react'
import { css } from '@emotion/react'
import { CREATE_FAQ, GET_FAQS } from '@api/faq'
import { withQuery, WithQueryExtension } from '@components/with-query'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { EkExpansionItem } from '@components/ek-expansion-item'
import { EkSkeletonFaq } from '@components/graphics/ek-skeleton-item'
import { FaqInput, Faq, FaqPage } from '@types'
import { Pagination } from '@components/pagination'

const _FaqList: FC<
  WithMutationExtension<Faq[], FaqInput> & WithQueryExtension<FaqPage>
> = ({ queryData, addData, previousPage, nextPage }) => {
  const [expandedId, setExpandedId] = useState('')

  const styles = css({
    paddingTop: '8px',
  })

  const {
    faqs: { data = [], after, before },
  } = queryData

  return (
    <div css={styles}>
      {data.length ? (
        data.map(({ question = '', answer = '', _id = '' }) => (
          <EkExpansionItem
            title={question}
            key={_id}
            isOpen={_id === expandedId}
            onTitleClick={() =>
              setExpandedId(() => (_id === expandedId ? '' : _id))
            }
          >
            {answer}
          </EkExpansionItem>
        ))
      ) : (
        <p>No data.</p>
      )}
      <Pagination
        before={before}
        after={after}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </div>
  )
}

const FaqList = withQuery<FaqPage>({
  query: GET_FAQS,
  loader: EkSkeletonFaq,
})(withMutation<Faq[], FaqInput>(CREATE_FAQ)(_FaqList))

export { FaqList }
