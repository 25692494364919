enum AppRoutes {
  Login = '/login',
  Logout = '/logout',
  Forgotpassword = '/forgotpassword',
  Newpassword = '/newpassword',
  Home = '/',
  IncomingProducts = '/incoming-products',
  IncomingProduct = '/incoming-products/:id',
  WorkingOrders = '/working-orders',
  WorkingOrder = '/working-orders/:id',
  WorkingOrderSearch = '/working-orders/search',
  AssignToOrder = '/working-orders/:id/assign',
  ArchivedOrders = '/archived-orders',
  ArchivedOrder = '/archived-orders/:id',
  ArchivedOrderSearch = '/archived-orders/search',
  Articles = '/articles',
  Article = '/articles/:id',
  EditArticle = '/articles/:id/edit',
  ArticleFromSDF = '/articles/sdf',
  NewArticle = '/articles/new',
  ArticleSearch = '/articles/search',
  ArticleInventory = '/article-inventory/:id',
  StockBalances = '/stock-balance',
  StockBalance = '/stock-balance/:id',
  Users = '/users',
  NewUser = '/users/new',
  User = '/users/:id',
  Help = '/help',
  Reports = '/reports',
  Storages = '/storages',
  NewStorage = '/storages/new',
  Storage = '/storages/:id',
  EditStorage = '/storages/:id/edit',
  AdjustStorageInventory = '/storages/:id/adjust',
  AdjustStorageInventoryRemove = '/storages/:id/removearticles',
  AddToStorage = '/storages/add',
  Scan = '/scan',
  Moves = '/move',
  Move = '/move/:id',
  SetPassword = '/setpass',
  AdminReports = '/admin/reports',
  AdminBalanceThreshold = '/admin/balance',
}

export { AppRoutes }
