import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { VariantType } from './ek-list-row'
import { TEXT_STYLE } from '@styles'

interface EkListCellProps {
  children?: ReactNode
  width?: string
  justify?:
    | 'auto'
    | 'normal'
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
  align?:
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'baseline'
    | 'inherit'
    | 'initial'
    | 'unset'
  padding?: string
  variant?: VariantType
}

const EkListCell: FC<EkListCellProps> = ({
  children = [],
  width = '100%',
  justify = 'start',
  align = 'center',
  padding = '0.5em',
  variant = 'row',
}) => {
  const styling = css([
    variant === 'header'
      ? TEXT_STYLE.subtitle[2]
      : TEXT_STYLE.list[1],
    {
      display: 'grid',
      width,
      justifyContent: justify,
      alignItems: align,
      padding,
    },
  ])
  return <div css={styling}>{children}</div>
}

export { EkListCell }
