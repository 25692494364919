/* eslint-disable max-lines */
// TODO: Ta bort detta när GraphQL är implementerat.
import React, { FC, useState } from 'react'
import { css } from '@emotion/react'
import { Check, ExpandMore, ExpandLess } from '@material-ui/icons'
import { COLORS, TEXT_STYLE } from '@styles'

interface SelectItem {
  id: string
  value: any
  name: string
}

interface EkSelectProps {
  title: string
  items: SelectItem[]
  selectedValue?: any
  multiSelect?: boolean
  onToggle?: (event: boolean) => void
  onChange?: (selection: SelectItem[]) => void
  open?: boolean
}

const EkSelect: FC<EkSelectProps> = ({
  title = '',
  items = [],
  multiSelect = false,
  onToggle = () => {},
  onChange = () => {},
  open = false,
  selectedValue,
}) => {
  //const emptyList: SelectItem[] = []
  const preSelected: SelectItem[] = items.filter(
    (item) => item.value === selectedValue
  )
  //console.log(items, selectedValue)
  //console.log('preselected', preSelected)

  const [isOpen, setIsOpen] = useState(open)

  const [selection, setSelection] = useState(preSelected)

  const [selectedTitle, setSelectedTitle] = useState(
    preSelected[0]?.name || title
  )

  const handleOnClick = (item: SelectItem) => {
    let selectList: SelectItem[] = []
    if (!selection.some((current) => current.id === item.id)) {
      if (!multiSelect) {
        selectList = [item]
        //setSelection([item])
        //onChange([item])
        setIsOpen(!isOpen)
      } else if (multiSelect) {
        selectList = [...selection, item]
        //setSelection([...selection, item])
        //onChange([...selection, item])
      }
    } else {
      let selectionAfterRemoval = selection
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current) => current.id !== item.id
      )
      selectList = [...selectionAfterRemoval]
      //setSelection([...selectionAfterRemoval])
      //onChange([...selectionAfterRemoval])
    }
    setSelection(selectList)
    setSelectedTitle(selectList.map((s) => s.name).join(', '))
    onChange(selectList)
  }

  const isItemInSelection = (item: SelectItem) => {
    if (selection.some((current) => current.id === item.id)) {
      return true
    }
    return false
  }

  const styling = css([
    TEXT_STYLE.body[2],
    {
      color: COLORS.gray[90],
      display: 'flex',
      minHeight: '2em',
      padding: '0.5em 0',
      flexWrap: 'wrap',
      border: '1px solid #ccc',

      borderRadius: 5,
      '.header ': {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        width: '100%',
        padding: '0 2em',
      },
      '.list': {
        boxShadow: '0 .125rem .25rem rgba(0,0,0,.075) !important',
        padding: 0,
        margin: 0,
        width: '100%',
        marginTop: '1em',
        li: {
          listStyleType: 'none',

          '&:first-of-type': {
            '> button': {
              borderTop: `1px solid ${COLORS.gray[40]}`,
            },
          },
          button: {
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            padding: '15px 20px 15px 20px',
            border: 0,
            borderBottom: `1px solid ${COLORS.gray[40]}`,
            width: '100%',
            textAlign: 'left',

            '&:hover, &:focus': {
              cursor: 'pointer',
              backgroundColor: COLORS.gray[40],
            },
          },
        },
      },
    },
  ])

  const iconStyle = css({
    color: COLORS.gray[90],
  })

  return (
    <div css={styling}>
      <div
        tabIndex={0}
        className="header"
        role="button"
        onKeyPress={() => {
          setIsOpen(!isOpen)
          onToggle(!isOpen)
        }}
        onClick={() => {
          setIsOpen(!isOpen)
          onToggle(!isOpen)
        }}
      >
        <div className="header__title">
          <p className="header__title--bold">{selectedTitle}</p>
        </div>
        <div className="header__action">
          <p>
            {isOpen ? (
              <ExpandLess css={iconStyle} />
            ) : (
              <ExpandMore css={iconStyle} />
            )}
          </p>
        </div>
      </div>
      {isOpen && (
        <ul className="list">
          {items.map((item) => (
            <li className="list-item" key={item.id}>
              <button
                type="button"
                onClick={() => handleOnClick(item)}
              >
                <span>{item.name}</span>
                <span>
                  {isItemInSelection(item) && (
                    <Check css={iconStyle} />
                  )}
                </span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export { EkSelect }
