/* eslint-disable max-lines */
/* This is a generated file */

export interface Page {
  before?: string,
  after?: string,
}

export enum ORDER_STATUS {
  DEFAULT = 'DEFAULT',
  COMPLETED = 'COMPLETED',
}

export enum STORAGE_SPACE_TRANSACTION_TYPE {
  UTTAG = 'UTTAG',
  INSATTNING = 'INSATTNING',
  FLYTT = 'FLYTT',
  KORRIGERING = 'KORRIGERING',
  TRASIG = 'TRASIG',
  BORTTAPPAD = 'BORTTAPPAD',
}

export enum STORAGE_SPACE_TYPE {
  DEFAULT = 'DEFAULT',
  FORDON = 'FORDON',
  HYLLA = 'HYLLA',
  LADA = 'LADA',
  LAGER = 'LAGER',
  YTA = 'YTA',
}

export enum UNIT_OF_MEASURE {
  DEFAULT = 'DEFAULT',
  GRAM = 'GRAM',
  KILOGRAM = 'KILOGRAM',
  KUBIKLITER = 'KUBIKLITER',
  KUBIKMETER = 'KUBIKMETER',
  LITER = 'LITER',
  METER = 'METER',
  RULLE = 'RULLE',
  STYCK = 'STYCK',
}

export interface Faq {
  _id: string,
  _ts: string,
  question: string,
  answer: string,
}

export interface FaqInput {
  question: string,
  answer: string,
}

export interface FaqPage extends Page {
  data?:  Faq[],
  before?: string,
  after?: string,
}

export interface Query {
  faqs?:  Faq[],
  findFaqByID?:  Faq ,
  items?:  Item[],
  findItemByID?:  Item ,
  searchItems?:  ItemPage ,
  searchItemsByNumber?:  ItemPage ,
  searchItemsByName?:  ItemPage ,
  searchItemsByTag?:  ItemPage ,
  searchItemsLookup?:  ItemPage ,
  searchItemsLookupByNumber?:  ItemPage ,
  searchItemsLookupByName?:  ItemPage ,
  itemsWithBalanceWarning?:  ItemPage ,
  customers?:  Customer[],
  findCustomerByID?:  Customer ,
  storageSpaceTransactions?:  StorageSpaceTransaction[],
  findStorageSpaceTransactionByID?:  StorageSpaceTransaction ,
  inventoryItems?:  InventoryItem[],
  findInventoryItemByID?:  InventoryItem ,
  orders?:  Order[],
  findOrderByID?:  Order ,
  getOrdersByStatus?:  OrderPage ,
  searchOrders?:  OrderPage ,
  orderItems?:  OrderItem[],
  findOrderItemByID?:  OrderItem ,
  storageSpaces?:  StorageSpace[],
  findStorageSpaceByID?:  StorageSpace ,
  findStorageSpaceByBarcode?:  StorageSpace ,
  isAParentOfB?: boolean ,
  getStorageByVirtual?:  StorageSpacePage ,
  getStoragesByParent?:  StorageSpacePage ,
  getStoragesRoot?:  StorageSpacePage ,
  tags?:  Tag[],
  findTagByID?:  Tag ,
  users?:  User[],
  findUserByID?:  User ,
  displayLabels?:  DisplayLabel[],
  findDisplayLabelByID?:  DisplayLabel ,
  accounts?:  Account[],
  findAccountByID?:  Account ,
  authenticated?: boolean ,
  clients?:  Client[],
  findClientByID?:  Client ,
  incomingOrders?:  IncomingOrder[],
  findIncomingOrderByID?:  IncomingOrder ,
  syncIncomingOrders?: boolean ,
  jsonCaches?:  JsonCache[],
  findJsonCacheByID?:  JsonCache ,
  articleReferences?:  ArticleReference[],
  findArticleReferenceByID?:  ArticleReference ,
  itemReportAll: ItemReport,
  relevantInventoryItems?:  InvItemsPage,
  itemReportByStorage?:  InvItemsPage ,
}



export interface Item {
  _id: string,
  _ts: string,
  number: string,
  sdfId: string,
  name: string,
  description?: string,
  tags: TagPage,
  images?: string[],
  defaultImage?: string,
  unitOfMeasure?:  UNIT_OF_MEASURE,
  orderItems?:  OrderItemPage,
  supplier?: string,
  netPrice?: number,
  grossPrice?: number,
  sellingPrice?: number,
  inventoryItems: InventoryItemPage,
  addedManually?: boolean,
  notes?: string,
  balanceWarning?: number,
}

export interface ItemInput {
  number: string,
  sdfId: string,
  name: string,
  description?: string,
  tags: ItemTagsRelation,
  images?: string[],
  defaultImage?: string,
  unitOfMeasure?:  UNIT_OF_MEASURE,
  orderItems?:  ItemOrderItemsRelation,
  supplier?: string,
  netPrice?: number,
  grossPrice?: number,
  sellingPrice?: number,
  addedManually?: boolean,
  notes?: string,
  balanceWarning?: number,
}

export interface PartialUpdateItemInput {
  number?: string,
  sdfId?: string,
  name?: string,
  description?: string,
  tags?:  ItemTagsRelation,
  images?: string[],
  defaultImage?: string,
  unitOfMeasure?:  UNIT_OF_MEASURE,
  orderItems?:  ItemOrderItemsRelation,
  supplier?: string,
  netPrice?: number,
  grossPrice?: number,
  sellingPrice?: number,
  addedManually?: boolean,
  notes?: string,
  balanceWarning?: number | null,
}

export interface ItemOrderItemsRelation {
  create?:  OrderItemInput[],
  connect?: string[],
  disconnect?: string[],
}

export interface ItemTagsRelation {
  create?:  TagInput[],
  connect?: string[],
  disconnect?: string[],
}

export interface ItemPage extends Page {
  data?:  Item[],
  before?: string,
  after?: string,
}

export interface Customer {
  _id: string,
  _ts: string,
  number?: string ,
  projectOrder?: string,
  adress?: string,
  invoiceAdress?: string,
}

export interface CustomerInput {
  number: string,
  projectOrder?: string,
  adress?: string,
  invoiceAdress?: string,
}

export interface CustomerPage extends Page {
  data?:  Customer[],
  before?: string,
  after?: string,
}

export interface StorageSpaceTransaction {
  _id: string,
  _ts: string,
  item?:  Item,
  quantity: number,
  balanceBeforeTransactionA?: number,
  balanceAfterTransactionA?: number,
  balanceBeforeTransactionB?: number,
  balanceAfterTransactionB?: number,
  fromStorageSpace?:  StorageSpace,
  toStorageSpace?:  StorageSpace,
  toOrder?:  Order,
  fromOrder?:  Order,
  user?:  User,
  type: STORAGE_SPACE_TRANSACTION_TYPE,
  comment?: string,
}

export interface StorageSpaceTransactionInput {
  item?:  StorageSpaceTransactionItemRelation,
  quantity: number,
  balanceBeforeTransactionA?: number,
  balanceAfterTransactionA?: number,
  balanceBeforeTransactionB?: number,
  balanceAfterTransactionB?: number,
  fromStorageSpace?:  StorageSpaceTransactionFromStorageSpaceRelation,
  toStorageSpace?:  StorageSpaceTransactionToStorageSpaceRelation,
  toOrder?:  StorageSpaceTransactionToOrderRelation,
  fromOrder?:  StorageSpaceTransactionFromOrderRelation,
  user?:  StorageSpaceTransactionUserRelation,
  type: STORAGE_SPACE_TRANSACTION_TYPE,
  comment?: string,
}

export interface StorageSpaceTransactionToOrderRelation {
  create?:  OrderInput,
  connect?: string,
  disconnect?: boolean,
}

export interface StorageSpaceTransactionFromOrderRelation {
  create?:  OrderInput,
  connect?: string,
  disconnect?: boolean,
}

export interface StorageSpaceTransactionItemRelation {
  create?:  ItemInput,
  connect?: string,
  disconnect?: boolean,
}

export interface StorageSpaceTransactionFromStorageSpaceRelation {
  create?:  StorageSpaceInput,
  connect?: string,
  disconnect?: boolean,
}

export interface StorageSpaceTransactionToStorageSpaceRelation {
  create?:  StorageSpaceInput,
  connect?: string,
  disconnect?: boolean,
}

export interface StorageSpaceTransactionUserRelation {
  create?:  UserInput,
  connect?: string,
  disconnect?: boolean,
}

export interface StorageSpaceTransactionPage extends Page {
  data?:  StorageSpaceTransaction[],
  before?: string,
  after?: string,
}

export interface InventoryEntry {
  id: string,
  quantity: number,
}

export interface InventoryCorrectionEntryInput {
  id: string,
  quantity: number,
  diff: number,
}

export interface InventoryItem {
  _id: string,
  _ts: string,
  item: Item,
  storageSpace: StorageSpace,
  quantity: number,
}

export interface InventoryItemInput {
  item: InventoryItemItemRelation,
  storageSpace: InventoryItemStorageSpaceRelation,
  quantity: number,
}

export interface InventoryItemItemRelation {
  create?:  ItemInput,
  connect?: string,
}

export interface InventoryItemStorageSpaceRelation {
  create?:  StorageSpaceInput,
  connect?: string,
}

export interface InventoryItemPage extends Page {
  data?:  InventoryItem[],
  before?: string,
  after?: string,
}

export interface Order {
  _id: string,
  _ts: string,
  description?: string,
  orderNumber?: string ,
  sdfId?: string ,
  createdDate?: string,
  serviceDate?: string,
  customerPhone?: string,
  projectNumber?: string,
  projectName?: string,
  serviceAddress?: string,
  invoiceAddress?: string,
  status?:  ORDER_STATUS,
  archived?: boolean,
  items: OrderItemPage,
  assignedUser?:  User,
}

export interface OrderInput {
  description?: string,
  orderNumber: string,
  sdfId: string,
  createdDate?: string,
  serviceDate?: string,
  customerPhone?: string,
  projectNumber?: string,
  projectName?: string,
  serviceAddress?: string,
  invoiceAddress?: string,
  archived?: boolean,
  status?:  ORDER_STATUS,
  items?:  OrderItemsRelation,
  assignedUser?:  OrderAssignedUserRelation,
}

export interface PartialUpdateOrderInput {
  description?: string,
  orderNumber?: string,
  sdfId?: string,
  createdDate?: string,
  serviceDate?: string,
  customerPhone?: string,
  projectNumber?: string,
  projectName?: string,
  serviceAddress?: string,
  invoiceAddress?: string,
  status?:  ORDER_STATUS,
  archived?: boolean,
  items?:  OrderItemsRelation,
  assignedUser?:  OrderAssignedUserRelation,
}

export interface OrderAssignedUserRelation {
  connect?: string,
  disconnect?: boolean,
}

export interface OrderItemsRelation {
  create?:  OrderItemInput[],
  connect?: string[],
  disconnect?: string[],
}

export interface OrderCustomerRelation {
  create?:  CustomerInput,
  connect?: string,
  disconnect?: boolean,
}

export interface OrderPage extends Page {
  data?:  Order[],
  before?: string,
  after?: string,
}

export interface OrderItem {
  _id: string,
  _ts: string,
  item: Item,
  quantity: number,
  order: Order,
}

export interface OrderItemInput {
  item?:  OrderItemItemRelation,
  quantity: number,
  order?:  OrderItemOrderRelation,
}

export interface OrderItemItemRelation {
  create?:  ItemInput,
  connect?: string,
}

export interface OrderItemOrderRelation {
  create?:  OrderInput,
  connect?: string,
}

export interface OrderItemPage extends Page {
  data?:  OrderItem[],
  before?: string,
  after?: string,
}

export interface StorageSpace {
  _id: string,
  _ts: string,
  name: string,
  description?: string,
  type: STORAGE_SPACE_TYPE,
  virtual?: boolean,
  items: InventoryItemPage,
  parent?: string,
  displayLabel?:  DisplayLabel,
  deleted?: boolean,
}

export interface StorageSpaceInput {
  name: string,
  description?: string,
  type: STORAGE_SPACE_TYPE,
  virtual: boolean,
  displayLabel?:  StorageSpaceDisplayLabelRelation,
  parent?: string,
  deleted?: boolean,
}

export interface StorageSpaceParentRelation {
  create?:  StorageSpaceInput,
  connect?: string,
  disconnect?: boolean,
}

export interface StorageSpaceDisplayLabelRelation {
  create?:  DisplayLabelInput,
  connect?: string,
  disconnect?: boolean,
}

export interface StorageSpacePage extends Page {
  data?:  StorageSpace[],
  before?: string,
  after?: string,
}

export interface Tag {
  _id: string,
  _ts: string,
  name: string,
  items: ItemPage,
}

export interface TagInput {
  name: string,
}

export interface TagPage extends Page {
  data?:  Tag[],
  before?: string,
  after?: string,
}

export interface User {
  _id: string,
  _ts: string,
  firstName: string,
  lastName: string,
  role: string,
  account?:  Account,
  assignedOrders: OrderPage,
}

export interface UserAssignedOrdersRelation {
  create?:  OrderInput[],
  connect?: string[],
  disconnect?: string[],
}

export interface UserInput {
  firstName: string,
  lastName: string,
  role: string,
}

export interface UserPage extends Page {
  data?:  User[],
  before?: string,
  after?: string,
}

export interface DisplayLabel {
  _id: string,
  _ts: string,
  barcode?: string ,
  isIncomingOrder?: boolean,
  storageSpace?:  StorageSpace ,
}

export interface DisplayLabelInput {
  barcode: string,
  isIncomingOrder?: boolean,
}

export interface DisplayLabelPage extends Page {
  data?:  DisplayLabel[],
  before?: string,
  after?: string,
}

export interface Account {
  _id: string,
  _ts: string,
  phoneNumber?: string ,
  /** This field will always be null on the client for security purposes. */
  salt: string,
  /** This field will always be null on the client for security purposes. */
  hashedPassword: string,
  suspended?: boolean,
  deleted?: boolean,
  user?:  User,
}

export interface AccountUpdateInput {
  phoneNumber?: string,
  password?: string,
  suspended?: boolean,
  deleted?: boolean,
}

export interface AccountInput {
  firstName: string,
  lastName: string,
  role: string,
  phoneNumber: string,
  password: string,
}

export interface AccountUpdateRawInput {
  phoneNumber?: string,
  salt?: string,
  hashedPassword?: string,
  suspended?: boolean,
  deleted?: boolean,
}

export interface AccountRawInput {
  firstName: string,
  lastName: string,
  role: string,
  phoneNumber: string,
  /** This field will always be null on the client for security purposes. */
  salt: string,
  /** This field will always be null on the client for security purposes. */
  hashedPassword: string,
}

export interface AccountPage extends Page {
  data?:  Account[],
  before?: string,
  after?: string,
}

export interface Client {
  _id: string,
  _ts: string,
  clientId: string,
  clientSecret: string,
}

export interface ClientInput {
  clientId: string,
  clientSecret: string,
}

export interface ClientPage extends Page {
  data?:  Client[],
  before?: string,
  after?: string,
}

export interface IncomingItem {
  _id: string,
  _ts: string,
  item: Item,
  quantity: number,
  price: number,
  description: string,
  IncomingOrder: IncomingOrder,
}

export interface IncomingItemInput {
  item?:  IncomingItemItemRelation,
  quantity: number,
  price: number,
  description: string,
  IncomingOrder?:  IncomingItemIncomingOrderRelation,
}

export interface IncomingItemPage extends Page {
  data?:  IncomingItem[],
  before?: string,
  after?: string,
}

export interface IncomingOrder {
  _id: string,
  _ts: string,
  orderNumber: string,
  supplier: string,
  createdDate: string,
  expectedDeliveryDate?: string,
  ordererName?: string,
  ordererContact?: string,
  totalPrice?: number,
  scanned?: boolean,
  items: IncomingItemPage,
  storageSpace: StorageSpace,
}

export interface IncomingOrderInput {
  orderNumber: string,
  supplier: string,
  createdDate: string,
  expectedDeliveryDate?: string,
  ordererName?: string,
  ordererContact?: string,
  totalPrice?: number,
  scanned?: boolean,
  items?:  IncomingOrderItemsRelation,
  storageSpace: IncomingOrderStorageSpaceRelation,
}

export interface IncomingOrderStorageSpaceRelation {
  create?:  StorageSpaceInput,
  connect?: string,
  disconnect?: boolean,
}

export interface IncomingItemItemRelation {
  create?:  ItemInput,
  connect?: string,
}

export interface IncomingOrderItemsRelation {
  create?:  IncomingItemInput[],
  connect?: string[],
  disconnect?: string[],
}

export interface IncomingItemIncomingOrderRelation {
  create?:  IncomingOrderInput,
  connect?: string,
  disconnect?: boolean,
}

export interface IncomingOrderPage extends Page {
  data?:  IncomingOrder[],
  before?: string,
  after?: string,
}

export interface JsonCache {
  _id: string,
  _ts: string,
  key?: string ,
  map: string,
}

export interface JsonCacheInput {
  key: string,
  map: string,
}

export interface JsonCachePage extends Page {
  data?:  JsonCache[],
  before?: string,
  after?: string,
}

export interface ArticleReference {
  _id: string,
  _ts: string,
  sdfId?: string ,
  eNumber: string,
  description?: string,
}

export interface ArticleReferenceInput {
  sdfId: string,
  eNumber: string,
  description?: string,
}

export interface ArticleReferencePage extends Page {
  data?:  ArticleReference[],
  before?: string,
  after?: string,
}

export interface SimplifiedStorage {
  id: string,
  parent?: string,
  name?: string,
  description?: string,
  virtual?: boolean,
  type?:  STORAGE_SPACE_TYPE,
}

export interface ItemReportEntry {
  id: string,
  quantity?: number,
  balanceWarning?: number,
  name?: string,
  number?: string,
  supplier?: string,
  unit?:  UNIT_OF_MEASURE,
  storages?:  SimplifiedStorage[],
}

export interface ItemReport {
  data?:  ItemReportEntry[],
}

export interface StorageIdPage {
  data?: string[],
  after?: string,
}

export interface ItemStub {
  id?: string,
  name?: string,
  number?: string,
  supplier?: string,
  unitOfMeasure?:  UNIT_OF_MEASURE,
  balanceWarning?: number,
}

export interface StorageStub {
  id?: string,
  parent?: string,
  name?: string,
  description?: string,
  type?:  STORAGE_SPACE_TYPE,
}

export interface InvItemStub {
  quantity?: number,
  item?:  ItemStub,
  storageSpace?:  StorageStub,
}

export interface InvItemsPage {
  data?:  InvItemStub[],
  after?: string,
}