/* eslint-disable max-lines */
// TODO: Remove this once GraphQL is implemented. This was added so the ghost data could be present for testing purposes. It will be checked into git for demo purposes. Ignore this behaviour in Code Review.
import React, { FC, useEffect } from 'react'
import {
  navigate,
  RouteComponentProps,
  useLocation,
} from '@reach/router'
import { Search } from '@material-ui/icons'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { useBottomNav, useQueryParam } from '@hooks'
import { NavItem } from '@context'
import { SimpleHeader } from '@components/header'
import {
  WorkingOrdersCardView,
  WorkingOrdersListView,
  WorkingOrdersListItem,
} from '@components/pages-components/working-orders'
import { EkCardWorkOrderSingle } from '@components/ek-card-workorder-single'

import { EkViewSwitcher } from '@components/ek-view-switcher'
import { Divider } from '@pure-components/graphics'
import { A, H6 } from '@components/base'
import { COLORS } from '@styles'
import { OrderPage, ORDER_STATUS, User } from '@types'
import { GET_ORDERS_BY_STATUS } from '@api/order'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { Pagination } from '@components/pagination'
import { AppRoutes } from '@router/app-routes'
import { GET_USER_WITH_ORDERS } from '@api/user'
import { getUserId } from '@common/get-user-id-from-token'
//import { getSortedArray } from '@common/get-sorted-array'

const _WorkingOrders: FC<
  RouteComponentProps &
    WithQueryExtension<User> &
    WithQueryExtension<OrderPage>
> = ({ queryData }) => {
  const {
    getOrdersByStatus: {
      data = [],
      after,
      before,
      previousPage,
      nextPage,
    },
    findUserByID: {
      assignedOrders: { data: userOrders = [] },
    },
  } = queryData
  const { activeItem, setActiveItem } = useBottomNav()

  useEffect(() => {
    setActiveItem(NavItem.Order)
  }, [activeItem, setActiveItem])

  const location = useLocation()
  const view = useQueryParam('view')

  const handleSearchButton = () => {
    navigate(AppRoutes.WorkingOrderSearch)
  }

  const styles = css({
    color: COLORS.gray[90],
    width: '100%',
    padding: view === 'card' ? '24px' : '0',
    '.heading': {
      margin: '12px 24px',
    },
    '.spacingtop': {
      marginTop: '2em',
    },
  })

  // TODO: Once GQL is implemented, rework theses to fit the data structure

  // const filterMyOrders = workingOrderData.filter(
  //   (o) => o.assignTo === 'me'
  // )
  // const filterAllOrders = workingOrderData.filter(
  //   (o) => o.assignTo !== 'me'
  // )

  return (
    <>
      <SimpleHeader text="Order">
        <EkViewSwitcher view={view} location={location} />
        <span
          onClick={handleSearchButton}
          onKeyDown={handleSearchButton}
          role="button"
          tabIndex={-1}
        >
          <Search />
        </span>
      </SimpleHeader>
      <LayoutStandard scroll={true}>
        <section css={styles}>
          {view === 'card' ? (
            <>
              <H6 className="heading">Mina order</H6>
              <WorkingOrdersCardView>
                {data.map((workorder) => (
                  <EkCardWorkOrderSingle
                    key={workorder._id}
                    {...workorder}
                  />
                ))}
              </WorkingOrdersCardView>
              <H6 className="heading">Alla order</H6>
              <WorkingOrdersCardView>
                {data.map((workorder) => (
                  <EkCardWorkOrderSingle
                    key={workorder._id}
                    {...workorder}
                  />
                ))}
              </WorkingOrdersCardView>
            </>
          ) : (
            <>
              <H6 className="heading">Mina order</H6>
              <WorkingOrdersListView>
                {userOrders &&
                  userOrders.map((workorder, i) => (
                    <React.Fragment key={`workorder${workorder._id}`}>
                      <A
                        to={`${AppRoutes.WorkingOrders}/${workorder._id}`}
                      >
                        <WorkingOrdersListItem
                          key={workorder._id}
                          {...workorder}
                        />
                      </A>
                      {i < data.length - 1 && (
                        <Divider key={`divider${workorder._id}`} />
                      )}
                    </React.Fragment>
                  ))}
              </WorkingOrdersListView>
              <H6 className="heading spacingtop">Alla order</H6>
              <WorkingOrdersListView>
                {data &&
                  data
                    .filter(
                      (w) =>
                        !w.orderNumber
                          ?.toLowerCase()
                          .includes('sätts vid ok')
                    )
                    .map((workorder, i) => (
                      <React.Fragment
                        key={`workorder${workorder._id}`}
                      >
                        <A
                          to={`${AppRoutes.WorkingOrders}/${workorder._id}`}
                        >
                          <WorkingOrdersListItem
                            key={workorder._id}
                            {...workorder}
                          />
                        </A>
                        {i < data.length - 1 && (
                          <Divider key={`divider${workorder._id}`} />
                        )}
                      </React.Fragment>
                    ))}
              </WorkingOrdersListView>
            </>
          )}
        </section>
      </LayoutStandard>
      <section>
        <Pagination
          before={before}
          after={after}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </section>
    </>
  )
}

const WorkingOrders = withQuery<OrderPage>({
  query: GET_ORDERS_BY_STATUS,
  variables: { _size: 100, status: ORDER_STATUS.DEFAULT },
  loader: EkLoader,
})(
  withQuery<User>({
    query: GET_USER_WITH_ORDERS,
    variables: { id: getUserId() },
    loader: EkLoader,
  })(_WorkingOrders)
)

export { WorkingOrders }
