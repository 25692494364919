/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { COLORS, TEXT_STYLE } from '@styles'
import { InventoryItem } from '@types'
import { EkCheckbox } from '@components/input'
/*import { A } from '@components/base'
import { AppRoutes } from '@router'
import { Delete, Edit } from '@material-ui/icons'*/

interface StorageListItemSelectProps {
  onUpdateIsSelected?: (isSelected: boolean) => void
  //onConfirmButtonClick?: (item: Item, quantity: number) => void
}

const StorageListItemSelect: FC<
  InventoryItem & StorageListItemSelectProps
> = ({
  _id = '',
  quantity = 0,

  item,
  onUpdateIsSelected = (isSelected: boolean) => {},
  //onConfirmButtonClick = (item: Item, quantity: number) => {},
  //onDeleteButtonClick = (item: Item, quantity: number) => {},
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)

  /* eslint-disable */
  useEffect(() => {
    onUpdateIsSelected(isSelected)
  }, [isSelected])
  /* eslint-enable */

  const listStyle = css([TEXT_STYLE.list[1]], {
    width: '100%',
    color: COLORS.gray[90],
    display: 'grid',
    gridTemplateColumns: '5fr 1fr 1fr',
    gap: '0.5em',
    margin: '6px 0',
    padding: '0 24px',
    gridTemplateAreas:
      '"name amount selectamount" "number amount selectamount"',

    '.name': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'name',
      },
    ],
    '.number': [
      TEXT_STYLE.caption[1],
      {
        gridArea: 'number',
      },
    ],
    '.selectamount': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'selectamount',
        placeSelf: 'center end',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        span: {
          marginLeft: '0.5rem',
        },
      },
    ],
    '.amount': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'amount',
        placeSelf: 'center end',
      },
    ],
    '.buttons': {
      gridArea: 'buttons',
      placeSelf: 'center end',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '10px',
    },
  })
  return (
    <article css={listStyle} className="row">
      <p className="name">{item.name}</p>
      <p className="number">{item.number}</p>
      <div className="selectamount">
        <EkCheckbox
          checked={isSelected}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const state = event.target.checked
            setIsSelected(state)
          }}
        />
      </div>
    </article>
  )
}

export { StorageListItemSelect }
