import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'

interface IncomingOrderListViewProps {
  children?: ReactNode
}

const IncomingOrderListView: FC<IncomingOrderListViewProps> = ({
  children = <></>,
}) => {
  const styles = css({
    overflow: 'auto',
  })

  return <section css={styles}>{children}</section>
}

export { IncomingOrderListView }
