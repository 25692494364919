import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { InventoryItem } from '@types'
import { SelectedItemsItem } from './article-cart-list-item'

interface AdjustItem {
  item: InventoryItem
  quantity: number
  delete?: boolean
  diff: number
}

interface AdjustItemListProps {
  itemList: AdjustItem[]
  children?: ReactNode
}

const isEmpty = (list: any[]) => {
  return (
    list.length === undefined ||
    list.length === null ||
    list.length === 0
  )
}

const AdjustItemList: FC<AdjustItemListProps> = ({
  itemList,
  children = <></>,
}) => {
  const styling = css({
    padding: 24,
    maxHeight: '40vh',
    overflow: 'auto',
  })
  return (
    <div css={styling}>
      {!isEmpty(itemList) &&
        itemList.some((i) => i.diff !== 0) &&
        itemList.map(
          ({
            item: {
              item: { name, number, _id },
            },
            diff,
          }) => {
            return diff !== 0 ? (
              <SelectedItemsItem
                key={_id}
                name={name}
                quantity={diff > 0 ? `+${diff}` : diff}
                itemId={number}
              />
            ) : (
              <></>
            )
          }
        )}
      {children}
    </div>
  )
}

export { AdjustItemList }
export type { AdjustItem }
