import { gql } from '@apollo/client'

const SYNC_ITEM_FROM_SDF = gql`
  mutation syncItemFromSdf($sdfId: String!) {
    syncItemFromSdf(sdfId: $sdfId) {
      _id

      number
      sdfId
      name
      description
      tags {
        data {
          _id
          name
        }
        before
        after
      }
      images
      defaultImage
      unitOfMeasure
      supplier
      netPrice
      grossPrice
      sellingPrice
      addedManually
      notes
    }
  }
`

export { SYNC_ITEM_FROM_SDF }
