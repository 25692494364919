import { css } from '@emotion/react'
import { COLORS } from '@styles'
import React, { FC, ReactNode, MouseEvent } from 'react'

interface EkListProps {
  children?: ReactNode
  onClick?: (e: MouseEvent) => void
}
const EkList: FC<EkListProps> = ({
  children = [],
  onClick = () => {},
}) => {
  const handleOnClick = (e: MouseEvent) => {
    onClick(e)
  }

  const styles = css({
    width: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    '.selected': {
      background: COLORS.orange[10],
    },
  })

  return (
    <div
      css={styles}
      onClick={(e) => handleOnClick(e)}
      aria-hidden="true"
    >
      {children}
    </div>
  )
}

export { EkList }
