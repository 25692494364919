import React, { FC } from 'react'
import { css } from '@emotion/react'
import { COLORS, TEXT_STYLE } from '@styles'
import { Avatar, AvatarProps } from '@pure-components/data-display'

const EkAvatar: FC<AvatarProps> = ({
  content = null,
  size = '24px',
  color = COLORS.gray[0],
  backgroundColor = COLORS.green[70],
  borderColor = COLORS.gray[100],
  borderWidth = '0px',
  padding = '18px',
  style = css(),
}) => {
  const styling = css([TEXT_STYLE.overline[1]], {
    width: size,
    height: size,
    borderRadius: 9999,
    color,
    backgroundColor,
    border: `${borderWidth} solid ${borderColor}`,
    padding,
    display: 'grid',
    placeContent: 'center center',
    textAlign: 'center',
  })
  return <Avatar style={styling} css={style} content={content} />
}

export { EkAvatar }
