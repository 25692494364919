import React, { FC, ReactNode } from 'react'
import { resetButton } from '@styles'
import { css, SerializedStyles } from '@emotion/react'
import { defaultIcon } from './default-icon'
import { useMaxHeight } from './use-max-height'

interface ExpansionItem {
  title?: ReactNode
  children?: ReactNode
  icon?: ReactNode
  isOpen?: boolean
  onTitleClick?: () => void
  transitionTime?: string
  titleStyle?: SerializedStyles | SerializedStyles[]
  contentStyle?: SerializedStyles | SerializedStyles[]
}

const ExpansionItem: FC<ExpansionItem> = ({
  title = <></>,
  children = <></>,
  icon,
  isOpen = false,
  onTitleClick = () => {},
  transitionTime = '300ms',
  titleStyle = css(),
  contentStyle = css(),
}) => {
  const { contentElement, maxHeight } = useMaxHeight({ isOpen })

  const buttonStyle = css([
    resetButton,
    {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 24px',
      '&:focus': {
        outline: 'none',
      },
    },
    titleStyle,
  ])

  const iconContainerStyle = css({
    display: 'inline-block',
    transition: `all ${transitionTime} ease`,
    transformOrigin: 'center center',
    transform: 'rotateZ(0deg)',
    '&.rotate': {
      transform: 'rotateZ(180deg)',
    },
  })

  const contentWrapperStyle = css({
    maxHeight: `${maxHeight}px`,
    overflow: 'hidden',
    transition: `all ${transitionTime} ease`,
  })

  const _contentStyle = css([
    {
      padding: '8px 24px',
      width: '100%',
    },
    contentStyle,
  ])

  return (
    <div>
      <button css={buttonStyle} onClick={onTitleClick}>
        {title}
        <div
          css={iconContainerStyle}
          className={isOpen ? 'rotate' : ''}
        >
          {icon || defaultIcon}
        </div>
      </button>
      <div css={contentWrapperStyle} ref={contentElement}>
        <div css={_contentStyle}>{children}</div>
      </div>
    </div>
  )
}

export { ExpansionItem }
