import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { SnackbarContext } from './snackbar-context'
import { css } from '@emotion/react'
import { useBottomNav } from '@hooks'

interface SnackbarProviderProps {
  children: ReactNode
}

// TODO: Maybe later add possibility to stack a group

const AUTO_DISMISS_TIME = 5000

const SnackbarProvider: FC<SnackbarProviderProps> = ({
  children,
}) => {
  const [alert, setAlert] = useState<ReactNode>(null)
  const { height } = useBottomNav()
  const [isOpen, setIsOpen] = useState(false)
  const [autoDismissTime, setAutoDismissTime] = useState(
    AUTO_DISMISS_TIME
  )

  const close = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (alert) {
      setIsOpen(() => true)
      const timer = setTimeout(() => close(), autoDismissTime)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [alert, autoDismissTime])

  const addAlert = useCallback<
    (content: ReactNode, autoDismissTime?: number) => void
  >((content, autoDismissTime = AUTO_DISMISS_TIME) => {
    setAutoDismissTime(autoDismissTime)
    setAlert(content)
  }, [])

  const dismissAlert = () => {
    close()
  }

  const onTransitionEnd = () => {
    if (!isOpen) {
      setAlert(null)
    }
  }

  const style = css({
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transformOrigin: 'top',
    transition: 'all 250ms ease',
    transform: 'translate(-50%, 100%)',
    opacity: 0,
    '&.open': {
      transform: `translate(-50%, calc(-${height} + -16px))`,
      opacity: 1,
    },
  })

  return (
    <SnackbarContext.Provider value={{ addAlert, dismissAlert }}>
      {children}
      <div
        css={style}
        className={isOpen ? 'open' : ''}
        onTransitionEnd={onTransitionEnd}
      >
        {alert}
      </div>
    </SnackbarContext.Provider>
  )
}

export { SnackbarProvider }
