import React from 'react'
import { Subtitle2 } from '@components/base-extended'
import { EkAvatar } from '@components/ek-avatar'
import { createInitials } from '@common'
import { css } from '@emotion/react'

const AssigneeCard = ({
  name = 'Test Testsson',
  phone = '0712345678',
}) => {
  const assigneeCardStyles = css({
    display: 'grid',
    gridTemplateColumns: '50px auto',

    '& > *': {
      alignSelf: 'center',
    },
  })

  return (
    <div css={assigneeCardStyles}>
      <div>
        <EkAvatar content={createInitials(name)} />
      </div>
      <div>
        <Subtitle2>{name}</Subtitle2>
        <Subtitle2>{phone}</Subtitle2>
      </div>
    </div>
  )
}

export { AssigneeCard }
