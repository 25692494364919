import React from 'react'
import { css, keyframes } from '@emotion/react'
import { COLORS } from '@styles'

const CircularSpinnerLoader = () => {
  const animation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  
  `
  const styling = css({
    display: 'inline-block',
    position: 'relative',
    width: '80px',
    height: '80px',
    '&> div': {
      boxSizing: 'border-box',
      display: 'block',
      position: 'absolute',
      width: '64px',
      height: '64px',
      margin: '8px',
      border: '8px solid #333',
      borderRadius: '50%',
      animation: `${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
      borderColor: `${COLORS.gray[30]} transparent transparent transparent`,
    },
    ':nth-child(1)': {
      animationDelay: '-0.45s',
    },
    ':nth-child(2)': {
      animationDelay: '-0.3s',
    },
    ':nth-child(3)': {
      animationDelay: '-0.15s',
    },
  })

  return (
    <div css={styling}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export { CircularSpinnerLoader }
