/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { COLORS, TEXT_STYLE } from '@styles'
import { Item, Order, UNIT_OF_MEASURE } from '@types'
import { EkNumberField } from '@components/ek-number-field'
import { formatUnitOfMeasure } from '@common/get-unit-of-measure'

interface WorkingOrderListItemProps {
  _id: string
  item: Item
  quantity: number
  order: Order
  min?: number
  editEnabled?: boolean
  onUpdateAmount?: (quantity: number) => void
}

const WorkingOrderListItem: FC<WorkingOrderListItemProps> = ({
  _id = '',
  item = {},
  quantity = 0,
  min = 0,
  editEnabled = false,
  onUpdateAmount = (quantity: number) => {},
}) => {
  const [selectedQuantity, setSelectedQuantity] = useState(0)

  /* eslint-disable */
  useEffect(() => {
    onUpdateAmount(selectedQuantity)
  }, [selectedQuantity])

  const listStyle = css([TEXT_STYLE.list[1]], {
    width: '100%',
    color: COLORS.gray[90],
    display: 'grid',
    gridTemplateColumns: '5fr 1fr 1fr',
    gap: '0.5em',
    margin: '6px 0',
    padding: '0 24px',
    gridTemplateAreas:
      '"name amount selectamount" "number amount selectamount"',

    '.name': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'name',
      },
    ],
    '.number': [
      TEXT_STYLE.caption[1],
      {
        gridArea: 'number',
      },
    ],
    '.selectamount': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'selectamount',
        placeSelf: 'center end',
      },
    ],
    '.amount': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'amount',
        placeSelf: 'center end',
      },
    ],
    '.buttons': {
      gridArea: 'buttons',
      placeSelf: 'center end',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '10px',
    },
  })
  return (
    <article css={listStyle} className="row">
      <p className="name" role="presentation">
        {item.name}
      </p>
      <p className="number">{item.number}</p>
      <p className="amount">
        {quantity}{' '}
        {formatUnitOfMeasure(
          (item.unitOfMeasure as UNIT_OF_MEASURE) || ''
        )}
      </p>
      {editEnabled && (
        <div className="selectamount">
          <EkNumberField
            value={selectedQuantity}
            max={quantity}
            min={min}
            width="60"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              let val = parseFloat(event.target.value)
              //console.log('event value', val)
              if (val > quantity) {
                val = quantity
              } else if (val < min) {
                val = min
              }
              setSelectedQuantity(val)
            }}
          />
        </div>
      )}
    </article>
  )
}

export { WorkingOrderListItem }
