import { createContext } from 'react'
import { bottomNavHeight } from '@components/ek-bottom-nav'
import { NavItem } from './nav-item'

type BottomNavContextType = {
  activeItem: NavItem
  setActiveItem: (value: NavItem) => void
  height: string
}

const BottomNavContext = createContext<BottomNavContextType>({
  activeItem: NavItem.Home,
  setActiveItem: () => {},
  height: bottomNavHeight,
})

export { BottomNavContext }
