import React, { FC } from 'react'
import { css } from '@emotion/react'

interface CrossProps {
  size?: string
  color?: string
}

const Cross: FC<CrossProps> = ({
  size = '24px',
  color = 'black',
}) => {
  const styles = css({
    height: size,
    width: size,
    position: 'relative',
    overflow: 'hidden',
    '&::before,&::after': {
      content: '""',
      position: 'absolute',
      height: '30%',
      width: '128%',
      background: color,
      borderRadius: '9999px',
      top: '50%',
      left: '50%',
      transformOrigin: 'center',
    },
    '&::before': {
      transform: 'translate(-50%,-50%) rotate(45deg)',
    },
    '&::after': {
      transform: 'translate(-50%,-50%) rotate(-45deg)',
    },
  })

  return <div css={styles}></div>
}

export { Cross }
