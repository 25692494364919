import { css } from '@emotion/react'
import { Button } from '@pure-components/input'
import React, { FC } from 'react'

interface PaginationProps {
  before: string | undefined
  after: string | undefined
  previousPage: () => void
  nextPage: () => void
}

const Pagination: FC<PaginationProps> = ({
  before = null,
  after = null,
  previousPage = () => {},
  nextPage = () => {},
}) => {
  const styling = css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: 12,
    gap: 12,
    //position: 'fixed',
    //bottom: 70,
    background: 'white',
  })

  if (!before && !after) return null

  return (
    <section css={styling}>
      <Button
        disabled={before && before !== null ? false : true}
        onClick={() => previousPage()}
      >
        Föregående Sida
      </Button>
      <Button
        disabled={after && after !== null ? false : true}
        onClick={() => nextPage()}
      >
        Nästa Sida
      </Button>
    </section>
  )
}

export { Pagination }
