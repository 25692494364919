import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'

interface WorkingOrderCardViewProps {
  children?: ReactNode
}

const WorkingOrderCardView: FC<WorkingOrderCardViewProps> = ({
  children = <></>,
}) => {
  const cardStyles = css({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(250px, 1fr) )',
    gap: '1em',
  })
  return <section css={cardStyles}>{children}</section>
}

export { WorkingOrderCardView }
