import React, { FC } from 'react'
import { css } from '@emotion/react'
import { COLORS } from '@styles'

interface SkeletonItemProps {
  type: 'title' | 'text' | 'image'
}

const SkeletonItem: FC<SkeletonItemProps> = ({ type = 'title' }) => {
  const styling = css({
    '.skeleton': {
      background: COLORS.gray[50],
      overflow: 'hidden',
      borderRadius: 4,
      margin: '10px 0',
    },
    '.title': {
      width: '50%',
      height: 24,
    },
    '.text': {
      width: '100%',
      height: '1em',
      marginBottom: '.5em',
    },
    '.image': {
      width: '200px',
      height: '200px',
    },
  })
  return (
    <div css={styling}>
      <div className={`skeleton ${type}`}>&nbsp;</div>
    </div>
  )
}

export { SkeletonItem }
