import React, { FC } from 'react'
import { navigate } from '@reach/router'
import { ChevronLeft } from '@material-ui/icons'

interface BackButtonProps {
  path?: string
}

const BackButton: FC<BackButtonProps> = ({ path = '' }) => {
  // Reach router 'navigate' supports -1 to go backwards but typing is incorrect
  const navigablePath: any = path !== '' ? path : -1

  const handleClick = () => {
    navigate(navigablePath)
  }

  return (
    <span
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={-1}
    >
      <ChevronLeft />
    </span>
  )
}

export { BackButton }
