/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const GET_ORDERS = gql`
  query orders($_size: Int, $_cursor: String) {
    orders(_size: $_size, _cursor: $_cursor) {
      data {
        _id

        description
        orderNumber
        sdfId
        createdDate
        serviceDate
        customerPhone
        projectNumber
        projectName
        serviceAddress
        invoiceAddress
        status
        assignedUser {
          _id
          firstName
          lastName
        }
        items {
          data {
            _id

            item {
              _id

              number
              sdfId
              name
              description
              tags {
                data {
                  _id
                  name
                }
              }
              images
              defaultImage
              unitOfMeasure
              supplier
              netPrice
              grossPrice
              sellingPrice
            }
            quantity
          }
        }
      }
      before
      after
    }
  }
`

const GET_ORDERS_BY_STATUS = gql`
  query getOrdersByStatus(
    $status: ORDER_STATUS!
    $_size: Int
    $_cursor: String
  ) {
    getOrdersByStatus(
      status: $status
      _size: $_size
      _cursor: $_cursor
    ) {
      data {
        _id

        description
        orderNumber
        sdfId
        createdDate
        serviceDate
        customerPhone
        projectNumber
        projectName
        serviceAddress
        invoiceAddress
        status
      }
      before
      after
    }
  }
`

const GET_ORDER = gql`
  query findOrderByID($id: ID!) {
    findOrderByID(id: $id) {
      _id

      description
      orderNumber
      sdfId
      createdDate
      serviceDate
      customerPhone
      projectNumber
      projectName
      serviceAddress
      invoiceAddress
      status
      assignedUser {
        _id
        firstName
        lastName
      }
      items {
        data {
          _id

          item {
            _id

            number
            sdfId
            name
            description
            tags {
              data {
                _id
                name
              }
              before
              after
            }
            images
            defaultImage
            unitOfMeasure
            supplier
            netPrice
            grossPrice
            sellingPrice
            inventoryItems {
              data {
                _id
                quantity
                storageSpace {
                  _id
                  name
                  description
                  type
                  virtual
                  parent
                  displayLabel {
                    _id
                    barcode
                  }
                }
              }
              before
              after
            }
          }
          quantity
        }
      }
    }
  }
`

const SEARCH_ORDERS = gql`
  query searchOrders($query: String!, $status: ORDER_STATUS!) {
    searchOrders(query: $query, status: $status) {
      data {
        _id

        description
        orderNumber
        sdfId
        createdDate
        serviceDate
        customerPhone
        projectNumber
        projectName
        serviceAddress
        invoiceAddress
        status
      }
      before
      after
    }
  }
`

export { GET_ORDERS, GET_ORDER, GET_ORDERS_BY_STATUS, SEARCH_ORDERS }
