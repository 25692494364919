import React, { FC } from 'react'
import { css } from '@emotion/react'
import { Skeleton } from '@pure-components/graphics/skeleton'
import { COLORS } from '@styles'

interface EkSkeletonFaqProps {
  loading?: boolean
}

const EkSkeletonFaq: FC<EkSkeletonFaqProps> = ({
  loading = true,
}) => {
  const styling = css({
    display: 'grid',
    gridTemplateColumns: 'auto 24px',
    gap: 12,
    '.box': {
      borderRadius: 5,
      background: COLORS.gray[50],
      height: 24,
    },
  })
  return (
    <Skeleton loading={loading}>
      <div css={styling}>
        <div className="box">&nbsp;</div>
        <div className="box">&nbsp;</div>
      </div>
    </Skeleton>
  )
}

export { EkSkeletonFaq }
