import React, { ChangeEvent, FC } from 'react'
import { css } from '@emotion/react'
import { useRadioGroup } from './use-radio-group'
import { COLORS } from '@src/styles'

interface RadioProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  color?: string
  borderColor?: string
  background?: string
  markerColor?: string
  value?: string
  disabled?: boolean
  name?: string
}

const Radio: FC<RadioProps> = ({
  onChange = () => {},
  borderColor = COLORS.gray[100],
  background = COLORS.gray[0],
  markerColor = COLORS.gray[100],
  value = '',
  disabled = false,
  name = '',
}) => {
  const size = '24px'
  const borderWidth = '3px'

  const styles = css({
    width: size,
    height: size,
    border: `${borderWidth} solid ${borderColor}`,
    borderRadius: '9999px',
    position: 'relative',
    opacity: disabled ? 0.4 : 1,
    '&:after': {
      pointerEvents: 'none',
      position: 'absolute',
      content: '""',
      top: '50%',
      left: '50%',
      height: `calc(${size} * 2)`,
      width: `calc(${size} * 2)`,
      borderRadius: '9999px',
      transition: 'all 250ms ease',
      transform: 'translate(-50%, -50%)',
      background: 'transparent',
    },
    '&:hover:after': {
      // TODO: standardize hover
      background: 'rgba(0,0,0,0.15)',
    },
  })

  const hiddenRadio = css({
    cursor: 'pointer',
    width: `calc(${borderWidth} * 2 + 100%)`,
    height: `calc(${borderWidth} * 2 + 100%)`,
    position: 'absolute',
    top: `-${borderWidth}`,
    left: `-${borderWidth}`,
    opacity: 0,
    margin: 0,
  })

  const markerStyles = css({
    width: '75%',
    height: '75%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: markerColor,
    background: markerColor,
    borderRadius: 9999,
    pointerEvents: 'none',
    '&*': {
      pointerEvents: 'none',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })

  const { value: _value, setValue } = useRadioGroup()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value)
    onChange(event)
  }

  const isChecked = value === _value

  return (
    <div css={styles}>
      <input
        type="radio"
        css={hiddenRadio}
        value={value}
        checked={isChecked}
        disabled={disabled}
        name={name}
        onChange={handleChange}
      />
      {isChecked ? <div css={markerStyles}> &#160; </div> : null}
    </div>
  )
}

export { Radio }
