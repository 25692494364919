import { UNIT_OF_MEASURE } from '../functions/lib/types'

const getUnitOfMeasure = (unit: string): UNIT_OF_MEASURE => {
  switch (unit) {
    case 'ST':
      return UNIT_OF_MEASURE.STYCK
    case 'G':
      return UNIT_OF_MEASURE.GRAM
    case 'KG':
      return UNIT_OF_MEASURE.KILOGRAM
    case 'M':
      return UNIT_OF_MEASURE.METER
    case 'M3':
      return UNIT_OF_MEASURE.KUBIKMETER
    case 'L':
      return UNIT_OF_MEASURE.LITER
    default:
      return UNIT_OF_MEASURE.DEFAULT
  }
}

const formatUnitOfMeasure = (unit: UNIT_OF_MEASURE): string => {
  switch (unit) {
    case UNIT_OF_MEASURE.STYCK:
      return 'st'
    case UNIT_OF_MEASURE.GRAM:
      return 'g'
    case UNIT_OF_MEASURE.KILOGRAM:
      return 'kg'
    case UNIT_OF_MEASURE.METER:
      return 'm'
    case UNIT_OF_MEASURE.KUBIKMETER:
      return 'm3'
    case UNIT_OF_MEASURE.LITER:
      return 'l'
    default:
      return ''
  }
}

export { getUnitOfMeasure, formatUnitOfMeasure }
