import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { InsertPhoto } from '@material-ui/icons'
import { COLORS, TEXT_STYLE } from '@styles'
import { EkCheckBoxStar } from '@components/input/ek-checkbox-star'

interface StorageCardProps {
  label?: string
  icon?: ReactNode
  checked?: boolean
  variant?: 'action' | 'storage'
  onClick?: () => void
}

const StorageCard: FC<StorageCardProps> = ({
  label = 'label',
  icon = <InsertPhoto />,
  checked = false,
  variant = 'storage',
  onClick,
}) => {
  const styling = css({
    width: 100,
    height: 100,
    color: COLORS.gray[90],
    borderRadius: 5,
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '24px 20px auto',
    gap: 5,
    background:
      variant === 'action' ? COLORS.orange[10] : COLORS.gray[10],
    ':hover:after': {
      boxShadow: '0.125em 0.125em 0.375em 0 rgba(0,0,0,0.5)',
    },
    ':after': {
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: 5,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      boxShadow: '0.125em 0.125em 0.375em 0 rgba(0,0,0,0.15)',
    },
    '.cardpart': {
      display: 'block',
      width: '100px',
    },
    '.checkbox': {
      display: 'grid',
      placeContent: 'start end',
      padding: 6,
    },
    '.icon': {
      display: 'grid',
      placeContent: 'center center',
      fontSize: '30px',
    },
    '.label': [
      TEXT_STYLE.caption[1],
      {
        textAlign: 'center',
        padding: 6,
        minHeight: 24,
      },
    ],
  })

  return (
    <div
      css={styling}
      onClick={onClick}
      role="button"
      onKeyDown={onClick}
      tabIndex={0}
    >
      <div className="cardpart checkbox">
        {variant === 'action' ? null : <EkCheckBoxStar />}
      </div>
      <div className="cardpart icon">{icon}</div>
      <p className="cardpart label">{label}</p>
    </div>
  )
}

export { StorageCard }
