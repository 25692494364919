import { decode } from 'jsonwebtoken'
import { JWTPayload } from '@functions/lib/authenticate/handlers/token-payload-handler'
import { getAccessTokenFromStore } from './local-store'

const getUserId = (): string => {
  const token = getAccessTokenFromStore()
  try {
    const { userId } = decode(token) as JWTPayload
    return userId
  } catch (e) {
    return ''
  }
}

export { getUserId }
