/* eslint-disable max-lines */
import React, { FC, useState } from 'react'
import { H6 } from '@components/base'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { RouteComponentProps } from '@reach/router'
import {
  Order,
  OrderInput,
  OrderPage,
  ORDER_STATUS,
  User,
} from '@types'
import { CREATE_ORDER, GET_ORDERS_BY_STATUS } from '@api/order'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { css } from '@emotion/react'
import { Button, ButtonSize, TextField } from '@pure-components/input'
import { EkList, EkListCell, EkListRow } from '@components/ek-list'
import { GET_USER_WITH_ORDERS } from '@api/user'
import { getUserId } from '@common/get-user-id-from-token'
import { COLORS } from '@styles'

interface ModalSelectOrderProps extends RouteComponentProps {
  closeModal?: (orderId: string | undefined) => void
}

const _ModalSelectOrder: FC<
  ModalSelectOrderProps &
    RouteComponentProps &
    WithMutationExtension<Order[], OrderInput> &
    WithQueryExtension<User> &
    WithQueryExtension<OrderPage>
> = ({
  queryData,
  addData,
  previousPage,
  nextPage,
  closeModal = () => {},
}) => {
  const {
    getOrdersByStatus: { data = [] },
    findUserByID: {
      assignedOrders: { data: userOrders = [] },
    },
  } = queryData

  const [searchFilter, setSearchFilter] = useState<string>('')
  const [appliedSearchFilter, setAppliedSearchFilter] = useState<
    string
  >('')
  const [selectedOrder, setSelectedOrder] = useState<null | Order>(
    null
  )

  const styles = css({
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto',
    overflow: 'hidden',
    '.title': {
      marginBottom: '10px',
    },
  })

  const searchFieldContainerStyles = css({
    height: '70px',
    width: '100%',
    overflow: 'hidden !important',
    background: COLORS.gray[0],
  })

  const searchFieldStyles = css({
    padding: '12px 12px',
    width: '100%',
    background: COLORS.gray[0],
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr',
    gridTemplateAreas: '"input button"',
    columnGap: '0.7rem',
    alignItems: 'baseline',
    '.searchinput': {
      gridArea: 'input',
    },
    '.searchbutton': {
      gridArea: 'button',
      width: '60px',
    },
  })

  const handleSearchChange = (event: any) => {
    setSearchFilter(event.target.value)
  }

  const handleSearchButton = (event: any) => {
    setAppliedSearchFilter(searchFilter)
  }

  const handleKeyPress = async (
    event: React.KeyboardEvent<Element>
  ) => {
    if (event.key === 'Enter') {
      await handleSearchButton(event)
    }
  }

  const onRowClick = (clickedItem: Order) => {
    if (selectedOrder?.orderNumber === clickedItem.orderNumber)
      setSelectedOrder(null)
    else setSelectedOrder(clickedItem)
  }

  const isButtonDisabled = () => {
    return selectedOrder === null
  }

  return (
    <section css={styles}>
      <div>
        <H6 className="heading">Mina order</H6>
        <EkList>
          {userOrders &&
            userOrders.map((workorder, i) => (
              <EkListRow
                key={workorder.orderNumber}
                classes={
                  selectedOrder?.orderNumber === workorder.orderNumber
                    ? 'selected'
                    : ''
                }
                onClick={(e) => onRowClick(workorder)}
              >
                <EkListCell>
                  <p>
                    {workorder.orderNumber}
                    {workorder.description ? ' - ' : ''}
                    {workorder.description &&
                    workorder.description.length > 25
                      ? `${workorder.description.substring(0, 25)}...`
                      : workorder.description}
                  </p>
                </EkListCell>
              </EkListRow>
            ))}
        </EkList>
      </div>

      <div>
        <H6 className="title">Sök order</H6>
        <div css={searchFieldContainerStyles}>
          <section css={searchFieldStyles}>
            <div className="searchinput">
              <TextField
                placeholder="Sök ordernummer"
                inputType="search"
                value={searchFilter}
                onChange={(e) => handleSearchChange(e)}
                onKeyUp={(e) => handleKeyPress(e)}
              />
            </div>
            <div className="searchbutton">
              <Button
                disabled={searchFilter.length < 1}
                size={ButtonSize.Small}
                onClick={handleSearchButton}
              >
                Sök
              </Button>
            </div>
          </section>
        </div>
      </div>
      <EkList>
        {data
          .filter(
            (workorder: Order) =>
              workorder.orderNumber!.indexOf(appliedSearchFilter) > -1
          )
          .map((workorder: Order, i) => (
            <EkListRow
              key={workorder.orderNumber}
              classes={
                selectedOrder?.orderNumber === workorder.orderNumber
                  ? 'selected'
                  : ''
              }
              onClick={(e) => onRowClick(workorder)}
            >
              <EkListCell>
                <p>
                  {workorder.orderNumber}
                  {workorder.description ? ' - ' : ''}
                  {workorder.description &&
                  workorder.description.length > 25
                    ? `${workorder.description.substring(0, 25)}...`
                    : workorder.description}
                </p>
              </EkListCell>
            </EkListRow>
          ))}
      </EkList>
      <Button
        disabled={isButtonDisabled()}
        onClick={() => closeModal(selectedOrder?._id)}
      >
        Flytta artiklar
      </Button>
    </section>
  )
}

const ModalSelectOrder = withQuery<OrderPage>({
  query: GET_ORDERS_BY_STATUS,
  variables: { _size: 5000, status: ORDER_STATUS.DEFAULT },
  loader: EkLoader,
})(
  withQuery<User>({
    query: GET_USER_WITH_ORDERS,
    variables: { id: getUserId() },
    loader: EkLoader,
  })(
    withMutation<Order[], OrderInput>(CREATE_ORDER)(_ModalSelectOrder)
  )
)

export { ModalSelectOrder }
