/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const CREATE_INVENTORY_ITEM = gql`
  mutation CreateInventoryItem(
    $item: InventoryItemItemRelation!
    $storageSpace: InventoryItemStorageSpaceRelation!
    $quantity: Float!
  ) {
    createInventoryItem(
      data: {
        item: $item
        storageSpace: $storageSpace
        quantity: $quantity
      }
    ) {
      _id

      item {
        _id

        number
        sdfId
        name
        description
        tags {
          data {
            _id
            name
          }
          before
          after
        }
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
      }
      storageSpace {
        _id

        name
        description
        type
        parent
      }
      quantity
    }
  }
`

const UPDATE_INVENTORY_ITEM = gql`
  mutation UpdateInventoryItem(
    $id: ID!
    $item: InventoryItemItemRelation!
    $storageSpace: InventoryItemStorageSpaceRelation!
    $quantity: Float!
  ) {
    updateInventoryItem(
      id: $id
      data: {
        item: $item
        storageSpace: $storageSpace
        quantity: $quantity
      }
    ) {
      _id

      item {
        _id

        number
        sdfId
        name
        description
        tags {
          data {
            _id
            name
          }
          before
          after
        }
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
      }
      storageSpace {
        _id

        name
        description
        type
        parent
      }
      quantity
    }
  }
`

const DELETE_INVENTORY_ITEM = gql`
  mutation DeleteInventoryItem($id: ID!) {
    deleteInventoryItem(id: $id) {
      _id
    }
  }
`

export {
  CREATE_INVENTORY_ITEM,
  UPDATE_INVENTORY_ITEM,
  DELETE_INVENTORY_ITEM,
}
