import React, { FC, ReactNode } from 'react'

interface StorageListViewProps {
  children?: ReactNode
}

const StorageListView: FC<StorageListViewProps> = ({
  children = <></>,
}) => {
  return <section>{children}</section>
}

export { StorageListView }
