const COLORS = {
  gray: {
    '0': 'rgb(255,255,255)',
    '10': 'rgb(246,247,248)',
    '30': 'rgb(221,223,226)',
    '40': 'rgb(196,196,197)',
    '50': 'rgb(147,149,153)',
    '70': 'rgb(80,82,86)',
    '90': 'rgb(34,36,40)',
    '100': 'rgb(0,0,0)',
  },
  orange: {
    '10': 'rgb(240,181,93)',
    '30': 'rgb(239,150,46)',
    '50': 'rgb(240,125,23)',
    '70': 'rgb(180,84,0)',
    '80': 'rgb(148,52,32)',
  },
  green: {
    '50': 'rgb(175,215,164)',
    '70': 'rgb(102,156,87)',
  },
  success: {
    '50': 'rgb(175,215,164)',
  },
  danger: {
    '50': 'rgb(185,28,29)',
  },
}

export { COLORS }
