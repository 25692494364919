import { css } from '@emotion/react'
import React, { FC, ReactNode } from 'react'
import { Handle } from './handle'
import { useSwipeDown } from './use-swipe-down'

interface BottomSheetProps {
  isOpen?: boolean
  bottomOffset?: string
  children?: ReactNode
  padding?: string
  boxShadow?: string
  transition?: string
  onRequestClose?: () => void
  closeThreshold?: number
  background?: string
}

// TODO: Figure out if there should be a shade behind, like the modal.
// TODO: Throttle move
// TODO: Maybe click on handle should close?

const BottomSheet: FC<BottomSheetProps> = ({
  bottomOffset = '70px',
  isOpen = false,
  padding = '0 32px 32px 32px',
  boxShadow = '0px 8px 32px rgba(0, 0, 0, 0.1)',
  transition = 'transform 250ms ease',
  background = 'tranparent',
  children = [],
  onRequestClose = () => {},
  closeThreshold = 0.4,
}) => {
  const {
    currentTransition,
    onDown,
    onMove,
    onRelease,
    transform,
    ref,
    yFromPointer,
  } = useSwipeDown({
    isOpen,
    closeThreshold,
    transition,
    onRequestClose,
  })

  const style = css({
    maxHeight: '80vh',
    width: '100%',
    position: 'fixed',
    bottom: bottomOffset,
    boxShadow,
    background,
    transform,
    transition: currentTransition,
  })

  const navStyle = css({
    padding,
  })

  return (
    <div css={style}>
      <Handle
        onPointerDown={yFromPointer(onDown)}
        onPointerMove={yFromPointer(onMove)}
        onPointerLeave={yFromPointer(onRelease)}
        onPointerUp={yFromPointer(onRelease)}
      />
      <nav css={navStyle} ref={ref}>
        {children}
      </nav>
    </div>
  )
}

export { BottomSheet }
