import { gql } from 'apollo-server-lambda'

const UPDATE_ACCOUNT = gql`
  mutation updateAccount($id: ID!, $data: AccountUpdateInput!) {
    updateAccount(id: $id, data: $data) {
      _id

      phoneNumber
      suspended
      deleted
      user {
        _id

        firstName
        lastName
        role
      }
    }
  }
`

const UPDATE_ACCOUNT_PASSWORD_SMS_KEY = gql`
  mutation updateAccountPasswordSmsKey(
    $smsKey: String!
    $newPassword: String!
  ) {
    updateAccountPasswordSmsKey(
      smsKey: $smsKey
      newPassword: $newPassword
    ) {
      _id

      phoneNumber
      suspended
      deleted
      user {
        _id

        firstName
        lastName
        role
      }
    }
  }
`

const CREATE_ACCOUNT_SMS = gql`
  mutation createAccountSms($data: AccountInput!) {
    createAccountSms(data: $data) {
      _id

      phoneNumber
      suspended
      deleted
      user {
        _id

        firstName
        lastName
        role
      }
    }
  }
`

/***
 * this ignores password and suspended fields on input, only phonenumber is used.
 * PASSWORD SHOULD BE AN EMPTY STRING
 * */
const RECOVER_ACCOUNT_SMS = gql`
  mutation recoverAccountSms($phoneNumber: String!) {
    recoverAccountSms(phoneNumber: $phoneNumber) {
      phoneNumber
    }
  }
`

export {
  UPDATE_ACCOUNT,
  CREATE_ACCOUNT_SMS,
  UPDATE_ACCOUNT_PASSWORD_SMS_KEY,
  RECOVER_ACCOUNT_SMS,
}
