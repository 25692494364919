import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { InventoryItem, OrderItem } from '@types'
import { SelectedItemsItem } from './article-cart-list-item'

interface CartItem {
  item: InventoryItem | OrderItem
  quantity: number
}

interface SelectedItemsListProps {
  itemList: CartItem[]
  children?: ReactNode
}

const isEmpty = (list: any[]) => {
  return (
    list.length === undefined ||
    list.length === null ||
    list.length === 0
  )
}

const SelectedItemsList: FC<SelectedItemsListProps> = ({
  itemList,
  children = <></>,
}) => {
  const styling = css({
    padding: 24,
    maxHeight: '40vh',
    overflow: 'auto',
  })
  return (
    <div css={styling}>
      {!isEmpty(itemList) &&
        itemList.some((i) => i.quantity > 0) &&
        itemList.map(
          ({
            item: {
              item: { name, number, _id },
            },
            quantity,
          }) => {
            return quantity > 0 ? (
              <SelectedItemsItem
                key={_id}
                name={name}
                quantity={quantity}
                itemId={number}
              />
            ) : (
              <></>
            )
          }
        )}
      {children}
    </div>
  )
}

export { SelectedItemsList }
export type { CartItem }
