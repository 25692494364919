const mq = (minOrMax: 'min' | 'max') => (bp: number) =>
  `@media (${minOrMax}-width: ${bp}px)`

const max = {
  '20': mq('max')(576),
  '40': mq('max')(768),
  '60': mq('max')(992),
  '80': mq('max')(1200),
}

const min = {
  '20': mq('min')(576),
  '40': mq('min')(768),
  '60': mq('min')(992),
  '80': mq('min')(1200),
}

const BREAKPOINTS = {
  max,
  min,
}

export { BREAKPOINTS }
