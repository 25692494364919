enum NavItem {
  Home = 'home',
  Storages = 'storages',
  Order = 'order',
  SideMenu = 'side-menu',
  Scan = 'scan',
  None = 'none',
}

export { NavItem }
