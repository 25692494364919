import styled from '@emotion/styled'
import { COLORS } from '@styles'
import { TEXT_STYLE } from '@styles'

const Subtitle1 = styled.p(
  TEXT_STYLE.subtitle[1],
  ({ color = COLORS.gray[100] }) => ({ color })
)

const Subtitle2 = styled.p(
  TEXT_STYLE.subtitle[2],
  ({ color = COLORS.gray[100] }) => ({ color })
)

export { Subtitle1, Subtitle2 }
