/* eslint-disable max-lines */
// TODO: Remove this once GQL is implemented
import React from 'react'
import { AppRoutes } from '@router'
import { RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { truncateString } from '@common'
import { A } from '@components/base'
import { EkList, EkListRow, EkListCell } from '@components/ek-list'
import { SimpleHeader } from '@components/header'
import { LayoutStandard } from '@layouts'
import { Search } from '@material-ui/icons'
import { Divider } from '@pure-components/graphics'
import {
  Button,
  ButtonVariant,
  Radio,
  RadioGroup,
} from '@pure-components/input'
import { AssigneeCard } from '@components/pages-components/working-orders/assign'
import { useState } from 'react'

interface AssignToOrderProps extends RouteComponentProps {
  id?: string
}

const assignableUsers = [
  {
    id: '35531',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    phone: 'Telefonnummer',
  },
  {
    id: '523452',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    phone: 'Telefonnummer',
  },
  {
    id: '334153',
    firstName: 'Ettannatnamn',
    lastName: 'Superefternamn',
    phone: 'Telefonnummer',
  },
  {
    id: '13513',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    phone: 'Telefonnummer',
  },
  {
    id: '44425',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    phone: 'Telefonnummer',
  },
]

/*
ID som kommer in är vilken order som avses.
Hur kommer man tillbaka till vyn man kom ifrån? Borde räcka med att länka till 
samma ID. Osparade ändringar försvinner ju men det borde man ha med i tanken.
Saknas tillbakaknapp i Figman. Kanske räcker med att gå bakåt i mobilen?
*/

const AssignToOrder = ({ id }: AssignToOrderProps) => {
  const bottomStyling = css({
    position: 'absolute',
    width: '100%',
    padding: 24,
    bottom: '70px',
  })

  const headerStyling = css({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    padding: 24,
  })

  const [assignee, setAssignee] = useState({
    id,
    firstName: 'Test',
    lastName: 'Testsson',
    phone: '0712345534',
  })

  const [selected, setSelected] = useState('')

  const handleAssignee = () => {
    const _assignee = assignableUsers.find(
      (user) => user.id === selected
    )
    _assignee && setAssignee(_assignee)
  }

  return (
    <>
      <SimpleHeader text={`Lagersaldo ${truncateString(id, 18)} `}>
        <Search />
      </SimpleHeader>
      <LayoutStandard>
        <section css={headerStyling}>
          <AssigneeCard
            name={`${assignee.firstName} ${assignee.lastName}`}
            phone={assignee.phone}
          />
          <div>
            <Button
              variant={ButtonVariant.Outlined}
              onClick={handleAssignee}
            >
              Tilldela
            </Button>
          </div>
        </section>
        <section>
          <RadioGroup onChange={(value) => setSelected(value)}>
            {assignableUsers.map((user, i) => (
              <EkList key={`stock${user.id}`}>
                <EkListRow>
                  <EkListCell width="30px">
                    <Radio value={user.id} />
                  </EkListCell>
                  <A to={`${AppRoutes.Users}/${user.id}`}>
                    <EkListCell>
                      {`${user.firstName} ${user.lastName}`}
                    </EkListCell>
                  </A>
                  <EkListCell justify="end">{user.phone}</EkListCell>
                </EkListRow>
                {i < assignableUsers.length - 1 && (
                  <Divider key={`divider${user.id}`} />
                )}
              </EkList>
            ))}
          </RadioGroup>
        </section>
        <section css={bottomStyling}>
          <Button variant={ButtonVariant.Primary}>Spara</Button>
        </section>
      </LayoutStandard>
    </>
  )
}

export { AssignToOrder }
