import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { EkList, EkListRow, EkListCell } from '@components/ek-list'
import { SimpleHeader } from '@components/header'
import { LayoutStandard } from '@layouts'
import { Search } from '@material-ui/icons'
import { Divider } from '@pure-components/graphics'
import { truncateString } from '@common'
import { GET_ORDER, CREATE_ORDER } from '@api/order'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import {
  WithMutationExtension,
  withMutation,
} from '@components/with-mutation'
import { WithQueryExtension, withQuery } from '@components/with-query'
import { Order, OrderInput } from '@types'

interface ArchivedOrderProps extends RouteComponentProps {
  id?: string
}

// const archivedOrderData = [
//   {
//     id: '3153153',
//     name: 'Artikel med antal',
//     amount: '5st',
//   },
//   {
//     id: '13531515',
//     name: 'Artikel med massa',
//     amount: '8kg',
//   },
//   {
//     id: '13515135',
//     name: 'Artikel med längd',
//     amount: '30cm',
//   },
// ]

const _ArchivedOrder: FC<
  ArchivedOrderProps &
    WithMutationExtension<Order[], OrderInput> &
    WithQueryExtension<Order>
> = ({ queryData, id }) => {
  const {
    findOrderByID: {
      _id,
      serviceAddress,
      orderNumber,
      serviceDate,
      items,
    },
  } = queryData
  return (
    <>
      <SimpleHeader
        text={`${truncateString(_id, 18)}, ${serviceAddress} `}
        afterText={`${orderNumber} + ${serviceDate}`}
      >
        <Search />
      </SimpleHeader>
      <LayoutStandard>
        <section>
          {items.data!.map((archivedOrderItem, i) => (
            <EkList key={`archivedOrderItem${archivedOrderItem._id}`}>
              <EkListRow>
                <EkListCell>{archivedOrderItem._id}</EkListCell>
                <EkListCell justify="end">
                  {archivedOrderItem.quantity}
                  {/* TODO: This should be the name of the item */}
                </EkListCell>
                <EkListCell justify="end">
                  {archivedOrderItem.quantity}
                </EkListCell>
              </EkListRow>
              {i < items.data!.length - 1 && (
                <Divider key={`divider${archivedOrderItem._id}`} />
              )}
            </EkList>
          ))}
        </section>
      </LayoutStandard>
    </>
  )
}

const ArchivedOrder = withQuery<Order>({
  query: GET_ORDER,
  loader: EkLoader,
})(withMutation<Order[], OrderInput>(CREATE_ORDER)(_ArchivedOrder))

export { ArchivedOrder }
