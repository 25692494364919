import React, { ChangeEvent, FC } from 'react'
// import DateFnsUtils from '@date-io/date-fns'
import { css } from '@emotion/react'
import { COLORS, TEXT_STYLE } from '@styles'

interface EkDatePickerProps {
  value?: string
  onChange?: (event: ChangeEvent) => void
}

// new Date().toDateString() did not work, here's a fulhack
const today = new Date()
const dd = String(today.getDate()).padStart(2, '0')
const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
const yyyy = today.getFullYear()

const date = `${yyyy}-${mm}-${dd}`

const EkDatePicker: FC<EkDatePickerProps> = ({
  value = date,
  onChange,
}) => {
  const styling = css([TEXT_STYLE.button[1]], {
    background: COLORS.gray[10],
    padding: '1em 1.5em',
    border: 'none',
    boxShadow: '1px 1px 4px 0px rgba(0,0,0,0.15)',
    borderRadius: 5,
  })

  return (
    <input
      css={styling}
      type="date"
      value={value}
      onChange={onChange}
    />
  )
}

export { EkDatePicker }
