import { css } from '@emotion/react'

const CLAMP = {
  standard: css({
    margin: '0 auto',
    width: 'clamp(250px, 100vw, 700px)',
  }),
}

export { CLAMP }
