/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const GET_ITEM_REPORT_ALL = gql`
  query itemReportAll {
    itemReportAll {
      data {
        id
        name
        number
        supplier
        unit
        quantity
        balanceWarning
        storages {
          id
          parent
          name
          description
          virtual
          type
        }
      }
    }
  }
`

const GET_ITEM_REPORT_BY_STORAGE = gql`
  query itemReportByStorage($id: ID!, $cursor: String) {
    itemReportByStorage(id: $id, cursor: $cursor) {
      data
      after
    }
  }
`

const GET_RELEVANT_INVENTORY_ITEMS = gql`
  query relevantInventoryItems($cursor: String) {
    relevantInventoryItems(cursor: $cursor) {
      data {
        quantity
        item {
          id
          name
          number
          supplier
          unitOfMeasure
          balanceWarning
        }
        storageSpace {
          id
          parent
          name
          description
          type
        }
      }
      after
    }
  }
`

export {
  GET_ITEM_REPORT_ALL,
  GET_ITEM_REPORT_BY_STORAGE,
  GET_RELEVANT_INVENTORY_ITEMS,
}
