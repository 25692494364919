/* eslint-disable max-lines */
// This is likely because of all the GQL-fakedata. I suppose this needs to be reviewed. TODO: Review this.
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@components/header'
import {
  Button,
  ButtonVariant,
  TextField,
  TextFieldInput,
} from '@pure-components/input'
import { Radio, RadioGroup } from '@pure-components/input'
import { Caption, Subtitle1 } from '@components/base-extended'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { User } from '@src/functions/lib/types'
import { useIsAdmin, useSnackbar } from '@hooks'
import { GET_USER } from '@api/user'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { Modal, useModal } from '@components/modal'
import { ModalConfirmSuspend } from '@components/pages-components/users/modal-confirm-suspend'
import { getUpdateUserMutation } from '@src/functions/entities/user/user-utils'
import { client } from '@api/client'
import { UPDATE_ACCOUNT } from '@api/account/account-mutations'
import { AccountUpdateInput } from '@types'
import { Snackbar } from '@pure-components/feedback'
import { ModalConfirmDelete } from '@components/pages-components/users/modal-confirm-delete'
import { AppRoutes } from '@router'

interface UserProps extends RouteComponentProps {
  id?: string
}

const _User: FC<UserProps & WithQueryExtension<User>> = ({
  queryData,
  refetch,
  id,
}) => {
  const {
    //findStorageSpaceByID: storageSpace,
    findUserByID: { firstName, lastName, role, account },
  } = queryData

  const { addAlert } = useSnackbar()

  const isAdmin = useIsAdmin()

  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const {
    isVisible: isVisibleSuspendConfirm,
    closeModal: closeModalSuspendConfirm,
    openModal: openModalSuspendConfirm,
  } = useModal()

  const {
    isVisible: isVisibleDeleteConfirm,
    closeModal: closeModalDeleteConfirm,
    openModal: openModalDeleteConfirm,
  } = useModal()

  const [inputFirstName, setFirstName] = useState<string>(firstName)
  const [inputLastName, setLastName] = useState<string>(lastName)
  const [inputRole, setRole] = useState<string>(role)
  const [inputPhoneNumber, setPhoneNumber] = useState<string>(
    account?.phoneNumber || ''
  )

  const handleEditButton = () => {
    setIsEditMode(true)
  }

  const handleSaveButton = async () => {
    setIsEditMode(false)
    addAlert(<Snackbar text={'Saving...'} />)
    await saveEdits()
    addAlert(<Snackbar text={'Saved'} />)
    refetch()
  }

  const handleSuspendButton = () => {
    openModalSuspendConfirm()
  }

  const handleSuspendConfimed = async () => {
    await updateAccountSuspension(true)
    closeModalSuspendConfirm()
    refetch()
  }

  const handleReactivateAccount = async () => {
    await updateAccountSuspension(false)
    refetch()
  }

  const handleDeleteButton = () => {
    openModalDeleteConfirm()
  }

  const handleDeleteConfirmed = async () => {
    await updateAccountDeletion(true)
    closeModalDeleteConfirm()
    navigate(AppRoutes.Users, { replace: true })
  }

  const saveEdits = async () => {
    try {
      await updateUser()
      await updateAccountPhone()
    } catch (e) {
      console.log(e)
      addAlert(<Snackbar text={'Something went wrong'} />)
    }
  }

  const updateUser = async () => {
    try {
      if (id) {
        const mutation = getUpdateUserMutation(id, {
          firstName: inputFirstName,
          lastName: inputLastName,
          role: inputRole,
        })
        //console.log('query:', query, variables)
        const result = await client.mutate({
          mutation,
        })
        console.log('user update result', result)
      }
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  const updateAccount = async (data: AccountUpdateInput) => {
    try {
      //console.log(data)

      if (account) {
        const mutation = UPDATE_ACCOUNT
        //console.log('query:', query, variables)
        const result = await client.mutate({
          mutation,
          variables: {
            id: account._id,
            data,
          },
        })
        console.log('user update result', result)
      }
    } catch (e) {
      console.log(e)
      addAlert(<Snackbar text={'Something went wrong'} />)
      throw e
    }
  }

  const updateAccountPhone = async () => {
    await updateAccount({ phoneNumber: inputPhoneNumber })
  }

  const updateAccountSuspension = async (suspended: boolean) => {
    await updateAccount({ suspended })
  }

  const updateAccountDeletion = async (deleted: boolean) => {
    await updateAccount({ deleted })
  }

  const styles = css({
    display: 'grid',
    rowGap: '12px',
    padding: '1em',
    paddingTop: '2em',
    '.radiobuttons': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, auto)',
      gap: '1em',
      placeItems: 'center start',
    },
    '.radio': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, auto)',
      gap: '0.5em',
      placeItems: 'center center',
    },
  })

  interface FormFields {
    id: string
    inputType: TextFieldInput
    value: string
    placeholder: string
    setter: Dispatch<SetStateAction<string>>
  }

  const formData: FormFields[] = [
    {
      id: 'firstname',
      inputType: 'text',
      value: inputFirstName,
      placeholder: 'Förnamn',
      setter: setFirstName,
    },
    {
      id: 'lastname',
      inputType: 'text',
      value: inputLastName,
      placeholder: 'Efternamn',
      setter: setLastName,
    },
    {
      id: 'phonenumber',
      inputType: 'tel',
      value: inputPhoneNumber,
      placeholder: 'Mobilnummer / inlogg',
      setter: setPhoneNumber,
    },
  ]

  return (
    <>
      <SimpleHeader text={`${firstName} ${lastName}`} />
      <LayoutStandard>
        <section css={styles}>
          {formData.map((props) => (
            <div className="textfield" key={`field_${props.id}`}>
              <TextField
                {...props}
                variant="primary"
                multiline={false}
                helperText=""
                key={props.placeholder}
                disabled={!isEditMode}
                onChange={(e) => {
                  props.setter(
                    (e as ChangeEvent<HTMLInputElement>).target.value
                  )
                }}
              />
            </div>
          ))}
        </section>
        <section css={styles}>
          <Subtitle1>Roll</Subtitle1>
          <RadioGroup
            value={inputRole}
            onChange={(val) => {
              setRole(val)
            }}
          >
            <div className="radiobuttons">
              <div className="radio">
                <Radio value={'admin'} disabled={!isEditMode} />
                <Caption>Administratör</Caption>
              </div>
              <div className="radio">
                <Radio value={'user'} disabled={!isEditMode} />
                <Caption>Vanlig användare</Caption>
              </div>
            </div>
          </RadioGroup>
        </section>
        <section css={styles}>
          {!isEditMode && (
            <Button
              variant={ButtonVariant.Primary}
              onClick={handleEditButton}
              disabled={isEditMode}
            >
              Redigera
            </Button>
          )}

          {isAdmin && !account?.suspended && (
            <Button
              variant={ButtonVariant.Outlined}
              onClick={handleSuspendButton}
              disabled={isEditMode}
            >
              Avaktivera användare
            </Button>
          )}

          {isAdmin && account?.suspended && (
            <Button
              variant={ButtonVariant.Outlined}
              onClick={handleReactivateAccount}
              disabled={isEditMode}
            >
              Återaktivera användare
            </Button>
          )}

          {isAdmin && (
            <Button
              variant={ButtonVariant.Danger}
              onClick={handleDeleteButton}
              disabled={isEditMode}
            >
              Ta bort användare
            </Button>
          )}

          {isEditMode && (
            <Button
              variant={ButtonVariant.Primary}
              onClick={handleSaveButton}
              disabled={!isEditMode}
            >
              Spara
            </Button>
          )}
        </section>
        <Modal
          isVisible={isVisibleSuspendConfirm}
          closeModal={closeModalSuspendConfirm}
        >
          <ModalConfirmSuspend
            userName={`${firstName} ${lastName}`}
            onConfirm={() => {
              handleSuspendConfimed()
            }}
            onCancel={closeModalSuspendConfirm}
          />
        </Modal>
        <Modal
          isVisible={isVisibleDeleteConfirm}
          closeModal={closeModalDeleteConfirm}
        >
          <ModalConfirmDelete
            userName={`${firstName} ${lastName}`}
            onConfirm={() => {
              handleDeleteConfirmed()
            }}
            onCancel={closeModalDeleteConfirm}
          />
        </Modal>
      </LayoutStandard>
    </>
  )
}

const UserPage = withQuery<User>({
  query: GET_USER,
  loader: EkLoader,
})(_User)

export { UserPage }
