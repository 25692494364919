/* eslint-disable max-lines */
// TODO: Radera detta när GraphQL implementerats, filen blir för lång med spökdatan vilket är varför disable är tillagt.
import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  navigate,
  RouteComponentProps,
  useLocation,
} from '@reach/router'
import { css } from '@emotion/react'
import { SimpleHeader } from '@components/header'

import { truncateString } from '@common'
import { useQueryParam, useBottomSheet, useIsAdmin } from '@hooks'
import {
  ModalSelectOrder,
  ModalSelectStorage,
  StorageListItemWithAmount,
  StorageListView,
} from '@components/pages-components/storage'

import { Button, ButtonVariant } from '@pure-components/input'
import { COLORS } from '@styles'
import { Divider } from '@pure-components/graphics'
import { Modal, useModal } from '@components/modal'
import {
  GET_STORAGES_BY_PARENT,
  GET_STORAGE_SPACE,
  UPDATE_STORAGE_SPACE,
} from '@api/storage-space'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { withQuery, WithQueryExtension } from '@components/with-query'
import {
  InventoryItem,
  InventoryItemInput,
  StorageSpace,
  StorageSpaceInput,
  StorageSpaceTransaction,
} from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { LayoutStandard } from '@layouts'
import { getIconByVariant } from '@components/pages-components/storage/common'
//import { ModalEditArticleCard } from '@components/pages-components/storage/modal-edit-article-card'
import { PERFORM_ADD_TRANSACTION } from '@api/perform-transaction'
import {
  CartItem,
  SelectedItemsList,
} from '@components/pages-components/storage/article-cart-list'

import { gql } from '@apollo/client'
import { PERFORM_MOVE_TRANSACTION_MULTIPLE } from '@api/perform-transaction/perform-move-transaction-multiple-mutation'
import { client } from '@api/client'
import { PERFORM_WITHDRAW_TRANSACTION_MULTIPLE } from '@api/perform-transaction/perform-withdraw-transaction-multiple-mutation'
import { parse } from 'query-string'
import { AppRoutes } from '@router'
import { getSortedArray } from '@common/get-sorted-array'
import { Edit, MenuOpen } from '@material-ui/icons'
import { StorageListStorageItem } from '@components/pages-components/storage/list-item-child-storage'

const getChildren = async (parentId: String) => {
  const result = await client.query({
    query: gql`
      ${GET_STORAGES_BY_PARENT}
    `,
    variables: { parentId, _size: 10000 },
  })

  return result.data['getStoragesByParent'].data
}

interface WorkOrderProps extends RouteComponentProps {
  id?: string
}

const _Storage: FC<
  WorkOrderProps &
    WithMutationExtension<
      StorageSpaceTransaction,
      { inventoryItem: InventoryItemInput }
    > &
    WithQueryExtension<StorageSpace>
> = ({ queryData, addData, refetch, id }) => {
  const location = useLocation()
  const params = parse(location.search)
  const refOrderId: string = params.order as string
  const refOrderNumber: string = params.ordernumber as string

  const { isAdmin } = useIsAdmin()

  const {
    //findStorageSpaceByID: storageSpace,
    findStorageSpaceByID: {
      name,
      items,
      type,
      description,
      virtual,
      parent,
    },
  } = queryData

  //console.log('queryData', queryData)

  const [isLoading, setIsLoading] = useState(true)

  const [itemMap, setItemMap] = useState<{
    [key: string]: InventoryItem[]
  }>({})
  const [listItems, setListItems] = useState<InventoryItem[]>([])

  const [childStorages, setChildStorages] = useState<StorageSpace[]>(
    []
  )

  const [selectedItems, setSelectedItems] = useState<CartItem[]>([])

  const { setChildren, setIsOpen } = useBottomSheet()

  /* eslint-disable */
  useEffect(() => {
    //console.log('debug set itemMap')
    const obj: {
      [key: string]: InventoryItem[]
    } = {}
    obj[id || ''] = items?.data || []
    setItemMap(obj)
  }, [setItemMap])

  useEffect(() => {
    //console.log('debug useEffect dep: data', itemMap)
    //console.log('debug set items for storage: ', id)
    setListItems(itemMap[id || ''] || [])
  }, [itemMap, location.pathname])

  useEffect(() => {
    //console.log('debug set childStorageMap')
    setIsLoading(true)
    const gc = async () => {
      const children = await getChildren(id || '')

      setChildStorages(children)

      setIsLoading(false)
    }

    gc()
  }, [setChildStorages, location.pathname])
  /* es-lint-enable */

  /*useEffect(() => {
    console.log('debug items useEffect', id, data)
    if (!data[id || ''] || data[id || ''].length === 0) {
      console.log('debug set empty list')
      setListItems([])
    } else {
      console.log('debug set list to', id)
      setListItems(data[id || ''])
    }
  }, [data])*/

  const is_storage_empty = () => {
    return (
      childStorages.length === 0 &&
      (items.data === null ||
        items.data === undefined ||
        items.data?.length === 0)
    )
  }

  const bottomSheetStyles = css({
    display: 'grid',
    gap: '1em',
    marginTop: '2em',
    padding: 24,
  })

  const openBottomSheetContext = () => {
    setChildren(
      <>
        <section css={bottomSheetStyles}>
          <>
            {!virtual && (
              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => {
                  setIsOpen(false)
                  navigate(
                    `${AppRoutes.AddToStorage}?storage=${id}`,
                    {
                      state: {
                        recommendedItems: listItems.map(
                          (inv) => inv.item
                        ),
                      },
                    }
                  )
                }}
              >
                Insättning
              </Button>
            )}
            {isAdmin && (
              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => {
                  setIsOpen(false)
                  navigate(`${AppRoutes.Storages}/${id}/adjust`)
                }}
              >
                Korrigering
              </Button>
            )}
            {isAdmin && (
              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => {
                  setIsOpen(false)
                  navigate(
                    `${AppRoutes.Storages}/${id}/removearticles`
                  )
                }}
              >
                Korrigering: Ta Bort
              </Button>
            )}
            {isAdmin && (
              <Button
                variant={ButtonVariant.Danger}
                disabled={!is_storage_empty()}
                onClick={async () => {
                  setIsOpen(false)
                  await deleteStorage()
                  if (parent) {
                    navigate(`${AppRoutes.Storages}/${parent}`)
                  } else {
                    navigate(`${AppRoutes.Storages}`)
                  }
                }}
              >
                Ta bort förvaring
              </Button>
            )}
          </>
        </section>
      </>
    )
    setIsOpen(true)
  }

  const openBottomSheetTransfer = () => {
    setChildren(
      <>
        <SelectedItemsList
          itemList={selectedItems}
        ></SelectedItemsList>
        <section css={bottomSheetStyles}>
          {refOrderId && refOrderId !== '' ? (
            <Button
              variant={ButtonVariant.Outlined}
              onClick={() => moveToOrderAndReturn()}
            >
              Lägg till i order {refOrderNumber}
            </Button>
          ) : (
            <>
              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => openMoveToOrderModal()}
              >
                Lägg till i order
              </Button>
              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => openMoveToStorageModal()}
              >
                Flytta till ny förvaringsplats
              </Button>
            </>
          )}
        </section>
      </>
    )
    setIsOpen(true)
  }

  const {
    isVisible: isMoveToStorageModalVisible,
    closeModal: closeMoveToStorageModal,
    openModal: openMoveToStorageModal,
  } = useModal()
  const {
    isVisible: isMoveToOrderModalVisible,
    closeModal: closeMoveToOrderModal,
    openModal: openMoveToOrderModal,
  } = useModal()

  const view = useQueryParam('view')

  //When we select articles for checkout
  const onUpdateItemAmount = (
    itemToUpdate: InventoryItem,
    selectedQuantity: number
  ) => {
    // create object dictionary for easier manipulation
    const dictionary: { [x: string]: CartItem } = {}
    for (const ci of selectedItems) {
      dictionary[ci.item._id] = ci
    }

    //Add or update Item based on _id
    dictionary[itemToUpdate._id] = {
      item: itemToUpdate,
      quantity: selectedQuantity,
    }

    // Convert dictionary to array of objects
    const selected = Array.from(Object.values(dictionary)).filter(
      (i) => i.quantity !== 0
    )

    setSelectedItems(selected)
  }

  const selectedItemsLength = useMemo(() => {
    return selectedItems.reduce((sum: number, item: CartItem) => {
      sum += item.quantity
      return sum
    }, 0)
  }, [selectedItems])

  const handleButtonEdit = () => {
    navigate(`${AppRoutes.Storages}/${id}/edit`)
  }

  const handleClick = (article_id: string) => {
    navigate(`${AppRoutes.Articles}/${article_id}`)
  }

  const handleClickChildStorage = (storage_id: string) => {
    //console.log('handle child storage click')
    navigate(`${AppRoutes.Storages}/${storage_id}`)
    window.location.reload()
  }

  const moveToOrderAndReturn = async () => {
    await moveArticlesToOrder(refOrderId)
    setIsOpen(false)
    navigate(`${AppRoutes.WorkingOrders}/${refOrderId}`)
  }

  const moveArticlesToOrder = async (orderId: string | undefined) => {
    if (orderId) {
      try {
        //console.log('query:', query, variables)
        const result = await client.mutate({
          mutation: gql`
            ${PERFORM_WITHDRAW_TRANSACTION_MULTIPLE}
          `,
          variables: {
            inventories: selectedItems
              .filter((i) => i.quantity !== 0)
              .map((i) => ({
                id: i.item._id,
                quantity: i.quantity,
              })),
            order: orderId,
          },
        })
        //console.log('result', result)
        refetch()
      } catch (e) {
        console.log(e)
      }
    }
    closeMoveToOrderModal()
    setIsOpen(false)
  }

  const moveArticlesToStorage = async (
    storageSpace: StorageSpace | undefined
  ) => {
    //console.log(storageSpace?._id)
    if (storageSpace?._id) {
      try {
        //console.log('query:', query, variables)
        const result = await client.mutate({
          mutation: PERFORM_MOVE_TRANSACTION_MULTIPLE,
          variables: {
            fromInventories: selectedItems
              .filter((i) => i.quantity !== 0)
              .map((i) => ({
                id: i.item._id,
                quantity: i.quantity,
              })),
            toStorage: storageSpace._id,
          },
        })
        //console.log('result', result)
        refetch()
      } catch (e) {
        console.log(e)
      }
    }
    closeMoveToStorageModal()
    setIsOpen(false)
  }

  const deleteStorage = async () => {
    if (id) {
      try {
        const variables: StorageSpaceInput & { id: string } = {
          id,
          name,
          description,
          type,
          virtual: virtual || false,
          deleted: true,
        }

        const result = await client.mutate({
          mutation: UPDATE_STORAGE_SPACE,
          variables,
        })
        //console.log(result)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const contextMenuStyles = css({
    //bottom: '70px',
    padding: '12px 24px',
    width: '100%',
    //position: 'fixed',
    background: COLORS.gray[0],
  })

  const cartStyles = css({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: '0.5em',
    padding: '1rem 1.5rem 0 1.5rem',
    borderTop: '1px lightgray solid',
    '.article-info': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  const storageInfoStyles = css({
    padding: '10px 15px 10px 15px',
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'auto 1fr',
    marginTop: '10px',
    borderBottom: '1px lightgray solid',
    position: 'fixed',
    top: 45,
    height: 40,
    width: '100%',
    background: COLORS.gray[0],
    '.icon': {
      display: 'grid',
      minHeight: 24,
      placeContent: 'center center',
      fontSize: '24px',
    },
    '.description': {
      paddingTop: '6px',
    },
  })

  const styles = css({
    display: 'grid',
    color: COLORS.gray[90],
    padding: view === 'card' ? '24px' : '0',
    gap: '1em',
    '.button-wrapper-reset': {
      display: 'flex',
      gap: '0.5em',
      padding: '0 24px',
      '.article-info': {},
    },
  })

  // @ts-ignore
  return (
    <>
      {isLoading && <EkLoader />}
      {!isLoading && (
        <>
          <SimpleHeader text={`${truncateString(name, 18)}`}>
            {/*
        <MoveToInbox onClick={() => openMoveToStorageModal()} />
        <Search onClick={() => openMoveToOrderModal()} />
      */}
            <div
              onClick={handleButtonEdit}
              role="button"
              onKeyDown={handleButtonEdit}
              tabIndex={-2}
            >
              <Edit />
            </div>
          </SimpleHeader>
          <LayoutStandard
            scroll={true}
            padding={'45px 0 0 0'}
            key={`active_storage_${id}`}
          >
            <div css={storageInfoStyles}>
              <div className="icon">{getIconByVariant(type)}</div>
              <div className="description">{description}</div>
            </div>
            <section css={styles}>
              <>
                <div className="childStoragesList">
                  <StorageListView>
                    {childStorages.length > 0 &&
                      getSortedArray(childStorages, (a, b) =>
                        a.name.localeCompare(b.name)
                      )
                        .filter((s) => !s.deleted)
                        .map((storage: StorageSpace, i: number) => (
                          <React.Fragment
                            key={`storage_${id}_${storage._id}`}
                          >
                            <StorageListStorageItem
                              key={`${id}_${storage._id}`}
                              {...storage}
                              onClick={() => {
                                handleClickChildStorage(storage._id)
                              }}
                            />
                            {i < listItems.length - 1 && (
                              <Divider key={`divider${i}`} />
                            )}
                          </React.Fragment>
                        ))}
                  </StorageListView>
                </div>
                <Divider key={`divider_section1`} />
                <StorageListView>
                  {listItems.length > 0 &&
                    getSortedArray(
                      //data.filter((i) => i.quantity !== 0),
                      listItems,
                      (a, b) =>
                        parseInt(a.item.number) -
                        parseInt(b.item.number)
                    ).map(
                      (inventoryItem: InventoryItem, i: number) => (
                        <React.Fragment
                          key={`item_${id}_${inventoryItem.item._id}`}
                        >
                          <StorageListItemWithAmount
                            key={`${id}_${inventoryItem.item._id}`}
                            {...inventoryItem}
                            onClick={() => {
                              handleClick(inventoryItem.item._id)
                            }}
                            onUpdateAmount={(
                              selectedQuantity: number
                            ) => {
                              /*console.log(
                                'onUpdateAmount',
                                selectedQuantity
                              )*/
                              onUpdateItemAmount(
                                inventoryItem,
                                selectedQuantity
                              )
                            }}
                          />
                          {i < listItems.length - 1 && (
                            <Divider
                              key={`divider${inventoryItem.item._id}`}
                            />
                          )}
                        </React.Fragment>
                      )
                    )}
                </StorageListView>
              </>
            </section>
          </LayoutStandard>
          {selectedItemsLength > 0 && (
            <div css={cartStyles}>
              <div className="article-info">
                {selectedItemsLength} vald
                {selectedItemsLength === 1 ? '' : 'a'}{' '}
                {selectedItemsLength === 1 ? 'artikel' : 'artiklar'}
              </div>
              <Button
                variant={ButtonVariant.Primary}
                onClick={() => openBottomSheetTransfer()}
                disabled={selectedItemsLength === 0}
              >
                Flytta
              </Button>
            </div>
          )}
          <section css={contextMenuStyles}>
            <Button
              variant={ButtonVariant.Outlined}
              onClick={openBottomSheetContext}
            >
              <MenuOpen />
            </Button>
          </section>
          {/*<Modal
        isVisible={isEditArticleModalCardVisible}
        closeModal={closeEditArticleModalCard}
      >
        <ModalEditArticleCard
          storageSpace={storageSpace}
          closeModal={closeEditArticleModalCard}
          inventoryItem={inventoryItem}
          onInventoryItemChange={onInventoryItemChange}
        />
      </Modal>*/}

          <Modal
            isVisible={isMoveToOrderModalVisible}
            closeModal={closeMoveToOrderModal}
          >
            <ModalSelectOrder closeModal={moveArticlesToOrder} />
          </Modal>
          <Modal
            isVisible={isMoveToStorageModalVisible}
            closeModal={closeMoveToStorageModal}
          >
            <ModalSelectStorage
              closeModal={moveArticlesToStorage}
              currentStorageId={id}
              selectedItems={selectedItems}
              confirmText="Flytta artiklar"
            />
          </Modal>
        </>
      )}
    </>
  )
}

const Storage = withQuery<StorageSpace>({
  query: GET_STORAGE_SPACE,
  loader: EkLoader,
})(
  withMutation<
    StorageSpaceTransaction,
    { inventoryItem: InventoryItemInput }
  >(PERFORM_ADD_TRANSACTION)(_Storage)
)

export { Storage }
