import { css } from '@emotion/react'
import { InsertEmoticon } from '@material-ui/icons'
import React, {
  ReactNode,
  MouseEvent,
  KeyboardEvent,
  FC,
} from 'react'

interface DetailsItemProps {
  icon?: ReactNode
  text?: string
  span?: number
  label?: string
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
}

const DetailsItem: FC<DetailsItemProps> = ({
  span = 1,
  icon = <InsertEmoticon />,
  text = '',
  label = '',
  onClick = () => {},
}) => {
  const styling = css({
    gridColumn: `span ${span}`,
    display: 'flex',
    margin: '0.3rem',
    flexDirection: 'column',
    '.icon, .text': {
      verticalAlign: 'middle',
      display: 'inline-block',
    },
    '.label': {
      fontWeight: 'bold',
      marginBottom: '.3rem',
    },
  })

  return (
    <div
      css={styling}
      onClick={onClick}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {}}
      role="button"
      tabIndex={0}
    >
      <span className="label">{label}</span>
      <div>
        <span className="icon">{icon}</span>
        <span className="text">{text}</span>
      </div>
    </div>
  )
}

export { DetailsItem }
