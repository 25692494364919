import React, { ReactNode } from 'react'
import {
  Archive,
  DirectionsCar,
  Home,
  NotListedLocation,
  ViewAgenda,
  ZoomOutMap,
} from '@material-ui/icons'
import { STORAGE_SPACE_TYPE } from '@src/types'

const iconByVariant: Map<STORAGE_SPACE_TYPE, ReactNode> = new Map([
  [
    STORAGE_SPACE_TYPE.DEFAULT,
    <NotListedLocation fontSize="inherit" />,
  ],
  [STORAGE_SPACE_TYPE.FORDON, <DirectionsCar fontSize="inherit" />],
  [STORAGE_SPACE_TYPE.HYLLA, <ViewAgenda fontSize="inherit" />],
  [STORAGE_SPACE_TYPE.LADA, <Archive fontSize="inherit" />],
  [STORAGE_SPACE_TYPE.LAGER, <Home fontSize="inherit" />],
  [STORAGE_SPACE_TYPE.YTA, <ZoomOutMap fontSize="inherit" />],
])

const getIconByVariant = (
  variant: STORAGE_SPACE_TYPE = STORAGE_SPACE_TYPE.DEFAULT
) => {
  return (
    iconByVariant.get(variant) ?? (
      <NotListedLocation fontSize="inherit" />
    )
  )
}

export { getIconByVariant }
