import { createContext } from 'react'

type LoginContextType = {
  isLoggedIn: boolean
  setIsLoggedIn: (value: boolean) => void
}

const LoginContext = createContext<LoginContextType>({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
})

export { LoginContext }
