/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const GET_INVENTORY_ITEMS = gql`
  query inventoryItems($_size: Int, $_cursor: String) {
    inventoryItems(_size: $_size, _cursor: $_cursor) {
      data {
        _id

        item {
          _id
          balanceWarning
          number
          sdfId
          name
          description
          tags {
            data {
              _id
              name
            }
            before
            after
          }
          images
          defaultImage
          unitOfMeasure
          supplier
          netPrice
          grossPrice
          sellingPrice
        }
        storageSpace {
          _id

          name
          description
          type
          parent
        }
        quantity
      }
      before
      after
    }
  }
`

const GET_INVENTORY_ITEM = gql`
  query findInventoryItemByID($id: ID!) {
    findInventoryItemByID(id: $id) {
      _id

      item {
        _id
        balanceWarning
        number
        sdfId
        name
        description
        tags {
          data {
            _id
            name
          }
          before
          after
        }
        images
        defaultImage
        unitOfMeasure
        supplier
        netPrice
        grossPrice
        sellingPrice
      }
      storageSpace {
        _id

        name
        description
        type
        parent
      }
      quantity
    }
  }
`

export { GET_INVENTORY_ITEMS, GET_INVENTORY_ITEM }
