import { BoxShadow, getBoxShadow } from '@styles'
import { css } from '@emotion/react'

const hoverMap = new Map([
  [BoxShadow.Small, getBoxShadow(BoxShadow.Medium)],
  [BoxShadow.Medium, getBoxShadow(BoxShadow.Large)],
  [BoxShadow.Large, getBoxShadow(BoxShadow.Huge)],
  [BoxShadow.Huge, getBoxShadow(BoxShadow.Huge)],
])

const getBoxShadowHover = (boxShadow = BoxShadow.Small) => {
  return css({
    transition:
      'box-shadow 0.3s cubic-bezier(.25,.8,.25,1), transform 0.3s cubic-bezier(.25,.8,.25,1)',
    ':hover': {
      boxShadow:
        hoverMap.get(boxShadow) ?? getBoxShadow(BoxShadow.Medium),
      transform: 'translateY(-0.5em)',
    },
  })
}

export { getBoxShadowHover }
