import { css } from '@emotion/react'
import { standardActive } from '@styles/utils'
import { COLORS } from '@styles'

const bottomNavWrapperStyles = css({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  zIndex: 2000,
})

const iconStyle = (iconSize: string) =>
  css([
    standardActive,
    {
      transition: 'color ease 250ms',
      color: COLORS.gray[50],
      height: iconSize,
      width: iconSize,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '.icon': {
        fontSize: 30,
      },
      '&.selected': {
        color: COLORS.gray[0],
      },
    },
  ])

const buttonStyleOverrides = css({
  '&:focus>*': {
    color: COLORS.gray[30],
  },
  '&:active': {
    transform: 'scale(0.96) translate3d( 0, 0, 0) perspective(1px)',
  },
})

const cameraStyle = (iconSize: string) => [
  iconStyle(iconSize),
  css({
    background: COLORS.orange[30],
    color: COLORS.gray[0],
  }),
]

export {
  bottomNavWrapperStyles,
  iconStyle,
  buttonStyleOverrides,
  cameraStyle,
}
