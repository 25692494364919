import React, { FC, ReactNode } from 'react'

interface IncomingOrdersListViewProps {
  children?: ReactNode
}

const IncomingOrdersListView: FC<IncomingOrdersListViewProps> = ({
  children,
}) => {
  return <section>{children}</section>
}

export { IncomingOrdersListView }
