/** Truncates a given string to length provided including length of appended suffix string provided
 * truncateString('12345',5,'!') will return 1234! - 5 characters, including the specified suffix
 *
 */
const truncateString = (str = '', n = 0, ellipsis = `...`) => {
  return str.length > n - 1
    ? `${str.substr(0, n - ellipsis.length)}${ellipsis}`
    : str
}

export { truncateString }
