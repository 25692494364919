import { gql } from '@apollo/client'

const CREATE_TAG = gql`
  mutation CreateTag($name: String!) {
    createTag(data: { name: $name }) {
      _id

      name
    }
  }
`

export { CREATE_TAG }
