import { gql } from '@apollo/client'

const GET_USERS = gql`
  query users($_size: Int, $_cursor: String) {
    users(_size: $_size, _cursor: $_cursor) {
      data {
        _id

        firstName
        lastName
        role
        account {
          _id
          deleted
          suspended
          phoneNumber
        }
        assignedOrders {
          data {
            _id
          }
        }
      }
      before
      after
    }
  }
`

const GET_USER = gql`
  query findUserByID($id: ID!) {
    findUserByID(id: $id) {
      _id
      firstName
      lastName
      role
      account {
        _id
        phoneNumber
        suspended
        deleted
      }
      assignedOrders {
        data {
          _id
        }
      }
    }
  }
`

const GET_USER_WITH_ORDERS = gql`
  query findUserByID($id: ID!) {
    findUserByID(id: $id) {
      _id

      firstName
      lastName
      role
      account {
        _id

        phoneNumber
        suspended
        deleted
      }
      assignedOrders {
        data {
          _id

          description
          orderNumber
          sdfId
          createdDate
          serviceDate
          customerPhone
          projectNumber
          projectName
          serviceAddress
          invoiceAddress
          status
        }
      }
    }
  }
`

export { GET_USERS, GET_USER, GET_USER_WITH_ORDERS }
