import { css } from '@emotion/react'
import { TEXT_STYLE, COLORS } from '@styles'
import { Order } from '@types'
import React from 'react'

interface DetailsItemProps {
  caption?: string
  text?: string | Order
  cols?: number
  rows?: number
}

const DetailsItem = ({
  caption = '',
  text = '',
  cols = 1,
  rows = 1,
}) => {
  const styling = css({
    gridColumn: `span ${cols}`,
    gridRow: `span ${rows}`,
    '.caption': [
      TEXT_STYLE.caption[1],
      {
        color: COLORS.orange[10],
        width: '100%',
        display: 'block',
      },
    ],
    '.text': [
      TEXT_STYLE.subtitle[1],
      {
        color: COLORS.gray[10],
      },
    ],
  })

  return (
    <p css={styling}>
      <span className="caption">{caption}</span>
      <span className="text">{text}</span>
    </p>
  )
}

export { DetailsItem }
