/* eslint-disable max-lines */
// TODO: Ta bort denna när datan ersätts med GQL
import React, { FC, useEffect } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { css } from '@emotion/react'
import { AddCircleOutline, Search } from '@material-ui/icons'
import { LayoutStandard } from '@layouts'
import { useBottomNav } from '@hooks'
import { NavItem } from '@context'
import { SimpleHeader } from '@components/header'
import {
  StorageCard,
  StoragesListItem,
} from '@components/pages-components/storage'
import { A, H6 } from '@components/base'
import { getIconByVariant } from '@components/pages-components/storage/common/get-icon-by-variant'
import { StorageSpaceInput, StorageSpacePage } from '@src/types'
import { Divider } from '@pure-components/graphics'
import { AppRoutes } from '@router'
import { withQuery, WithQueryExtension } from '@components/with-query'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import {
  CREATE_STORAGE_SPACE,
  GET_ROOT_STORAGES,
} from '@api/storage-space'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { Pagination } from '@components/pagination'
import { Modal, useModal } from '@components/modal'
import { ModalSearchStorage } from '@components/pages-components/storage/modal-search-storage'

const _Storages: FC<
  RouteComponentProps &
    WithMutationExtension<Storage[], StorageSpaceInput> &
    WithQueryExtension<StorageSpacePage>
> = ({ queryData, addData, previousPage, nextPage, path }) => {
  const {
    getStoragesRoot: {
      data = [],
      after: nextPageCursor,
      before: previousPageCursor,
    },
  } = queryData

  const { setActiveItem } = useBottomNav()

  const {
    isVisible: isSearchModalVisible,
    closeModal: closeSearchModal,
    openModal: openSearchModal,
  } = useModal()

  useEffect(() => {
    setActiveItem(NavItem.Storages)
  })

  const handleStorageClicked = (storage_id: string) => {
    navigate(`${AppRoutes.Storages}/${storage_id}`)
  }

  const styles = css({
    padding: 0,
    '.subtitle': {
      margin: '0 24px',
      marginBottom: 12,
    },
    '.buttons': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      margin: 12,
      gap: 12,
    },
  })
  const cardStyles = css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
    gap: 12,
    margin: 24,
    marginBottom: 24,
  })

  // const filterMyFavorites = data.filter(
  //   (o) => o.favoriteBy === 'me'
  // )
  // TODO: Keep this for when we add some sort of favourited by feature to storages

  return (
    <>
      <SimpleHeader text={`Förvaringsplatser`}>
        <Search onClick={() => openSearchModal()} />
      </SimpleHeader>
      <LayoutStandard scroll={true}>
        <div css={styles}>
          <div css={cardStyles}>
            <A to={`${AppRoutes.Storages}/new`}>
              <StorageCard
                variant="action"
                icon={<AddCircleOutline fontSize="inherit" />}
                label="Lägg till ny förvaringsplats"
              />
            </A>
            {/* {filterMyFavorites.map(({ data }) => (
              <A
                to={`${AppRoutes.Storages}/${data.id}`}
                key={`link${data.name}`}
              >
                <StorageCard
                  label={data.name}
                  icon={getIconByVariant(data.variant)}
                />
              </A>
            ))} */}
          </div>
          <H6 className="subtitle">Alla förvaringsplatser</H6>
          {data
            .filter((s) => !s.deleted)
            .map(({ _id, name, type }, i) => (
              <React.Fragment key={`item${name}`}>
                <A to={`${AppRoutes.Storages}/${_id}`}>
                  <StoragesListItem
                    label={name}
                    icon={getIconByVariant(type)}
                    // checked={favoriteBy === 'me'}
                  />
                </A>
                {i < data.length - 1 && (
                  <Divider key={`divider${_id}`} />
                )}
              </React.Fragment>
            ))}
        </div>
      </LayoutStandard>
      <Pagination
        before={previousPageCursor}
        after={nextPageCursor}
        previousPage={previousPage}
        nextPage={nextPage}
      />
      <Modal
        isVisible={isSearchModalVisible}
        closeModal={closeSearchModal}
      >
        <ModalSearchStorage closeModal={handleStorageClicked} />
      </Modal>
    </>
  )
}

const Storages = withQuery<StorageSpacePage>({
  query: GET_ROOT_STORAGES,
  loader: EkLoader,
})(
  withMutation<Storage[], StorageSpaceInput>(CREATE_STORAGE_SPACE)(
    _Storages
  )
)

export { Storages }
