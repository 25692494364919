/* eslint-disable max-lines */
// TODO: Radera detta när GraphQL implementerats, filen blir för lång med spökdatan vilket är varför disable är tillagt.
import React, { FC, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { SimpleHeader } from '@components/header'

import { useQueryParam } from '@hooks'

import { Button, ButtonSize, TextField } from '@pure-components/input'
import { COLORS } from '@styles'

import { withQuery, WithQueryExtension } from '@components/with-query'
import { OrderPage, Order, ORDER_STATUS } from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { LayoutStandard } from '@layouts'

import { AppRoutes } from '@router'
import { EkList, EkListCell, EkListRow } from '@components/ek-list'
import { GET_ORDERS_BY_STATUS } from '@api/order'
import { cancellableOrderSearch } from '@common/search/search-orders'

interface WorkOrderProps extends RouteComponentProps {
  id?: string
}

const _SearchOrder: FC<
  WorkOrderProps & WithQueryExtension<OrderPage>
> = ({ queryData, refetch, id }) => {
  const {
    getOrdersByStatus: { data = [] },
  } = queryData

  data.sort(
    (a, b) =>
      parseInt(a.orderNumber || '0') - parseInt(b.orderNumber || '0')
  )

  const [activeSearch, setActiveSearch] = useState<any>(null)
  const [resultList, setResultList] = useState<Order[]>(data)

  /*const { setChildren, setIsOpen } = useBottomSheet()

  const bottomSheetStyles = css({
    display: 'grid',
    gap: '1em',
    marginTop: '2em',
  })*/

  const [searchFilter, setSearchFilter] = useState('')

  const searchOrders = async (query: string) => {
    //console.log('performing search for ', query)
    const search = cancellableOrderSearch(query, ORDER_STATUS.DEFAULT)
    setActiveSearch(search)

    const orders = await search.orders
    //console.log(orders)
    setResultList(orders)
    setActiveSearch(null)
  }

  const handleSearchChange = async (event: any) => {
    setSearchFilter(event.target.value)
  }

  const handleSearchButton = async (event: any) => {
    if (activeSearch !== null) {
      console.log('aborting active search')
      activeSearch.abort()
    }

    if (searchFilter.length >= 1) {
      await searchOrders(searchFilter)
    } else {
      //setItemList(data)
      setActiveSearch(null)
    }
  }

  const handleKeyPress = async (
    event: React.KeyboardEvent<Element>
  ) => {
    if (event.key === 'Enter') {
      await handleSearchButton(event)
    }
  }

  const view = useQueryParam('view')

  const onRowClick = (clickedOrder: Order) => {
    navigate(`${AppRoutes.WorkingOrders}/${clickedOrder._id}`)
  }

  const searchFieldContainerStyles = css({
    height: '70px',
    width: '100%',
    overflow: 'hidden !important',
    background: COLORS.gray[0],
  })

  const searchFieldStyles = css({
    padding: '12px 12px',
    width: '100%',
    background: COLORS.gray[0],
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr',
    gridTemplateAreas: '"input button"',
    columnGap: '0.7rem',
    alignItems: 'baseline',
    '.searchinput': {
      gridArea: 'input',
    },
    '.searchbutton': {
      gridArea: 'button',
      width: '60px',
    },
  })

  const styles = css({
    display: 'grid',
    color: COLORS.gray[90],
    padding: view === 'card' ? '24px' : '0',
    gap: '1em',
    height: resultList.length < 8 ? '100vh' : 'auto',
    '.storage-info': {
      padding: '0 15px 10px 15px',
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: 'auto 1fr',
      marginTop: '10px',
      borderBottom: '1px lightgray solid',
      '.icon': {
        display: 'grid',
        minHeight: 24,
        placeContent: 'center center',
        fontSize: '24px',
      },
      '.description': {
        paddingTop: '6px',
      },
    },
    '.button-wrapper-selected-article': {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: '0.5em',
      padding: '1rem 1.5rem 0 1.5rem',
      borderTop: '1px lightgray solid',
      '.article-info': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '.button-wrapper-reset': {
      display: 'flex',
      gap: '0.5em',
      padding: '0 24px',
      '.article-info': {},
    },
  })

  // @ts-ignore
  return (
    <>
      <SimpleHeader text={`Sök Order`}>
        {/*
        <MoveToInbox onClick={() => openMoveToStorageModal()} />
        <Search onClick={() => openMoveToOrderModal()} />
      */}
      </SimpleHeader>
      <div css={searchFieldContainerStyles}>
        <section css={searchFieldStyles}>
          <div className="searchinput">
            <TextField
              placeholder="Sök på beskrivning, ordernummer"
              inputType="search"
              value={searchFilter}
              onChange={(e) => handleSearchChange(e)}
              onKeyUp={(e) => handleKeyPress(e)}
            />
          </div>
          <div className="searchbutton">
            <Button
              disabled={
                activeSearch !== null || searchFilter.length < 1
              }
              size={ButtonSize.Small}
              onClick={handleSearchButton}
            >
              Sök
            </Button>
          </div>
        </section>
      </div>
      <LayoutStandard scroll={true}>
        <section css={styles}>
          <>
            {activeSearch !== null ? (
              <EkLoader />
            ) : (
              <EkList>
                {resultList.map((order: Order, i) => (
                  <EkListRow
                    key={order._id}
                    onClick={(e) => onRowClick(order)}
                  >
                    <EkListCell>
                      <p className="itemName">
                        {order.projectName
                          ? order.projectName + ','
                          : ''}
                        {order.serviceAddress}
                      </p>
                      <p className="itemNumber">
                        {order.orderNumber}
                      </p>
                    </EkListCell>
                  </EkListRow>
                ))}
              </EkList>
            )}
          </>
        </section>
      </LayoutStandard>
    </>
  )
}

const SearchOrder = withQuery<OrderPage>({
  query: GET_ORDERS_BY_STATUS,
  variables: { _size: 500, status: ORDER_STATUS.DEFAULT },
  loader: EkLoader,
})(_SearchOrder)

export { SearchOrder }
