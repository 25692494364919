import React, { FC } from 'react'
import { STORAGE_SPACE_TYPE } from '@types'
import { Button } from '@pure-components/input'
import { CompareArrows } from '@material-ui/icons'
import { css } from '@emotion/react'

interface ArticleInventoryListItemProps {
  storageSpaceName?: string
  type?: STORAGE_SPACE_TYPE
  quantity?: number
}

const ArticleInventoryListItem: FC<ArticleInventoryListItemProps> = ({
  storageSpaceName = '',
  type = STORAGE_SPACE_TYPE.DEFAULT,
  quantity = 0,
}) => {
  const styling = css({
    display: 'grid',
    gridTemplateColumns: 'auto 48px 64px',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridTemplateAreas: '"name quanity button" "type quantity button"',
    alignContent: 'center',
    '.name': {
      gridArea: 'name',
    },
    '.type': {
      gridArea: 'type',
    },
    '.quantity': {
      gridArea: 'quantity',
    },
    '.button': {
      gridArea: 'button',
      justifyContent: 'end',
      padding: 24,
    },
  })

  return (
    <article css={styling}>
      <div className="name">{storageSpaceName}</div>
      <div className="type">{type.toString()}</div>
      <div className="quantity">{quantity} st</div>
      <div className="button">
        <Button fullWidth={false}>
          <CompareArrows />
        </Button>
      </div>
    </article>
  )
}

export { ArticleInventoryListItem }
