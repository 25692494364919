import { STORAGE_SPACE_TYPE } from '@src/types'

const labelByVariant: Map<STORAGE_SPACE_TYPE, string> = new Map([
  [STORAGE_SPACE_TYPE.DEFAULT, 'Okänd'],
  [STORAGE_SPACE_TYPE.FORDON, 'Bil'],
  [STORAGE_SPACE_TYPE.HYLLA, 'Hylla'],
  [STORAGE_SPACE_TYPE.LADA, 'Låda'],
  [STORAGE_SPACE_TYPE.LAGER, 'Lager'],
  [STORAGE_SPACE_TYPE.YTA, 'Yta'],
])

const getLabelByVariant = (
  variant: STORAGE_SPACE_TYPE = STORAGE_SPACE_TYPE.DEFAULT
) => {
  return labelByVariant.get(variant) ?? 'Okänd'
}

export { getLabelByVariant }
