import React, { FC, MouseEvent } from 'react'
import { resetButton } from '@styles'
import { Cross } from '@pure-components/graphics'
import { css } from '@emotion/react'

interface CrossButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  background?: string
  color?: string
  size?: string
}

const CrossButton: FC<CrossButtonProps> = ({
  onClick,
  background = 'black',
  color = 'white',
  size = '24px',
}) => {
  const styles = css([
    resetButton,
    {
      background: background,
      padding: `calc(${size} / 3)`,
      borderRadius: '9999px',
    },
  ])

  return (
    <button css={styles} onClick={onClick}>
      <Cross color={color} size={`calc(${size} / 3 * 2)`} />
    </button>
  )
}

export { CrossButton }
