/* eslint-disable max-lines */
// This is likely because of all the GQL-fakedata. I suppose this needs to be reviewed. TODO: Review this.
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@components/header'
import {
  Button,
  ButtonVariant,
  TextField,
  TextFieldInput,
} from '@pure-components/input'
import { Radio, RadioGroup } from '@pure-components/input'
import { Caption, Subtitle1 } from '@components/base-extended'
import { useIsAdmin, useSnackbar } from '@hooks'
import { useModal } from '@components/modal'
import { client } from '@api/client'
import { CREATE_ACCOUNT_SMS } from '@api/account/account-mutations'
import { Snackbar } from '@pure-components/feedback'
import { AppRoutes } from '@router'

interface UserProps extends RouteComponentProps {}

const _NewUser: FC<UserProps> = () => {
  const { addAlert } = useSnackbar()

  /*eslint-disable */
  const isAdmin = useIsAdmin()
  /*eslint-enable */

  /*eslint-disable */
  const { isVisible, closeModal, openModal } = useModal()
  /*eslint-enable */

  const [inputFirstName, setFirstName] = useState<string>('')
  const [inputLastName, setLastName] = useState<string>('')
  const [inputRole, setRole] = useState<string>('user')
  const [inputPhoneNumber, setPhoneNumber] = useState<string>('')

  const handleSaveButton = async () => {
    addAlert(<Snackbar text={'Saving...'} />)
    try {
      const userId = await createUser()
      addAlert(<Snackbar text={'Saved'} />)

      navigate(`${AppRoutes.Users}/${userId}`)
    } catch (e) {
      addAlert(<Snackbar text={'Something went wrong'} />)
    }
  }

  const createUser = async (): Promise<string> => {
    const mutation = CREATE_ACCOUNT_SMS
    //console.log('query:', query, variables)
    const result = await client.mutate({
      mutation,
      variables: {
        data: {
          firstName: inputFirstName,
          lastName: inputLastName,
          role: inputRole,
          phoneNumber: inputPhoneNumber,
          password: '00000', //replaced by a randomly generated password on the server.
        },
      },
    })
    //console.log('user update result', result)
    return result.data?.createAccountSms.user?._id || ''
  }

  /*const updateUser = async () => {
    try {
      console.log(inputFirstName)
      console.log(inputLastName)
      console.log(inputRole)

      if (id) {
        const mutation = getUpdateUserMutation(id, {
          firstName: inputFirstName,
          lastName: inputLastName,
          role: inputRole,
        })
        //console.log('query:', query, variables)
        const result = await client.mutate({
          mutation,
        })
        console.log('user update result', result)
      }
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  const updateAccount = async (data: AccountUpdateInput) => {
    try {
      console.log(data)

      if (account) {
        const mutation = UPDATE_ACCOUNT
        //console.log('query:', query, variables)
        const result = await client.mutate({
          mutation,
          variables: {
            id: account._id,
            data,
          },
        })
        console.log('user update result', result)
      }
    } catch (e) {
      console.log(e)
      addAlert(<Snackbar text={'Something went wrong'} />)
      throw e
    }
  }*/

  const styles = css({
    display: 'grid',
    rowGap: '12px',
    padding: '1em',
    paddingTop: '2em',
    '.radiobuttons': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, auto)',
      gap: '1em',
      placeItems: 'center start',
    },
    '.radio': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, auto)',
      gap: '0.5em',
      placeItems: 'center center',
    },
  })

  interface FormFields {
    id: string
    inputType: TextFieldInput
    value: string
    placeholder: string
    setter: Dispatch<SetStateAction<string>>
  }

  const formData: FormFields[] = [
    {
      id: 'firstname',
      inputType: 'text',
      value: inputFirstName,
      placeholder: 'Förnamn',
      setter: setFirstName,
    },
    {
      id: 'lastname',
      inputType: 'text',
      value: inputLastName,
      placeholder: 'Efternamn',
      setter: setLastName,
    },
    {
      id: 'phonenumber',
      inputType: 'tel',
      value: inputPhoneNumber,
      placeholder: 'Mobilnummer / inlogg',
      setter: setPhoneNumber,
    },
  ]

  return (
    <>
      <SimpleHeader text={`Ny Användare`} />
      <LayoutStandard>
        <section css={styles}>
          {formData.map((props) => (
            <div className="textfield" key={`field_${props.id}`}>
              <TextField
                {...props}
                variant="primary"
                multiline={false}
                helperText=""
                key={props.placeholder}
                onChange={(e) => {
                  props.setter(
                    (e as ChangeEvent<HTMLInputElement>).target.value
                  )
                }}
              />
            </div>
          ))}
        </section>
        <section css={styles}>
          <Subtitle1>Roll</Subtitle1>
          <RadioGroup
            value={inputRole}
            onChange={(val) => {
              setRole(val)
            }}
          >
            <div className="radiobuttons">
              <div className="radio">
                <Radio value={'admin'} />
                <Caption>Administratör</Caption>
              </div>
              <div className="radio">
                <Radio value={'user'} />
                <Caption>Vanlig användare</Caption>
              </div>
            </div>
          </RadioGroup>
        </section>
        <section css={styles}>
          <Button
            variant={ButtonVariant.Primary}
            onClick={handleSaveButton}
            disabled={inputPhoneNumber.length < 3}
          >
            Spara
          </Button>
        </section>
      </LayoutStandard>
    </>
  )
}

const NewUser = _NewUser

export { NewUser }
