import { css } from '@emotion/react'

const resetButton = css({
  textDecoration: 'none',
  border: 'none',
  display: 'inline-block',
  cursor: 'pointer',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  padding: 0,
  background: 'transparent',
})

export { resetButton }
