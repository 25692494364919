import { css } from '@emotion/react'
import React, { FC } from 'react'
import { COLORS, TEXT_STYLE, resetButton } from '@styles'

// TODO: Fix active and focus state

interface SnackbarProps {
  onAction?: () => void
  actionText?: string
  background?: string
  textColor?: string
  actionColor?: string
  boxShadow?: string
  text: string
}

const Snackbar: FC<SnackbarProps> = ({
  text = 'Snackbar text',
  actionText = 'Dismiss',
  onAction = () => {},
  background = COLORS.gray[70],
  textColor = COLORS.gray[0],
  actionColor = COLORS.orange[30],
  boxShadow = '0px 0px 18px 0px rgba(0,0,0,0.2)',
}) => {
  const hasAction = actionText.length > 0

  const style = css({
    background,
    borderRadius: '3px',
    display: 'grid',
    gridTemplateColumns: 'min-content min-content',
    width: 'min-content',
    alignItems: 'center',
    boxShadow,
  })

  const textStyle = css([
    TEXT_STYLE.caption[1],
    {
      color: textColor,
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '18px',
      paddingRight: hasAction ? 0 : '18px',
      display: 'inline-block',
      width: '70vw',
      maxWidth: '600px',
    },
  ])

  const buttonStyle = css([
    resetButton,
    {
      padding: '12px 18px',
      height: '100%',
      color: actionColor,
    },
  ])

  return (
    <div css={style}>
      <span css={textStyle}>{text}</span>
      {hasAction ? (
        <button css={buttonStyle} onClick={onAction}>
          {actionText}
        </button>
      ) : null}
    </div>
  )
}

export { Snackbar }
