import React, { ReactNode, useState } from 'react'
import { BottomSheetContext } from './bottom-sheet-context'

type BottomSheetProviderProps = {
  children: ReactNode
}

const BottomSheetProvider = ({
  children = [],
}: BottomSheetProviderProps) => {
  const [sheetChildren, setChildren] = useState<ReactNode>(<div />)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <BottomSheetContext.Provider
      value={{
        isOpen,
        setIsOpen,
        children: sheetChildren,
        setChildren,
      }}
    >
      {children}
    </BottomSheetContext.Provider>
  )
}

export { BottomSheetProvider }
