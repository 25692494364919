import React, { FC, ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { CrossButton } from '@pure-components/input/cross-button'
import { css } from '@emotion/react'
import { ShadowOverlay } from '@pure-components/surface'

interface ModalProps {
  isVisible: boolean
  closeModal?: () => void
  children?: ReactNode
  backgroundColor?: string
  CrossButtonBackgroundColor?: string
  borderRadius?: string
  shadowColor?: string
  shadowOpacity?: number
  autoHeight?: boolean
}

/**
 * A pop-up window.
 * Can be placed anywhere in the tree.
 * Use the hook useModal to control whether it is shown or not.
 */
const Modal: FC<ModalProps> = ({
  isVisible,
  closeModal = () => {},
  children = [],
  backgroundColor = 'white',
  CrossButtonBackgroundColor = 'black',
  borderRadius = '5px',
  shadowColor = 'black',
  shadowOpacity = 0.5,
  autoHeight = false,
}) => {
  const [isCenter, setIsCenter] = useState(false)

  useEffect(() => {
    setTimeout(() => setIsCenter(isVisible), 10)
  }, [isVisible])

  const onTransitionEnd = () => {
    if (!isCenter) {
      closeModal()
    }
  }

  const close = () => {
    setIsCenter(false)
  }

  const transition = 'all 200ms ease'

  const modalStyles = css({
    background: backgroundColor,
    borderRadius: borderRadius,
    padding: '16px',
    maxWidth: '900px',
    top: '50%',
    left: '50%',
    position: 'fixed',
    transform: 'translate(-50%, 100vh)',
    opacity: 0,
    overflow: 'hidden',
    height: autoHeight ? 'auto' : '90vh',
    width: '85vw',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    boxSizing: 'content-box',
    transition,
    '&.center': {
      transform: 'translate(-50%,-50%)',
      opacity: 1,
    },
  })

  const topPanel = css({
    display: 'grid',
    justifyContent: 'right',
  })

  return isVisible
    ? createPortal(
        <ShadowOverlay
          onClick={close}
          color={shadowColor}
          transition={transition}
          opacity={isCenter ? shadowOpacity : 0}
        >
          <div
            css={modalStyles}
            className={isCenter ? 'center' : ''}
            onTransitionEnd={onTransitionEnd}
          >
            <div css={topPanel}>
              <CrossButton
                onClick={close}
                background={CrossButtonBackgroundColor}
                color={backgroundColor}
              />
            </div>
            {children}
          </div>
        </ShadowOverlay>,
        document.body
      )
    : null
}

export { Modal }
