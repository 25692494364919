import React, { FC } from 'react'
import { css } from '@emotion/react'
import { TEXT_STYLE, COLORS } from '@styles'

interface SelectedItemsItemProps {
  name?: string
  itemId?: string
  quantity?: number | string
}

const SelectedItemsItem: FC<SelectedItemsItemProps> = ({
  name = '',
  itemId = '',
  quantity = 0,
}) => {
  const listStyle = css([TEXT_STYLE.list[1]], {
    width: '100%',
    color: COLORS.gray[90],
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: '0.5em',
    margin: '6px 0',
    padding: '0 24px',
    gridTemplateAreas: '"name amount" "number amount"',

    '.name': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'name',
      },
    ],
    '.number': [
      TEXT_STYLE.caption[1],
      {
        gridArea: 'number',
      },
    ],
    '.amount': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'amount',
        placeSelf: 'center end',
      },
    ],
    '.buttons': {
      gridArea: 'buttons',
      placeSelf: 'center end',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '10px',
    },
  })
  return (
    <article css={listStyle} className="row">
      <p className="name">{name}</p>
      <p className="number">{itemId}</p>
      <p className="amount">{quantity}</p>
    </article>
  )
}

export { SelectedItemsItem }
