function contrastImage(imageData: any, contrast: number) {
  const data = imageData.data
  const factor = (259 * (contrast + 255)) / (255 * (259 - contrast))

  for (let i = 0; i < data.length; i += 4) {
    data[i] = factor * (data[i] - 128) + 128
    data[i + 1] = factor * (data[i + 1] - 128) + 128
    data[i + 2] = factor * (data[i + 2] - 128) + 128
  }
  return imageData
}

const whiteoutImage = (imageData: any, threshold: number) => {
  const data = imageData.data

  for (let i = 0; i < data.length; i += 4) {
    if (
      data[i] < threshold &&
      data[i + 1] < threshold &&
      data[i + 2] < threshold
    ) {
      data[i] = data[i] * 0.2
      data[i + 1] = data[i + 1] * 0.2
      data[i + 2] = data[i + 2] * 0.2
    } else {
      data[i] = Math.min(data[i] * 1.5, 255)
      data[i + 1] = Math.min(data[i + 1] * 1.5, 255)
      data[i + 2] = Math.min(data[i + 2] * 1.5, 255)
    }
  }
  return imageData
}

const cloneData = (imageData: ImageData) => {
  return new ImageData(
    new Uint8ClampedArray(imageData.data),
    imageData.width,
    imageData.height
  )
}

const processImage = (
  imageData: any,
  contrast: number,
  threshold: number
): Promise<ImageData> => {
  return new Promise((resolve, reject) => {
    resolve(
      contrastImage(
        whiteoutImage(cloneData(imageData), threshold),
        contrast
      )
    )
  })
}

const getImageData = (src: string): Promise<ImageData> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      //const canvas = createCanvas(img.width, img.height)
      const ctx = canvas.getContext('2d')

      const mp = img.width * img.height
      let factor = 1
      if (mp > 3000000) {
        //console.log('scale down')
        factor = 3000000 / mp
      }
      ctx!.scale(factor, factor)
      ctx!.drawImage(img, 0, 0)
      resolve(
        ctx!.getImageData(
          0,
          0,
          img.width * factor,
          img.height * factor
        )
      )
    }
    img.src = src
  })
}

const ImageDataToBlob = function (imageData: any): Promise<any> {
  const w = imageData.width
  const h = imageData.height
  const canvas = document.createElement('canvas')
  canvas.width = w
  canvas.height = h
  const ctx = canvas.getContext('2d')
  ctx!.putImageData(imageData, 0, 0) // synchronous

  return new Promise((resolve) => {
    canvas.toBlob(resolve) // implied image/png format
  })
}

export { processImage, getImageData, ImageDataToBlob }
