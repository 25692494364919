import { createContext } from 'react'

type RoleContextType = {
  isAdmin: boolean
  role: string
  userId: string
  setIsAdmin: (value: boolean) => void
  setRole: (value: string) => void
  setUserId: (value: string) => void
}

const RoleContext = createContext<RoleContextType>({
  isAdmin: false,
  setIsAdmin: () => {},
  role: 'user',
  setRole: () => {},
  userId: '',
  setUserId: () => {},
})

export { RoleContext }
