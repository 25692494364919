import React, { ChangeEvent, FC, ReactNode } from 'react'
import { Checkbox } from '@pure-components/input/checkbox'
import { COLORS } from '@styles'
import { Remove } from '@material-ui/icons'

interface EkCheckboxProps {
  checked?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  icon?: ReactNode
}

const EkCheckbox: FC<EkCheckboxProps> = ({
  checked = false,
  onChange = () => {},
  disabled,
  icon = <Remove />,
}) => {
  return (
    <Checkbox
      checked={checked}
      background={checked ? COLORS.gray[90] : COLORS.gray[0]}
      borderColor={COLORS.gray[90]}
      iconColor={COLORS.gray[0]}
      icon={<Remove />}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export { EkCheckbox }
