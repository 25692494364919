/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const CREATE_ORDER = gql`
  mutation CreateOrder(
    $description: String!
    $orderNumber: String!
    $sdfId: String!
    $createdDate: String!
    $serviceDate: String!
    $customerPhone: String
    $projectNumber: String
    $projectName: String
    $serviceAddress: String!
    $invoiceAddress: String!
    $status: ORDER_STATUS!
  ) {
    createOrder(
      data: {
        description: $description
        orderNumber: $orderNumber
        sdfId: $sdfId
        createdDate: $createdDate
        serviceDate: $serviceDate
        customerPhone: $customerPhone
        projectNumber: $projectNumber
        projectName: $projectName
        serviceAddress: $serviceAddress
        invoiceAddress: $invoiceAddress
        status: $status
      }
    ) {
      _id

      description
      orderNumber
      sdfId
      createdDate
      serviceDate
      customerPhone
      projectNumber
      projectName
      serviceAddress
      invoiceAddress
      status
      assignedUser {
        _id
        firstName
        lastName
      }
      items {
        data {
          _id

          item {
            _id

            number
            sdfId
            name
            description
            tags {
              data {
                _id
                name
              }
              before
              after
            }
            images
            defaultImage
            unitOfMeasure
            supplier
            netPrice
            grossPrice
            sellingPrice
          }
          quantity
        }
      }
    }
  }
`
const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $id: ID!
    $description: String!
    $orderNumber: String!
    $sdfId: String!
    $createdDate: String!
    $serviceDate: String!
    $customerPhone: String!
    $projectNumber: String!
    $projectName: String!
    $serviceAddress: String!
    $invoiceAddress: String!
    $status: ORDER_STATUS!
    $assignedUser: OrderAssignedUserRelation!
  ) {
    updateOrder(
      id: $id
      data: {
        description: $description
        orderNumber: $orderNumber
        sdfId: $sdfId
        createdDate: $createdDate
        serviceDate: $serviceDate
        customerPhone: $customerPhone
        projectNumber: $projectNumber
        projectName: $projectName
        serviceAddress: $serviceAddress
        invoiceAddress: $invoiceAddress
        status: $status
        assignedUser: $assignedUser
      }
    ) {
      _id

      description
      orderNumber
      sdfId
      createdDate
      serviceDate
      customerPhone
      projectNumber
      projectName
      serviceAddress
      invoiceAddress
      status
      assignedUser {
        _id
        firstName
        lastName
      }
      items {
        data {
          _id

          item {
            _id

            number
            sdfId
            name
            description
            tags {
              data {
                _id
                name
              }
              before
              after
            }
            images
            defaultImage
            unitOfMeasure
            supplier
            netPrice
            grossPrice
            sellingPrice
          }
          quantity
        }
      }
    }
  }
`

const UPDATE_ORDER_PARTIAL = gql`
  mutation partialUpdateOrder(
    $id: ID!
    $description: String
    $orderNumber: String
    $sdfId: String
    $createdDate: String
    $serviceDate: String
    $customerPhone: String
    $projectNumber: String
    $projectName: String
    $serviceAddress: String
    $invoiceAddress: String
    $status: ORDER_STATUS
    $assignedUser: OrderAssignedUserRelation
  ) {
    partialUpdateOrder(
      id: $id
      data: {
        description: $description
        orderNumber: $orderNumber
        sdfId: $sdfId
        createdDate: $createdDate
        serviceDate: $serviceDate
        customerPhone: $customerPhone
        projectNumber: $projectNumber
        projectName: $projectName
        serviceAddress: $serviceAddress
        invoiceAddress: $invoiceAddress
        status: $status
        assignedUser: $assignedUser
      }
    ) {
      _id

      description
      orderNumber
      sdfId
      createdDate
      serviceDate
      customerPhone
      projectNumber
      projectName
      serviceAddress
      invoiceAddress
      status
      assignedUser {
        _id
        firstName
        lastName
      }
      items {
        data {
          _id

          item {
            _id

            number
            sdfId
            name
            description
            tags {
              data {
                _id
                name
              }
              before
              after
            }
            images
            defaultImage
            unitOfMeasure
            supplier
            netPrice
            grossPrice
            sellingPrice
          }
          quantity
        }
      }
    }
  }
`

const ASSIGN_ORDER_TO_USER = gql`
  mutation partialUpdateOrder(
    $id: ID!
    $assignedUser: OrderAssignedUserRelation!
  ) {
    partialUpdateOrder(
      id: $id
      data: { assignedUser: $assignedUser }
    ) {
      _id

      description
      orderNumber
      sdfId
      createdDate
      serviceDate
      customerPhone
      projectNumber
      projectName
      serviceAddress
      invoiceAddress
      status
      assignedUser {
        _id
        firstName
        lastName
      }
      items {
        data {
          _id

          item {
            _id

            number
            sdfId
            name
            description
            tags {
              data {
                _id
                name
              }
              before
              after
            }
            images
            defaultImage
            unitOfMeasure
            supplier
            netPrice
            grossPrice
            sellingPrice
          }
          quantity
        }
      }
    }
  }
`

export {
  CREATE_ORDER,
  UPDATE_ORDER,
  UPDATE_ORDER_PARTIAL,
  ASSIGN_ORDER_TO_USER,
}
