/* eslint-disable */
// TODO: fix the above
import { css } from '@emotion/react'

const TEXT_STYLE = {
  heading: {
    '1': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '96px',
      lineHeight: '112px',
      letterSpacing: '0em',
    }),
    '2': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '60px',
      lineHeight: '72px',
      letterSpacing: '0em',
    }),
    '3': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '48px',
      lineHeight: '56px',
      letterSpacing: '0em',
    }),
    '4': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '36px',
      letterSpacing: '0em',
    }),
    '5': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '0em',
    }),
    '6': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0em',
    }),
  },
  subtitle: {
    '1': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0em',
    }),
    '2': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0em',
    }),
  },
  body: {
    '1': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0em',
    }),
    '2': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0em',
    }),
  },
  button: {
    '1': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
    }),
  },
  caption: {
    '1': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    }),
  },
  overline: {
    '1': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '16px',
    }),
  },
  list: {
    '1': css({
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0px',
    }),
  },
}

export { TEXT_STYLE }
