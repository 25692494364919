/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { truncateString } from '@common'
import {
  FindStorage,
  styling,
} from '@components/pages-components/move'
import { LayoutStandard } from '@layouts'
import { A, H6 } from '@components/base'
import { Caption, ButtonText } from '@components/base-extended'
import { SimpleHeader } from '@components/header'
import { ArrowForward, Search } from '@material-ui/icons'
import {
  TextField,
  Button,
  ButtonVariant,
} from '@pure-components/input'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import {
  WithMutationExtension,
  withMutation,
} from '@components/with-mutation'
import { WithQueryExtension, withQuery } from '@components/with-query'
import {
  InventoryItem,
  InventoryItemInput,
  STORAGE_SPACE_TYPE,
} from '@types'
import {
  CREATE_INVENTORY_ITEM,
  GET_INVENTORY_ITEM,
} from '@api/inventory-item'
import { StorageCard } from '@components/pages-components/storage'
import { getIconByVariant } from '@components/pages-components/storage/common'
import { AppRoutes } from '@router'
import { useModal, Modal } from '@components/modal'

interface MoveProps extends RouteComponentProps {
  id?: string
}

const _Move: FC<
  MoveProps &
    WithMutationExtension<InventoryItem[], InventoryItemInput> &
    WithQueryExtension<InventoryItem>
> = ({ queryData, id }) => {
  const {
    findInventoryItemByID: { item, storageSpace, quantity },
  } = queryData

  const { isVisible, closeModal, openModal } = useModal()

  // const handleTargetStorage = async (targetId: string) => {
  //   const targetStorage = await runQuery(GET_STORAGE_SPACE, {
  //     id: targetId,
  //   })
  //   // do soemthing with target storage
  // }

  const [_quantity, set_Quantity] = useState(quantity)
  const [selectedStorageId, setSelectedStorageId] = useState('')
  const [selectedStorageName, setSelectedStorageName] = useState('')
  const [selectedStorageType, setSelectedStorageType] = useState<
    STORAGE_SPACE_TYPE
  >(STORAGE_SPACE_TYPE.DEFAULT)

  const handleSelectStorage = (
    _id: string,
    name: string,
    type: STORAGE_SPACE_TYPE
  ) => {
    setSelectedStorageId(_id)
    setSelectedStorageName(name)
    setSelectedStorageType(type)
    closeModal()
  }

  //TODO: figure out of this actually does something
  useEffect(() => {
    console.log('it worked!')
    console.log('selected id is', selectedStorageId)
    console.log('selected name is', selectedStorageName)
  }, [selectedStorageId, selectedStorageName])

  return (
    <>
      <SimpleHeader text={`Flytta ${truncateString(item.name, 18)}`}>
        <Search />
      </SimpleHeader>
      <LayoutStandard>
        <section css={styling}>
          <>
            <section className="header">
              <Caption>Flytta artikel</Caption>
              <H6>{item.name}</H6>
            </section>
            <section className="changeamount">
              <div className="heading">
                <ButtonText>Antal</ButtonText>
              </div>
              <div className="items">
                <div className="item left">
                  <TextField
                    inputType="number"
                    max={quantity}
                    min={0}
                    name="quantity"
                    value={
                      _quantity > quantity || _quantity < 0
                        ? quantity
                        : _quantity
                    }
                    onChange={(e) =>
                      set_Quantity(
                        Number((e.target as HTMLInputElement).value)
                      )
                    }
                  />
                </div>
                <div className="item right">
                  <Button
                    onClick={() => {
                      set_Quantity(quantity)
                    }}
                  >
                    Max antal
                  </Button>
                </div>
              </div>
            </section>
            <section className="storages">
              <div className="right">
                <ButtonText>från</ButtonText>
              </div>
              <div className="center">&nbsp;</div>
              <div className="left">
                <ButtonText>till</ButtonText>
              </div>
              <div className="right">
                <StorageCard
                  label={storageSpace.name}
                  icon={getIconByVariant(storageSpace.type)}
                />
              </div>
              <div className="center">
                <ArrowForward />
              </div>
              <div className="left">
                <StorageCard
                  variant="action"
                  label={selectedStorageName}
                  icon={getIconByVariant(selectedStorageType)}
                  onClick={openModal}
                />
              </div>
            </section>
            <section className="actions">
              <A to={`${AppRoutes.Storages}/${storageSpace._id}`}>
                <Button variant={ButtonVariant.Outlined}>
                  Avbryt
                </Button>
              </A>
              <Button variant={ButtonVariant.Primary}>Spara</Button>
            </section>
          </>
        </section>
        <Modal isVisible={isVisible} closeModal={closeModal}>
          <FindStorage saveFunction={handleSelectStorage} />
        </Modal>
      </LayoutStandard>
    </>
  )
}

const Move = withQuery<InventoryItem>({
  query: GET_INVENTORY_ITEM,
  loader: EkLoader,
})(
  withMutation<InventoryItem[], InventoryItemInput>(
    CREATE_INVENTORY_ITEM
  )(_Move)
)

export { Move }

//TODO: Sätt rätt query och mutation (flytta artikel)
/* Så vi ska alltså DELETE FROM oldStorage PARAMS 
    det id som ska påverkas kommer från adressfältet
    Och CREATE NEW STORAGE newStorage NEWPARAMS (baserat på vad som skrivs här i denna vyn)
*/
