/* eslint-disable max-lines */
import React, { FC, useState } from 'react'
import { H5 } from '@components/base'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { RouteComponentProps } from '@reach/router'
import { OrderPage, Tag, TagPage } from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { css } from '@emotion/react'
import { Button, TextField } from '@pure-components/input'
import { GET_TAGS } from '@api/tag'
import { Chip } from '@pure-components/data-display'
import { COLORS } from '@styles'

interface ModalAddTagProps extends RouteComponentProps {
  closeModal?: (tag: Tag) => void
}

const _ModalAddTag: FC<
  ModalAddTagProps & RouteComponentProps & WithQueryExtension<TagPage>
> = ({
  queryData,
  previousPage,
  nextPage,
  closeModal = () => {},
}) => {
  const {
    tags: { data = [] },
  } = queryData

  const [searchFilter, setSearchFilter] = useState<string>('')
  const [selectedTag, setSelectedTag] = useState<null | Tag>(null)

  const styles = css({
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto',
    overflow: 'hidden',
    '.title': {
      marginBottom: '10px',
    },
    '.tags': {
      display: 'flex',
      gap: 10,
      overflowX: 'scroll',
      padding: '0 0 12px 0',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
    },
    '.tags span': {
      fontSize: '1rem',
      padding: '4px 0px',
    },
  })

  const handleSearchChange = (event: any) => {
    setSearchFilter(event.target.value)
    setSelectedTag(null)
  }

  const handleSave = () => {
    const existing =
      selectedTag ||
      data.find(
        (t) => t.name.toLowerCase() === searchFilter.toLowerCase()
      )

    const tag =
      existing ||
      (({
        name: searchFilter.toLowerCase(),
      } as any) as Tag)
    closeModal(tag)
  }

  const onChipClick = (clickedTag: Tag) => {
    setSelectedTag(clickedTag)
    setSearchFilter(clickedTag.name)
    /*if (selectedOrder?.orderNumber === clickedItem.orderNumber)
      setSelectedOrder(null)
    else setSelectedOrder(clickedItem)*/
  }

  const isButtonDisabled = () => {
    return !(searchFilter.length > 0 || selectedTag !== null)
  }

  return (
    <section css={styles}>
      <H5 className="title">Lägg till tagg</H5>
      <TextField
        placeholder="Sök eller skriv nytt taggnamn"
        inputType="search"
        value={searchFilter}
        onChange={(e) => handleSearchChange(e)}
      />

      <div className="tags">
        {data
          .filter(
            (tag: Tag) =>
              tag
                .name!.toLowerCase()
                .indexOf(searchFilter.toLowerCase()) > -1
          )
          .slice(0, 25)
          .map((tag: Tag, i) => (
            <Chip
              key={tag._id}
              background={COLORS.orange[10]}
              text={tag.name}
              onClick={() => {
                onChipClick(tag)
              }}
            />
          ))}
      </div>

      <Button disabled={isButtonDisabled()} onClick={handleSave}>
        Lägg till Tagg
      </Button>
    </section>
  )
}

const ModalAddTag = withQuery<OrderPage>({
  query: GET_TAGS,
  variables: { _size: 5000 },
  loader: EkLoader,
})(_ModalAddTag)

export { ModalAddTag }
