/* eslint-disable max-lines */
import { gql } from '@apollo/client'

const CREATE_ITEM = gql`
  mutation CreateItem(
    $number: String!
    $sdfId: String!
    $name: String!
    $description: String
    $tags: ItemTagsRelation!
    $images: [String!]
    $defaultImage: String
    $unitOfMeasure: UNIT_OF_MEASURE
    $supplier: String
    $netPrice: Float
    $grossPrice: Float
    $sellingPrice: Float
    $notes: String
    $addedManually: Boolean
    $balanceWarning: Float
  ) {
    createItem(
      data: {
        number: $number
        sdfId: $sdfId
        name: $name
        description: $description
        tags: $tags
        images: $images
        defaultImage: $defaultImage
        unitOfMeasure: $unitOfMeasure
        supplier: $supplier
        netPrice: $netPrice
        grossPrice: $grossPrice
        sellingPrice: $sellingPrice
        notes: $notes
        addedManually: $addedManually
        balanceWarning: $balanceWarning
      }
    ) {
      _id
      balanceWarning
      number
      sdfId
      name
      description
      tags {
        data {
          _id
          name
        }
        before
        after
      }
      images
      defaultImage
      unitOfMeasure
      supplier
      netPrice
      grossPrice
      sellingPrice
      addedManually
    }
  }
`

const UPDATE_ITEM_PARTIAL = gql`
  mutation partialUpdateItem(
    $id: ID!
    $number: String
    $sdfId: String
    $name: String
    $description: String
    $tags: ItemTagsRelation
    $images: [String!]
    $defaultImage: String
    $unitOfMeasure: UNIT_OF_MEASURE
    $supplier: String
    $netPrice: Float
    $grossPrice: Float
    $sellingPrice: Float
    $notes: String
    $addedManually: Boolean
    $balanceWarning: Float
  ) {
    partialUpdateItem(
      id: $id
      data: {
        number: $number
        sdfId: $sdfId
        name: $name
        description: $description
        tags: $tags
        images: $images
        defaultImage: $defaultImage
        unitOfMeasure: $unitOfMeasure
        supplier: $supplier
        netPrice: $netPrice
        grossPrice: $grossPrice
        sellingPrice: $sellingPrice
        notes: $notes
        addedManually: $addedManually
        balanceWarning: $balanceWarning
      }
    ) {
      _id
      balanceWarning
      number
      sdfId
      name
      description
      tags {
        data {
          _id
          name
        }
        before
        after
      }
      images
      defaultImage
      unitOfMeasure
      supplier
      netPrice
      grossPrice
      sellingPrice
      addedManually
    }
  }
`

const DELETE_ITEM = gql`
  mutation Deletetem($id: ID!) {
    deleteItem(id: $id) {
      _id
    }
  }
`

export { CREATE_ITEM, UPDATE_ITEM_PARTIAL, DELETE_ITEM }
