import { css } from '@emotion/react'
import {
  Button,
  ButtonVariant,
  TextField,
} from '@pure-components/input'
import { RouteComponentProps, navigate } from '@reach/router'
import { COLORS } from '@styles'
import Logo from '../../static/logo.svg'
import React, { KeyboardEvent, useState } from 'react'
import { loginWithCredentials } from '@common/authenticate'
import { AppRoutes } from '@router'

const Login = (_: RouteComponentProps) => {
  const styling = css({
    background: COLORS.orange[30],
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '.logo-header': {
      display: 'grid',
      placeItems: 'center center',
    },
    '.logo': {
      width: 'clamp(25%, 320px, 50%)',
      margin: '5em 0',
    },
    '.form': {
      display: 'grid',
      rowGap: '12px',
      padding: '1em',
    },
    '@media (min-width: 768px)': {
      '.logo': {
        margin: '2em 0',
      },
    },
  })

  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')

  const validateFields = () => {
    //console.log(phone.length > 0 && password.length > 0)
    return phone.length > 0 && password.length > 0
  }

  const handleLogin = async () => {
    if (validateFields()) {
      try {
        await loginWithCredentials(phone, password)
        await navigate(AppRoutes.Home)
        window.location.reload()
      } catch (e: any) {
        console.log(e.message)
      }
    }
  }

  const handleKeyPress = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      await handleLogin()
    }
  }

  return (
    <article css={styling}>
      <header className="logo-header">
        <img src={Logo} alt="logotyp" className="logo" />
      </header>
      <section className="form">
        <TextField
          placeholder="Mobilnummer!"
          value={phone}
          onChange={(e) =>
            setPhone((e.target as HTMLInputElement).value)
          }
          onKeyUp={(e) => handleKeyPress(e)}
          inputType="tel"
        />
        <TextField
          placeholder="Lösenord"
          value={password}
          onChange={(e) =>
            setPassword((e.target as HTMLInputElement).value)
          }
          onKeyUp={(e) => handleKeyPress(e)}
          inputType="password"
        />
        <Button
          variant={ButtonVariant.Outlined}
          onClick={() => {
            navigate(AppRoutes.Forgotpassword)
          }}
        >
          Glömt lösenord?
        </Button>
        <Button variant={ButtonVariant.White} onClick={handleLogin}>
          Logga in
        </Button>
      </section>
    </article>
  )
}

export { Login }
