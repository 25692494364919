import React, { FC } from 'react'
import { css } from '@emotion/react'
import { Button, ButtonVariant } from '@pure-components/input'
import { navigate } from '@reach/router'
import { AppRoutes } from '@router'

interface ContextMenuProps {
  storageId: string
}

const ContextMenu: FC<ContextMenuProps> = ({ storageId = '' }) => {
  const styles = css({
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    '> *': {
      marginBottom: '0.5em',
    },
  })

  const handleStorageNav = () => {
    navigate(`${AppRoutes.Storages}/${storageId}`)
  }

  return (
    <div css={styles}>
      <Button
        variant={ButtonVariant.Outlined}
        onClick={handleStorageNav}
      >
        Plocka Artiklar
      </Button>
    </div>
  )
}

export { ContextMenu }
