import React, { FC } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { Divider } from '@pure-components/graphics'
import { Search } from '@material-ui/icons'
import { SimpleHeader } from '@components/header'
import { LayoutStandard } from '@layouts'
import { A } from '@components/base'
import { CREATE_ORDER, GET_ORDERS_BY_STATUS } from '@api/order'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import {
  WithMutationExtension,
  withMutation,
} from '@components/with-mutation'
import { WithQueryExtension, withQuery } from '@components/with-query'
import { Order, OrderInput, OrderPage, ORDER_STATUS } from '@types'
import { AppRoutes } from '@router'
import {
  WorkingOrdersListItem,
  WorkingOrdersListView,
} from '@components/pages-components/working-orders'
import { getSortedArray } from '@common/get-sorted-array'

// TODO: This is just the same as Working Orders, there currently exists no flag to determine if an order is archives. Once it does, rework this.
const _ArchivedOrders: FC<
  RouteComponentProps &
    WithMutationExtension<Order[], OrderInput> &
    WithQueryExtension<OrderPage>
> = ({ queryData, addData, previousPage, nextPage }) => {
  const {
    getOrdersByStatus: { data = [] },
  } = queryData

  const handleSearchButton = () => {
    navigate(AppRoutes.ArchivedOrderSearch)
  }

  return (
    <>
      <SimpleHeader text="Arkiverade Order">
        <span
          onClick={handleSearchButton}
          onKeyDown={handleSearchButton}
          role="button"
          tabIndex={-1}
        >
          <Search />
        </span>
      </SimpleHeader>
      <LayoutStandard scroll={true}>
        <section>
          <WorkingOrdersListView>
            {getSortedArray(
              data,
              (a, b) =>
                parseInt(a.orderNumber || '0') -
                parseInt(b.orderNumber || '0')
            ).map((workorder, i) => (
              <React.Fragment key={`workorder${workorder._id}`}>
                <A to={`${AppRoutes.WorkingOrders}/${workorder._id}`}>
                  <WorkingOrdersListItem
                    key={workorder._id}
                    {...workorder}
                  />
                </A>
                {i < data.length - 1 && (
                  <Divider key={`divider${workorder._id}`} />
                )}
              </React.Fragment>
            ))}
          </WorkingOrdersListView>
        </section>
      </LayoutStandard>
    </>
  )
}

const ArchivedOrders = withQuery<OrderPage>({
  query: GET_ORDERS_BY_STATUS,
  variables: { status: ORDER_STATUS.COMPLETED },
  loader: EkLoader,
})(withMutation<Order[], OrderInput>(CREATE_ORDER)(_ArchivedOrders))

export { ArchivedOrders }
