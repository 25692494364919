import React, { FC } from 'react'
import { css } from '@emotion/react'
import { Button, ButtonVariant } from '@pure-components/input'

interface ConfrimDeleteArticleProps {
  articleName: string
  onCancel: () => void
  onConfirm: () => void
}

const ModalConfirmDeleteArticle: FC<ConfrimDeleteArticleProps> = ({
  articleName = '',
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const styling = css({
    display: 'grid',
    gap: '1em',
    alignContent: 'space-between',
    justifyContent: 'center',
    margin: '2em 0',
    '.text': {
      marginBottom: '2em',
    },
    '.margin': {
      marginBottom: '1em',
    },
    '.header': {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  })

  return (
    <div css={styling}>
      <h3 className="header">
        Är du säker på att du vill ta bort artikel: "{articleName}"
        permanent?
      </h3>
      <div>
        <div className="margin">
          <Button variant={ButtonVariant.Outlined} onClick={onCancel}>
            Avbryt
          </Button>
        </div>
        <Button variant={ButtonVariant.Danger} onClick={onConfirm}>
          Ta bort
        </Button>
      </div>
    </div>
  )
}

export { ModalConfirmDeleteArticle }
