import React from 'react'
import { AppRouter } from '@router'
import { EkBottomNav } from '@components/ek-bottom-nav'
import {
  BottomNavProvider,
  BottomSheetProvider,
  LoginProvider,
  SnackbarProvider,
  RoleProvider,
} from '@context'
import { EkBottomSheet } from '@components/ek-bottom-sheet'

const App = () => {
  return (
    <>
      <LoginProvider>
        <RoleProvider>
          <BottomNavProvider>
            <SnackbarProvider>
              <BottomSheetProvider>
                <AppRouter />
                <>
                  <EkBottomSheet />
                  <EkBottomNav />
                </>
              </BottomSheetProvider>
            </SnackbarProvider>
          </BottomNavProvider>
        </RoleProvider>
      </LoginProvider>
    </>
  )
}

export { App }
