/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { COLORS, TEXT_STYLE } from '@styles'
import { InventoryItem } from '@types'
import { EkNumberField } from '@components/ek-number-field'
/*import { A } from '@components/base'
import { AppRoutes } from '@router'
import { Delete, Edit } from '@material-ui/icons'*/

interface StorageListItemWithAmountProps {
  onUpdateAmount?: (quantity: number) => void
  onClick?: () => void
  min?: number
  //onConfirmButtonClick?: (item: Item, quantity: number) => void
}

const StorageListItemWithAmount: FC<
  InventoryItem & StorageListItemWithAmountProps
> = ({
  _id = '',
  quantity = 0,
  min = 0,
  item,
  onUpdateAmount = (quantity: number) => {},
  onClick = () => {},
  //onConfirmButtonClick = (item: Item, quantity: number) => {},
  //onDeleteButtonClick = (item: Item, quantity: number) => {},
}) => {
  const [selectedQuantity, setSelectedQuantity] = useState(0)

  /* eslint-disable */
  useEffect(() => {
    onUpdateAmount(selectedQuantity)
  }, [selectedQuantity])
  /* eslint-enable */

  const listStyle = css([TEXT_STYLE.list[1]], {
    width: '100%',
    color: COLORS.gray[90],
    display: 'grid',
    gridTemplateColumns: '5fr 1fr 1fr',
    gap: '0.5em',
    margin: '6px 0',
    padding: '0 24px',
    gridTemplateAreas:
      '"name amount selectamount" "number amount selectamount"',

    '.name': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'name',
      },
    ],
    '.number': [
      TEXT_STYLE.caption[1],
      {
        gridArea: 'number',
      },
    ],
    '.selectamount': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'selectamount',
        placeSelf: 'center end',
      },
    ],
    '.amount': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'amount',
        placeSelf: 'center end',
      },
    ],
    '.buttons': {
      gridArea: 'buttons',
      placeSelf: 'center end',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '10px',
    },
  })
  return (
    <article css={listStyle} className="row">
      <p
        className="name"
        onClick={onClick}
        onKeyDown={onClick}
        role="presentation"
        tabIndex={-1}
      >
        {item.name}
      </p>
      <p className="number">{item.number}</p>
      <p className="amount">{quantity} st</p>
      <div className="selectamount">
        <EkNumberField
          value={selectedQuantity}
          max={quantity}
          min={min}
          width="60"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            let val = parseFloat(event.target.value)
            //console.log('event value', val)
            if (val > quantity) {
              val = quantity
            } else if (val < min) {
              val = min
            }
            setSelectedQuantity(val)
          }}
        />
      </div>
    </article>
  )
}

export { StorageListItemWithAmount }
