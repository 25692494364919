import { css } from '@emotion/react'
import {
  Button,
  ButtonVariant,
  TextField,
} from '@pure-components/input'
import { RouteComponentProps } from '@reach/router'
import { COLORS } from '@styles'
import Logo from '../../static/logo.svg'
import React from 'react'
import { Subtitle2 } from '@components/base-extended'

const SetPassword = (_: RouteComponentProps) => {
  const styling = css({
    background: COLORS.orange[30],
    height: '100vh',
    '.logo-header': {
      display: 'grid',
      placeItems: 'center center',
    },
    '.logo': {
      width: 'clamp(25%, 320px, 50%)',
      height: '100%',
      margin: '5em 0',
    },
    '.form': {
      display: 'grid',
      rowGap: '12px',
      padding: '1em',
    },
    '.infotext': {
      marginBottom: '3em',
    },
  })

  return (
    <>
      <article css={styling}>
        <header className="logo-header">
          <img src={Logo} alt="logotyp" className="logo" />
        </header>
        <section className="form">
          <Subtitle2 className="infotext">
            Fyll i ett nytt lösenord
          </Subtitle2>
          <TextField
            placeholder="Nytt lösenord"
            inputType="password"
          />
          <TextField
            placeholder="Bekräfta Lösenord"
            inputType="password"
          />

          <Button variant={ButtonVariant.White}>Spara</Button>
        </section>
      </article>
    </>
  )
}

export { SetPassword }
