import React from 'react'
import { BottomSheet } from '@pure-components/navigation/bottom-sheet'
import { useBottomNav, useBottomSheet, useLogin } from '@hooks'
import { COLORS } from '@src/styles'
import { ShadowOverlay } from '@pure-components/surface'

const EkBottomSheet = () => {
  const {
    isOpen: isBottomSheetOpen,
    children,
    setIsOpen: setIsBottomSheetOpen,
  } = useBottomSheet()
  const { height: bottomOffset } = useBottomNav()

  const { isLoggedIn } = useLogin()

  const transition = 'all 200ms ease'
  const shadowOpacity = 0.5

  return (
    <>
      {isLoggedIn && (
        <ShadowOverlay
          onClick={() => setIsBottomSheetOpen(false)}
          color={'black'}
          transition={transition}
          opacity={isBottomSheetOpen ? shadowOpacity : 0}
          pointerEvents={isBottomSheetOpen ? 'auto' : 'none'}
        >
          <BottomSheet
            isOpen={isBottomSheetOpen}
            bottomOffset={bottomOffset}
            onRequestClose={() => setIsBottomSheetOpen(false)}
            padding={'0 0 24px 0'}
            background={COLORS.gray[0]}
          >
            {children}
          </BottomSheet>
        </ShadowOverlay>
      )}
    </>
  )
}

export { EkBottomSheet }
