/* eslint-disable max-lines */
import { css } from '@emotion/react'
import {
  Button,
  ButtonVariant,
  TextField,
} from '@pure-components/input'
import { RouteComponentProps } from '@reach/router'
import { COLORS } from '@styles'
import Logo from '../../static/logo.svg'
import React, { useState } from 'react'
import { Subtitle2 } from '@components/base-extended'
import { Modal, useModal } from '@components/modal'
import { P2 } from '@components/base'
import { RECOVER_ACCOUNT_SMS } from '@api/account'
import { client } from '@api/client'
import { useSnackbar } from '@hooks'
import { Snackbar } from '@pure-components/feedback'

const Forgotpassword = (_: RouteComponentProps) => {
  const { isVisible, closeModal, openModal } = useModal()
  const styling = css({
    background: COLORS.orange[30],
    height: '100vh',
    '.logo-header': {
      display: 'grid',
      placeItems: 'center center',
    },
    '.logo': {
      width: 'clamp(25%, 320px, 50%)',
      height: '100%',
      margin: '5em 0',
    },
    '.form': {
      display: 'grid',
      rowGap: '12px',
      padding: '1em',
    },
    '.infotext': {
      marginBottom: '3em',
    },
  })

  const modalStyling = css({
    display: 'grid',
    rowGap: '1em',
    paddingTop: '1em',
  })

  const { addAlert } = useSnackbar()

  const [phone, setPhone] = useState('')

  const handleKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      await sendRecoveryLink()
    }
  }

  const sendRecoveryLink = async () => {
    try {
      const mutation = RECOVER_ACCOUNT_SMS
      //console.log('query:', query, variables)
      const result = await client.mutate({
        mutation,
        variables: {
          phoneNumber: phone,
        },
      })
      console.log('sms recovery result', result)
      addAlert(<Snackbar text={'Länk skickad'} />)
      openModal()
    } catch (e) {
      console.log(e)
      addAlert(<Snackbar text={'Something went wrong'} />)
    }
  }

  return (
    <>
      <Modal isVisible={isVisible} closeModal={closeModal}>
        <section css={modalStyling}>
          <P2>Vi har skickat en länk till telefonnumret du angav.</P2>
          <P2>
            Klicka på länken i textmeddelandet så omdirigeras du till
            en sida där du kan fylla i ditt nya lösenord.
          </P2>
          <P2>
            Om du inte har fått något sms, kontrollera att du fyllt i
            rätt mobilnummer. Tryck på tillbaka för att försöka igen.
            Om problemet kvarstår, kontakta en administratör.
          </P2>
          <Button onClick={() => closeModal()}>Tillbaka</Button>
        </section>
      </Modal>
      <article css={styling}>
        <header className="logo-header">
          <img src={Logo} alt="logotyp" className="logo" />
        </header>
        <section className="form">
          <TextField
            placeholder="Mobilnummer!"
            value={phone}
            onChange={(e) =>
              setPhone((e.target as HTMLInputElement).value)
            }
            onKeyUp={(e) => handleKeyPress(e)}
            inputType="tel"
          />
          <Subtitle2 className="infotext">
            Fyll i ditt mobilnummer så skickar vi ett sms med en länk
            som du kan använda för att skapa ett nytt lösenord.
          </Subtitle2>

          <Button
            variant={ButtonVariant.White}
            onClick={sendRecoveryLink}
          >
            Skicka återställningslänk
          </Button>
        </section>
      </article>
    </>
  )
}

export { Forgotpassword }
