/* eslint-disable max-lines */
// TODO: Refactor this so the file is sub 100 lines

import React, { FC, useState } from 'react'
import {
  navigate,
  RouteComponentProps,
  useLocation,
} from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@components/header'
//import { AddAPhoto } from '@material-ui/icons'

import { COLORS } from '@styles'

import { P2 } from '@components/base'
import { DELETE_ITEM, GET_ITEM, UPDATE_ITEM_PARTIAL } from '@api/item'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { Item, PartialUpdateItemInput, UNIT_OF_MEASURE } from '@types'
import {
  TextField,
  Button,
  ButtonVariant,
} from '@pure-components/input'
import { parse } from 'query-string'
import { EkSelect } from '@components/ek-select'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { WithQueryExtension, withQuery } from '@components/with-query'
import { Modal, useModal } from '@components/modal'
import { ModalConfirmDeleteArticle } from '@components/pages-components/articles/modal-confirm-delete-article'
import { client } from '@api/client'
import { Divider } from '@pure-components/graphics'

interface ArticleProps extends RouteComponentProps {
  id?: string
}

const _EditArticle: FC<
  ArticleProps &
    WithMutationExtension<Item, PartialUpdateItemInput> &
    WithQueryExtension<Item>
> = ({ addData, id, queryData }) => {
  /*const imageStyles = css({
    padding: '0 24px',
    position: 'relative',
    '.photo': { width: '100%', height: '200px', objectFit: 'cover' },
    '.photobutton': {
      width: 32,
      height: 32,
      position: 'absolute',
      top: 20,
      right: 20,
      background: COLORS.gray[30],
      padding: 3,
      borderRadius: 5,
    },
    marginTop: 12,
    marginBottom: 24,
  })*/

  const {
    findItemByID: {
      name: itemName,
      number: itemNumber,
      description: itemDescription,
      supplier,
      netPrice,
      unitOfMeasure,
    },
  } = queryData

  //console.log('query data', queryData)

  const styles = css({
    padding: '0 24px',
    marginBottom: 12,
    '.info': {
      padding: '6px 24px',
    },
    '.details': {
      color: COLORS.gray[50],
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '0.2em',
      padding: '12px 0',
      placeContent: 'center center',
    },
    '.tempFix': {
      width: 'calc(100% - 1.8em)',
    },
    '.margin-fix': {
      marginBottom: '0.8rem',
    },
    '.tags': {
      display: 'flex',
      gap: 12,
      overflowX: 'scroll',
      padding: '0 0 12px 0',
    },
    '.tags > *:first-of-type': {
      marginLeft: 24,
    },
    '.buttons': {
      display: 'grid',
      gap: 12,
      marginTop: 24,
    },
  })

  const [name, setName] = useState(itemName || '')
  //const [quantity, setQuantity] = useState('')
  const [number, setNumber] = useState(itemNumber || '')
  const [price, setPrice] = useState(netPrice?.toString() || '0')
  const [description, setDescription] = useState(
    itemDescription || ''
  )
  const [supplierInput, setSupplier] = useState(supplier || '')
  const [unit, setUnit] = useState(unitOfMeasure)
  //const [tags, setTags] = useState([])

  const location = useLocation()
  const params = parse(location.search)
  //console.log('params: ', params)
  const backRoute: string = params.from as string

  //console.log('backroute', backRoute)

  const validated = () => {
    return name !== ''
  }

  const handleCancel = () => {
    window.history.back()
  }

  const handleConfirm = async () => {
    await handleSave()
  }

  const handleSave = async () => {
    if (id) {
      try {
        const variables: PartialUpdateItemInput & { id: string } = {
          id,
          name,
          number,
          description,
          netPrice: parseFloat(price),
          supplier: supplierInput,
          unitOfMeasure: unit,
        }
        await addData({
          variables,
        })

        if (backRoute) {
          const route = backRoute.includes('?')
            ? `${backRoute}&query=${encodeURIComponent(name)}`
            : `${backRoute}?query=${encodeURIComponent(name)}`
          navigate(route, { replace: true })
        } else {
          window.history.back()
        }
      } catch (e) {
        console.log('save error', e)
      }
    }
  }

  const {
    isVisible: isVisibleDelete,
    closeModal: closeModalDelete,
    openModal: openModalDelete,
  } = useModal()

  const handleDeleteConfirm = async () => {
    if (id) {
      try {
        // perform delete operation
        const result = await client.mutate({
          mutation: DELETE_ITEM,
          variables: {
            id,
          },
        })

        console.log('delete result', result)

        if (backRoute) {
          const route = backRoute.includes('?')
            ? `${backRoute}&query=${encodeURIComponent(name)}`
            : `${backRoute}?query=${encodeURIComponent(name)}`
          navigate(route, { replace: true })
        } else {
          window.history.go(-2)
        }
      } catch (e) {
        console.log('save error', e)
      }
    }
  }

  const handleDelete = () => {
    openModalDelete()
  }

  return (
    <>
      <SimpleHeader text={`Redigerar artikel: ${itemName}`} />

      <LayoutStandard scroll={true}>
        {/*<section css={imageStyles}>
          <img
            className="photo"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Solid_black.svg/768px-Solid_black.svg.png"
            alt="USER_CAMERA_NOT_IMPLEMENTED"
          />
          {/* Logiken för fotoknappen ska vara här, ta en bild och uppdatera databasen 
          Gör en funktion som sparar bilden i databas, passa in denna funktion i kamerakomponenten som behöver 
          modifieras lite för att tillåta detta (ta bort video), barcodescannern behöver dependa på denna och 
          denna behöver dependa på samma kamerakomponent (ny komponenet som ska skrivar) //låt stå
          }

          <div className="photobutton" role="button">
            <AddAPhoto />
          </div>
        </section>*/}
        <article css={styles}>
          <P2>Namn *</P2>
          <TextField
            name="name"
            placeholder="Ange namn"
            value={name}
            onChange={(e) => {
              setName((e.target as HTMLInputElement).value)
            }}
          />

          {/*<P2>Antal</P2>
          <TextField
            name="quantity"
            placeholder="Ange antal"
            onChange={(e) => {
              setQuantity((e.target as HTMLInputElement).value)
            }}
          />*/}

          <P2>Artikelnummer</P2>
          <TextField
            name="number"
            placeholder="Ange Artikelnummer"
            value={number}
            onChange={(e) => {
              setNumber((e.target as HTMLInputElement).value)
            }}
          />

          <P2>Enhet</P2>
          <div className="margin-fix">
            <EkSelect
              title="Välj Enhet"
              selectedValue={unit}
              items={Object.entries(UNIT_OF_MEASURE).map((v, k) => ({
                id: k + '',
                value: UNIT_OF_MEASURE[v[0] as UNIT_OF_MEASURE],
                name: v[0],
              }))}
              onChange={(selection) => {
                if (selection.length > 0) {
                  setUnit(selection[0].value)
                }
              }}
            />
          </div>

          <P2>Pris (per enhet)</P2>
          <TextField
            name="number"
            placeholder="Ange Pris"
            value={price}
            inputType="number"
            onChange={(e) => {
              setPrice((e.target as HTMLInputElement).value)
            }}
          />

          <P2>Leverantör</P2>
          <TextField
            name="text"
            placeholder="Ange leverantör"
            value={supplierInput}
            inputType="text"
            onChange={(e) => {
              setSupplier((e.target as HTMLInputElement).value)
            }}
          />

          <P2>Beskrivning</P2>
          <div className="tempFix">
            <TextField
              name="description"
              multiline={true}
              rows={4}
              value={description}
              onChange={(e) => {
                setDescription((e.target as HTMLInputElement).value)
              }}
            />
          </div>

          {/*<P2>Taggar</P2>
          <TextField
            name="tags"
            placeholder="Ange tagg"
            /*onChange={(e) => {
              setPassword((e.target as HTMLInputElement).value)
            }}
          />*/}

          <div className="buttons">
            <Button
              variant={ButtonVariant.Outlined}
              onClick={() => {
                handleCancel()
              }}
            >
              Avbryt
            </Button>
            <Button
              disabled={!validated()}
              variant={ButtonVariant.Primary}
              onClick={() => {
                handleConfirm()
              }}
            >
              Spara
            </Button>
            <Divider />
            <div className="deletebtn">
              <Button
                variant={ButtonVariant.Danger}
                onClick={() => {
                  handleDelete()
                }}
              >
                Ta bort
              </Button>
            </div>
          </div>
        </article>
      </LayoutStandard>
      <Modal
        isVisible={isVisibleDelete}
        closeModal={closeModalDelete}
      >
        <ModalConfirmDeleteArticle
          articleName={itemName}
          onConfirm={() => {
            handleDeleteConfirm()
          }}
          onCancel={closeModalDelete}
        />
      </Modal>
    </>
  )
}

const EditArticle = withQuery<Item>({
  query: GET_ITEM,
  loader: EkLoader,
})(
  withMutation<Item, PartialUpdateItemInput>(UPDATE_ITEM_PARTIAL)(
    _EditArticle
  )
)

export { EditArticle }
