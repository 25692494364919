/* eslint-disable max-lines */
import React, { FC, useState } from 'react'
import { H5 } from '@components/base'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { RouteComponentProps } from '@reach/router'
import {
  StorageSpace,
  StorageSpaceInput,
  StorageSpacePage,
} from '@types'
import {
  CREATE_STORAGE_SPACE,
  GET_PHYSICAL_STORAGE_SPACES,
} from '@api/storage-space'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import {
  Button,
  ButtonSize,
  ButtonVariant,
  TextField,
} from '@pure-components/input'
import { css } from '@emotion/react'
import { EkList, EkListCell, EkListRow } from '@components/ek-list'
import { BarcodeScanner } from '@components/barcode-scanner'
import { TEXT_STYLE } from '@styles'
import { CartItem } from './article-cart-list'
import { Divider } from '@pure-components/graphics'
import { STORAGE_SPACE_TYPE } from '@src/functions/lib/types'
import {
  ExtendedStorage,
  StorageListRowNested,
} from '@components/ek-list/storage-list-row-nested'

const nestData2 = (flat: StorageSpace[]) => {
  const rootId = '-1'

  const clonedArray: ExtendedStorage[] = JSON.parse(
    JSON.stringify(flat)
  ).map((s: ExtendedStorage) => {
    s.children = []
    if (!s.parent) {
      s.parent = '-1'
    }
    return s
  })

  const r: ExtendedStorage[] = []
  const o: any = {}
  clonedArray.forEach(function (a) {
    if (o[a._id] && o[a._id].children) {
      a.children = o[a._id] && o[a._id].children
    }
    o[a._id] = a
    if (a.parent === rootId) {
      r.push(a)
    } else {
      o[a.parent!] = o[a.parent!] || {}
      o[a.parent!].children = o[a.parent!].children || []
      o[a.parent!].children.push(a)
    }
  })
  /* eslint-disable */
  r.map((p) => {
    p.parent = undefined
  })
  /* eslint-enable */
  return r
}

interface ModalSelectStorageProps extends RouteComponentProps {
  confirmText?: string
  currentStorageId?: string
  selectedItems?: CartItem[]
  closeModal?: (storage: StorageSpace | undefined) => void
}

const _ModalSelectStorage: FC<
  ModalSelectStorageProps &
    RouteComponentProps &
    WithMutationExtension<Storage[], StorageSpaceInput> &
    WithQueryExtension<StorageSpacePage>
> = ({
  queryData,
  selectedItems = [],
  addData,
  previousPage,
  nextPage,
  path,
  currentStorageId = '',
  confirmText = 'Välj Förvaringsplats',
  closeModal = () => {},
}) => {
  const {
    getStorageByVirtual: { data = [] },
  } = queryData

  const nestedData = nestData2(data)

  const item =
    selectedItems.length === 1 ? selectedItems[0].item.item : null

  //console.log('moving item', item)

  const recommendedStorages =
    item?.inventoryItems?.data
      ?.filter(
        (inv) =>
          inv.storageSpace._id !== currentStorageId &&
          !inv.storageSpace.virtual &&
          !inv.storageSpace.deleted
      )
      .sort((a, b) => b.quantity - a.quantity)
      .map((inv) => inv.storageSpace) || []

  //console.log('recommended', recommendedStorages)

  const [searchFilter, setSearchFilter] = useState('')
  const [selectedStorage, setSelectedStorage] = useState<
    undefined | StorageSpace
  >(undefined)

  const [
    storageSpaceIdBarcodeScannerVisible,
    setStorageSpaceIdBarcodeScannerVisible,
  ] = useState<boolean>(false)

  const handleSearchChange = (event: any) => {
    setSearchFilter(event.target.value)
  }

  const onRowClick = (clickedItem: StorageSpace) => {
    if (selectedStorage?._id === clickedItem._id)
      setSelectedStorage(undefined)
    else setSelectedStorage(clickedItem)
  }

  const isButtonDisabled = () => {
    return selectedStorage === null
  }

  const vibrate = () => {
    if (window?.navigator?.vibrate) {
      window.navigator.vibrate(30)
    }
  }

  const handleStorageSpaceIdBarcodeMatch = (barcode: string) => {
    //console.log(barcode)
    if (barcode) {
      vibrate()
      const selectStorage = data.find(
        (storage) => storage.displayLabel?.barcode === barcode
      )
      if (selectStorage) {
        setSelectedStorage(selectStorage)
        handleStorageSpaceIdBarcodeClose()
      }
    }
  }

  const handleStorageSpaceIdBarcodeClose = () => {
    setStorageSpaceIdBarcodeScannerVisible(false)
  }

  const styles = css({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto auto 1fr auto',
    //gridTemplateAreas: '"head" "recommended" "main" "footer"',
    gap: '1em',
    overflow: 'hidden',
    position: 'relative',
    '.header': {
      display: 'grid',
      gridTemplateColumns: 'repeat( auto-fit, minmax(150px, 1fr) )',
      gap: '1em',
    },
    '.recommended-header': {
      marginLeft: 10,
      h5: {
        fontSize: '1rem',
      },
    },
    '.barcodeScannerWrapper': {
      position: 'absolute',
      zIndex: 2000,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      '.barcodeScannerContent': {
        maxWidth: '300px',
      },
    },
    '.barcodeScannerCloseButton': {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: '10px',
      marginTop: '-1em',
    },
    '.storageName': [
      TEXT_STYLE.subtitle[2],
      {
        textTransform: 'uppercase',
      },
    ],
    '.scrollbox': {
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
  })

  const recommendedBoxStyles = css({
    marginBottom: '0.5rem',
  })

  return (
    <section css={styles}>
      <div className="header">
        <div>
          <H5>Välj förvaringsplats</H5>
        </div>
        <div>
          <Button
            variant={ButtonVariant.Primary}
            onClick={() =>
              setStorageSpaceIdBarcodeScannerVisible(true)
            }
            fullWidth={false}
            size={ButtonSize.Small}
          >
            Skanna kod
          </Button>
        </div>
      </div>
      <TextField
        placeholder="Sök på namn"
        inputType="search"
        value={searchFilter}
        onChange={(e) => handleSearchChange(e)}
      />
      <div css={recommendedBoxStyles}>
        {recommendedStorages.length > 0 && (
          <>
            <div className="recommended-header">
              <H5>{`${
                item!.name.length > 30
                  ? `${item!.name.substring(0, 30)}...`
                  : item?.name
              } finns i:`}</H5>
            </div>
            <EkList>
              {recommendedStorages
                .filter(
                  (storage: StorageSpace) =>
                    storage._id !== currentStorageId &&
                    !storage.virtual &&
                    !storage.deleted &&
                    storage.type !== STORAGE_SPACE_TYPE.FORDON
                )
                .filter(
                  (storage: StorageSpace) =>
                    storage
                      .name!.toLowerCase()
                      .indexOf(searchFilter.toLowerCase()) > -1
                )
                .map((storage: StorageSpace, i) => (
                  <EkListRow
                    key={`recommended${storage._id}`}
                    classes={
                      selectedStorage?._id === storage._id
                        ? 'selected'
                        : ''
                    }
                    onClick={(e) => onRowClick(storage)}
                  >
                    <EkListCell>
                      <p className="storageName">{storage.name}</p>
                    </EkListCell>
                  </EkListRow>
                ))}
            </EkList>
            <Divider />
          </>
        )}
      </div>
      <div className="scrollbox">
        <div className="recommended-header">
          <H5>Förvaringar</H5>
        </div>
        <EkList>
          {nestedData
            .filter(
              (storage: ExtendedStorage) =>
                storage._id !== currentStorageId &&
                !storage.virtual &&
                !storage.deleted
            )
            .filter(
              (storage: ExtendedStorage) =>
                storage
                  .name!.toLowerCase()
                  .indexOf(searchFilter.toLowerCase()) > -1
            )
            .map((storage: ExtendedStorage, i) => (
              <StorageListRowNested
                key={storage._id}
                item={storage}
                subitems={storage.children}
                onClick={(s) => onRowClick(s)}
                selectedId={selectedStorage?._id}
              />
            ))}
        </EkList>
      </div>

      <Button
        disabled={isButtonDisabled()}
        onClick={() => closeModal(selectedStorage)}
      >
        {confirmText}
      </Button>

      {storageSpaceIdBarcodeScannerVisible && (
        <div className="barcodeScannerWrapper">
          <div className="barcodeScannerContent">
            <BarcodeScanner
              onMatch={handleStorageSpaceIdBarcodeMatch}
            />
            <div className="barcodeScannerCloseButton">
              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => handleStorageSpaceIdBarcodeClose()}
              >
                Avbryt
              </Button>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

const ModalSelectStorage = withQuery<StorageSpacePage>({
  query: GET_PHYSICAL_STORAGE_SPACES,
  variables: { _size: 16000 },
  loader: EkLoader,
})(
  withMutation<Storage[], StorageSpaceInput>(CREATE_STORAGE_SPACE)(
    _ModalSelectStorage
  )
)

export { ModalSelectStorage }
