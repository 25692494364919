import { bottomNavHeight } from '@components/ek-bottom-nav'
import React, { ReactNode, useState } from 'react'
import { BottomNavContext } from './bottom-nav-context'
import { NavItem } from './nav-item'

type BottomNavProviderProps = {
  children: ReactNode
}

const BottomNavProvider = ({
  children = [],
}: BottomNavProviderProps) => {
  const [activeItem, setActiveItem] = useState(NavItem.Home)

  return (
    <BottomNavContext.Provider
      value={{ activeItem, setActiveItem, height: bottomNavHeight }}
    >
      {children}
    </BottomNavContext.Provider>
  )
}

export { BottomNavProvider }
