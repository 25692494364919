import React, { FC } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@components/header'
import { Add } from '@material-ui/icons'
import { AppRoutes } from '@router'
import { User, UserPage } from '@types'
import { GET_USERS } from '@api/user'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { EkList } from '@components/ek-list'
import { Pagination } from '@components/pagination'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { UserItem } from '@components/pages-components/users/user-item'
import { parse } from 'query-string'

const _Users: FC<
  RouteComponentProps & WithQueryExtension<UserPage>
> = ({
  queryData,
  previousPage,
  nextPage,
  after,
  before,
  location,
  refetch,
}) => {
  const {
    users: { data = [] },
  } = queryData
  const styles = css({
    display: 'grid',
    rowGap: '12px',
    justifyContent: 'center',
    width: '100vw',
  })

  let previousPageHandler = previousPage

  const params = parse(location!.search)
  if (params.cursor !== undefined && !before) {
    before = 'lastBackToken'
    previousPageHandler = () => {
      location!.search = ''
      refetch({ _size: 10, _cursor: null })
    }
  }

  const handleButtonNew = () => {
    navigate(`${AppRoutes.NewUser}`)
  }

  return (
    <>
      <SimpleHeader text="Användare">
        {/*<EkViewSwitcher view={view} location={location} />
        <Search />*/}
        <div
          onClick={handleButtonNew}
          role="button"
          onKeyDown={handleButtonNew}
          tabIndex={-2}
        >
          <Add />
        </div>
      </SimpleHeader>
      <LayoutStandard scroll={true}>
        <section css={styles}>
          <>
            <EkList>
              {data
                .filter((u) => !u.account?.deleted)
                .map((user: User, i) => (
                  <UserItem
                    key={user._id + '_' + i}
                    id={user._id}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    phoneNumber={user.account?.phoneNumber}
                  />
                ))}
            </EkList>
          </>
        </section>
      </LayoutStandard>
      <Pagination
        before={before || undefined}
        after={after || undefined}
        previousPage={previousPageHandler}
        nextPage={nextPage}
      />
    </>
  )
}

const Users = withQuery<UserPage>({
  query: GET_USERS,
  loader: EkLoader,
  useNewCursor: true,
})(_Users)

export { Users }
