import React, { FC, MouseEvent, ReactNode } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { resetButton } from '@styles'

interface BottomNavItemProps {
  children?: ReactNode
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  padding?: string
  style?: SerializedStyles | SerializedStyles[]
}

const BottomNavItem: FC<BottomNavItemProps> = ({
  children = null,
  onClick = () => {},
  padding = '10px 0',
  style = css(),
}) => {
  const styles = css([
    resetButton,
    {
      padding,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative',
      '&:focus': {
        outline: 'none',
      },
    },
  ])

  return (
    <button css={[styles, style]} onClick={onClick}>
      {children}
    </button>
  )
}

export { BottomNavItem }
