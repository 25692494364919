import { WindowLocation } from '@reach/router'

interface QueryParam {
  key: string
  value: string
}

const addQueryParams = (
  queryParams: QueryParam[] = [],
  windowLocation: WindowLocation
): string => {
  const search = new URLSearchParams(windowLocation.search)
  for (const { key, value } of queryParams) {
    search.set(key, value)
  }
  return `?${search.toString()}`
}

export type { QueryParam }
export { addQueryParams }
