import React, { FC } from 'react'
import { H6 } from '@components/base'
import { Modal } from '@components/modal'
import { NewStorageModalCard } from './new-storage-modal-card'
import { STORAGE_SPACE_TYPE } from '@src/types'
import { css } from '@emotion/react'
import { resetButton } from '@styles'
import { Caption } from '@components/base-extended'

interface NewStorageModalProps {
  isVisible: boolean
  closeModal?: () => void
  onSelectVariant?: (storageSpaceType: STORAGE_SPACE_TYPE) => void
}

const storages = [
  {
    variant: STORAGE_SPACE_TYPE.FORDON,
  },
  {
    variant: STORAGE_SPACE_TYPE.HYLLA,
  },
  {
    variant: STORAGE_SPACE_TYPE.LADA,
  },
  {
    variant: STORAGE_SPACE_TYPE.LAGER,
  },
  {
    variant: STORAGE_SPACE_TYPE.YTA,
  },
]

const NewStorageModal: FC<NewStorageModalProps> = ({
  isVisible = false,
  closeModal = () => {},
  onSelectVariant = () => {},
}) => {
  const styling = css({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 12,
    margin: '24px 0',
    '.button': [
      resetButton,
      {
        display: 'grid',
        border: 'none',
      },
    ],
  })
  return (
    <Modal
      isVisible={isVisible}
      closeModal={closeModal}
      css={styling}
    >
      <Caption>Lägg till förvaringsplats</Caption>
      <H6>Välj ikon</H6>
      <div css={styling}>
        {storages.map(({ variant }) => (
          <button
            key={variant}
            className="button"
            onKeyDown={() => onSelectVariant(variant)}
            onClick={() => onSelectVariant(variant)}
          >
            <NewStorageModalCard variant={variant} />
          </button>
        ))}
      </div>
    </Modal>
  )
}

export { NewStorageModal }
