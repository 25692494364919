/* eslint-disable max-lines */
// TODO: Refactor this so the file is sub 100 lines

import React, { FC, useState } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@components/header'
import {
  AddAPhoto,
  AddCircle,
  Edit,
  Image,
  Link,
} from '@material-ui/icons'
import { Modal, useModal } from '@components/modal'
import { COLORS } from '@styles'
import { Chip } from '@pure-components/data-display'
//import { A, H5, P2 } from '@components/base'
import { DetailsItem } from '@components/pages-components/articles/details-item'
//import { AppRoutes } from '@router'
import { GET_ITEM, CREATE_ITEM, UPDATE_ITEM_PARTIAL } from '@api/item'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { withQuery, WithQueryExtension } from '@components/with-query'
import { Item, ItemInput, PartialUpdateItemInput, Tag } from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import { ModalAddTag } from '@components/pages-components/articles/article-add-tag-modal'
import { client } from '@api/client'
import { gql } from '@apollo/client'
import { A, H5, P2 } from '@components/base'
import { AppRoutes } from '@router'

interface ArticleProps extends RouteComponentProps {
  id?: string
}

const _Article: FC<
  ArticleProps &
    WithMutationExtension<Item[], ItemInput> &
    WithQueryExtension<Item>
> = ({ queryData, id, refetch }) => {
  const {
    findItemByID: {
      name,
      number,
      description,
      supplier,
      netPrice,
      tags,
      unitOfMeasure,
      defaultImage,
      inventoryItems,
    },
  } = queryData

  //console.log('queryData', queryData)

  const sumInventory = inventoryItems?.data?.reduce(
    (acc, inv) => acc + inv.quantity,
    0
  )

  const { isVisible, closeModal, openModal } = useModal()

  const updateArticle = async (
    variables: { id: string } & PartialUpdateItemInput
  ) => {
    try {
      //console.log('query:', query, variables)
      const result = await client.mutate({
        mutation: gql`
          ${UPDATE_ITEM_PARTIAL}
        `,
        variables,
      })
      console.log('result', result)
      refetch()
    } catch (e) {
      console.log(e)
    }
  }

  const handleAddTag = async (tag: Tag) => {
    if (id) {
      const tagConnection = tag._id
        ? { connect: [tag._id] }
        : { create: [{ name: tag.name }] }
      /*console.log(tag, {
        id,
        tags: tagConnection,
      })*/
      await updateArticle({
        id,
        tags: tagConnection,
      })
    }
    closeModal()
  }

  // const { name, amount, description, storageLocation, image, tags } =
  //   articleData

  const imageStyles = css({
    position: 'relative',
    '.photo': { width: '100%', height: '200px', objectFit: 'cover' },
    '.photobutton': {
      width: 32,
      height: 32,
      position: 'absolute',
      top: 20,
      right: 20,
      background: COLORS.gray[30],
      padding: 3,
      borderRadius: 5,
    },
  })

  const imagePlaceholderStyles = css({
    svg: {
      height: '4rem',
      width: '4rem',
      position: 'absolute',
      top: '40%',
      left: '45%',
      color: 'lightgray',
    },
  })

  const styles = css({
    '.info': {
      padding: '6px 24px',
    },
    '.details': {
      color: COLORS.gray[90],
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '0.2em',
      padding: '12px 0',
      placeContent: 'center center',
    },
    '.tag-header': {
      display: 'flex',
      gap: 0,
      padding: '0 0 12px 0',
      marginLeft: 24,
    },
    '.tag-header > svg': {
      marginLeft: 12,
    },
    '.tags': {
      display: 'flex',
      gap: 8,
      padding: '0 0 12px 0',
      marginLeft: 24,
      flexWrap: 'wrap',
      alignContent: 'flex-start',
    },
  })

  /*const modalStyling = css({
    display: 'grid',
    rowGap: '1em',
    paddingTop: '1em',
    '.buttons': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 12,
    },
  })*/

  /*const [option, setOption] = useState('')*/

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [editMode, setEditMode] = useState(false)

  const openModalWithOption = (option: string) => {
    //setOption(option)
    openModal()
  }

  const handleButtonEdit = () => {
    navigate(`${AppRoutes.Articles}/${id}/edit`)
  }

  return (
    <>
      <SimpleHeader text={`${name}`}>
        {/*<AddCircle />*/}
        {/*editMode ? (
          <Cancel onClick={() => setEditMode(!editMode)} /> // TODO: Set this to EditOff once we update material icons library
        ) : (
          <Edit onClick={() => setEditMode(!editMode)} />
        )*/}
        <div
          onClick={handleButtonEdit}
          role="button"
          onKeyDown={handleButtonEdit}
          tabIndex={-2}
        >
          <Edit />
        </div>
      </SimpleHeader>
      <LayoutStandard>
        <section css={imageStyles}>
          {defaultImage ? (
            <img
              className="photo"
              src={defaultImage}
              alt={description}
            />
          ) : (
            <div className="photo" css={imagePlaceholderStyles}>
              <Image />
            </div>
          )}
          {/* Logiken för fotoknappen ska vara här, ta en bild och uppdatera databasen 
          Gör en funktion som sparar bilden i databas, passa in denna funktion i kamerakomponenten som behöver 
          modifieras lite för att tillåta detta (ta bort video), barcodescannern behöver dependa på denna och 
          denna behöver dependa på samma kamerakomponent (ny komponenet som ska skrivar) //låt stå
          */}
          {editMode && (
            <div className="photobutton" role="button">
              <AddAPhoto />
            </div>
          )}
        </section>
        <article css={styles}>
          <div className="info">
            {editMode ? (
              <H5 onClick={() => openModalWithOption('name')}>
                {name}
              </H5>
            ) : (
              <H5>{name}</H5>
            )}

            <div className="details">
              <DetailsItem
                icon={<></>}
                text={number}
                label={'E-Nummer'}
              />
              <DetailsItem
                icon={<></>}
                text={supplier}
                label={'Leverantör'}
              />
              <DetailsItem
                icon={<></>}
                text={String(netPrice?.toFixed(2))}
                label={`Pris / ${unitOfMeasure}`}
              />
              <DetailsItem
                icon={<></>}
                text={
                  String(sumInventory?.toFixed(2)) +
                  ` ${unitOfMeasure}`
                }
                label={`Saldo`}
              />
              {inventoryItems?.data?.length &&
              inventoryItems.data.length > 0 ? (
                <A to={`${AppRoutes.StockBalances}/${id}`}>
                  <DetailsItem
                    icon={<Link />}
                    text={inventoryItems?.data?.length + '' || ''}
                    label={`Förvaringsplatser`}
                  />
                </A>
              ) : (
                <DetailsItem
                  icon={<></>}
                  text={inventoryItems?.data?.length + '' || ''}
                  label={`Förvaringsplatser`}
                />
              )}
              {/*<DetailsItem
                icon={<></>}
                text={String(grossPrice?.toFixed(2))}
              />
              <DetailsItem
                icon={<></>}
                text={String(sellingPrice?.toFixed(2))}
            />*/}
              {/*<A to={`${AppRoutes.StockBalances}/${_id}`}>
                <DetailsItem
                  icon={<Looks4 />}
                  // text={storageLocation} // TODO: Find out where this item is and print here somehow
                  span={2}
                />
          </A>*/}
            </div>
          </div>
          <div className="tags">
            <H5>Taggar</H5>
            <AddCircle
              onClick={() => openModalWithOption('tags')}
              style={{ color: COLORS.orange[30] }}
            />
          </div>
          <div className="tags">
            {tags.data!.map((tag) => (
              <Chip
                key={tag._id}
                background={COLORS.orange[10]}
                text={tag.name}
              />
            ))}
          </div>
          <div className="info">
            {editMode ? (
              <P2 onClick={() => openModalWithOption('description')}>
                {description}
              </P2>
            ) : (
              <P2>{description}</P2>
            )}
          </div>
        </article>
      </LayoutStandard>
      <Modal isVisible={isVisible} closeModal={closeModal}>
        <ModalAddTag closeModal={handleAddTag} />
        {/*<section css={modalStyling}>
          {option && option === 'tags' && (
            <>
              <P2>Taggar</P2>
              <TextField placeholder="Ange tagg" />
            </>
          )}
          {option && option === 'amount' && (
            <>
              <P2>Redigera antal</P2>
              <TextField placeholder="Ange antal" />
            </>
          )}
          {option && option === 'articlenumber' && (
            <>
              <P2>Redigera artikelnummer</P2>
              <TextField placeholder="Ange Artikelnummer" />
            </>
          )}
          {option && option === 'name' && (
            <>
              <P2>Redigera namn</P2>
              <TextField placeholder="Ange namn" value={name} />
            </>
          )}
          {option && option === 'description' && (
            <>
              <P2>Redigera beskrivning</P2>
              <TextField multiline={true} rows={4} />
            </>
          )}

          <div className="buttons">
            <Button
              variant={ButtonVariant.Outlined}
              onClick={() => closeModal()}
            >
              Avbryt
            </Button>
            <Button
              variant={ButtonVariant.Primary}
              onClick={() => closeModal()}
            >
              Spara
            </Button>
          </div>
        </section>*/}
      </Modal>
    </>
  )
}

const Article = withQuery<Item>({
  query: GET_ITEM,
  loader: EkLoader,
})(withMutation<Item[], ItemInput>(CREATE_ITEM)(_Article))

export { Article }
