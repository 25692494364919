/* eslint-disable max-lines */
import React, { ComponentType, FC, useState } from 'react'
import {
  ApolloError,
  DocumentNode,
  OperationVariables,
  useQuery,
} from '@apollo/client'
import { DefaultSkeleton } from '@components/ek-skeleton'
import { DefaultError } from '@components/ek-error'
import { Page } from '@types'
import { OperationDefinitionNode } from 'graphql'
import { WindowLocation } from '@reach/router'
import { parse } from 'query-string'
import { addQueryParams } from '@common'

type GraphqlResponseData<
  ResultType extends Page & {
    previousPage: () => void
    nextPage: () => void
    refetch: () => void
  }
> = {
  [key: string]: ResultType
}

interface WithQueryTypes {
  query: DocumentNode
  loader?: ComponentType<any>
  error?: ComponentType<any>
  variables?: OperationVariables
  useNewCursor?: boolean
}

interface WithQueryExtension<ResultData> {
  queryData: GraphqlResponseData<
    ResultData & {
      previousPage: () => void
      nextPage: () => void
      refetch: () => void
    }
  >
  queryError: ApolloError | undefined
  previousPage: () => void
  nextPage: () => void
  refetch: (data?: any) => void
  before?: string | null
  after?: string | null
}

// interface PaginationState {
//   before: string | null | undefined
//   after: string | null | undefined
// }

interface PropsWithId extends Object {
  id?: string
  queryData?: any
}

const getKey = (query: DocumentNode): string | number | undefined => {
  return (
    (query.definitions as OperationDefinitionNode[])[0]?.name
      ?.value || undefined
  )
}

const withQuery = <ResultType extends Object>({
  query,
  variables = { _size: 10 },
  loader: Loader = DefaultSkeleton,
  error: Error = DefaultError,
  useNewCursor = false,
}: WithQueryTypes) => <ComponentProps extends PropsWithId>(
  Component: ComponentType<ComponentProps>
): FC<
  Omit<
    ComponentProps,
    | 'queryData'
    | 'queryError'
    | 'nextPage'
    | 'previousPage'
    | 'refetch'
  >
> => ({ ..._props }) => {
  const location = window.location as WindowLocation
  const params = parse(location.search)

  const [cursorHistory, setCursorHistory] = useState<string[]>([])

  const [cursors, setCursors] = useState<
    (string | null | undefined)[]
  >([null, null])

  const [queryKey, setQueryKey] = useState<
    string | number | undefined
  >('')

  if (_props.id) {
    variables.id = _props.id
  }

  if (params.cursor) {
    variables._cursor = params.cursor
  }

  const { data, loading, error, refetch } = useQuery<
    GraphqlResponseData<
      ResultType & {
        previousPage: () => void
        nextPage: () => void
        refetch: () => void
      }
    >,
    OperationVariables
  >(query, {
    variables,
    notifyOnNetworkStatusChange: true,
    onCompleted: (fetchedData) => {
      // Graphql returns an object with the key of the operation performed.
      /* We need to get this key from the query definition so that we can 
      extract the page cursors */
      const key = getKey(query)
      setQueryKey(key)

      const _fetchedData = fetchedData[key || 0]
      //console.log('Fetched data:', fetchedData)

      let before = (_fetchedData as Page)?.before || null

      if (useNewCursor) {
        if (cursorHistory.length === 1) {
          before = 'lastBackToken'
        } else {
          before = cursorHistory[cursorHistory.length - 2] || null
        }
      }

      const after = (_fetchedData as Page)?.after || null
      setCursors([before, after])
    },
  })

  let queryState: WithQueryExtension<ResultType> = {
    queryData: {},
    queryError: undefined,
    previousPage: () => {},
    nextPage: () => {},
    refetch: () => {},
  }

  const key = queryKey || getKey(query)
  if (data && key && data[key]) {
    const previousPage = () => {
      let backCursor = cursors[0]

      let search
      if (useNewCursor && cursorHistory.length === 1) {
        search = addQueryParams([], location)
        backCursor = null
      } else {
        search = addQueryParams(
          [{ key: 'cursor', value: cursors[0] || '' }],
          location
        )
      }

      const newUrl =
        location.protocol +
        '//' +
        location.host +
        location.pathname +
        search
      //console.log('previous cursor: ', cursors[0], newUrl)
      window.history.pushState({ path: newUrl }, '', newUrl)

      if (useNewCursor) {
        const history = cursorHistory
        history.pop()
        setCursorHistory(history)
      }

      refetch({ ...variables, _cursor: backCursor })
    }

    const nextPage = () => {
      const search = addQueryParams(
        [{ key: 'cursor', value: cursors[1] || '' }],
        location
      )
      const newUrl =
        location.protocol +
        '//' +
        location.host +
        location.pathname +
        search
      //console.log('next cursor: ', cursors[1], newUrl)
      window.history.pushState({ path: newUrl }, '', newUrl)

      if (useNewCursor) {
        const history = cursorHistory
        if (cursors[1]) {
          history.push(cursors[1])
          setCursorHistory(history)
        }
      }

      refetch({ ...variables, _cursor: cursors[1] })
    }

    const keyData = {
      ...data[key],
      previousPage,
      nextPage,
    }

    const queryDataPrev = (_props as any)?.queryData || {}

    const queryData = {
      ...queryDataPrev,
      [key]: keyData,
    }

    queryState = {
      queryData: {
        ...queryData,
      },
      queryError: error,
      previousPage,
      nextPage,
      refetch,
      before: cursors[0],
      after: cursors[1],
    }
  }

  if (loading) {
    return <Loader {...queryState} />
  }

  if (error) {
    return <Error {...queryState} />
  }

  const __props = {
    ...(_props as ComponentProps),
    ...queryState,
  }

  return <Component {...__props} />
}

export { withQuery }
export type { WithQueryExtension }
