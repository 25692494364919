import React, { ReactNode, MouseEvent, FC } from 'react'
import { css } from '@emotion/react'

import { COLORS, resetButton } from '@styles'

import { ButtonVariant } from './button-variant'
import { ButtonSize } from './button-size'
import {
  getBackgroundColor,
  getTextColor,
  getTextStyle,
  getPadding,
  getBorder,
} from './button-utils'

interface ButtonProps {
  children?: ReactNode
  variant?: ButtonVariant
  size?: ButtonSize
  fullWidth?: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

const Button: FC<ButtonProps> = ({
  children = [],
  variant = ButtonVariant.Primary,
  size = ButtonSize.Large,
  fullWidth = true,
  onClick = () => {},
  disabled = false,
}) => {
  const styles = css([
    resetButton,
    getTextStyle(size),
    {
      background: disabled
        ? COLORS.gray['50']
        : getBackgroundColor(variant),
      color: disabled ? COLORS.gray['70'] : getTextColor(variant),
      padding: getPadding(size),
      border: disabled ? '2px solid transparent' : getBorder(variant),
      borderRadius: '5px',
      textAlign: 'center',
      width: fullWidth ? '100%' : 'initial',
      position: 'relative',
      transition: 'all 200ms ease',
      WebkitBackfaceVisibility: 'initial',
      MozBackfaceVisibility: 'initial',
      '[disabled]': {},
      '&::before': {
        content: "''",
        position: 'absolute',
        borderRadius: '3px',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        transition: 'all 200ms ease',
        boxShadow: `0 0 0 3px rgba(0,0,0,0.2) inset`, // TODO: Establish focused state in design
      },
      '&:focus': {
        outline: 'none',
        '&::before': {
          opacity: 1,
        },
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        borderRadius: '3px',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.1)',
        transition: 'all 200ms ease',
        opacity: 0,
      },
      '&:hover': {
        transform:
          'scale(0.98) translate3d( 0, 0, 0) perspective(1px)',
        '&::after': {
          opacity: 1,
        },
      },
      '&:active': {
        transform:
          'scale(0.96) translate3d( 0, 0, 0) perspective(1px)',
      },
    },
  ])

  return (
    <button css={styles} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}

export { Button }
