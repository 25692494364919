import { gql } from '@apollo/client'

const GET_STORAGE_SPACE_TRANSACTIONS = gql`
  query storageSpaceTransactions($_size: Int, $_cursor: String) {
    storageSpaceTransactions(_size: $_size, _cursor: $_cursor) {
      data {
        _id

        item {
          _id

          number
          sdfId
          name
          description
          tags {
            data {
              _id
              name
            }
            before
            after
          }
          images
          defaultImage
          unitOfMeasure
          supplier
          netPrice
          grossPrice
          sellingPrice
        }
        quantity
        fromStorageSpace {
          _id

          name
          description
          type
          parent
        }
        toStorageSpace {
          _id

          name
          description
          type
          parent
        }
        user {
          _id

          firstName
          lastName
          role
        }
        type
        comment
      }
      before
      after
    }
  }
`

export { GET_STORAGE_SPACE_TRANSACTIONS }
