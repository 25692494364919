/* eslint-disable max-lines */
import React, { FC, useState } from 'react'
import { RouteComponentProps, useLocation } from '@reach/router'
import { ArrowDropDownCircle, MenuOpen } from '@material-ui/icons'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@components/header'
import { useBottomSheet, useQueryParam } from '@hooks'
import { DetailsItem } from '@components/pages-components/working-orders/id'
import { truncateString } from '@common'
import { COLORS, resetButton } from '@styles'
import { EkViewSwitcher } from '@components/ek-view-switcher'
import { Divider } from '@pure-components/graphics'
import {
  withMutation,
  WithMutationExtension,
} from '@components/with-mutation'
import { withQuery, WithQueryExtension } from '@components/with-query'
import {
  IncomingOrder,
  IncomingOrderInput,
  InventoryItem,
} from '@types'
import { EkLoader } from '@components/graphics/ek-loader/ek-loader'
import {
  CREATE_INCOMING_ORDER,
  GET_INCOMING_ORDER,
} from '@api/incoming-order'
import { normalizeDate } from '@common/normalize-date'
import {
  IncomingOrderListItem,
  IncomingOrderListView,
} from '@components/pages-components/incoming-products/id'
import { ContextMenu } from '@components/pages-components/incoming-products/id/context-menu'
import { Button, ButtonVariant } from '@pure-components/input'
import { BackButton } from '@components/back-button.ts'

interface IncomingOrderProps extends RouteComponentProps {
  id?: string
}

const _IncomingProduct: FC<
  IncomingOrderProps &
    WithMutationExtension<IncomingOrder, IncomingOrderInput> &
    WithQueryExtension<IncomingOrder>
> = ({ queryData, id, refetch }) => {
  const {
    findIncomingOrderByID: {
      //_id,
      orderNumber,
      supplier,
      createdDate,
      expectedDeliveryDate,
      ordererName,
      ordererContact,
      //scanned,
      storageSpace,
      //items,
    },
  } = queryData
  const { setChildren, setIsOpen } = useBottomSheet()

  const location = useLocation()

  const view = useQueryParam('view')

  const data = storageSpace?.items?.data || []

  //const handleEditMode = createSetEditMode(location)

  const openBottomSheet = () => {
    setChildren(<ContextMenu storageId={storageSpace._id} />)
    setIsOpen(true)
  }

  const detailsRow = [
    {
      caption: 'Ordernummer',
      text: orderNumber,
    },
    { caption: 'Skapad', text: normalizeDate(createdDate) },
    {
      caption: 'Leverantör',
      text: supplier,
    },
    {
      caption: 'Leveransdatum',
      text: normalizeDate(expectedDeliveryDate || ''),
    },
    {
      caption: 'Beställare',
      text: ordererName,
    },
    {
      caption: 'Beställare Kontakt',
      text: ordererContact,
    },
  ]

  const styles = css({
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: '12px',
    justifyContent: 'center',
    padding: view === 'card' ? '24px' : '0 24px ',
  })

  const contextMenuStyles = css({
    padding: '12px 24px',
    width: '100%',
    background: COLORS.gray[0],
  })

  const [detailsOpen, setDetailsOpen] = useState(false)

  const headerWrapperStyles = css({
    display: 'flex',
    //justifyContent: 'space-between',
    //alignItems: 'center',
    background: COLORS.gray[70],
    color: COLORS.gray[10],
    padding: '16px 16px',
  })

  const headerStyles = css({
    display: 'grid',
    //zIndex: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    '.order-number': {
      fontSize: '12px',
    },
    '.service-address': {
      fontSize: '12px',
    },
    '.project-order': {
      fontSize: '16px',
      fontWeight: 800,
    },
    '.header-line-two': {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      marginTop: '10px',
    },
  })
  const detailStyles = css({
    display: detailsOpen ? 'grid' : 'none',
    opacity: detailsOpen ? 1 : 0,
    height: detailsOpen ? 'auto' : 0,
    maxHeight: 1000,
    background: COLORS.gray[70],
    color: COLORS.gray[10],
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: '12px 24px',
    paddingBottom: '2em',
    gap: '0.5em',
    transition: 'transform 250ms ease',
    zIndex: 999,
  })

  const backWrapperStyle = css({
    //flex: 1
    marginRight: 16,
  })

  const buttonStyling = css([
    resetButton,
    {
      color: COLORS.gray[10],
    },
  ])

  return (
    <>
      <div css={headerWrapperStyles}>
        <div css={backWrapperStyle}>
          <BackButton />
        </div>
        <div css={headerStyles}>
          <div className="order-number">
            {supplier} beställning: {orderNumber}
          </div>
          <div className="header-line-two">
            <div className="project-order">Detaljer</div>
            <button
              css={buttonStyling}
              onClick={() => setDetailsOpen(!detailsOpen)}
            >
              {detailsOpen ? (
                <ArrowDropDownCircle transform="rotate(-180)" />
              ) : (
                <ArrowDropDownCircle />
              )}
            </button>
          </div>
        </div>
      </div>
      {false && (
        <SimpleHeader
          text={`${truncateString(
            `${supplier} ${orderNumber}`,
            18
          )} ${normalizeDate(createdDate)}`}
          afterText={<></>}
        >
          <EkViewSwitcher view={view} location={location} />
        </SimpleHeader>
      )}
      <section css={detailStyles}>
        {detailsRow.map((detail) => (
          <DetailsItem key={`key${detail.caption}`} {...detail} />
        ))}
      </section>
      <LayoutStandard scroll={true}>
        <section css={styles}>
          <IncomingOrderListView>
            {data!
              .filter((itm) => itm.quantity > 0)
              .map((incomingItem: InventoryItem, i: number) => (
                <div key={`orderItem${incomingItem._id}`}>
                  <IncomingOrderListItem
                    key={incomingItem._id}
                    {...incomingItem}
                  />
                  {i < storageSpace.items.data!.length - 1 && (
                    <Divider key={`divider${incomingItem._id}`} />
                  )}
                </div>
              ))}
          </IncomingOrderListView>
        </section>
      </LayoutStandard>
      <section css={contextMenuStyles}>
        <Button
          variant={ButtonVariant.Outlined}
          onClick={openBottomSheet}
        >
          <MenuOpen />
        </Button>
      </section>
    </>
  )
}

const IncomingProduct = withQuery<IncomingOrder>({
  query: GET_INCOMING_ORDER,
  loader: EkLoader,
})(
  withMutation<IncomingOrder, IncomingOrderInput>(
    CREATE_INCOMING_ORDER
  )(_IncomingProduct)
)

export { IncomingProduct }
