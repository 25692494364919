import React, { createContext, ReactNode } from 'react'

type BottomSheetContextType = {
  children: ReactNode
  setChildren: (value: ReactNode) => void
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

const BottomSheetContext = createContext<BottomSheetContextType>({
  children: <div></div>,
  setChildren: () => {},
  setIsOpen: () => [],
  isOpen: false,
})

export { BottomSheetContext }
