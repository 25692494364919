/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react'
import {
  navigate,
  RouteComponentProps,
  useLocation,
} from '@reach/router'
import { css } from '@emotion/react'
import { LayoutStandard } from '@layouts'
import { useBottomNav, useSnackbar } from '@hooks'
import { NavItem } from '@context'
import { BarcodeScanner } from '@components/barcode-scanner'
import { Snackbar } from '@pure-components/feedback'
import { AppRoutes } from '@router'
import {
  GET_STORAGE_SPACE_BY_BARCODE,
  REPLACE_STORAGE_SPACE_BARCODE,
} from '@api/storage-space'
import { gql } from '@apollo/client'
import { client } from '@api/client'
import { Modal, useModal } from '@components/modal'
import { ModalInputIncomingOrder } from '@components/pages-components/scan/modal-input-order-number'
import { parse, stringify } from 'query-string'

const Scan = (_: RouteComponentProps) => {
  const location = useLocation()
  const params = parse(location.search)
  //console.log('params:', params)

  const { setActiveItem, height } = useBottomNav()
  //const { setChildren, setIsOpen } = useBottomSheet()
  const { addAlert } = useSnackbar()

  const [resumeScan, setResumeScan] = useState(0)

  const [currentBarcode, setCurrentbarcode] = useState('')

  const { isVisible, closeModal, openModal } = useModal()
  useEffect(() => {
    setActiveItem(NavItem.Scan)
  }, [setActiveItem])

  const styles = css({
    position: 'relative',
    height: `calc(100vh - ${height})`,
    //background: COLORS.gray[100],
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  })

  const checkCodes = async (
    codes: string[]
  ): Promise<{ found: boolean; id: string | null }> => {
    //console.log('codes to test', codes)
    let found = false
    let id = null
    addAlert(<Snackbar text={'Searching...'} />)
    for (const code of codes) {
      const result = await client.query({
        query: gql`
          ${GET_STORAGE_SPACE_BY_BARCODE}
        `,
        variables: { barcode: code },
      })

      if (result?.data?.findStorageSpaceByBarcode?._id) {
        found = true
        id = result.data.findStorageSpaceByBarcode._id
        break
      }
    }
    return { found, id }
  }

  const handleCancelInputModel = () => {
    //console.log('handle cancel input modal')
    setResumeScan(resumeScan + 1)
    closeModal()
  }

  const handleInputModal = async (text: string | null) => {
    //console.log('text from modal: ', text)
    if (text) {
      try {
        const result = await client.mutate({
          mutation: gql`
            ${REPLACE_STORAGE_SPACE_BARCODE}
          `,
          variables: { barcode: text, newBarcode: currentBarcode },
        })

        //console.log('Link result', result)

        if (result?.data?.replaceStorageSpaceBarcode?._id) {
          const id = result.data.replaceStorageSpaceBarcode._id
          //console.log(`${AppRoutes.Storages}/${id}`)
          navigate(`${AppRoutes.Storages}/${id}`)
        } else {
          addAlert(
            <Snackbar
              text={
                'Unable to find storage with the specified ordernumber'
              }
            />
          )
          setResumeScan(resumeScan + 1)
        }
      } catch (e) {
        console.log('apollo error', e)
        setResumeScan(resumeScan + 1)
      }
    } else {
      setResumeScan(resumeScan + 1)
    }
    closeModal()
  }

  const handleSchenkerBarcode = async (code: string) => {
    setCurrentbarcode(code)
    //console.log('handleSchenkerBarcode')
    openModal()
  }

  const navigateToStorage = (storageId: string) => {
    const query = stringify(params)
    //console.log(`${AppRoutes.Storages}/${storageId}?${query}`)
    navigate(`${AppRoutes.Storages}/${storageId}?${query}`)
  }

  const handleBarcodeMatch = async (text: string) => {
    //console.log('raw code', text)
    const clean = text
      .replace(
        /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, //eslint-disable-line
        ''
      )
      .replace(']C', '')
    const codesToTest = [clean, clean.slice(0, -2)]
    if (clean) {
      try {
        const { found, id } = await checkCodes(codesToTest)
        //console.log('found/id', found, id)
        if (found) {
          navigateToStorage(id ?? '')
        } else if (clean.includes('COM')) {
          handleSchenkerBarcode(clean)
          return
        } else {
          addAlert(
            <Snackbar
              text={'Unable to find storage linked to this label'}
            />
          )
          setResumeScan(resumeScan + 1)
        }
      } catch (e) {
        console.log('apollo error', e)
        setResumeScan(resumeScan + 1)
      }
    }
  }

  return (
    <LayoutStandard>
      <Modal
        isVisible={isVisible}
        closeModal={handleCancelInputModel}
        autoHeight={true}
      >
        <ModalInputIncomingOrder closeModal={handleInputModal} />
      </Modal>
      <div css={styles}>
        <BarcodeScanner
          onMatch={handleBarcodeMatch}
          resumeTrigger={resumeScan}
        />
      </div>
    </LayoutStandard>
  )
}

export { Scan }
