import React, { FC } from 'react'
import { css } from '@emotion/react'
import { TEXT_STYLE } from '@styles'

interface WorkingOrdersListItemProps {
  _id?: string | undefined
  description?: string | undefined
  orderNumber?: string | undefined
  createdDate?: string | undefined
  serviceDate?: string | undefined
}

const WorkingOrdersListItem: FC<WorkingOrdersListItemProps> = ({
  _id = '',
  description = '',
  orderNumber = '',
  createdDate = '',
  serviceDate = '',
}) => {
  const listStyle = css([TEXT_STYLE.caption[1]], {
    display: 'grid',
    padding: '6px 24px',
    gap: '0.2em 1.2em',
    gridTemplateColumns: '1fr 1fr 24px',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridTemplateAreas: '"headline address icon" "subline city icon"',
    '.headline': [
      TEXT_STYLE.subtitle['1'],
      {
        gridArea: 'headline',
      },
    ],
    '.subline': {
      gridArea: 'subline',
    },
    '.address': [
      TEXT_STYLE.subtitle['1'],
      {
        gridArea: 'address',
      },
    ],
    '.city': {
      gridArea: 'city',
    },
    '.icon': {
      display: 'grid',
      gridArea: 'icon',
      placeSelf: 'center center',
      placeContent: 'center center',
      placeItems: 'center center',
    },
  })
  return (
    //<A to={`./${_id}`}>
    <article css={listStyle}>
      <p className="headline">{orderNumber}</p>
      <p className="subline">{description}</p>
    </article>
    //</A>
  )
}

export { WorkingOrdersListItem }
