import React, { ReactNode, FC } from 'react'
import { css } from '@emotion/react'
import { TEXT_STYLE } from '@styles'
import { Person, AccountBox } from '@material-ui/icons'
import { A } from '@components/base'
import { AppRoutes } from '@router'

interface UserProps {
  id?: string
  icon?: ReactNode
  firstName?: string
  lastName?: string
  phoneNumber?: string
  admin?: boolean
}

const listStyle = css([TEXT_STYLE.caption[1]], {
  display: 'grid',
  padding: '6px 24px',
  gap: '0.2em 1.2em',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: 'repeat(2, 1fr)',
  gridColumnGap: '1.2em',
  '.headline': [
    TEXT_STYLE.subtitle['1'],
    {
      gridArea: '1 / 2 / 2 / 3',
    },
  ],
  '.subline': {
    gridArea: '2 / 2 / 3 / 3',
  },
  '.icon': {
    gridArea: '1 / 1 / 3 / 2',
    display: 'grid',
    placeSelf: 'center center',
    placeContent: 'center center',
    placeItems: 'center center',
    paddingBottom: '0.5em',
  },
})

const defaultIcon = <Person />
const adminIcon = <AccountBox />

const UserItem: FC<UserProps> = ({
  id = '',
  icon,
  firstName = '',
  lastName = '',
  phoneNumber = '',
  admin = false,
}) => {
  return (
    <A to={`${AppRoutes.Users}/${id}`}>
      <article css={listStyle}>
        <p className="headline">
          {firstName} {lastName}
        </p>
        <p className="subline">{phoneNumber}</p>
        <span className="icon">
          {admin ? adminIcon : defaultIcon}
        </span>
      </article>
    </A>
  )
}

export { UserItem }
