import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { SimpleHeader } from '@components/header'
import { LayoutStandard } from '@layouts'
import {
  ArticleInventoryList,
  ArticleInventoryListItem,
} from '@components/pages-components/article-inventory'
import { STORAGE_SPACE_TYPE } from '@types'
import { css } from '@emotion/react'

interface ArticleInventoryProps extends RouteComponentProps {
  id?: string
}

const ArticleInventory: FC<ArticleInventoryProps> = ({ id }) => {
  const styling = css({
    padding: 0,
  })

  return (
    <>
      <SimpleHeader text={`Lagersaldo [${id}]`} />
      <LayoutStandard>
        <section css={styling}>
          <ArticleInventoryList>
            <ArticleInventoryListItem
              storageSpaceName="Förvaringsplats"
              type={STORAGE_SPACE_TYPE.FORDON}
              quantity={5}
            />
          </ArticleInventoryList>
        </section>
      </LayoutStandard>
    </>
  )
}

export { ArticleInventory }
