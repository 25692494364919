import { createContext } from 'react'

type RadioGroupContextType = {
  value: string
  setValue: (value: string) => void
}

const RadioGroupContext = createContext<RadioGroupContextType>({
  value: '',
  setValue: () => {},
})

export { RadioGroupContext }
