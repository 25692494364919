/* eslint-disable max-lines */
import React, { FC } from 'react'
import { css } from '@emotion/react'
import { COLORS, TEXT_STYLE } from '@styles'
import { getIconByVariant } from './common'
import { StorageSpace } from '@types'
/*import { A } from '@components/base'
import { AppRoutes } from '@router'
import { Delete, Edit } from '@material-ui/icons'*/

interface StorageListStorageItemProps {
  onUpdateAmount?: (quantity: number) => void
  onClick?: () => void
  min?: number
  //onConfirmButtonClick?: (item: Item, quantity: number) => void
}

const StorageListStorageItem: FC<
  StorageSpace & StorageListStorageItemProps
> = ({
  _id = '',
  onClick = () => {},
  name,
  type,
  //onConfirmButtonClick = (item: Item, quantity: number) => {},
  //onDeleteButtonClick = (item: Item, quantity: number) => {},
}) => {
  const listStyle = css([TEXT_STYLE.list[1]], {
    width: '100%',
    color: COLORS.gray[90],
    display: 'grid',
    gridTemplateColumns: '5fr 1fr 1fr',
    gap: '0.5em',
    margin: '6px 0',
    padding: '0 24px',
    gridTemplateAreas:
      '"name amount selectamount" "number amount selectamount"',

    '.name': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'name',
      },
    ],
    '.number': [
      TEXT_STYLE.caption[1],
      {
        gridArea: 'number',
      },
    ],
    '.selectamount': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'selectamount',
        placeSelf: 'center end',
      },
    ],
    '.amount': [
      TEXT_STYLE.subtitle[2],
      {
        gridArea: 'amount',
        placeSelf: 'center end',
      },
    ],
    '.buttons': {
      gridArea: 'buttons',
      placeSelf: 'center end',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '10px',
    },
  })
  return (
    <article css={listStyle} className="row">
      <div className="icon">{getIconByVariant(type)}</div>
      <p
        className="name"
        onClick={onClick}
        onKeyDown={onClick}
        role="presentation"
        tabIndex={-1}
      >
        {name}
      </p>
    </article>
  )
}

export { StorageListStorageItem }
