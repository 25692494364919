import {
  ApolloClient,
  ApolloClientOptions,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getAccessTokenFromStore } from '@common/local-store'
import { replaceDomain } from '@common/replace-domain'

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAccessTokenFromStore()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const getApolloClientOptions = (
  env: NodeJS.ProcessEnv
): ApolloClientOptions<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    uri: replaceDomain(
      env.REACT_APP_API_URL || '',
      window.location.host
    ),
  })

  return {
    cache: new InMemoryCache(),
    defaultOptions,
    headers: {},
    link: authLink.concat(httpLink),
  }
}

const getApolloClient = (
  env: NodeJS.ProcessEnv
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient(getApolloClientOptions(env))
}

const client = getApolloClient(process.env)

export { client, getApolloClient }
