import { signOut } from '@common/sign-out'
import { useBottomSheet, useLogin } from '@hooks'
import { RouteComponentProps } from '@reach/router'
import React, { useEffect } from 'react'

const Logout = (_: RouteComponentProps) => {
  const { setIsOpen } = useBottomSheet()
  const { setIsLoggedIn } = useLogin()

  useEffect(() => {
    setIsOpen(false)
    setIsLoggedIn(false)
    signOut()
  })
  return <></>
}

export { Logout }
