import { InvItemStub, ItemReport, ItemReportEntry } from '@types'

const reduceToReport = (invItems: InvItemStub[]): ItemReport => {
  const data = invItems.reduce(
    (acc: { [key: string]: ItemReportEntry }, inv: InvItemStub) => {
      if (!inv.item) {
        return acc
      }

      const itemId = inv.item!.id!

      const exists = acc[itemId]
      if (exists) {
        Object.assign(acc, {
          [itemId]: {
            id: itemId,
            quantity: (inv.quantity || 0) + exists.quantity!,
            name: inv.item.name,
            number: inv.item.number,
            supplier: inv.item.supplier,
            unit: inv.item.unitOfMeasure,
            balanceWarning: inv.item.balanceWarning,
            storages: [...exists.storages!, inv.storageSpace],
          },
        })
      } else {
        Object.assign(acc, {
          [itemId]: {
            id: itemId,
            quantity: inv.quantity,
            name: inv.item.name,
            number: inv.item.number,
            supplier: inv.item.supplier,
            unit: inv.item.unitOfMeasure,
            balanceWarning: inv.item.balanceWarning,
            storages: [inv.storageSpace],
          },
        })
      }
      return acc
    },
    {}
  )
  return { data: Object.values(data) }
}

export { reduceToReport }
