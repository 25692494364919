import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { LayoutStandard } from '@layouts'
import { SimpleHeader } from '@src/components/header'
import { FaqList } from '@components/pages-components/help'

const Help = (_: RouteComponentProps) => {
  return (
    <>
      <SimpleHeader text="Vanliga frågor" />
      <LayoutStandard>
        <FaqList />
      </LayoutStandard>
    </>
  )
}

export { Help }
