import styled from '@emotion/styled'
import { COLORS } from '@styles'
import { TEXT_STYLE } from '@styles'

const P1 = styled.p(
  TEXT_STYLE.body[1],
  ({ color = COLORS.gray[100] }) => ({ color })
)

const P2 = styled.p(
  TEXT_STYLE.body[2],
  ({ color = COLORS.gray[100] }) => ({ color })
)

export { P1, P2 }
