import { useState, useRef, useEffect } from 'react'

interface UseMaxHeightProps {
  isOpen: boolean
}

const useMaxHeight = ({ isOpen = false }: UseMaxHeightProps) => {
  const [maxHeight, setMaxHeight] = useState(0)

  const contentElement = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentElement && contentElement.current) {
      isOpen
        ? setMaxHeight(contentElement.current.scrollHeight)
        : setMaxHeight(0)
    }
  }, [isOpen])

  return {
    maxHeight,
    contentElement,
  }
}

export { useMaxHeight }
