import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { InsertPhoto } from '@material-ui/icons'
import { COLORS, TEXT_STYLE } from '@styles'
import { A } from '@components/base'

interface HomeCardProps {
  icon?: ReactNode
  label?: string
  url?: string
}

const HomeCard: FC<HomeCardProps> = ({
  label = 'label',
  icon = <InsertPhoto />,
  url = '',
}) => {
  const styling = css({
    width: 100,
    height: 100,
    color: COLORS.gray[90],
    borderRadius: 5,
    position: 'relative',
    display: 'grid',
    gridTemplateRows: 'auto auto',
    placeItems: 'center center',
    background: COLORS.orange[10],
    ':hover:after': {
      boxShadow: '0.125em 0.125em 0.375em 0 rgba(0,0,0,0.5)',
    },
    '::after': {
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: 5,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      boxShadow: '0.125em 0.125em 0.375em 0 rgba(0,0,0,0.15)',
    },
    '.cardpart': {
      display: 'block',
      width: '100px',
    },
    '.icon': {
      display: 'grid',
      placeContent: 'end center',
      fontSize: '30px',
      minHeight: '50px',
    },
    '.label': [
      TEXT_STYLE.caption[1],
      {
        textAlign: 'center',
        padding: 6,
        minHeight: '50px',
      },
    ],
  })
  return (
    <A to={url}>
      <div css={styling}>
        <div className="cardpart icon">{icon}</div>
        <p className="cardpart label">{label}</p>
      </div>
    </A>
  )
}

export { HomeCard }
