import React, { ComponentType, FC } from 'react'
import {
  ApolloError,
  DocumentNode,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'
import { CircularSpinnerLoader } from '@pure-components/feedback'
import { ShadowOverlay } from '@pure-components/surface'
import { Modal, useModal } from '@components/modal'

interface WithMutationExtension<ResultData, MutationInput> {
  addData: (
    options?:
      | MutationFunctionOptions<ResultData, MutationInput>
      | undefined
  ) => Promise<
    FetchResult<ResultData, Record<string, any>, Record<string, any>>
  >
  mutationData: any
  mutationError: ApolloError | undefined
}

const withMutation = <
  ResultType,
  MutationVariables extends OperationVariables
>(
  mutation: DocumentNode
) => <ComponentProps extends object>(
  Component: ComponentType<ComponentProps>
): FC<
  Omit<ComponentProps, 'addData' | 'mutationError' | 'mutationData'>
> => ({ ...props }) => {
  const [
    addData,
    { data: mutationData, loading, error },
  ] = useMutation<ResultType, MutationVariables>(mutation)
  const mutationState = {
    addData,
    mutationData,
    mutationError: error,
  }

  const { isVisible, closeModal, openModal } = useModal()

  if (error) {
    openModal()
    console.error(error)
  }

  const _props = {
    ...(props as ComponentProps),
    ...mutationState,
  }

  return (
    <>
      <Component {..._props} />
      {loading && (
        <ShadowOverlay>
          <CircularSpinnerLoader />
        </ShadowOverlay>
      )}
      <Modal isVisible={isVisible} closeModal={closeModal}>
        Datan kunde inte sparas.
      </Modal>
    </>
  )
}

export { withMutation }
export type { WithMutationExtension }

/*

1. Skicka en mutation 
2. Visa någon slags loading
3. Kör en with-Mutation eller nåt för att få den nya datan

*/
