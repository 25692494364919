import { truncateString } from '@common'
import { css } from '@emotion/react'
import { Card } from '@pure-components/surface'
import { TEXT_STYLE } from '@styles'
import { Item, Order } from '@types'
import React, { FC, ReactNode } from 'react'

interface EkCardWorkOrderProps {
  children?: ReactNode
  _id: string
  item: Item
  quantity: number
  order: Order
  imageHeight?: number
}

const EkCardWorkOrder: FC<EkCardWorkOrderProps> = ({
  children = <></>,
  _id = '',
  item = {},
  quantity = '',
  imageHeight = 200,
}) => {
  const imageStyle = css({
    width: '100%',
    height: imageHeight ?? 200, //Känns som både bälte och hängslen, men det borde fasiken inte bli fel :)
    objectFit: 'cover',
  })
  const cardStyle = css({
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gap: '1em',
    padding: '1em',
    gridTemplateAreas:
      '"item-name item-amount" "item-number item-number"',
    overflowWrap: 'break-word',
    '.item-name': [
      TEXT_STYLE.subtitle['1'],
      {
        gridArea: 'item-name',
      },
    ],
    '.item-number': [
      TEXT_STYLE.caption['1'],
      {
        gridArea: 'item-number',
      },
    ],
    '.item-amount': [
      TEXT_STYLE.subtitle['1'],
      {
        gridArea: 'item-amount',
        textAlign: 'right',
      },
    ],
  })
  return (
    <Card>
      <img css={imageStyle} src={item.defaultImage} alt={_id} />
      <article css={cardStyle}>
        <p className="item-name">{truncateString(item.name, 24)}</p>
        <p className="item-number">{item.number}</p>
        <p className="item-amount">{quantity}</p>
      </article>
    </Card>
  )
}

export { EkCardWorkOrder }
