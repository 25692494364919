import React, { FC } from 'react'
import { css } from '@emotion/react'
import { COLORS } from '@styles'

interface DividerProps {
  color?: string
  width?: string
  /** Height in pixels */
  height?: string
  /** Number of columns to span in a grid layout */
  columns?: number
}

const Divider: FC<DividerProps> = ({
  color = COLORS.gray[30],
  width = '100%',
  height = 1,
  columns = 1,
}) => {
  const styling = css({
    backgroundColor: color,
    width,
    height,
    gridColumn: `span ${columns}`,
  })
  return <div css={styling}>&nbsp;</div>
}

export { Divider }
