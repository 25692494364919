import { gql } from '@apollo/client'

const CREATE_FAQ = gql`
  mutation CreateFaq($question: String!, $answer: String!) {
    createFaq(data: { question: $question, answer: $answer }) {
      _id

      question
      answer
    }
  }
`

export { CREATE_FAQ }
