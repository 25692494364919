import React, { FC, ReactNode } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { COLORS, TEXT_STYLE } from '@styles'

interface AvatarProps {
  content?: ReactNode
  size?: string
  color?: string
  backgroundColor?: string
  borderColor?: string
  borderWidth?: string
  padding?: string
  style?: SerializedStyles | SerializedStyles[]
}

const Avatar: FC<AvatarProps> = ({
  content = null,
  size = '24px',
  color = COLORS.gray[10],
  backgroundColor = COLORS.gray[90],
  borderColor = COLORS.gray[10],
  borderWidth = '0px',
  padding = '18px',
  style = css(),
}) => {
  const styling = css([TEXT_STYLE.overline[1]], {
    width: size,
    height: size,
    borderRadius: 9999,
    color,
    backgroundColor,
    border: `${borderWidth} solid ${borderColor}`,
    padding,
    display: 'grid',
    placeContent: 'center center',
    placeSelf: 'center center',
    placeItems: 'center center',
    textAlign: 'center',
  })
  return <div css={[styling, style]}>{content}</div>
}

export { Avatar }
export type { AvatarProps }
