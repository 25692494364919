import React, { ReactNode, useState } from 'react'
import { LoginContext } from './login-context'

type LoginProviderProps = {
  children: ReactNode
}

const LoginProvider = ({ children = [] }: LoginProviderProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  return (
    <LoginContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

export { LoginProvider }
